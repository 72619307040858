.blog-page {
	.columns {
		display: flex;

		.column.main {
			flex: 1;
		}
	}

	.sidebar {
		display: flex;
		float: none;
		justify-content: space-between;
		margin-top: $ten-gutter;
		padding: 0;
		width: 100%;

		@include min-screen($screen__m) {
			margin: 0 auto;
			max-width: rem(730);
			padding-top: rem(19);
		}

		.widget,
		.block {
			flex-basis: calc((100% - #{$fifteen-gutter}) / 2);

			@include min-screen($screen__m) {
				flex-basis: calc((100% - #{$twenty-gutter}) / 2);
			}
		}

		.blog-search {
			order: 1;

			.form {
				position: relative;
			}

			.label {
				display: none;
			}

			.input-text {
				background-color: $white;
				border: none;
				border-radius: 3px;
				height: rem(40);
				padding: $ten-gutter rem(35) $ten-gutter $ten-gutter;

				@include min-screen($screen__m) {
					padding: $ten-gutter $fifty-gutter $ten-gutter $fifteen-gutter;
				}

				&,
				&::placeholder {
					@include type__13($line-height: rem(20));
					font-style: normal;
					text-transform: uppercase;

					@include min-screen($screen__m) {
						@include type__14(
							$font-weight: $font-weight-semibold,
							$line-height: rem(20)
						);
					}
				}

				&::placeholder {
					opacity: 1;
				}
			}

			.action.search {
				@include svg-background(
					$name: "search-icon",
					$size: contain
				);
				background-color: unset;
				border: none;
				bottom: 0;
				display: block;
				height: rem(20);
				margin: auto;
				padding: 0;
				position: absolute;
				right: $ten-gutter;
				top: 0;
				width: rem(20);

				@include min-screen($screen__m) {
					right: $fifteen-gutter;
				}

				span {
					display: none;
				}
			}
		}

		.block-categories {
			position: relative;
			text-align: left;

			.block-title {
				@include type__13($line-height: rem(20));
				background-color: $white;
				border: none;
				border-radius: 3px;
				font-style: normal;
				height: rem(40);
				padding: $ten-gutter rem(35) $ten-gutter $ten-gutter;
				position: relative;
				text-transform: uppercase;

				@include min-screen($screen__m) {
					@include type__14(
						$font-weight: $font-weight-semibold,
						$line-height: rem(20)
					);
					padding: $ten-gutter $fifty-gutter $ten-gutter $fifteen-gutter;
				}

				&:after {
					@include svg-background(
						$name: "arrow-down",
						$size: contain
					);
					bottom: 0;
					content: "";
					display: block;
					height: rem(9);
					margin: auto;
					position: absolute;
					right: $ten-gutter;
					top: 0;
					width: rem(15);

					@include min-screen($screen__m) {
						right: $fifteen-gutter;
					}
				}

				strong {
					font-weight: $font-weight-semibold;
				}
			}

			.accordion {
				@include transition(all 0.4s ease-in-out);
				background-color: $white;
				border: none;
				border-radius: 3px;
				max-height: 0;
				opacity: 0;
				overflow: hidden;
				padding: $ten-gutter;
				pointer-events: none;
				position: absolute;
				top: rem(40);
				width: 100%;

				@include min-screen($screen__m) {
					padding: $ten-gutter $fifteen-gutter;
				}

				li {
					a {
						@include type__13($line-height: rem(20));

						@include min-screen($screen__m) {
							@include type__14(
								$font-weight: $font-weight-semibold,
								$line-height: rem(20)
							);
						}
					}

					&:not(:last-of-type) {
						margin-bottom: $ten-gutter;
					}
				}
			}

			&.active {
				.block-title {
					border-radius: 3px 3px 0 0;

					&:after {
						@include svg-background(
							$name: "arrow-up",
							$size: null
						);
					}
				}

				.accordion {
					border-radius: 0 0 3px 3px;
					box-shadow: 0 2px 5px rgba($color: $black, $alpha: 0.13);
					max-height: rem(1000);
					opacity: 1;
					pointer-events: all;
					z-index: 1;
				}
			}
		}
	}

	.post-info {
		.post-author {
			@extend %visuallyhidden;
		}

		.post-posed-date {
			@extend %visuallyhidden;

			~ .post-categories {
				.label {
					@extend %visuallyhidden;
				}

				a {
					@include type__16 ($font-weight: $font-weight-semibold);
					margin-bottom: rem(11);
				}
			}
		}
	}
}
