/* -------------------------------------------------
 * -------------------------------------------------
 * Customer account dropdown
 *
 * -------------------------------------------------
 * @author Fisheye
 */

.customer-welcome,
.header-link,
.block-search {
	// the first li in header links
	display: flex;
	height: 100%;

	&:hover {
		cursor: pointer;
	}

	.customer-name,
	.header-link-button,
	.search-toggle {
		// span containing account button
		display: flex; // needed as the button is this span then in an li and a ul so vertical aligning will not work any other way
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
		position: relative;

		&.active {
			@if $dropdown-enable-triangle == true {
				&:before {
					@include css-triangle(
						$triangle-size: 7.5px,
						$triangle-color: $dropdown-arrow-colour,
						$triangle-direction: bottom
					);

					@include max-screen($screen__ml - 1) {
						bottom: -13px;
					}

					@include min-screen($screen__ml) {
						bottom: -16px;
					}
				}
			}
		}

		button {
			display: block;
			opacity: 1;
			padding: 0;
			line-height: inherit;
			border: 0;
			background: none;

			&:hover,
			&:focus {
				border: none;
				outline: 0;
				background: none;
			}

			svg {
				margin: 0 auto;
			}
		}
	}

	.header.links {
		display: flex;
		flex-direction: column;

		.authorization-link {
			order: 8;
		}

		li {
			margin: $account-navigation-item-margin;

			a {
				@include type__16;
				align-items: center;
				display: flex;

				&:before {
					@include icon__arrow-black;
					margin-right: $ten-gutter;
					height: 10px;
					width: 10px;
				}
			}
		}

		li span {
			display: block !important; // Important to override inline style from pagebuilder
			padding-top: $dropdown-list-spacing;
			@include type__16;
			text-transform: capitalize;

			&:first-child {
				order: 8;
			}
		}
	}

	// actual svg to open menu
	.account-open,
	.header-link-open {
		display: block;
	}

	.help-center-open {
		.st0 {
			fill: none;
		}
	}

	// actual svg to close menu
	.account-close,
	.header-link-close {
		display: none;

		.st0 {
			// not texhnically a hover but swaps to this when interacted with so close enough...
			fill: $svg-icon-color-hover;
		}
	}

	&.active {
		z-index: 150;
		background-color: $header-links-active-background; // actual svg to open menu
	}

	&.active {
		.customer-menu {
			display: block;
		}
	}
}

.customer-welcome {
	display: none;

	@include min-screen($screen__m) {
		display: flex;
	}
}

.customer-help-center {
	display: none;
	margin-right: 0;

	@include min-screen($screen__m) {
		display: flex;
		margin-right: $twenty-gutter;
	}

	@include min-screen($screen__ll) {
		margin-right: $forty-gutter;
	}
}

.header-phone-link-title {
	display: none;

	@include min-screen($screen__m) {
		display: block;
	}
}
