/* -------------------------------------------------
 * -------------------------------------------------
 * Cart Promotion & help area
 * -------------------------------------------------
 * @author Fisheye
 */

.checkout-cart-index {
	// -------------------------------------------------
	// Basket discount, giftcards, gift messages
	// -------------------------------------------------

	.block {
		&.discount,
		&.giftcard {
			border-bottom: $basket-promo-border;

			.title {
				padding: $basket-promo-spacing-vertical $twenty-gutter $basket-promo-spacing-vertical $basket-promo-spacing-horizontal;
				position: relative;
				cursor: pointer;
	
				strong {
					@include text-crop;
					@include type__14(
						$color: $basket-promo-color,
						$font-weight: $font-weight-semibold
					);
					text-transform: uppercase;
				}
	
				span {
					display: none;
				}
	
				&:after {
					content: "";
					height: $basket-promo-icon-size;
					width: $basket-promo-icon-size;
					margin: auto;
					position: absolute;
					top: 0;
					bottom: 0;
					right: 0;
					@include svg-background(
						$name: "plus", 
						$size: contain
					);
				}
			}
	
			.content {
				padding-bottom: $twenty-gutter;

				.fieldset {
					position: relative;

					.label {
						@extend %visuallyhidden;
					}

					.field {
						margin-bottom: 0;

						.control {
							flex: 0 1 100%;

							.input-text {
								padding: rem(13) $twenty-gutter $fifteen-gutter;

								&::placeholder {
									@include type__16($font-weight: $font-weight-normal);
									text-transform: inherit;
								}

								@include min-screen($screen__m) {
									padding: $ten-gutter $forty-gutter $fifteen-gutter $fifteen-gutter;
								}
							}
						}
					}

					.actions-toolbar {
						height: 100%;
						width: rem(40);
						position: absolute;
						top: 0;
						bottom: 0;
						right: 0;
						max-height: rem(47);

						.primary {
							height: 100%;
							display: flex;
							align-items: center;
							justify-content: center;

							button {
								height: 14px;
								width: 14px;
								padding: 0;
								background-color: transparent;
								@include svg-background(
									$name: "arrow-right-mine-shaft",
									$size: auto 100%
								);
								border: none;
								border-radius: 0;
								font-size: 0;
							}
						}

						.secondary {
							display: none;
						}
					}
				}
			}
	
			&.active {
				.title {
					&:after {
						@include svg-background('minus');
					}
				}
			}
		}

		&.discount {
			@include min-screen($screen__m) {
				padding-right: $twenty-gutter;
				border-right: $basket-promo-border;
			}
		}
	
		&.giftcard {
			@include min-screen($screen__m) {
				padding-left: $twenty-gutter;
			}
		}
	}

	.gift-services-container {
		@include max-screen($screen__ml - 1) {
			margin-top: $fifteen-gutter;
		}

		.gift-item-block {
			.title {
				@include button__default;
				width: 100%;

				strong {
					padding-left: $forty-gutter;
					position: relative;
					font-weight: $font-weight-semibold;

					&:before {
						content: "";
						display: block;
						height: rem(21);
						width: rem(34);
						@include svg-background(
							$name: "gift-icon-mine-shaft",
							$size: contain
						);
						margin: auto;
						position: absolute;
						top: 0;
						bottom: 0;
						left: 0;
					}
				}

				&:hover,
				&:focus {
					strong {
						&:before {
							@include svg-background(
								$name: "gift-icon-white",
								$size: null
							);
						}
					}
				}
			}
		}
	}
}
