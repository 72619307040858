/* -------------------------------------------------
 * -------------------------------------------------
 * Company Profile
 *
 * -------------------------------------------------
 * @author Fisheye
 */

.company-profile-index {
	.page-main {
		.page-title-wrapper {
			.edit-company-profile {
				@include type__13($color: $white);
				border-bottom: 1px solid $white;
			}
		}

		.columns {
			.column.main {
				.block {
					padding: $twenty-gutter 0;

					&.block-company-profile-account {
						padding-top: 0;
					}

					&.block-company-profile-contacts {
						.block-content {
							display: flex;
							flex-wrap: wrap;

							.box {
								flex: 0 1 100%;
								padding-bottom: $twenty-gutter;

								@include min-screen($screen__xs) {
									flex: 0 1 50%;
									padding-bottom: 0;
								}

								.box-title {
									@include type__14(
										$color: $body-font-color
									);
									display: block;
									margin-bottom: $twenty-gutter;
								}
							}
						}
					}

					&.block-company-profile-payment-methods {
						.block-content {
							.company-profile-payment-methods {
								display: flex;
								flex-wrap: wrap;

								@include min-screen($screen__xs) {
									flex-wrap: nowrap;
								}

								.payment-methods-label {
									@include type__14(
										$color: $body-font-color
									);
									flex: 0 1 100%;
									white-space: nowrap; /* don't break on to a new line */
									padding-bottom: $twenty-gutter;

									@include min-screen($screen__xs) {
										flex: 0 1 auto;
										padding-bottom: 0;
									}
								}

								.payment-methods-list {
									flex: 0 1 100%;

									@include min-screen($screen__xs) {
										padding-left: $row-gutter;
									}

									li {
										@include type__13(
											$color: $body-font-color
										)
									}
								}
							}
						}
					}

					.block-title {
						margin-bottom: rem(23);
					}

					.box-content,
					.box-content p {
						@include type__13(
							$color: $body-font-color
						)
					}
				}
			}
		}
	}
}

/* Company Profile edit*/
.company-profile-edit {
	.legend {
		span {
			@include type__13();
		}
	}

	.form-edit-company {
		.fieldset.info {
			.field.street {
				margin-bottom: $twenty-gutter;
			}
		}
	}
}
