/* -------------------------------------------------
 * -------------------------------------------------
 * The minicart in the header.
 * -------------------------------------------------
 * @author Fisheye
 */

.header.content {
	.minicart-wrapper {
		position: initial; // overwrite dropdowns in mage ui
		height: 100%;
		order: 4;

		.block-minicart {
			width: 100%;
			transform: translateY(10px);
			overflow-y: auto;
			// Panel Wrapper height and mobile browsers header which is included in vh calculations
			max-height: calc(100vh - (#{$header-mobile-content-height} + 60px));

			@include min-screen($screen__m) {
				// Set Max height for popup to be 100vh - (header height + panel wrapper height)
				// Panel Wrapper height made up of font-size + top & bottom margin
				max-height: calc(100vh - (#{$header-desktop-content-height} + #{type-scale(13) + (2 * $five-gutter)}));
				transform: none;
				right: 30px;
				width: em(476);
				position: fixed;
				top: calc(#{$header-desktop-content-height} + #{rem(28)});
			}
		}

		.block-title {
			background: $header-background;
			border-bottom: 1px solid $gallery;
			flex-flow: row wrap;
			position: sticky;
			top: 0;
			z-index: 102;

			strong {
				position: relative;
				display: flex;
				align-items: center;

				&:before {
					content: "";
					display: inline-block;
					height: 23px;
					width: 23px;
					margin-right: rem(8);

					@include svg-background(
						$name: 'minicart-icon',
						$size: 23px 23px
					);
				}

				span {
					@include type__17($letter-spacing: 1px);
					text-transform: uppercase;
				}
			}

			.action {
				&.close {
					span {
						@include type__18;

						> span:after {
							content: '';
							display: inline-block;
							height: 10px;
							width: 10px;
							margin-left: rem(6);

							@include svg-background(
								$name: 'close-icon-black',
							);
						}
					}
				}
			}
		}

		div.basket-delivery-upsell {
			background: 0;
			border-bottom: 1px solid $gallery;
			padding: rem(18) rem(13) $twenty-gutter;

			@include min-screen($screen__m) {
				padding: rem(9) $twenty-gutter;
			}

			.upsell-message,
			.upsell-message-alt {
				@include type__17($letter-spacing: 2px);
				text-transform: uppercase;
			}
		}

		.sign-in-link {
			width: 100%;
			margin-top: rem(11);

			a {
				@include type__15($font-weight: $font-weight-normal);

				@include min-screen($screen__m) {
					@include type__16($font-weight: $font-weight-normal);
				}

				&:before {
					@include icon__arrow-black;
					margin-right: rem(8);
				}
			}
		}

		.details-qty {
			flex: 0 1 auto;
			position: relative;
			margin-top: rem(12);

			label {
				display: $minibasket-qty-label-display;
			}

			.qty-increments-wrapper {
				display: flex;
			}

			input {
				@include input-style-five;
				line-height: 0;
			}

			.product-item-pricing {
				flex: 0 1 45%;
			}
		}

		.minicart-items-wrapper .product-item-details .actions {
			background: transparent;
			position: absolute;
			top: rem(2);
			right: 0;

			.primary {
				display: none;
			}
		}

		.giftwrap-wrapper {
			.action.giftwrap {
				@include button__default($padding: 0);
				display: flex !important; // Override inline
				justify-content: center !important; // Override inline
				align-items: center !important; // Override inline
				width: 100%;
				min-height: auto;
				margin-bottom: $fifteen-gutter;
				height: rem(42);

				@include min-screen($screen__m) {
					height: rem(37);
					margin-bottom: $twenty-gutter;
				}

				&:hover,
				&:focus,
				&:active {
					&:before {
						@include svg-background('gift-icon-white');
					}
				}

				&:before {
					content: '';
					display: inline-block;
					height: 21px;
					width: 36px;
					margin-right: rem(7);

					@include svg-background(
						$name: 'giftwrap-icon',
						$size: 100% 100%
					);
				}
			}
		}

		.actions {
			background-color: $gallery;
			padding-bottom: $twenty-gutter;

			.primary {
				span {
					@include type__18(
						$font-weight: $font-weight-normal,
						$color: $white
					);
				}

				.express-checkout-title,
				.express-checkout-title span {
					@include type__18(
						$font-weight: $font-weight-normal,
						$font-family: $font-family-serif,
						$color: $body-font-color
					);
					font-style: italic;
				}
			}

			.secondary {
				margin-left: auto; // forces the close icon to the right

				.delete {
					position: relative;
					text-transform: uppercase;
					display: flex;
					align-items: center;

					@include type__12(
						$font-weight: $font-weight-normal,
						$line-height: 1
					);

					&:before {
						content: '';
						display: inline-block;
						height: 17px;
						width: 17px;
						margin-right: rem(6);

						@include svg-background(
							$name: 'bin-icon',
							$size: 17px 17px
						);
					}
				}
			}
		}

		// When the minicart is open this class is added to the minicart-wrapper via javascript
		&.active {
			z-index: 150;
			background-color: $header-links-active-background; // swaps the open and close icons
		}
	}

	.subtotal {
		display: flex;
		padding: $twenty-gutter 0;
		background: $gallery;

		@include min-screen($screen__m) {
			padding: $minicart-subtotal-spacing;
		}

		.label {
			flex: 0 1 auto;
			@include type__17($font-weight: $font-weight-normal);
			text-transform: uppercase;
		}

		.amount {
			flex: 0 1 auto;
			text-align: right;
			margin-left: auto;

			.price {
				@include type__17($font-weight: $font-weight-semibold);
			}
		}
	}

	.action.showcart {
		// span containing basket button
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		justify-content: center;
		margin: 0 auto;
		position: relative;

		@include min-screen($screen__m) {
			flex-direction: column;
		}

		&:before {
			display: none;
		}

		&:hover,
		&:focus {
			opacity: 1;
		}

		&.active {
			@if $dropdown-enable-triangle == true {
				&:before {
					@include css-triangle(
						$triangle-size: 7px,
						$triangle-color: $dropdown-arrow-colour,
						$triangle-direction: bottom
					);
					transform: translateY(10px);

					@include min-screen($screen__m) {
						transform: none;
						bottom: -11px;
					}

					@include min-screen($screen__ml) {
						bottom: -15px;
					}
				}
			}
		}

		.minicart-title {
			display: none;

			@include min-screen($screen__m) {
				display: block;
				text-align: center;
				padding-right: calc(#{$five-gutter} + #{$minicart-count-bubble-width-tablet});
			}
		}

		.minicart-subtotal {
			display: none;
		}
	}

	.basket-open {
		// svg container
		display: block;
	}

	.basket-close {
		// svg container
		display: none;

		.st0 {
			// not texhnically a hover but swaps to this when interacted with so close enough...
			fill: $header-icons-color;
		}
	}
}

/* -----------------------------------------------
 * main block containing ol.minicart-items of items
 */

// Item listing
.minicart-items-wrapper {
	width: 100%;
	height: auto !important; //JS height applied but not needed and breaks responsiveness
	direction: rtl; // moves the scroll bar to the left so delete option can be selected
	padding: 0; // needed for flush border;

	.minicart-items {
		direction: ltr; //reset the direction of each item
	}

	.product-item {
		border-top: 1px solid $gallery;
		position: relative;

		@include min-screen($screen__m) {
			margin: 0 #{-$twenty-gutter}; // Negative margin to pull border out without impacting styling for all dropdown elements
			padding: 0 $twenty-gutter;
		}

		&.updating,
		&.removing {
			&::after {
				@extend %element-loader;
				content: '';
			}

			> .product {
				opacity: 0.2;
				pointer-events: none;
			}
		}

		> .product { // the chevron ensures that these styles don't get appended further down the dom by accident on items such as `product options`
			display: flex;
			align-items: center;
			flex-direction: row;
			padding: rem(17) 0;

			@include min-screen($screen__m) {
				padding: $minicart-item-spacing;
			}

			.options,
			.minicart-options {
				flex-direction: column;
				padding: rem(9) 0 0 0;
				flex: 1 1 100%;
				flex-flow: column;
				align-items: flex-start;

				@include min-screen($screen__m) {
					padding: rem(6) 0 0 0;
				}

				&.pinned {
					flex: 0 1 auto;
					padding: 0 $twenty-gutter 0 0;

					.values {
						span {
							@include type__16($font-weight: $font-weight-semibold);
						}
					}
				}

				span {
					@include type__16($font-weight: $font-weight-semibold);
					cursor: pointer;
				}

				.bundle-label {
					@include type__14($font-weight: $font-weight-semibold);
					margin-bottom: $five-gutter;
				}

				.content {
					.subtitle {
						@extend %visuallyhidden;
					}
				}

				.values,
				.bundle-value {
					&:not(:last-child) {
						margin-bottom: $item-gutter;
					}

					// I have no meaningful class so > needed to ensure styles do not get applied to child spans
					> span {
						width: 100%;
						display: flex;
						flex-direction: column;

						.price {
							@include type__14($font-weight: $font-weight-semibold);
							margin-left: auto;
						}
					}
				}

				.show-options,
				.hide-options {
					@include type__13($font-weight: $font-weight-bold);
					cursor: pointer;
				}

				.hide-options {
					display: none;
				}

				&:last-child {
					margin-bottom: 0;
				}

				&.active {
					.show-options {
						display: none;
					}

					.hide-options {
						display: block;
					}
				}
			}
		}

		.product-image-container {
			display: block;
			margin-right: $minicart-image-spacing;
			max-width: rem(78);

			@include min-screen($screen__m) {
				max-width: rem(98);
			}

			span {
				padding-bottom: 0 !important; /* override inline styles from JS */
			}
		}

		.product-item-photo {
			flex: 0 0 auto;
			max-width: 25%;
			margin-right: rem(4);

			@include min-screen($screen__m) {
				margin-right: rem(9);
			}
		}

		.product-item-details {
			display: flex;
			flex-flow: row wrap;
			align-items: center;
			flex: 1;
			margin: $minicart-product-item-details-margin;
			position: relative;

			.aw-sarp2-subscription-label {
				@extend %visuallyhidden;
			}
		}

		&:first-child {
			border: 0;
		}

		.product-item-inner {
			display: flex;
			justify-content: space-between;
			align-items: flex-end;
		}
	}

	.product-item-details {
		.product-item-name {
			@include type__16($font-weight: $font-weight-normal);
			flex: 1 1 100%;
			padding-right: 100px;
		}

		.product-item-pricing {
			align-items: center;
			display: flex;
			justify-content: flex-end;
			margin-top: $item-gutter;
			flex: 0 1 auto;

			.price-container {
				display: flex;
				flex: 1;
				justify-content: flex-end;
				order: 1;
			}
		}

		.details-qty {
			display: flex;
			flex-flow: row wrap;
			margin-left: auto;
			padding-right: $twenty-gutter;

			span {
				@include type__16;
			}

			.value {
				font-weight: $font-weight-semibold;
			}

			.update-cart-item {
				@include button__default($padding: $five-gutter $ten-gutter);
				position: relative;
				margin-left: $twenty-gutter;
			}
		}

		.actions {
			text-align: right;
			margin-left: $ten-gutter;

			@include min-screen($screen__m) {
				margin-left: $gutter;
			}

			>.primary,
			>.secondary {
				display: inline;
			}
		}
	}
}


/* -----------------------------------------------
 * Subtotal block
 */

.subtotal {
	.label {
		max-width: em(240); // Max-width needed here to move 'Order Total' text onto two lines on mobile.
		// Cannot be done to the subtotal container otherwise subtotal amount will move
		&:after {
			display: none;
		}

		@include min-screen($screen__m) {
			max-width: initial; // cancel out max-width not needed on desktop
		}
	}
}


/* -----------------------------------------------
 * All actions container, including go to checkout,
 * view edit basket, checkout with paypal
 */

.actions {
	&.primary {
		border-bottom: 1px solid $mercury;
	}

	.primary {
		.action.viewcart {
			@include button__journey;
			width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			text-align: center;
		}
	}

	.minicart-extra-actions {
		@extend %visuallyhidden;
	}
}

.express-checkout {
	border-top: 1px solid $white;
	flex: 0 1 100%;
	margin: rem(18) rem(-18) 0;
	padding: rem(18) 0 rem(16);
	text-align: center;

	> * {
		padding: 0 rem(18);
	}

	.express-checkout-title {
		padding-bottom: rem(18);
		display: block;
	}

	.express-checkout-title,
	.express-checkout-title > span {
		@include type__19(
			$font-family: $font-family-serif,
			$letter-spacing: 1px
		);
		font-style: italic;
	}

	.extra-actions-text {
		display: block;
		padding-bottom: $minicart-extra-action-spacing;
	}

	.extra-actions-container { // paypal options container
		display: flex;
		align-items: center;
		background-color: $minicart-paypal-container-background-color;
	}

	.paypal {
		flex: 0 1 100%;

		.zoid-outlet {
			width: 100% !important;
			z-index: 9; // Less than overlays and the sticky header
		}
	}
}
