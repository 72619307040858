/* new */
.checkout-footer {
	margin-top: auto;

	.store-information-wrapper {
		padding: rem(31) $fifteen-gutter;
		background-color: $gallery;
		text-align: center;
	
		@include min-screen($screen__m) {
			padding: rem(47) $gutter;
		}
	
		h2 {
			margin-bottom: rem(11);

			@include min-screen($screen__m) {
				margin-bottom: rem(8);
			}
	
			@include type__28(
				$color: $body-font-color,
				$letter-spacing: 1px
			);
	
			@include min-screen($screen__ml) {
				@include type__35(
					$font-family: null,
					$letter-spacing: 1px
				);
			}
	
			span {
				font-style: italic;
			}
	
			~ span {
				@include type__16($letter-spacing: 1px);
				text-transform: uppercase;
		
				@include min-screen($screen__ml) {
					@include type__22(
						$font-weight: $font-weight-normal,
						$letter-spacing: 1px
					);
				}
		
				a {
					text-decoration: underline;
				}
			}
		}
	}

	.checkout-footer-bottom {
		padding: $twenty-gutter $fifteen-gutter;

		@include min-screen($screen__m) {
			padding: $twenty-gutter $gutter;
		}

		.pagebuilder-column-group {
			@include max-screen($screen__m - 1) {
				flex-wrap: wrap;
			}
		}

		.pagebuilder-column {
			> * {
				flex-basis: auto;
			}

			p,
			a {
				@include type__13($font-weight: $font-weight-normal);

				@include min-screen($screen__m) {
					@include type__14;
				}
			}

			&:first-child {
				align-items: center;
				order: 2;

				@include min-screen($screen__m) {
					order: revert;
					align-items: flex-start;
					order: 0;
				}

				[data-content-type="text"] {
					display: flex;
					flex-wrap: wrap;

					@include min-screen($screen__ml) {
						flex-wrap: nowrap;
					}

					p {
						&:last-of-type {
							margin-left: rem(4);
						}
					}
				}
			}

			&:nth-child(2) {
				align-items: center;

				[data-content-type="html"] {
					margin: auto auto $fifteen-gutter;

					@include min-screen($screen__m) {
						margin: 0 auto;
					}

					svg {
						@include min-screen($screen__m) {
							width: 44px;
						}

						@include min-screen(800px) {
							width: 52px;
						}
					}
				}
			}

			&:last-child {
				order: 3;

				@include min-screen($screen__m) {
					order: revert;
				}

				ul {
					li {
						&:not(:last-of-type) {
							&:after {
								content: "";
								display: inline-block;
								height: 11px;
								width: 1px;
								background: $mine-shaft;
								margin: 0 rem(3) 0 rem(7);
								position: relative;
								top: rem(1);
							}
						}
					}
				}
			}

			&:not(.footer-payment-cards) {
				@include max-screen($screen__m - 1) {
					width: 100% !important; // To override pagebuilder styles
					text-align: center;
				}

				@include min-screen($screen__m) {
					justify-content: center !important; // To override pagebuilder styles
				}

				@include max-screen($screen__ml - 1) {
					width: 50% !important; // To override pagebuilder styles
				} 
			}

			&:last-of-type {
				@include min-screen($screen__m) {
					text-align: right;
				}
			}
		}

		ul {
			li {
				display: inline-block;
				vertical-align: top;
			}
		}

		.footer-payment-cards {
			@include max-screen($screen__ml - 1) {
				flex-basis: 100%;
				margin-bottom: $twenty-gutter;
			}

			ul {
				display: flex;
				justify-content: center;

				li {
					&:not(:last-of-type) {
						margin-right: $ten-gutter;

						&:after {
							display: none;
						}
					}
				}
			}
		}
	}
}
