.loading-mask {
	background: $overlay-color-alt;
	width: 100vw;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 100;

	.gallery-placeholder._block-content-loading & {
		background: $default-overlay-color;
		width: auto;
		position: relative;

		.loader {
			position: absolute;
		}
	}
}

.loader {
	width: 80px;
	height: 80px;
	border: 2px solid $spinner-border-color;
	border-top: 3px solid $spinner-color;
	border-radius: 100%;
	position: fixed;
	top:0;
	bottom:0;
	left:0;
	right: 0;
	margin: auto;
	animation: spin 1s infinite linear;

	img,
	p {
		display: none;
	}
}

%element-loader {
	width: 80px;
	height: 80px;
	border: 2px solid $spinner-border-color;
	border-top: 3px solid $spinner-color;
	border-radius: 100%;
	position: absolute;
	top:0;
	bottom:0;
	left:0;
	right: 0;
	margin: auto;
	animation: spin 1s infinite linear;

	img,
	p {
		display: none;
	}
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	} 

	to {
		transform: rotate(360deg);
	}
}
