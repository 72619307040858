/* -------------------------------------------------
 * -------------------------------------------------
 * Company Users
 *
 * Table containing all the user's of a given company.
 * -------------------------------------------------
 * @author Fisheye
 */

.company-users-index {
	.data-grid-filters-wrap {
		button {
			@include button__default();
			margin-bottom: $twenty-gutter;
		}
	}

	.data-grid {
		.data-row {
			&:last-child {
				border-bottom: 1px solid $border-color;
			}

			.col.actions {
				display: flex;
				flex-direction: column;
			}
		}
	}

	.table-wrapper {
		table.data-grid.data.table {
			tbody {
				width: 100%;
			}
		}
	}

	.modal-popup.modal-slide {
		left: 0;
	}
}
