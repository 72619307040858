/* ------------------------------------------------
 * ------------------------------------------------
 * paypal basket review page
 * accessed by selecting pay with paypal on the basket page
 *
 * ------------------------------------------------
 * @author Fisheye
 */


/* page layout
 * ------------------------------------------------
 */

.braintree-paypal-review {
	.paypal-review {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		align-items: flex-start;

		@include min-screen($screen__ml) {
			flex-direction: row;
		}

		.block-order-details-view {
			// delivery address summary sidebar
			order: 2;
			flex: 1 1 100%;
			width: 100%;

			@include min-screen($screen__ml) {
				flex: 1 1 50%;
				padding-left: $gutter;
			}

			.box-order-shipping-method {
				.box-title,
				.delivery-instructions-wrapper > label {
					border-bottom: 1px solid $silver-chalice;
					padding-bottom: $fifteen-gutter;
					margin-bottom: $twenty-gutter;
					display: flex;
					flex-wrap: wrap;
					align-items: flex-end;
					@include type__18;
					text-transform: uppercase;

					@include min-screen($screen__m) {
						@include type__30;
						padding-bottom: $fifteen-gutter;
						margin-bottom: $gutter;
						flex-wrap: nowrap;
					}
				}
			}

			.box-order-shipping-address,
			.box-order-shipping-method,
			.box-order-billing-address {
				padding: 0;
				margin-bottom: $fifteen-gutter;

				@include min-screen($screen__m) {
					margin-bottom: $gutter;
				}
			}
		}

		.paypal-review-items {
			// order table
			flex: 1 1 100%;
			margin: $forty-gutter 0 $twenty-gutter;
			order: 4;
			width: 100%;

			@include min-screen($screen__ml) {
				border-right: 1px solid $silver-chalice;
				flex: 1 1 50%;
				margin: 0 0 $gutter;
				order: 1;
				padding-right: $gutter;
			}
		}

		.paypal-review-discount {
			order: 3;
			flex: 1 1 100%;

			@include min-screen($screen__m) {
				flex: 0 1 50%;
			}
		}

		select {
			option {
				color: $body-font-color; // Firefox contrast fix
			}
		}
	}
}


/* order table
 * ------------------------------------------------
 */

.braintree-paypal-review {
	.paypal-review-items {
		.paypal-review-title {
			border-bottom: 1px solid $silver-chalice;
			padding-bottom: $fifteen-gutter;
			display: flex;
			flex-wrap: wrap;
			align-items: flex-end;

			@include min-screen($screen__xl) {
				padding-bottom: $fifteen-gutter;
				flex-wrap: nowrap;
			}

			strong {
				@include type__18;
				text-transform: uppercase;
				flex: 1 1 100%;
				margin-bottom: $ten-gutter;

				@include min-screen($screen__xs) {
					margin-bottom: 0;
					flex: 1 1 65%;
				}

				@include min-screen($screen__m) {
					@include type__30;
				}

				@include min-screen($screen__ml) {
					flex: 1 1 100%;
				}

				@include min-screen($screen__xl) {
					flex: 1;
				}
			}

			a {
				display: block;
				position: relative;
				top: rem(3);

				@include min-screen($screen__m) {
					text-align: right;
					top: 0;
				}

				@include min-screen($screen__xl) {
					margin-bottom: rem(9);
				}

				> span {
					@include link__underline;
					@include type__13;
					position: relative;
				}
			}
		}

		.block-cart-item {
			// product row
			padding: 0;
			border-bottom: dashed 1px $border-color;
		}

		.cart-item-row {
			position: relative;
			padding: $heading-gutter / 4 0;
			display: table;

			@include min-screen($screen__m) {
				display: flex;
				padding: $twenty-gutter 0;
				justify-content: flex-start;
			}

			.photo {
				display: table-cell;
				vertical-align: top;

				.photo {
					width: 100%;
				}

				@include min-screen($screen__m) {
					width: em(130);
				}
			}

			.details {
				display: inline-block;
				padding-left: $twenty-gutter * 0.75;
				flex: 0 19%;

				@include min-screen($screen__xl) {
					flex: 1 1 0;
				}
			}

			.cart-details {
				display: flex;
				flex-wrap: wrap;

				@include min-screen($screen__m) {
					padding-left: 0;
					flex-wrap: nowrap;
					padding-right: $twenty-gutter;
					padding-right: 0;
				}

				.subtotal {
					display: inline-flex;
					padding-right: 0;
					text-align: left;

					@include min-screen($screen__m) {
						text-align: center;
						min-width: em(220);
						padding-right: 0;
					}
				}

				.cart-item-col {
					@include min-screen($screen__m) {
						flex: 1 percentage(1/3);
						padding-left: 0; // @include type-setting(-1);
						padding-right: $twenty-gutter / 2;

						&.price {
							flex: 1 25%;
						}
					}

					&.qty {
						display: inline-flex;
						flex: 1 1 100%; // as soon as flex-wrap is applied the sub total is forced below
						margin-bottom: $item-gutter;
						padding-right: 0;
					}

					.price-including-tax {
						align-self: center;
						margin-right: 0;
					}
				}

				.qty {
					.cart-product-actions {
						height: 100%;
						position: absolute;
						left: em(10);
						bottom: em(-50);

						@include min-screen($screen__m) {
							bottom: em(-60);
							left: 45%;
						}

						.update {
							@include min-screen($screen__m) {
								height: 100%;
							}
						}
					}

					.cart-item-qty {
						-webkit-appearance: none; // removed inner boxshadow on mobile safari
					}

					.qty-button {
						border-radius: 0;
						width: em(49);
						height: em(49);
					}
				}
			}
		}
	}
}


/* order totals
 * ------------------------------------------------
 */

.table-paypal-review-items {
	margin-top: $twenty-gutter;

	@include min-screen($screen__m) {
		margin-top: $gutter;
	}

	thead {
		// the header doesnt have any relavent information
		display: none;
	}

	.cart.item {
		display: flex;
		padding-bottom: $twenty-gutter;
		margin-bottom: $twenty-gutter;
		border-bottom: 1px solid $silver-chalice;

		&:last-of-type {
			border: 0;
			margin-bottom: $twenty-gutter;
			padding-bottom: 0;
		}

		@include min-screen($screen__m) {
			padding-bottom: $forty-gutter;
			margin-bottom: $forty-gutter;

			&:last-of-type {
				margin-bottom: $gutter;
			}
		}

		.item {
			display: flex;
			align-items: center;
			padding: 0;
			flex: 1;

			.product-image-photo {
				margin-right: $twenty-gutter;
			}

			.name {
				display: block;
				@include type__13;
			}

			.item-options {
				margin-top: rem(7);

				.toggle {
					@include type__13($font-weight: $font-weight-semibold);
					cursor: pointer;
				}

				.hide-options {
					display: none;
				}

				&.active {
					.show-options {
						display: none;
					}

					.hide-options {
						display: block;
					}
				}

				dt,
				dd {
					@include type__13;
				}

				dt {
					font-weight: $font-weight-semibold;

					&:first-child {
						margin-top: $five-gutter;
					}
				}

				dd {
					margin-bottom: $five-gutter;

					&:last-child {
						margin-bottom: 0;
					}
				}
			}

			.qty {
				@include type__13($font-weight: $font-weight-bold);
				text-transform: uppercase;
				margin-top: rem(18);
			}
		}

		.qty,
		.price,
		.subtotal {
			align-self: flex-end;
			padding: 0;
			position: relative;
			bottom: 50%;
		}

		.col {
			&.price {
				display: none; // Per-item price, row total is still shown to match other areas of site
			}

			&.subtotal {
				margin-left: $twenty-gutter;
			}
		}
	}

	.opc-block-summary {
		display: block;
		background-color: $concrete;
		padding: $twenty-gutter;
		margin-bottom: $twenty-gutter;

		@include min-screen($screen__m) {
			margin-bottom: $gutter;
			padding: $gutter;
		}

		th {
			flex: 1;
		}

		.totals-tax-details {
			display: none;
		}
	}

	tr {
		display: flex;
		flex: 1;

		th,
		td {
			padding: 0;
		}
	}

	th,
	td {
		display: block;
		padding: $item-gutter;
	}

	&:last-child {
		.mark,
		.amount {
			padding-bottom: 0;
			padding-top: 0;
		}
	}

	.totals,
	.totals-tax-summary {
		padding-bottom: $fifteen-gutter;

		@include min-screen($screen__m) {
			padding-bottom: rem(17);
		}

		&:last-child {
			padding-bottom: 0;
		}

		th,
		td {
			line-height: 1.4; // custom to stop text on 2 or more lines looking squashed
		}
	}

	.mark {
		// subtotal and vat header
		text-align: left;
		padding-left: 0;
		padding-top: 0;
		padding-right: $twenty-gutter;
		@include type__16(
			$color: $nobel,
			$font-weight: $font-weight-bold
		);

		@include min-screen($screen__m) {
			@include type__19(
				$color: $nobel,
				$font-weight: $font-weight-bold
			);
		}
	}

	.amount {
		text-align: right;
		padding-right: 0;
	}
}

.delivery-note {
	@include type__14($font-weight: $font-weight-bold);
	margin-bottom: $ten-gutter;
}


/* place order btn
 * ------------------------------------------------
 */

.paypal-review-items {
	.actions-toolbar {
		.primary {
			width: 100%;

			button {
				@include button__journey;
				width: 100%;
			}
		}
	}
}


/* gift card
 * ------------------------------------------------
 */

.paypal-review-discount {
	display: inline-flex;
	border-bottom: 0;
	width: 100%;

	&:after {
		// makes the dropdown the same width as the order table above
		content: "";
		flex: 1;
		max-width: em(460);
		min-width: em(370);
		display: none;
	}

	@include min-screen($screen__ml) {
		&:after {
			display: block;
		}
	}

	.giftcard {
		display: none;
	}

	.discount {
		border-top: 1px solid $silver-chalice;
		border-bottom: 1px solid $silver-chalice;
		flex: 1 0 100%;

		.title {
			padding: $fifteen-gutter 0;
			text-transform: uppercase;
			display: flex;
			align-items: center;
			cursor: pointer;
			position: relative;

			strong {
				@include type__13($font-weight: $font-weight-semibold);

				@include min-screen($screen__m) {
					@include type__15($font-weight: $font-weight-semibold);
				}
			}

			&:after {
				content: "";
				width: em(22);
				height: em(11);
				display: inline-block;
				position: absolute;
				@include svg-background("arrow-down");
				right: 0;
			}
		}

		.field {
			margin-bottom: 0;

			.control {
				margin-right: 0;
				margin-bottom: $fifteen-gutter;
			}

			input {
				width: $basket-promo-input-width;
				@include input-style-two;
			}
		}

		.fieldset {
			position: relative;

			.actions-toolbar {
				align-items: center;
				bottom: 0;
				display: flex;
				position: absolute;
				right: em(15);
				top: 0;
			}

			button.primary {
				padding: 0;
				background: transparent;
				border: 0;

				span {
					@extend %visuallyhidden;
				}

				&:after {
					@include svg-background('arrow-right');
					background-size: contain;
					content: '';
					display: inline-block;
					width: em(20);
					height: em(20);
					vertical-align: middle;
				}
			}
		}

		label {
			@extend %visuallyhidden;
		}

		.actions-toolbar {
			.apply.action {
				margin-right: 0;

				&:hover {
					border: 0;
				}
			}
		}
	}

	.discount.active {
		.title:after {
			@include svg-background("arrow-up");
			content: "";
		}
	}
}


/* sidebar
 * ------------------------------------------------
 */

.paypal-review {
	.block-content {
		.box {
			min-width: 100%; // min width applied to override width specified in module file
			flex: 1 100%;
		}
	}
}

.block-order-details-view {
	.box-title {
		display: block;
		margin-bottom: $fifteen-gutter;
	}

	.box-content {
		@include type__13;
	}

	.box-order-shipping-address {
		margin-bottom: 0;
	}

	.box-order-billing-address {
		margin-bottom: 0;

		.box-content {
			br:last-of-type {
				// makes icon inline with email
				display: none;
			}

			img {
				display: block;
				margin-top: $twenty-gutter;
			}
		}
	}
}

// Delivery Instructions
.delivery-instructions-wrapper {
	margin-top: $forty-gutter;
}

.delivery-instructions-other-wrapper {
	margin-top: $twenty-gutter;

	> label {
		@extend %visuallyhidden;
	}
}
