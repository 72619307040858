// ------------------------------------------------------------------------------------
// This is the back button at the top of the mobile menu
.navigation {
	&.nav-open {
		.fisheye-menu-back {
			// menu back button only used on mobile
			width: 100%;
			text-align: center;
			position: relative;
			padding: $mobile-nav-header-padding;
			border-bottom: $mobile-nav-header-border-bottom;

			.back-button-view-all {
				display: block;
				text-decoration: underline;
			}
		} 
	}

	.mobile-menu {
		.fisheye-back-button {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 100%;
			width: 48px;
			cursor: pointer;
			margin: auto;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			font-size: 0;
			@include transition(all 0.3s ease-in-out);

			&:hover {
				color: $mobile-nav-link-hover;
			}

			&:before {
				content: '';
				cursor: pointer;
				display: inline-block;
				height: 15px;
				margin-right: rem(14);
				transform: rotate(180deg);
				width: 10px;
				@include svg-background(
					$name: mobile-menu-arrow,
					$size: contain
				);
			}
		}
	}
}
