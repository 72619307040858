/* -------------------------------------------------
 * -------------------------------------------------
 * Customer dashboard
 *
 * -------------------------------------------------
 * @author Fisheye
 */
 .account.customer-account-index {
	.page-title-wrapper {
		.page-title {
			.base {
				font-style: italic;
			}
		}
	}
}

.customer-account-dashboard {
	border: 1px solid $grey-lighter1;
	display: flex;
	margin-top: 0; // This mimics extending row-container but with margins instead.
	flex-flow: column wrap;

	@include min-screen($screen__ml) {
		flex-flow: row nowrap;
	}

	// each section of information, container with two boxes each
	.block {
		padding: 0;
		flex: 100%;
		margin-bottom: $twenty-gutter;

		@include min-screen($screen__ll) {
			flex: percentage(1/2);
			margin-bottom: 0;
		}
	}
	
	.block-content {
		display: flex;
		flex-flow: column nowrap;

		@include min-screen($screen__s) {
			flex-flow: row wrap;
			margin-bottom: 0;
		}
	}

	// -------------------------------------------------
	// -------------------------------------------------
	// container with two boxes each
	.block {
		.box,
		.box-newsletter form {
			display: flex;
			flex-direction: column;
			width: 100%; // fix ie overlap

			.box-content,
			p {
				flex-grow: 1;
			}

			.box-content {
				display: flex;
			}
		}

		.box {
			padding: $fifteen-gutter $twenty-gutter $twenty-gutter;

			&.box-billing-address,
			&.box-newsletter {
				@include min-screen($screen__s) {
					padding-left: $twenty-gutter;
					border-top: 0;
				}
			}

			&.box-shipping-address,
			&.box-information {
				@include min-screen($screen__s) {
					padding-right: $twenty-gutter;
				}
			}

			&.box-shipping-address,
			&.box-billing-address {
				border-right: 0; 
				border-bottom: 1px solid $grey-lighter1;

				@include min-screen($screen__ml) {
					border-bottom: 0;
					border-right: 1px solid $grey-lighter1;
				}
			}

			// controlling where the tick box sits
			&.box-newsletter {
				@extend %visuallyhidden;

				.box-content {
					height: 100%;

					form {
						position: relative;
						height: 100%;

						.fieldset {
							bottom: 0;
							width: 100%;
						}
					}
				}
			}

			h3 {
				@include type__17($font-weight: $font-weight-normal);
				text-transform: uppercase;
				margin-bottom: $ten-gutter;
				letter-spacing: 1px;
			}

			.fieldset .field.choice .label {
				// overwrites default form styling just for this area
				width: 100%;
				margin-bottom: 0; // cancels default label spacing

				span {
					@include type__13;
				}
			}
		} // -------------------------------------------------
		// -------------------------------------------------
		&.block-dashboard-addresses {
			@include min-screen($screen__ml) {
				flex: 1 1 66%;
				margin-bottom: 0;
			}

			.box {
				flex: 1 1 100%;

				@include min-screen($screen__ml) {
					flex: 1;
				}

				&.box-billing-address {
					order: 2; // reverse the order of shipping and billing addresses again
				}
			}
		} // -------------------------------------------------
		// -------------------------------------------------
		&.block-dashboard-info {
			order: 2;
			border-right: 0;

			@include min-screen($screen__m) {
				flex: 1 1 33%;
			}

			.box {
				.fieldset .field {
					&.choice {
						// cancel margin under tick from CORE
						margin-bottom: 0;

						@include min-screen($screen__m) {
							position: relative;
						}

						.label {
							flex: 100%;
							@include type__address;
						}
					}
				} // hide the save button
				.save {
					display: none;
				}
			}
		} // -------------------------------------------------
		// -------------------------------------------------
		// Defaults for all boxes
		.block-content {
			height: 100%;

			.box-content {
				address,
				p {
					@include type__16();
					margin-bottom: $ten-gutter;
				}
			}
		}

		.box-actions {
			a {
				@include type__16($font-weight: $font-weight-thin);

				&:before {
					content: "";
					display: inline-block;
					height: 9px;
					width: 5px;
					margin-right: $ten-gutter;
					@include svg-background(
						$name: 'arrow-right-black',
						$size: contain
					);
				}

				&.change-password {
					display: none;
				}
			}
		}
	}
}

.block.block-dashboard-orders {
	margin-bottom: $account-dashboard-info-margin-top-mobile;
	padding: $fifteen-gutter 0 0;

	@include min-screen($screen__m) {
		margin-bottom: $account-dashboard-info-margin-top;
		padding: 0;
	}
}
