/* -------------------------------------------------
 * -------------------------------------------------
 * Footer block styles
 * -------------------------------------------------
 * @author Fisheye
 */

 .page-footer {
	// ----------------------------------------------------------------------------
	// Footer Row One
	// Contains Instagram & Newsletter blocks
	// ----------------------------------------------------------------------------

	.footer-row-one {
		display: flex;
		flex-direction: column;
		padding: 0;
		background-color: $footer-row-one-bg;

		.block {
			&.instagram {
				.pagebuilder-column-group {
					.pagebuilder-column {
						flex-direction: row !important;

						@include max-screen($screen__ml - 1) {
							flex-basis: 100%;
						}

						&:first-of-type {
							@include max-screen($screen__ml - 1) {
								display: none !important; // To override the inline styles
							}
						}

						[data-content-type="image"] {
							flex-basis: 50%;

							img {
								width: 100%;
							}
						}

						&.column-hashtag {
							[data-content-type="image"] {
								@include min-screen($screen__ml) {
									flex-basis: 25%;
								}

								&:first-of-type {
									@include max-screen($screen__ml - 1) {
										display: none;
									}
								}
							}
						}
					}
				}

				.hashtag {
					display: flex;
					align-items: center;
					flex-grow: 1;
					justify-content: center;
					padding: $fifteen-gutter;
					background: $shadow-green;

					a {
						position: relative;
						@include type__16(
							$color: $white,
							$font-weight: $font-weight-medium,
							$letter-spacing: 1px
						);
						text-align: center;
						text-transform: uppercase;

						@include min-screen($screen__ml) {
							@include type__22(
								$color: null,
								$font-weight: null,
								$letter-spacing: null
							);
							text-align: left;
						}
						
						&:before {
							content: "";
							display: block;
							height: 18px;
							width: 18px;
							margin: auto;
							@include svg-background(
								$name: "insta-panel-icon",
								$size: contain
							);

							@include min-screen($screen__ml) {
								position: absolute;
								top: 25px;
								left: 0;
							}
						}
					}

					span {
						display: block;
						@include type__17(
							$color: $white,
							$font-family: $font-family-serif
						);
						font-style: italic;
						text-transform: capitalize;

						@include min-screen($screen__ml) {
							margin-left: 25px;
							@include type__19(
								$color: $white,
								$font-family: $font-family-serif,
								$letter-spacing: 1px
							);
						}
					}
				}
			}

			&.newsletter {
				order: 1;
				padding: $gutter $fifteen-gutter;
				text-align: center;

				@include min-screen($screen__m) {
					padding: $forty-gutter;
				}

				.title {
					margin-bottom: $fifteen-gutter;

					@include min-screen($screen__m) {
						margin-bottom: $twenty-gutter;
					}

					strong {
						@include type__17($letter-spacing: 1px);
						text-transform: uppercase;
					}
				}

				.content {
					.newsletter-description,
					.label {
						display: none;
					}

					.form {
						position: relative;
						
						@include min-screen($screen__m) {
							max-width: em(345);
							margin: auto;
						}
					}

					.control {
						input {
							@include svg-background(
								$name: "envelope-icon",
								$size: 23px
							);
							background-position: rem(15) center;
							@include input-style-two(
								$padding: rem(13) $forty-gutter rem(13) rem(55)
							);		
						}
					}

					.actions {
						position: absolute;
						top: 0;
						right: 0;
						bottom: 0;

						.subscribe {
							@include form-submit-inline-button($width: 7px);
							margin-right: $fifteen-gutter;

							span {
								display: none;
							}
						}
					}
				}
			}
		}
	}

	// ----------------------------------------------------------------------------
	// Footer Row Two
	// Contains help information, links & additional information blocks
	// ----------------------------------------------------------------------------

	.footer-row-two {
		display: flex;
		flex-direction: column;
		background-color: $footer-row-two-bg;
		border-top: 1px solid $gallery;

		@include min-screen($screen__m) {
			flex-direction: row;
			flex-wrap: wrap;
			padding: $forty-gutter 0;
		}

		@include min-screen($screen__ll) {
			flex-wrap: nowrap;
		}

		.additional-info {
			padding: $gutter;

			@include min-screen($screen__m) {
				order: 2;
				padding: 0 $gutter;
				width: 50%;
			}

			@include min-screen($screen__ll) {
				flex-basis: 20%;
			}

			[data-content-type="row"] {
				&:nth-of-type(2) {
					display: none; // Not required on mobile (logo's underneath social icons)

					@include min-screen($screen__m) {
						display: block;
					}
				}
			}

			.pagebuilder-buttons {
				display: flex;
				justify-content: center;

				@include min-screen($screen__m) {
					justify-content: flex-end;
				}

				[data-content-type="button-item"] {
					margin: 0 $five-gutter;
				}

				[data-element="link"] {
					display: block;
					height: 45px;
					width: 45px;
					border-radius: 50%;

					span {
						font-size: 0;
					}
				}

				$social-icon-list: (
					twitter: 'round-twitter-icon',
					facebook: 'round-facebook-icon',
					instagram: 'round-instagram-icon',
					pinterest: 'round-pinterest-icon'
				);
				@each $class, $path in $social-icon-list {
					.#{$class} {
						@include svg-background(
							$name: $path,
							$size: contain
						);
					}
				}
			}

			[data-content-type="image"] {
				@include max-screen($screen__m - 1) {
					display: none;
				}

				@include min-screen($screen__m) {
					align-self: flex-end;
					max-width: 95px;
					margin-top: 25px;
				}
			}
		}

		.need-help.safe-shopping {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			order: 1;
			padding: $gutter;

			@include max-screen($screen__m - 1) {
				border-top: 1px solid $gallery;
				text-align: center;
			}

			@include min-screen($screen__m) {
				padding: 0 $gutter;
				position: relative;
				width: 50%;
			}

			@include min-screen($screen__ll) {
				flex-basis: 20%;
				order: 0;
			}

			[data-content-type="html"] {
				a {
					display: block;
					margin-bottom: $ten-gutter;
					@include type__22(
						$font-weight: $font-weight-normal,
						$letter-spacing: 1px
					);
				}

				span {
					display: block;
					@include type__17(
						$font-family: $font-family-serif
					);
					font-style: italic;
				}
			}

			.safe-shopping-cards {
				@include max-screen($screen__m - 1) {
					margin-top: 25px;
				}

				h4 {
					@include max-screen($screen__m - 1) {
						display: none;
					}

					@include min-screen($screen__m) {
						content: "Safe Shopping";
						display: block;
						margin-bottom: $twenty-gutter;
						@include type__17;
						text-transform: uppercase;
					}
				}

				ul {
					display: flex;

					@include max-screen($screen__m - 1) {
						justify-content: center;
					}

					li {
						&:not(:last-of-type) {
							margin-right: $ten-gutter;
						}
					}
				}
			}
		}

		.useful-info {
			@include max-screen($screen__m - 1) {
				padding: $twenty-gutter 0;
				border-top: 1px solid $gallery;
			}

			@include min-screen($screen__m) {
				flex-basis: 100%;
				padding-bottom: $forty-gutter;
				border-bottom: 1px solid $gallery;
				margin-bottom: $forty-gutter;
			}

			@include min-screen($screen__ll) {
				flex-basis: 60%;
				order: 1;
				padding-bottom: 0;
				border-bottom: none;
				margin-bottom: 0;
			}

			.pagebuilder-column {
				@include max-screen($screen__m - 1) {
					margin-bottom: $ten-gutter;
				}
	
				@include min-screen($screen__m) {
					flex-basis: calc(100% / 3);
					padding: 0 $gutter;
				}
			}
	
			h3 {
				margin-bottom: $twenty-gutter;
				@include type__17(
					$letter-spacing: 1px
				);
				text-transform: uppercase;
			}
	
			ul {
				li {
					padding-left: $fifteen-gutter;
					margin-bottom: $ten-gutter;
					position: relative;

					&:before {
						content: "";
						display: block;
						height: 9px;
						width: 5px;
						@include svg-background(
							$name: 'arrow-right-black',
							$size: 5px
						);
						position: absolute;
						top: 7px;
						left: 0;
					}

					a {
						@include type__16;
					}
				}
			}
		}
	}

	// ----------------------------------------------------------------------------
	// Footer Row Three
	// Contains legal information blocks
	// ----------------------------------------------------------------------------

	.footer-row-three {
		padding: $twenty-gutter $fifteen-gutter;
		border-top: 1px solid $gallery;

		@include max-screen($screen__m - 1) {
			text-align: center;
		} 

		@include min-screen($screen__m) {
			padding: $fifteen-gutter $gutter;
		}

		.pagebuilder-column {
			&:first-of-type {
				@include max-screen($screen__m - 1) {
					margin-bottom: $five-gutter;
				}
			}

			&:last-of-type {
				@include min-screen($screen__m) {
					text-align: right;
				}
			}
		}

		p,
		a {
			@include type__13($font-weight: $font-weight-normal);

			@include min-screen($screen__m) {
				@include type__14;
			}
		}

		ul {
			li {
				display: inline-block;
				vertical-align: top;

				&:not(:last-of-type) {
					&:after {
						content: "";
						display: inline-block;
						height: 10px;
						width: 1px;
						background: $mine-shaft;
						margin: 0 $five-gutter;
						vertical-align: middle;
					}
				}
			}
		}

		.pagebuilder-column {
			&[data-content-type="column"] {
				ul {
					margin-bottom: rem(35);

					@include min-screen($screen__m) {
						margin-bottom: 0;
					}
				}
			}
		}

		.feefo-block-display {
			div {
				.feefo-block {
					display: flex;
					position: fixed;
					bottom: 0;
					width: 100%;
					left: 0;
					height: 35px;
					border-top: 1px solid $gallery;
					background-color: $white !important; // To override standard styles applied for checkout feefo
					
					@include min-screen($screen__m) {
						display: none;
					}
				}
			}
		}
	}
}
