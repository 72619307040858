/* -------------------------------------------------
 * -------------------------------------------------
 * Slider Styles
 * -------------------------------------------------
 * @author Fisheye
 */

.slider {
    img {
        width: 100%;
    }

    .bluefoot-slider {
        margin: 0;
    }
}