/* -------------------------------------------------
 * -------------------------------------------------
 * Account Menu and collapsible
 *
 * -------------------------------------------------
 * @author Fisheye
 */

.page-layout-2columns-left {
	&.account {
		.sidebar-main {
			@media (min-width: $screen__m) {
				display: flex;
				flex-direction: column;
				margin: 0;
				padding: $fifteen-gutter $twenty-gutter $fifteen-gutter 0;

				.help-block {
					order: 1;
					margin: $account-sidebar-help-margin-top-desktop;
				}
			}
		}
	}
}

.account-sidebar-title {
	background: $grey-medium1;
	@include type__17();
	display: none;
	letter-spacing: 1px;
	text-transform: uppercase;

	@include min-screen($screen__m) {
		display: block;
		padding: $fifteen-gutter $twenty-gutter 0;
	}
}

.block {
	&.account-nav {
		// ------------------------------------------------------------------------------------
		// This is the main container for the account menu .block is needed as well for specificty
		// This should expand and collapse on mobile but always expanded on desktop
		height: initial; // this needs to be static px value for js - (Have removed static height as it was overriden on previous websites, and have tested is working correctly without)
		background: none;
		padding: 0; // this should be set to 0 in the child theme if there is no background-color;
		overflow: hidden;
		border-top: solid 1px $grey-lighter1;
		border-bottom: solid 1px $grey-lighter1;
		margin: $twenty-gutter $page-padding-mobile 0;

		@include min-screen($screen__m) {
			background: $grey-medium1;
			margin: 0;
			height: auto;
			border: none;
			padding: $ten-gutter $twenty-gutter 0;
		}
		
		// -------------------------------------------------
		// Main title at top of sidebar "Account Menu"
		// Should have + / - on mobile but no icon on desktop
		// In some cases this hidden completely on desktop. Please do this in child themes
		.account-nav-title {
			@include svg-background("plus");
			margin-bottom: 0;
			background-position: 100% center;
			background-size: em(13);
			padding: $twenty-gutter 0;
			cursor: pointer;

			strong {
				@include type__15($font-weight: $font-weight-semibold);
				text-transform: uppercase;
			}

			@include min-screen($screen__m) {
				background: none;
				padding: 0 0 $twenty-gutter 0;
			}
		} // When filter is open .active is added to .block.account-nav

		li {
			&.nav.item {
				margin: $account-navigation-item-margin;
				padding-left: 0;

				@include min-screen($screen__m) {
					padding-left: $fifteen-gutter;
				}

				a,
				strong {
					@include type__16($font-weight: $font-weight-normal);
					display: flex;
					align-items: center;
					justify-content: flex-start;

					&:before {
						content: "";
						display: block;
						height: 9px;
						width: 5px;
						@include svg-background(
							$name: 'arrow-right-black',
							$size: 5px
						);
						position: absolute;
						top: 7px;
						left: 0;
					}
				}

				&:last-child {
					padding-bottom: $ten-gutter;
				}
			}
		}

		&.active {
			height: auto;

			.account-nav-title {
				@include svg-background("minus");
				background-position: 100% center;
				background-size: em(14);
				
				&:hover {
					cursor: pointer;
				}

				@include min-screen($screen__m) {
					background: none;

					&:hover {
						cursor: default;
					}
				}
			}
		}
	}
} // -------------------------------------------------
