/* -------------------------------------------------
 * -------------------------------------------------
 * Bundles Product Styles
 *
 * Includes the customise options styles
 *
 * -------------------------------------------------
 * @author Fisheye
 */

.bundle-options-container {
	margin: 0 auto;
	clear: both; // For IE11

	.product-add-form {
		max-width: $product-bundle-options-max-width;
		margin: 0 auto;
	}

	form[id="product_addtocart_form"] {
		@include min-screen($screen__m) {
			display: flex;
		}

		.block-bundle-summary {
			@include min-screen($screen__m) {
				flex: $product-bundle-options-summary-flex;
			}

			.product-addto-links {
				margin: 0;
			}
		}

		legend[id="customizeTitle"] {
			@include type__30();
		}

		.action.customization {
			@extend %cancel-button;
			@include link__underline;
			padding: 0;
			margin-bottom: $product-bundle-options-back-button-margin-bottom;
		}

		.field {
			&.option {
				display: block;
				padding-top: $product-bundle-options-field-option-padding-top;
				border-top: $product-bundle-options-field-option-border-top;

				&:first-of-type {
					border-top: 0;
					padding-top: 0;
				}

				.label {
					width: 100%;
					flex: 1;
					margin-bottom: 0;
				}
			}

			&.qty {
				margin-right: $twenty-gutter;

				.label {
					@extend %cancel-visuallyhidden;
					height: auto; // For IE11
					flex: 0 1 99px;
					align-self: flex-start;
					margin-top: $ten-gutter;
					margin-left: 0;
					overflow: hidden;

					@include min-screen($screen__m) {
						flex: 0 1 117px;
						margin-right: $twenty-gutter;
					}

					@include min-screen($screen__ll) {
						margin-bottom: 0;
						align-self: center;
						margin-right: $gutter;
						flex: 0 1 107px;
						margin-top: 0;
					}

					> span {
						text-transform: uppercase;
						@include type__13($font-weight: $font-weight-semibold);

						@include min-screen($screen__m) {
							@include type__15($font-weight: $font-weight-semibold);
						}
					}
				}	

				.control {
					display: flex;

					.input-text {
						width: rem(40);
						letter-spacing: 2px;
					}
				}

				.input-text {
					width: 20px;
					@include input-style-five(
						$border-radius: 0,
						$padding: 0,
						$width: 20px
					);
				}

				.qty-control {
					width: rem(43);
					height: rem(40);
					@include button__default(
						$padding: 0,
						$background-color: $nobel
					);
					border: 0;
					align-items: center;
					@include type__24(
						$color: $white,
						$font-weight: $font-weight-normal
					);
				}

				.minus-qty {
					border-top-right-radius: 0;
					border-bottom-right-radius: 0;
				}

				.add-qty {
					border-top-left-radius: 0;
					border-bottom-left-radius: 0;
				}
			}
		}

		.qty-holder {
			.label {
				@extend %cancel-visuallyhidden;
			}
		}

		p.required {
			display: none;
		}
	}

	.block-bundle-summary {
		.title {
			strong {
				@include type__30;
			}
		}

		.product-image-container,
		.product.name,
		.stock {
			@extend %visuallyhidden;
		}

		.tocart {
			@include button__journey;
		}

		.price-box {
			margin-top: $product-bundle-options-price-box-margin-top;
		}

		.price {
			@include product-page-price;
		}

		.subtitle {
			@include type__19;
		}

		.bundle {
			li {
				margin-top: $product-bundle-options-summary-bundle-item;
			}
		}
	}
}

.page-layout-bundle-hidden-children {
	.product-info-main { 
		.product-info-addto,
		.product-addto-links { // unnecessary spacing coming from hidden simple products container
			margin: 0;
		}
	}

	.product-info-price {
		.price-box {
			&:last-child {
				justify-content: flex-start;
			}
		}
	}

	.bundle-options-container {
		form[id="product_addtocart_form"] {
			.block-bundle-summary {
				flex: 0 1 100%;
			}
		}
	}

	.box-tocart {
		.actions {
			display: flex;
			flex-direction: column;
		}

		.action.primary.tocart {
			order: 2;
		}

		.fieldset {
			flex-flow: row wrap;
		}
	}

	.product-options-wrapper {
		h4 {
			display: flex;
			justify-content: space-between;
			align-items: center;
			text-transform: uppercase;
			cursor: pointer;

			@include type__13(
				$color: $blaze-orange,
				$font-weight: $font-weight-semibold
			);

			&:after {
				content: '';
				height: 10px;
				width: 21px;
				@include svg-background(
					$name: 'expand-arrow-green',
					$size: contain
				);
			}

			@include min-screen($screen__m) {
				@include type__15(
					$color: $blaze-orange,
					$font-weight: $font-weight-semibold
				);
			}
		}

		.fieldset {
			.field {
				&.file {
					padding-bottom: rem(12);

					@include min-screen($screen__m) {
						padding-bottom: rem(29);
					}
				}
			}

			&.fieldset-bundle-options {
				margin-top: $twenty-gutter;

				.field {
					&.option {
						border: 0;
						padding: 0;
						margin: 0;

						> .label {
							justify-content: center;
							padding: rem(13) $fifteen-gutter;
							background-color: $gallery;
						}

						&.required {
							// This is to hide the options with pre-configured values that the
							// user doesn't need to enter.
							@extend %visuallyhidden;
						}

						.options-list {
							.label {
								display: flex;
								height: 100%;

								> span {
									display: flex;
									flex-direction: column;
								}

								span {
									text-align: left;
								}
							}
						}
					}
				}
			}
		}
	}
}
