/* -------------------------------------------------
 * -------------------------------------------------
 * Specific styling for promotional banner content
 * These are populated by Bluefoot in most cases
 * Most styles should be defined elsewhere i.e. buttons, typography
 *
 * Default Styles - These classes are all added automatically through templates
 *
 * -------------------------------------------------
 * @author Fisheye
 */


/* Applies to every row.
 * -------------------------------------------------
 * @author Fisheye
 */

.row {
	// There should be no need to add padding or margin to the top of rows
	padding-bottom: $row-gutter / 2;
	@include min-screen($screen__m) {
		padding-bottom: $row-gutter;
	}
}


/* Each item, in most cases Each Fisheye Banner Item
 * -------------------------------------------------
 */

.banner-item {
	position: relative; // General styles for the info boxes overlaying banners
	.banners__info {
		position: absolute;
		z-index: 20;
		display: flex;
		flex-direction: column;
		bottom: $item-gutter;
		left: $item-gutter;
		right: $item-gutter;
		padding: $twenty-gutter;

		@include min-screen($screen__s) {
			padding: $twenty-gutter * 2;
		}
	}

	img {
		position: relative;
		z-index: 1;
	}

	.overlay {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		z-index: 10;
	}
}


/* -------------------------------------------------
 * -------------------------------------------------
 * Banner Type Styles - These classes would be added through Bluefoot
 *
 * -------------------------------------------------
 */


/* fullwidth Banners Specific Styles
 * -------------------------------------------------
 */

.banners__fullwidth {
	// this has to be added to the row in Bluefoot
	.banners__info {
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 1; //@include min-screen($screen__m) {
		@include min-screen(771px) {
			// TODO: Change this. Stupid bluefoot with stupid breakpoints. Gah!
			top: 0;
			bottom: 0;
			left: 62.5%;
			right: 0;
			justify-content: center;
		}
	}
}


/* Full width poster banner with background image and
 * centralised HTML content i.e. headings and buttons
 * -------------------------------------------------
 */

.poster {
	.banners__info {
		// This class is added to the row through bluefoot in the admin
		top: 0;
		bottom: 0;
		left: $twenty-gutter;
		right: $twenty-gutter;
		justify-content: center;
		text-align: center;

		@include min-screen($screen__m) {
			left: $twenty-gutter * 6;
			right: $twenty-gutter * 6;
		}
	}
}


/* Large Banners Specific Styles
 * -------------------------------------------------
 */

.banners__large {
	// this has to be added to the row in Bluefoot
	.banners__info {
		// on large banners there is a different color bg to default
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 1;
	}
}


/* Small Banners Specifc Styles
 * -------------------------------------------------
 */

.banners__small {
	// this has to be added to the row in Bluefoot
	// stops stretching of images
	display: block;

	.banners__info {
		bottom: $item-gutter / 4;
		left: $item-gutter / 4;
		right: $item-gutter / 4;
		width: calc(100% - #{$item-gutter / 2});
	}
}


/* Banners with no image just background color.
 * Nearly always used with .banners__small
 * This class is applied automatically to any banner
 * div when a background color is selected in the admin
 * -------------------------------------------------
 */

.htmlcolor {
	position: relative;
	height: 100%;
    width: 100%;
    
	.banners__small & {
		.banners__info {
			top: $item-gutter / 4;
			justify-content: center;
		}
	}
}


/* Banners with no image that need to be full height of column
 * -------------------------------------------------
 */

.fullheight {
	height: 100%;
	overflow: hidden;
}


/* Banners with no image that need to be half height of column
 * Usually two banner items nested
 * -------------------------------------------------
 */

.halfheight {
	height: calc(50% - #{$item-gutter});

	@include min-screen($screen__xs) {
		height: 100%;
	}
	@include min-screen($screen__l) {
		height: calc(50% - #{$item-gutter});
	}

	overflow: hidden;
}
