/* -------------------------------------------------
 * -------------------------------------------------
 * Table of contents
 * 1. CMS Trustbuilder 
 * -------------------------------------------------
 * @author Fisheye
 */

// -------------------------------------------------
// 1. CMS Trustbuilder
// -------------------------------------------------

$trustbuilder-background: $concrete; // Background colour of trustbuilder bar
$trustbuilder-color: $brand-accent; // Font color for trust builder items
$trustbuilder-inner-spacing-vertical: $ten-gutter; // Inner spacing (padding) for each trustbuilding item (top/bottom)
$trustbuilder-inner-spacing-horizontal: 0; // Inner spacing (padding) for each trustbuilding item (left/right)
$trustbuilder-title-weight: $font-weight-bold; // Controls the font weight of the leading text
$trustbuilder-item-border: 1px solid $brand-accent; // Dividing line between trust items.
$trustbuilder-display-mobile: none; // Display property of the trust builder on mobile
$trustbuilder-display-desktop: block; // Display property of the trust builder on desktop

$store-information-gutter: $twenty-gutter;
$trust-builder-border-display: true;
$store-information-gutter: $twenty-gutter;
