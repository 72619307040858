/* -------------------------------------------------
 * -------------------------------------------------
 * Product info tabs
 *
 * -------------------------------------------------
 * @author Fisheye
 */
 
 .product.info.detailed {
	position: relative;
	
	@include max-screen($screen__m - 1) {
		text-align: center;
	}

	h3 {
		@extend %cancel-visuallyhidden;
		display: $product-tabs-title-display;
		margin-bottom: rem(7);
		@include type__33(
			$font-family: $font-family-serif,
			$letter-spacing: 1px
		);
		text-align: center;
		overflow: hidden;

		.page-layout-subscription &,
		.page-layout-gift-subscription & {
			&:before {
				content: 'Subscription';
				font-style: italic;
			}
		}

		@include min-screen($screen__ml) {
			margin-bottom: rem(24);
			@include type__52(
				$font-family: $font-family-serif,
				$letter-spacing: 2px,
				$line-height: 1
			);
		}

		span {
			font-style: italic;

			.page-layout-subscription &,
			.page-layout-gift-subscription & {
				@extend %visuallyhidden;
			}
		}
	}

	.description-wrapper {
		.product {
			&.description {
				position: relative;
				max-height: rem(228);
				overflow: hidden;
				@include transition(max-height 0.4s ease-in-out);

				@include min-screen($screen__ml) {
					bottom: 3px;
					max-height: rem(158);
				}

				[data-element="inner"] {
					padding: 0 !important; // Override inline Pagebuilder style
					margin: 0 !important; // Override inline Pagebuilder style
				}

				&:after {
					content: "";
					display: block;
					height: rem(100);
					width: 100%;
					background: rgb(255, 255, 255);
					background: linear-gradient(0deg, rgba(255, 255, 255, 1) 25%, rgba(255, 255, 255, 0) 100%);
					position: absolute;
					bottom: 0;
					opacity: 1;
					@include transition(opacity 0.4s ease-in-out);

					@include min-screen($screen__ml) {
						height: 50%;
					}
				}
			}

			[data-content-type="text"] {
				p {
					margin-top: 0;
					margin-bottom: rem(25);
					@include type__17($line-height: 20px);
	
					@include min-screen($screen__m) {
						@include type__16($line-height: null);
					}
	
					&:last-of-type {
						margin-bottom: 0;
					}
				}
			}
		}
	}

	.product-tab-content-wrapper {
		border-top: 1px solid $gallery;

		@include max-screen($screen__ml - 1) {
			order: 3;
		}

		.title {
			.switch {
				display: flex;
				justify-content: space-between;
				min-height: rem(57);
				border-bottom: 1px solid $gallery;
				text-align: left;

				@include min-screen($screen__m) {
					min-height: rem(49);
				}

				&:after {
					content: "";
					width: rem(50);
					@include svg-background(
						$name: 'plus',
						$size: 10px
					);
					background-position: right center;
				}

				&:hover,
				&:focus {
					opacity: unset;
				}
			}

			h4 {
				display: flex;
				align-items: center;
				@include type__15(
					$font-weight: $font-weight-semibold,
					$letter-spacing: 1px,
					$line-height: 20px
				);
				text-transform: uppercase;

				@include min-screen($screen__m) {
					@include type__14(
						$font-weight: null,
						$letter-spacing: null,
						$line-height: 20px
					);
				}
			}

			&.active {
				.switch {
					&:after {
						@include svg-background(
							$name: 'minus',
							$size: 10px
						);
						background-position: right center;
					}
				}
			}

			// Hide product reviews accordion tab
			// Cannot be easily removed via layout or template without overrides etc
			&[id="tab-label-reviews"] {
				@extend %visuallyhidden;
			}
		}

		.content {
			padding: $twenty-gutter 0;
			border-bottom: 1px solid $gallery;
			text-align: left;

			[data-content-type="text"] {
				p {
					margin-top: 0;
					margin-bottom: $twenty-gutter;
					@include type__15;
	
					@include min-screen($screen__m) {
						@include type__14;
					}
	
					&:last-of-type {
						margin-bottom: 0;
					}
				}
			}
		}
	}
}

@if ($product-tabs-horizontal == true) {
	@include min-screen(map-get($product-tabs-breakpoints, 'desktop-horizontal')) {
		.product-additional-container {
			h3 {
				display: none;
			}
		 
			.product.items {
				@include clearfix;
			}
		 
			.data.title {
				float: left;
				width: auto;
	
				&:first-child {
					h4 {
						border-top: $product-tabs-horizontal-tab-border;
						border-left: $product-tabs-horizontal-tab-border;
					}
				}
	
				.data.switch {
					display: flex;
					align-items: center;
	
					&:focus {
						opacity: 1;
					}
				}
		 
				h4 {
					@include type__16(
						$color: $font-weight-semibold,
						$font-weight: $font-weight-bold
					);
					background: $concrete;
					padding: $product-tabs-horizontal-tab-padding;
					height: $product-tabs-horizontal-tab-height;
					border-bottom: 0;
					border-top: $product-tabs-horizontal-tab-border;
					border-right: $product-tabs-horizontal-tab-border;
					border-bottom: $product-tabs-horizontal-tab-border;
					width: 100%;
				}
	
				&.active {
					h4 {
						background: $product-tabs-horizontal-active-tab-background;
						color: $product-tabs-horizontal-active-tab-color; // Type mixin already set above
						border-bottom: $product-tabs-horizontal-active-tab-border-bottom;
						position: relative;
						height: $product-tabs-horizontal-tab-height-active; // Height of the tabs + 1 so the white border sits above the content border
					}
				}
			}
	
			.data.title.active {
				h4 {
					&:after {
						@include svg-background($name: minus);
					}
				}
			}
	
			.data.content {
				float: right;
				margin-left: -100%;
				width: 100%;
				margin-top: $product-tabs-horizontal-tab-height; // Height of the tabs
				border-top: $product-tabs-horizontal-content-border-top;
				padding-top: $product-tabs-horizontal-content-padding-top;
			}
		}
	}
} @else {
	@include min-screen(map-get($product-tabs-breakpoints, 'desktop-vertical')) {
		.product-additional-container {
			.product-info-wrapper {
				.product-tab-content-wrapper {
					display: flex;
					flex-direction: column;
				}
			}
		}
	}
}


// -------------------------------------------------
// Review Products -- need converting to vars
// -------------------------------------------------

.block.review-list {
	display: $product-tabs-review-list-display;

	.block-content {
		ol.items.review-items {
			li.item.review-item {
				width: $product-tabs-review-item-width;
				display: flex;
				flex-direction: column;
				margin-bottom: $product-tabs-review-item-margin;

				&:first-child {
					margin-top: $product-tabs-review-item-margin;
				}

				.review-title {
					border-bottom: $product-tabs-review-title-border;
					padding-bottom: $item-gutter;
				}

				.review-ratings {
					.rating-summary {
						display: flex;
						flex-direction: row;

						.rating-label {
							padding-right: $product-tabs-review-label-padding;
						}
					}
				}
			}
		}

		.toolbar.review-toolbar {
			background: $product-tabs-review-toolbar-background;
			justify-content: $product-tabs-review-toolbar-justification;

			.limiter {
				display: flex;
				width: $product-tabs-review-toolbar-limiter-width;
				max-width: 100%;
				align-items: center;

				strong {
					padding-right: $item-gutter;
					display: $product-tabs-review-toolbar-limiter-label-display;
				}

				.limiter-text {
					flex: $product-tabs-review-toolbar-limiter-text-flex;
					padding-left: $product-tabs-review-toolbar-limiter-text-spacing;
				}
			}
		}
	}
}
