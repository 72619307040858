/* --------------------------------------------------
 * --------------------------------------------------
 * Inner grid using flex for better directional control
 * Used for banners rather than page layout
 *
 * --------------------------------------------------
 * @author Fisheye
 */

// Additional Options
// 1. .odd - Toggels the flex direction of a row / column
// silent class to re-use flex
%flex {
    display: flex;
    box-sizing: border-box;
}

// Each flex item has half the amount of padding needed so that when placed next to each other
// They have the correct amount
// These should be applied to all layout elements i.e. header and sidebars as well so sue silent classes
.flex__item,
%flex__item {
    padding: $page-padding-mobile;

    @include min-screen($screen__m) {
        padding: $page-padding;
    }
}

.full-width {
    @extend %fullwidth;
}

.flex__row,
%flex__row {
    @extend %flex;
    flex-flow: row wrap;
    justify-content: center; // centre things by default
    // If direction we need to change direction of the row to column on mobile
    &.direction {
        flex-flow: column wrap; // Reset back to row
        @include min-screen($screen__m) {
            // This breakpoint needs to match direction on widths below
            flex-flow: row wrap;
        }
    } // This applies specifically to category pages as product listsings will have more padding than things like tool bars and titles etc. because of the grid inside as well
    .products-grid & {
        // products grid is actually the grid inside column.main we need this padding applying to column.main instead so that we don't have to apply to all elements inside
        margin-left: -$twenty-gutter / 2;
        margin-right: -$twenty-gutter / 2;
    } // fix for safari
    &:before,
    &:after {
        content: normal; // IE doesn't support `initial`
    }
}

.flex__column,
%flex__column {
    @extend %flex;
    flex-flow: column wrap; // If direction we need to change direction of the row to column on mobile
    &.direction {
        flex-flow: row wrap; // Reset back to row
        @include min-screen($screen__m) {
            // This breakpoint needs to match direction on widths below
            flex-flow: column wrap;
        }
    }
}

.flex__item {
    @extend %flex;
    flex: 1;
}

.flex__content {
    flex: 0 1 100%; // background-color: #efefef; // purely so we can see grid easily. would not be needed with live content
}

// ----------------------------------------------------------------------------------
// Building the structure of the grid
// Normally applied to the same container as the column or row class
// structure is used when we have multiple columns with nested columns and rows
.structure,
%structure {
    flex: 1; // Don't set  a width / height for this one
}

.structure-full,
%structure-full {
    flex: 0 1 100%;
}

.structure-three-quarters,
%structure-three-quarters {
    flex: 0 1 100%;
    @media screen and (min-width: $screen__m) {
        flex: 0 1 percentage(3/4);
    }
}

.structure-two-thirds,
%structure-two-thirds {
    flex: 0 1 100%;
    @media screen and (min-width: $screen__m) {
        flex: 0 1 percentage(2/3);
    }
}

.structure-two-fifths,
%structure-two-fifths {
    flex: 0 1 100%;
    @media screen and (min-width: $screen__m) {
        flex: 1 1 percentage(2/5);
    }
}

.structure-half,
%structure-half {
    flex: 0 1 100%;
    @media screen and (min-width: $screen__m) {
        flex: 0 1 percentage(1/2);
    }
}

.structure-third,
%structure-third {
    flex: 0 1 100%;
    @media screen and (min-width: $screen__m) {
        flex: 0 1 percentage(1/3);
    }
}

.structure-quarter,
%structure-quarter {
    flex: 0 1 percentage(1/2);
    @media (min-width: 768px) {
        flex: 0 1 percentage(1/4);
    }
}

.structure-fifth,
%structure-fifth {
    flex: 0 1 percentage(1/2);
    @media screen and (min-width: $screen__m) {
        flex: 1 1 percentage(1/5);
    }
}

.structure-sixth,
%structure-sixth {
    flex: 0 1 percentage(1/2);
    @media screen and (min-width: $screen__m) {
        flex: 0 1 percentage(1/6);
    }
}

// ----------------------------------------------------------------------------------
// ----------------------------------------------------------------------------------
// The Grid Elements using FLEX
// widths are based on basic 12 column grid concept on desktop 'dt'
// these would be applied to grid__item
// Better fallbacks for ie11 :(
@supports (display: flex) {
    // percentages
    .dt-width-10,
    %dt-width-10 {
        flex: 0 1 percentage(1/3);
        @include min-screen($screen__m) {
            flex: 0 1 10%;
        }
    }

    .dt-width-15,
    %dt-width-15 {
        flex: 0 1 percentage(1/3);
        @include min-screen($screen__m) {
            flex: 0 1 15%;
        }
    }

    .dt-width-20,
    %dt-width-20 {
        flex: 0 1 percentage(1/3);
        @include min-screen($screen__m) {
            flex: 0 1 20%;
        }
    }
    .dt-width-30,
    %dt-width-30 {
        flex: 0 1 50%;
        @include min-screen($screen__m) {
            flex: 0 1 30%;
        }
    }
    .dt-width-40,
    %dt-width-40 {
        flex: 0 1 50%;
        @include min-screen($screen__m) {
            flex: 0 1 40%;
        }
    }
    .dt-width-60,
    %dt-width-60 {
        flex: 0 1 100%;
        @include min-screen($screen__m) {
            flex: 0 1 60%;
        }
    }
    .dt-width-70,
    %dt-width-70 {
        flex: 0 1 100%;
        @include min-screen($screen__m) {
            flex: 0 1 70%;
        }
    }
    .dt-width-80,
    %dt-width-80 {
        flex: 0 1 100%;
        @include min-screen($screen__m) {
            flex: 0 1 80%;
        }
    }
    .dt-width-90,
    %dt-width-90 {
        flex: 0 1 100%;
        @include min-screen($screen__m) {
            flex: 0 1 90%;
        }
    } // fractions
    .dt-width-full,
    %dt-width-full {
        // Silent class added here so it can be applied to bluefoot classes that can't be removed as well
        flex: 0 1 100%;
    }
    .dt-width-three-quarters,
    %dt-width-three-quarters {
        flex: 0 1 percentage(3/4);
    }
    .dt-width-two-thirds,
    %dt-width-two-thirds {
        flex: 0 1 percentage(2/3);
    }
    .dt-width-half,
    %dt-width-half {
        flex: 0 1 percentage(1/2);
    }

    .dt-width-third,
    %dt-width-third {
        flex: 0 1 100%;

        @include min-screen($screen__m) {
            flex: 0 1 percentage(1/3);
        } // Any row with an odd number of items will default to one full width at the top
        // and then half width items in rows of two on mobile if this class is added
        &.odd {
            flex: 0 1 percentage(1/2);

            @include min-screen($screen__m) {
                flex: 0 1 percentage(1/3);
            }
        }
    }

    .dt-width-quarter,
    %dt-width-quarter {
        flex: 0 1 percentage(1/2);

        @include min-screen($screen__m) {
            flex: 0 1 percentage(1/3); // in product grid we don't want this to drop to three per row.
        }

        @include min-screen($screen__l) {
            flex: 0 1 percentage(1/4);
        }

        .account-top-links & {
            flex: 0 1 100%;

            @include min-screen($screen__s) {
                flex: 0 1 percentage(1/2);
            }

            @include min-screen($screen__m) {
                flex: 0 1 percentage(1/4);
            }
        }
    }

    .dt-width-fifth,
    %dt-width-fifth {
        // Any row with an odd number of items will default to one full width at the top
        // and then half width items in rows of two on mobile
        flex: 0 1 percentage(1/2);

        @include min-screen($screen__m) {
            flex: 0 1 percentage(1/5);
        }

        &:first-child {
            flex: 0 1 100%;
            @include min-screen($screen__m) {
                flex: 0 1 percentage(1/5);
            }
        }
    }
    .dt-width-sixth,
    %dt-width-sixth {
        flex: 0 1 percentage(1/2);
        @include min-screen($screen__m) {
            flex: 0 1 percentage(1/3);
        }
        @include min-screen($screen__l) {
            flex: 0 1 percentage(1/6);
        }
    }

    .dt-width-seventh,
    %dt-width-seventh {
        flex: 0 1 percentage(1/2);
        @include min-screen($screen__m) {
            flex: 0 1 percentage(1/3);
        }
        @include min-screen($screen__l) {
            flex: 0 1 percentage(1/7);
        }
    }

    .dt-width-eighth,
    %dt-width-eighth {
        flex: 0 1 percentage(1/2);
        @include min-screen($screen__m) {
            flex: 0 1 percentage(1/4);
        }
        @include min-screen($screen__l) {
            flex: 0 1 percentage(1/8);
        }
    }
} // closes @supports
