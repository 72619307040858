/* -------------------------------------------------
 * -------------------------------------------------
 * Cookie Script
 * For Cookie Script banner & related components
 * -------------------------------------------------
 * @author Fisheye
 */

// Cookie Banner category checkboxes
[id="cookiescript_checkboxs"] {
	justify-content: center;

	span.cookiescript_checkbox_text {
		@include type__11;
	}
}

// Button to trigger Cookie Banner popup
.csconsentlink {
	@include button__journey;

	margin-top: rem(8);
}
