/* -------------------------------------------------
 * -------------------------------------------------
 * Specific Styling to Blog Articles
 *
 * Most styles should be in relevant stylesheet i.e. buttons.scss
 *
 * -------------------------------------------------
 * @author Fisheye
 */
 
 .blog-post-view {
	.sidebar-additional {
		@extend %visuallyhidden;
	}

	.breadcrumbs {
		background-color: $gallery;
	}

	.columns {
		.main {
			width: 100%;
		}
	}

	.post-view {
		.post-content {
			.post-ftimg-hld {
				@extend %visuallyhidden;
			}

			.row-with-top-mobile-image {
				.pagebuilder-column {
					&:not(.column-with-image) {
						@include max-screen($screen__m - 1) {
							order: 1;
						}
					}
				}
			}

			.row-with-borders {
				border-bottom: 1px solid $mercury !important; // To override pagebuilder styles
				border-top: 1px solid $mercury !important; // To override pagebuilder styles
				margin: $twenty-gutter 0;
				padding: $twenty-gutter 0;

				@include min-screen($screen__m) {
					margin: $gutter 0;
					padding: $sixty-gutter 0;
				}

				.pagebuilder-column-group {
					margin-left: auto;
					margin-right: auto;
					max-width: rem(1165);
				}
			}

			.pagebuilder-column {
				@include max-screen($screen__m - 1) {
					padding-left: 0 !important; // To override pagebuilder styles
					padding-right: 0 !important; // To override pagebuilder styles
				}
			}

			[data-content-type='image'] {
				margin-bottom: $fifteen-gutter;

				@include min-screen($screen__m) {
					margin-bottom: $gutter;
				}

				.pagebuilder-image {
					display: block;
				}

				img {
					width: 100%;
				}
			}

			p {
				@include type__16;
				margin-bottom: rem(25);

				strong {
					font-weight: $font-weight-semibold;
				}
			}
		}

		.post-bottom {
			display: flex;
			flex-direction: column-reverse;

			.post-social-info {
				display: flex;
				justify-content: center;
				order: 3;
				padding: $twenty-gutter 0;

				@include min-screen($screen__m) {
					padding: $gutter 0;
				}

				.post-social-container {
					align-items: center;
					display: flex;

					@include max-screen($screen__m - 1) {
						flex-direction: column;
					}
				}
			}
		}

		.post-nextprev-hld {
			display: flex;
			justify-content: space-between;
			order: 4;

			.nextprev-link {
				@include button__journey(
					$padding: rem(9) $fifteen-gutter,
					$border-radius: 0
				);
				align-items: center;
				flex: 1 1 calc((100% - 1px) / 2);
				justify-content: center;

				& { // Required to override display set within button__journey mixin
					display: flex;
				}

				&:before,
				&:after {
					display: block;
					height: type-scale(14);
					margin-top: 1px; // Included to align with font that has a 1px whitespace
					width: type-scale(14);
				}

				span {
					display: inline-block;
					position: relative;
				}
			}

			.prev-link {
				margin-right: 0.5px;

				&:before {
					@include svg-background(
						$name: 'arrow-left-white',
						$size: contain
					);
					content: '';
				}

				&:hover,
				&:focus {
					&:before,
					&:after {
						@include svg-background(
							$name: 'arrow-left-shadow-green',
							$size: null
						);
					}
				}
			}

			.next-link {
				margin-left: 0.5px;

				&:after {
					@include svg-background(
						$name: 'arrow-right-white',
						$size: contain
					);
					content: '';
				}

				&:hover,
				&:focus {
					&:before,
					&:after {
						@include svg-background(
							$name: 'arrow-right-shadow-green',
							$size: null
						);
					}
				}
			}
		}
	}

	.post {
		&-info {
			display: flex;
		}

		&-author,
		&-posed-date {
			margin: 0 $fifteen-gutter;

			> .label {
				display: none;
			}
		}

		&-author {
			a {
				padding-left: $gutter;
				position: relative;
					
				&:before {
					@include svg-background(
						$name: 'account-icon',
						$size: contain
					);
					bottom: 0;
					content: '';
					display: block;
					height: rem(21);
					left: 0;
					margin: auto;
					position: absolute;
					top: 0;
					width: rem(22);
				}

				&:hover,
				&:focus {
					&:before {
						@include svg-background(
							$name: 'account-icon-shadow-green',
							$size: null
						);
					}
				}
			}
		}

		&-posed-date {
			order: 1;

			.value {
				padding-left: $gutter;
				position: relative;
					
				&:before {
					@include svg-background(
						$name: 'calendar-icon',
						$size: contain
					);
					bottom: 0;
					content: '';
					display: block;
					height: rem(21);
					left: 0;
					margin: auto;
					position: absolute;
					top: 0;
					width: rem(22);
				}
			}
		}

		&-tags {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			margin-bottom: $gutter;
			order: 2;

			li {
				margin-bottom: $ten-gutter;

				&:not(:last-child) {
					margin-right: $ten-gutter;
				}
			}

			a {
				background-color: $gallery;
				border-radius: 100px;
				display: block;
				padding: rem(1) $fifteen-gutter $five-gutter;

				&:hover {
					color: $acapulco;
					opacity: 1;
				}
			}
		}
	}

	.at-share-tbx-element {
		@include max-screen($screen__m - 1) {
			margin-top: $twenty-gutter;
		}

		.at-share-btn-elements {
			display: flex;
		}

		.at-share-btn {
			margin: 0 $ten-gutter;
		}
	}

	.post-links {
		> p {
			margin-bottom: $ten-gutter !important;
		}

		p:last-child {
			margin-top: rem(29);
		}
	}

	.post-text-hld {
		.post-col-row {
			text-align: center;
		
			@include min-screen($screen__m) {
				text-align: left;
			}
		}

		[data-content-type="row"] {
			.pagebuilder-column-group {
				@include max-screen($screen__ll - 1) {
					flex-direction: column;
				}

				.pagebuilder-column {
					@include max-screen($screen__ll - 1) {
						width: 100% !important; // Override inline Pagebuilder width
						flex: 1 1 100%;
						padding: 0 !important; // Override inline Pagebuilder padding
					}
				}
			}
		}

		.post-col-one {
			padding: 0;

			@include min-screen($screen__m) {
				padding-right: $five-gutter;
			}

			@include min-screen($screen__ml) {
				padding-right: $ten-gutter;
			}
		
			@include min-screen($screen__ll) {
				padding: 0 rem(25) rem(35) 0;
			}

			> figure {
				> picture {
					> img {
						height: auto;
						object-fit: cover;
						width: 100%;
					}
				}
			}
		}

		.post-col-two {
			padding: 0;

			@include min-screen($screen__m) {
				padding-left: $five-gutter;
			}

			@include min-screen($screen__ml) {
				padding-left: $ten-gutter;
			}

			@include min-screen($screen__ll) {
				padding: 0 0 0 rem(25);
			}
		}
	}

	.block.posts-related {
		@include min-screen($screen__m) {
			padding-bottom: $gutter;
		}
	}
}

.post-col-text {
	max-width: none;

	@include min-screen($screen__ll) {
		margin: 0 auto;
		max-width: 1100px;
	}
}

[id='block-relatedposts-heading'] {
	background-color: $wild-sand;
}
