// -------------------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------------------
//  All hacky code should be here
//  There should never be !important used in any file other than this or .defend
//  Please make sure you comment these well so its clear why this was needed
//  @author Fisheye
// -------------------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------------------
// Label text is currently entered in capitals, cannot find the translation for it. Using first-letter to make first letter capital and the rest lowercase.
.price-label {
	display: inline-block;
	text-transform: lowercase;

	&::first-letter {
		text-transform: uppercase;
	}
}

// PayPal checkout buttons are iFrames, so in order to style this correctly !importants are needed to override the iFrame styling
// The PayPal checkout buttons have been styled as much as possible in the Magento Admin, but have limited styling options to match designs
.checkout-methods-items {
	.express-checkout {
		.zoid-outlet {
			width: 100% !important;
		}
	} 
}
