/* -------------------------------------------------
 * -------------------------------------------------
 * General product listings. Mostly category pages
 *
 * Can also be in cross sells and upsells -
 * see _cms-products-list.scss for more styles for products grid in cms areas
 * Nearly everything should be defined elsewhere i.e.
 * how prices display, typography for product title, buttons etc.
 *
 * -------------------------------------------------
 * @author Fisheye
 */

.products-wrapper {
	&.products-grid {
		padding: 0 $fifteen-gutter;

		@include min-screen($screen__ml) {
			padding: 0 $gutter;
		}

		&:before,
		&:after {
			@include max-screen($screen__m - 1) {
				content: "";
				display: table;
				clear: both;
			}
		}
	}

	.product-listing-container {
		margin-right: rem(-7.5);
		margin-left: rem(-7.5);

		@include min-screen($screen__ml) {
			margin-right: -$fifteen-gutter;
			margin-left: -$fifteen-gutter;
		}
	}
}

.product-listing {
	flex: 0 1 percentage(1/2);
	padding: $fifteen-gutter rem(7.5);
	position: relative;
	display: flex;

	&:before {
		content: "";
		display: block;
		height: 1px;
		background: $mercury;
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		width: auto;

		@include min-screen($screen__ml) {
			width: 38vw;
		}
	}

	@include min-screen($screen__m) {
		flex: 0 1 percentage(1/3);
	}

	@include min-screen($screen__ml) {
		flex: 0 1 percentage(1/4);
	}

	@include min-screen($screen__ll) {
		flex: 0 1 percentage(1/5);
		padding: $gutter $fifteen-gutter;
	}

	&:first-of-type,
	&:nth-of-type(2) {
		&:before {
			display: none;
		}
	}

	&:nth-of-type(3) {
		&:before {
			@include min-screen($screen__m) {
				display: none;
			}
		}
	}

	&:nth-of-type(4),
	&:nth-of-type(5) {
		&:before {
			@include min-screen($screen__ml) {
				display: none;
			}
		}
	}

	&:nth-of-type(odd) {
		&:before {
			@include max-screen($screen__m - 1) {
				left: rem(7.5);
			}
		}
	}

	&:nth-of-type(even) {
		&:before {
			@include max-screen($screen__m - 1) {
				right: rem(7.5);
			}
		}
	}

	&:nth-of-type(3n) {
		&:before {
			@include min-screen($screen__m) {
				right: rem(7.5);
			}

			@include min-screen($screen__ml) {
				right: 0;
			}
		}
	}

	&:nth-of-type(3n + 1) {
		&:before {
			@include min-screen($screen__m) {
				left: rem(7.5);
			}

			@include min-screen($screen__ml) {
				left: 0;
			}
		}
	}

	&:nth-of-type(5n) {
		&:before {
			@include min-screen($screen__ml) {
				right: $fifteen-gutter;
			}
		}
	}

	&:nth-of-type(5n + 1) {
		&:before {
			@include min-screen($screen__ml) {
				left: $fifteen-gutter;
			}
		}
	}
}

.product-item-info {
	display: flex;
	flex-flow: column nowrap;
	height: 100%;
	width: 100%;

	.product-image-wrapper {
		padding-bottom: 0 !important; // Override inline padding bottom
		height: rem(200);

		@include min-screen($screen__m) {
			height: rem(220);
		}
	}

	.product-item-details {
		padding-top: $ten-gutter;

		.product-item-inner {
			.product-item-actions {
				.actions-primary {
					form {
						min-height: rem(105);
						display: flex;
						flex-direction: column;
						justify-content: space-between;
					}
				}
			}
		}

		.swatch-input {
			display: none; // remove input field for swatches
		}
	}

	.product-details-flex {
		display: flex;
		flex-wrap: wrap;
		min-height: 38px;
		flex: 1 1 100%;
	}

	.product-item-name {
		flex-basis: 100%;
		display: block;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;

		a {
			display: block;
			@include type__15;

			@include min-screen($screen__ml) {
				@include type__16
			}
		}
	}

	.price-box {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;

		p.price-to {
			@extend %visuallyhidden;
		}

		p.price-from {
			display: flex;
			flex-direction: column-reverse;
		}

		.old-price {
			display: block;
			width: 100%;
		}

		&.price-abstract_price {
			flex-direction: row;
			justify-content: flex-start;

			.price-label {
				@extend %visuallyhidden;
			}

			.aw-period {
				.separator {
					padding: 0 $five-gutter;
				}
			}
		}
	}

	.inc-vat-label {
		display: none;
	}

	.minimal-price-link { // refers to  tier pricing but duplicates price
		display: none;
	}

	.product-item-inner {
		flex: 1 1 100%;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		min-height: 104px;

		.price-weight-container {
			display: flex;
			align-items: flex-start;
			flex-flow: row wrap;
			flex: 1;
			flex-direction: column;

			.price-box {
				margin-right: rem(8);
				line-height: 1.4;
				text-align: left;
			}
		}

		.product-price-tooltip {
			position: relative;

			.dietary-tooltip-wrapper {
				position: absolute;
				top: 0;
				right: 0;
				display: flex;
				justify-content: flex-end;
				align-self: flex-end;
				margin-left: auto;
				padding-left: $fifteen-gutter;
			}

			.uom-denomination {
				> label {
					@extend %visuallyhidden; // Hide "Choose Weight" label
				}
			}
		}
	}

	.product-item-photo {
		display: block;
		position: relative;
		text-align: center;
		cursor: pointer;

		.product-short-desc {
			&:not(:empty) {
				@include transition(opacity 0.4s ease-in-out);
				@include type__17(
					$color: $white,
					$font-family: $font-family-serif,
					$letter-spacing: 1px
				);
				align-items: center;
				background: rgba($color: $mine-shaft, $alpha: 0.7);
				bottom: 0;
				display: flex;
				font-style: italic;
				justify-content: center;
				left: 0;
				margin: auto;
				opacity: 0;
				padding: $gutter;
				position: absolute;
				right: 0;
				top: 0;
			}

			.short-desc-text {
				display: -webkit-box;
				-webkit-line-clamp: 5;
				-webkit-box-orient: vertical;
				overflow: hidden;
			}
		}

		.featured-label {
			padding: $five-gutter $ten-gutter;
			background-color: rgba($color: $white, $alpha: 0.7);
			position: absolute;
			right: 0;
			bottom: 0;
			left: 0;
			@include type__14(
				$font-weight: $font-weight-semibold,
				$letter-spacing: 1px
			);
			text-align: center;
			text-transform: uppercase;
		}

		&:hover,
		&:focus {
			&,
			.product-short-desc {
				opacity: 1;
			}
		}

		.product-image-photo {
			width: 100%;
		}
	}

	div[class^="swatch-opt"] { // no generic classes available
		flex: 0 1 auto;

		.swatch-attribute {
			.swatch-option {
				cursor: pointer;
				margin-bottom: $ten-gutter;
				margin-left: $five-gutter;
				margin-right: $five-gutter;
			}

			&.height {
				.swatch-option {
					@include type__13($color: $silver-chalice);
					flex: $category-swatches-flex;
					border: 1px solid $silver;
					padding: rem(6) rem(8);

					@include min-screen($screen__m) {
						flex: 0 1 auto;
					}

					&.selected {
						border-color: $blaze-orange;
					}
				}
			}

			&.colour {
				.swatch-option {
					height: 30px;
					width: 30px;
					border: 2px solid $white;
					outline: 1px solid $silver;

					@include min-screen($screen__m) {
						height: 34px;
						width: 34px;
					}

					&.image {
						height: 30px !important; // override inline styles specific to image uploads
						width: 30px !important;
						background-size: cover !important;

						@include min-screen($screen__m) {
							height: 34px !important;
							width: 34px !important;
						}
					}

					&.selected {
						outline-color: $blaze-orange;
					}
				}
			}

			.swatch-more {
				flex: 1 1 100%;

				span {
					@include type__13(
						$color: $body-font-color,
						$font-weight: $font-weight-normal
					);
					@include link__underline;
				}
			}
		}

		.swatch-attribute-options {
			display: flex;
			flex-direction: column;
			align-items: center;
			max-width: rem(250);
			margin: 0 auto;

			@include min-screen($screen__m) {
				flex-flow: row wrap;
				justify-content: center;
			}
		}
	}

	.product-reviews-summary {
		padding-bottom: $category-product-item-review-bottom-spacing;
	}

	.rating-summary {
		.rating-result,
		.label {
			display: inline;
		}
	}

	.actions-secondary {
		@if $category-product-listing-wishlist-display == none and $category-product-listing-compare-display == none {
			display: none;
		}
	}

	.action.preorder {
		@if $category-product-listing-preorder-display == none {
			display: none;
		}
	}

	.tocart { // button
		@include button__journey;
		display: $category-product-listing-button-display;
		width: 100%;
	}

	.towishlist {
		display: $category-product-listing-wishlist-display;
	}

	.tocompare {
		display: $category-product-listing-compare-display;
	}

	.towishlist,
	.tocompare {
		align-content: center;
		text-align: left;

		&:before {
			content: "" !important; // cancels font-awesome icon from Magento UI
			display: block;
			width: 22px;
			height: 22px;
			top: 0;
			position: relative; // use these to hide icon until hover so that space the icon needs is still being used
		}

		span {
			padding: $category-compare-wishlist-spacing;
		}
	}

	.towishlist:before {
		@include svg-background($name: "heart", $size: contain);
	}

	.tocompare:before { // ToDo change icon to a compare icon
		@include svg-background($name: "star", $size: contain);
	}
}

// -------------------------------------------------------------------------------------
// Category Slider
// -------------------------------------------------------------------------------------
.category-cms-additional {
	.cms-brands {
		padding: $forty-gutter $gutter;

		@include min-screen($screen__m) {
			padding: em(77) $gutter;
		}

		li {
			margin-right: $five-gutter;

			@include min-screen($screen__m) {
				margin-right: $twenty-gutter;
			}

			&:last-child {
				margin-right: 0;
			}

			span {
				margin-top: $fifteen-gutter;
				max-width: em(195);
				white-space: pre-wrap;
			}
		}
	}
}

// -------------------------------------------------------------------------------------
// UOM Inputs
// -------------------------------------------------------------------------------------
.uom-denomination {
	select {
		@include type__16(
			$font-weight: $font-weight-normal,
			$line-height: 1.4
		);
		padding: 0 $twenty-gutter 0 0;
		margin-top: rem(5);
		border: 0;
		background-size: 13px 15px;
		background-position-x: right;
		background-position-y: center;
		width: fit-content;
	}
}
