/* -------------------------------------------------
 * -------------------------------------------------
 * Banner Styles
 * Used for common banners across sites
 * --------------------------------------------------
 * @author Fisheye
 */

// ----------------------------------------------------------------------------
// Product banners
// Bluefoot product banners, common on the homepage
// ----------------------------------------------------------------------------

.banner__products {
	.slick-track {
		display: flex;
	}

	.slick-slide {
		height: auto;
	}
	
	.flex__row {
		// The margins added to flex__row in flex-grid.scss is not required inside these product banners
		margin-left: 0;
		margin-right: 0;
	}
	
	.slick-slide {
		height: auto;
	}

	.flex__column {
		// This fixes a weird issue where slick slider applies a huge width to products on mobile.
		max-width: 100%;
	}
	
	.products.grid {
		max-width: 100%;
	}

	.bluefoot-structural {
		width: 100%;
	}
}

// ----------------------------------------------------------------------------
// "Great For" Banners
// Bluefoot banners on the homepage, usually linking to specific categories
// ----------------------------------------------------------------------------

.row.banner__for__them {
	padding-bottom: 0;

	.bluefoot-structural {
		width: 100%;
	}

	.banner-item .banners__info {
		position: static;
		padding: $banner-great-for-mobile-spacing;

		@include min-screen($screen__m) {
			padding: $banner-great-for-spacing;
		}

		h2 {
			@include type__18(
				$color: $banner-great-for-color,
				$font-weight: $banner-great-for-weight,
				$letter-spacing: $banner-great-for-letter-spacing,
			);
		}

		button {
			@include button__default(
				$border: 2px solid $white,
				$color: $white
			);
			width: $banner-great-for-button-width;
			margin-top: $banner-great-for-button-spacing-top;
		}
	}
}

// ----------------------------------------------------------------------------
// "Great For" Banners
// Bluefoot banners on the homepage, usually linking to specific categories
// ----------------------------------------------------------------------------

.row.banner__brands {
	display: $banner-brands-mobile-display;
	padding: $banner-brands-inner-spacing;
	background-color: $banner-brands-background-color;

	@include min-screen($screen__m) {
		display: $banner-brands-desktop-display;
	}

	.row-container {
		padding-left: 0;
		padding-right: 0;
	}

	.bluefoot-structural {
		width: 100%;
	}

	.listbuilder {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.listbuilder-item {
		flex: 0 1 auto;
		padding: 0 $banner-brands-outer-horizontal-spacing;
		
		img {
			width: 100%;
		}
	}

	.button {
		flex: 0 0 auto;
		
		a {
			@include button__default;
			display: block;
		}
	}
}

// ----------------------------------------------------------------------------
// "Our Story" Banners
// Bluefoot banners on the homepage
// ----------------------------------------------------------------------------

.row.banner__our__story {
	display: $banner-our-story-mobile-display;
	background-color: $banner-our-story-background-color;
	padding: $banner-our-story-inner-spacing;

	@include min-screen($screen__m) {
		display: $banner-our-story-desktop-display;
	}

	.bluefoot-structural {
		flex: 1;
	}

	h2 {
		margin-bottom: $banner-our-story-header-outer-vertical-spacing;
		
		span {
			@include type__30(
				$color: $banner-our-story-header-color,
				$font-weight: $banner-our-story-header-weight
			);
		}
	}

	.bluefoot-textarea {
		margin: 0;
	}

	p {
		@include type__16;
	}

	.bluefoot-buttons {
		margin: 0;
	}

	.button {
		@include button__default;
		margin-top: $banner-our-story-button-spacing-vertical;
		margin-bottom: 0;
	}
}
