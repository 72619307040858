/* -------------------------------------------------
 * -------------------------------------------------
 * Base Input styles
 *
 * All border and background colors should be set in _theme-variables.scss.
 * Not here
 *
 * Expected Usage:
 *	input {
 *		@extends %input-style-one;
 *	}
 *
 * -------------------------------------------------
 * @author Fisheye
 */

// -------------------------------------------------
// Primary input, used globally for most inputs
// -------------------------------------------------

@mixin input-style-one(
	$border: $field-border,
	$background-color: $field-background-color,
	$color: $field-color,
	$border-radius: $field-border-radius, // For Safari, it seems to add a radius.
	$padding: $field-padding
) {
	min-height: rem(40);
	max-height: rem(42);
	width: 100%;
	padding: $padding;
	background-color: $background-color;
	border: $border;
	border-radius: $border-radius;
	-webkit-appearance: none;

	@include type__16($color: $field-color);

	&::placeholder {
		opacity: 1;
		@include type__14(
			$color: $field-placeholder-color,
			$font-weight: $font-weight-semibold
		);
		text-transform: uppercase;
	}

	&:hover,
	&:active {
		border: $field-border-hover;
	}

	&:focus {
		border: $field-border-focus;

		&:placeholder {
			opacity: 0;
		}
	}
}

// -------------------------------------------------
// Secondary input, typically used for search
// -------------------------------------------------

@mixin input-style-two(
	$border: $field-two-border,
	$background-color: $field-two-background-color,
	$color: $field-two-color,
	$border-radius: $field-two-border-radius, // For Safari, it seems to add a radius.
	$padding: $field-two-padding,
) {
	@include type__16(
		$color: $field-two-color,
		$font-weight: $font-weight-light,
		$font-family: $body-font-family
	);
	border: $border;
	background-color: $background-color;
	color: $color;
	border-radius: $border-radius;
	padding: $padding;
	-webkit-appearance: none;

	&::placeholder {
		@include type__16(
			$color: $field-two-placeholder-color,
			$font-weight: $field-two-placeholder-weight,
			$font-family: $body-font-family
		);
		font-style: $field-two-placeholder-font-style;
		opacity: 1;
		line-height: normal;
	}

	&:hover,
	&:active {
		border: $field-two-border-hover;
	}

	&:focus {
		border: $field-two-border-focus;

		&:placeholder {
			opacity: 0;
		}
	}
}

// -------------------------------------------------
// Tertiary input, used for desktop search
// -------------------------------------------------

@mixin input-style-three(
	$border: $field-three-border,
	$background-color: $field-three-background-color,
	$color: $field-three-color,
	$border-radius: $field-three-border-radius, // For Safari, it seems to add a radius.
	$padding: $field-three-padding,
) {
	@include type__19(
		$color: $field-three-color,
		$font-weight: $font-weight-medium,
		$font-family: $font-family-serif
	);
	@include min-screen($screen__m) {
		@include type__35(
			$color: null,
			$font-weight: null,
			$font-family: null
		);
	}
	font-style: italic;
	border: $border;
	background-color: $background-color;
	color: $color;
	border-radius: $border-radius;
	padding: $padding;
	-webkit-appearance: none;

	&::placeholder {
		@include type__19(
			$color: $field-three-color,
			$font-weight: $font-weight-medium,
			$font-family: $font-family-serif
		);
		@include min-screen($screen__m) {
			@include type__35(
				$color: null,
				$font-weight: null,
				$font-family: null
			);
		}
		font-style: $field-three-placeholder-font-style;
		opacity: 1;
		line-height: normal;
	}

	&:hover,
	&:active {
		border: $field-three-border-hover;
	}

	&:focus {
		border: $field-three-border-focus;

		&:placeholder {
			opacity: 0;
		}
	}
}

// -------------------------------------------------
// Quaternary input, typically used for misc fields 
// (e.g. newsletter field in footer)
// -------------------------------------------------

@mixin input-style-four(
	$border: $field-four-border,
	$background-color: $field-four-background-color,
	$color: $field-four-color,
	$border-radius: $field-four-border-radius, // For Safari, it seems to add a radius.
	$padding: $field-four-padding,
) {
	@include type__16(
		$color: $field-four-color,
		$font-weight: $font-weight-light
	);
	border: $border;
	background-color: $background-color;
	color: $color;
	border-radius: $border-radius;
	padding: $padding;
	-webkit-appearance: none;

	&::placeholder {
		@include type__16(
			$color: $field-four-placeholder-color,
			$font-weight: $field-four-placeholder-weight
		);
		font-style: $field-four-placeholder-font-style;
		opacity: 1;
		line-height: normal;
	}

	&:hover,
	&:active {
		border: $field-four-border-hover;
	}

	&:focus {
		border: $field-four-border-focus;

		&:placeholder {
			opacity: 0;
		}
	}
}

// -------------------------------------------------
// Used for QTY fields
// -------------------------------------------------

@mixin input-style-five(
	$border: $field-five-border,
	$background-color: $field-five-background-color,
	$border-radius: $field-five-border-radius,
	$padding: $field-five-padding,
	$width: $field-five-width,
	$height: $field-five-height
) {
	@include type__16(
		$font-family: $body-font-family,
		$color: $field-five-color,
		$line-height: 1
	);
	border: $border;
	background-color: $background-color;
	border-radius: $border-radius;
	padding: $padding;
	width: $field-five-width;
	height: $field-five-height; 
	text-align: center;
	padding-bottom: rem(2);

	&:placeholder {
		@include type__16(
			$font-family: $body-font-family,
		);
	}

	&:hover,
	&:active {
		border: $field-five-border-hover;
	}

	&:focus {
		border: $field-five-border-focus;

		&:placeholder {
			opacity: 0;
		}
	}

	&.qty-increment {
		background-color: $gallery;
		background-position: center;
		border: none;
		font-size: 0;
		width: rem(29);
		height: $field-five-height; 

		&.qty-minus {
			@include svg-background(
				$name: "minus",
				$size: 10px
			);
			border-radius: 4px 0 0 4px;
		}

		&.qty-plus {
			@include svg-background(
				$name: "plus",
				$size: 10px
			);
			border-radius: 0 4px 4px 0;
		}
	}
}

// -------------------------------------------------
// Global select style
// -------------------------------------------------

// Default select style
@mixin select-style-one(
	$border: $select-border,
	$background-color: $select-background-color,
	$color: $select-color,
	$border-radius: $select-border-radius, // For Safari, it seems to add a radius.
	$padding: $select-padding,
) {
	@include type__13(
		$color: $color,
		$font-weight: $select-weight
	);
	-webkit-appearance: none;
	width: 100%;
	border: $select-border;
	background-color: $select-background-color;
	@include svg-background(
		$name: "arrow-down",
		$size: $select-arrow-background-size
	);
	background-position: $select-arrow-background-position; // background image used instead of pseudo to make the arrow clickable
	border-radius: $select-border-radius;
	padding: $select-padding;
	background-size: rem(15) rem(15);

	&:placeholder {
		@include type__14;
	}

	&:hover,
	&:active {
		border: $field-border-hover;
	}

	&:focus {
		border: $field-border-focus;

		&:placeholder {
			opacity: 0;
		}
	}
}

// Select style used on layered nav items
// Note, this style may need to be applied to an element that isn't select (i.e. a div)
@mixin select-style-two(
	$border-top: $select-two-border-top,
	$border-bottom: $select-two-border-bottom,
	$border-left: $select-two-border-left,
	$border-right: $select-two-border-right,
	$background-color: $select-two-background-color,
	$color: $select-two-color,
	$border-radius: $select-two-border-radius, // For Safari, it seems to add a radius.
	$padding: $select-two-padding,
) {
	@include type__14(
		$color: $color,
		$font-weight: $select-two-weight
	);
	-webkit-appearance: none;
	width: 100%;
	border-top: $select-two-border-top;
	border-bottom: $select-two-border-bottom;
	border-left: $select-two-border-left;
	border-right: $select-two-border-right;
	background-color: $select-two-background-color;
	@include svg-background(
		$name: "arrow-down",
		$size: $select-two-arrow-background-size
	);
	background-position: $select-two-arrow-background-position; // background image used instead of pseudo to make the arrow clickable
	border-radius: $select-two-border-radius;
	padding: $select-two-padding;

	&:placeholder {
		@include type__14;
	}
}

// Select style used on category toolbars
@mixin select-style-three(
	$padding: $select-three-padding,
	$background-color: $select-three-background-color,
	$border: $select-three-border,
	$border-radius: $select-three-border-radius,
	$color: $select-three-color
) {
	height: rem(40);
	width: 100%;
	padding: $padding;
	background-color: $background-color;
	@include svg-background(
		$name: "arrow-down",
		$size: $select-three-arrow-background-size
	);
	background-position: $select-three-arrow-background-position; // background image used instead of pseudo to make the arrow clickable
	border: $border;
	border-radius: $border-radius;
	@include type__14(
		$color: $color,
		$font-weight: $select-three-weight,
		$letter-spacing: 1px,
		$line-height: 1
	);
	text-transform: uppercase;
	-webkit-appearance: none;
}

// -------------------------------------------------
// Button on success page and yotpo
// -------------------------------------------------

%input-type-button {
	// used button styles instead as the input type is submit
	@include button__default;
}

// -------------------------------------------------
// Apply input styles
// -------------------------------------------------

input,
select {	
	outline: 0;

	&:focus,
	&:active {
		outline: 0;
	}
	
	&::-ms-clear {
		display: none;
	}
}

input {
	@include input-style-one;
}

select {
	// Additional styles for select
	@include select-style-one;
	appearance: none;
	cursor: pointer;
}

textarea {
	@include input-style-one;
}

.password-strength-meter {
	background-color: $form-hints-background;
	text-align: right;
	padding: $item-gutter / 2;
}

// -------------------------------------------------
// QTY Fields
// -------------------------------------------------

.field.qty {
	.control.qty {
		display: flex;
	}
	
	label[for*="qty"] {
		@extend %visuallyhidden;
	}

	input.qty {
		@include input-style-five;
	}
}

// input type number used for qty in basket
// the arrows need to be removed as the number cannot be align center if they exist
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
 	 margin: 0;
}

// firefox specific
input[type=number] {
  -moz-appearance: textfield;
}

// -------------------------------------------------
// Remove dotted lines - Important because we never
// Want to show them
// -------------------------------------------------

 input {
	&:focus {
		outline: none !important;
	}

	&::-moz-focus-inner {
		border: 0 !important;
	}
}

// -------------------------------------------------
// Radio buttons
// -------------------------------------------------

@mixin radio-style(
	$label-padding: 0,
	$height: $radio-height,
	$width: $radio-width,
	$border: 1px solid $silver,
	$checked-border: 1px solid $shadow-green,
	$border-radius: 100%,
) {
	display: none;

	+ label {
		padding: $label-padding;
		position: relative;
		cursor: pointer;

		&:before {
			content: '';
			display: inline-block;
			height: $height;
			width: $width;
			border: $border;
			border-radius: $border-radius;
			margin-right: rem(7);

			@include svg-background(
				$name: 'radio-deselected',
				$size: $radio-height $radio-width
			);
		}
	}

	&:checked {
		+ label {
			&:before {
				content: '';
				display: inline-block;
				height: $height;
				width: $width;
				border: $checked-border;
				border-radius: $border-radius;

				@include svg-background(
					$name: 'radio-selected',
					$size: $radio-height $radio-width
				);
			}
		}
	}
}

input[type="radio"] {
	@include radio-style;
}

.radio-wrapper {
	.radio {
		display: inline-block;
		height: $radio-height;
		width: $radio-width;
		margin-right: rem(7);
		border: 1px solid $silver;
		border-radius: 100%;

		@include svg-background(
			$name: 'radio-deselected',
			$size: rem(23) rem(23)
		);

		&.active {
			border: 1px solid $shadow-green;
			border-radius: 100%;

			@include svg-background(
				$name: 'radio-selected',
				$size: rem(23) rem(23)
			);
		}
	}
}

// -------------------------------------------------
// Checkboxes
// -------------------------------------------------

@mixin checkbox-style(
	$label-padding: 0 0 0 rem(35),
	$height: rem(25),
	$width: rem(25),
	$background-color: $white,
	$border: 1px solid $silver,
	$checked-svg: "tick-shadow-green",
	$checked-border: 1px solid $shadow-green,
	$size: auto
) {
	display: none;

	+ label {
		padding: $label-padding;
		position: relative;
		cursor: pointer;

		&:before {
			content: "";
			display: block;
			height: $height;
			width: $width;
			background-color: $background-color;
			border: $border;
			margin: auto;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
		}
	}

	&:checked {
		+ label {
			&:before {
				@include svg-background(
					$name: $checked-svg,
					$size: $size
				);
				border-color: $checked-border;
			}
		}
	}
}

input[type="checkbox"] {
	@include checkbox-style;
}

// -------------------------------------------------
// Required input asterisk
// -------------------------------------------------

%required-input-after {
	content: "*";
	display: inline-block;
	color: $field-required-input-color;
	margin-left: 0;
	padding-left: $item-gutter / 2;
}

// -------------------------------------------------
// Placeholder pseudo element
// -------------------------------------------------

::placeholder {
	@include type__16;
}

// --------------------------------------------------
// remove ie11 default select arrow icon
// --------------------------------------------------

select::-ms-expand {
    display: none;
}
