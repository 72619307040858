/* -------------------------------------------------
 * -------------------------------------------------
 * Specific Styling to Contact Page
 * -------------------------------------------------
 * @author Fisheye
 */

.cms-contact-us {
	.page-title-wrapper {
		display: none;
	}

	.main {
		a[href^="tel:"] {
			font-weight: $font-weight-semibold;
		}

		a[href^="mailto:"] {
			color: $acapulco;
		}

		.telephone {
			margin-top: rem(12);

			@include min-screen($screen__m) {
				margin-top: rem(9);
			}
		}
	}
}

.cms-contact-heading {
	padding: $gutter $page-padding-mobile rem(34);
	background-color: $gallery;

	@include min-screen($screen__m) {
		padding: rem(63) $gutter;
	}

	p {
		line-height: 1.45;
	}

	h1 {
		@include type__33($font-family: $font-family-serif);

		@include min-screen($screen__m) {
			@include type__52($font-family: null);
		}
	}

	h2 {
		@include type__22(
			$color: null,
			$font-weight: $font-weight-normal
		);
	}
}

.cms-contact-heading-orders {
	background-color: $white;
	max-width: 748px;
	width: 100%;
	margin: rem(14) auto 0;
	padding: $twenty-gutter;

	@include min-screen($screen__m) {
		margin-top: rem(34);
		padding: rem(27);
	}

	.including {
		margin-top: $fifteen-gutter;

		@include min-screen($screen__m) {
			margin-top: rem(14);
		}
	}

	.email {
		margin-top: rem(1);

		@include min-screen($screen__m) {
			margin-top: 0;
			line-height: 1.35;
		}
	}
}

.cms-contact-heading-tracking {
	background-color: $mine-shaft;
	max-width: 748px;
	width: 100%;
	margin: 0 auto;
	padding: rem(26) rem(19) rem(19);
	color: $white;

	@include min-screen($screen__m) {
		padding: rem(23) 0 $twenty-gutter;
	}

	h2 {
		font-family: $font-family-serif;
		font-weight: $font-weight-normal;
	}

	.despatch {
		margin-top: rem(13);

		@include min-screen($screen__m) {
			margin-top: rem(2);
		}
	}

	a {
		color: $white;

		&:not([href^="tel:"]) {
			text-decoration: underline;
		}
	}
}

.cms-contact-other {
	margin-top: $gutter;
	padding: 0 $fifteen-gutter;

	@include min-screen($screen__m) {
		margin-top: rem(69);
	}

	h2 {
		@include min-screen($screen__m) {
			margin-bottom: rem(38);
		}
	}

	.pagebuilder-column {
		@include min-screen($screen__m) {
			padding: 0 $fifteen-gutter;
		}

		@include max-screen($screen__m) { // Max media query as to not undo global styles
			&:not(:first-child) {
				[data-content-type="accordion"] {
					border-top: 0;
				}
			}
		}

		a[href^="tel:"] {
			font-weight: $font-weight-normal;
		}
	}
}
