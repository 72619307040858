/* -------------------------------------------------
 * -------------------------------------------------
 * Delivery address boxes when logged in
 *
 * -------------------------------------------------
 * @author Fisheye
 */

.opc-wrapper {
	.checkout-shipping-address {
		padding-bottom: $twenty-gutter;
		border-bottom: $checkout-methods-border;
		margin-bottom: $twenty-gutter;

		@include min-screen($screen__ml) {
			padding-bottom: $gutter;
			margin-bottom: $gutter;
		}

		.personal-details {
			.input-text {
				border: 1px solid $mercury;
			}
		}

		.address-details,
		.contact-details {
			padding: $twenty-gutter;
			background-color: $gallery;
			margin-bottom: 1px;
		}

		.address-details {
			margin-top: $twenty-gutter;

			@include min-screen($screen__m) {
				margin-top: $ten-gutter;
			}

			.address-fieldset {
				.field {
					&:last-of-type {
						margin-bottom: 0;
					}

					&.choice {
						margin-bottom: 0;
					}

					&.street {
						&:not(.visuallyhidden) {
							margin: $twenty-gutter 0;
						}
					}
				}
			}

			[name="shippingAddress.country_id"] {
				.country-wrapper {
					.country-value {
						@include type__15(
							$font-weight: $font-weight-semibold
						);

						@include min-screen($screen__m) {
							@include type__16(
								$font-weight: null
							);
						}
					}
				}
			}

			[name="shippingAddress.postcode"] {
				margin-bottom: 0;

				[name="getaddress_input"],
				[name="postcode"] {
					padding-right: rem(185);
				}

				.control {
					select {
						margin-top: $twenty-gutter;
						margin-bottom: 0;
					}
				}
			}

			[name="shippingAddress.street.2"] {
				margin-bottom: $twenty-gutter;
			}
		}

		.address-rendered {
			margin: 0 0 $twenty-gutter;

			span {
				display: block;
				@include type__16($font-weight: $font-weight-semibold);
			}
		}

		.contact-details {
			.field {
				&:last-of-type {
					margin-bottom: 0;
				}
			}
		}

		.action-show-popup {
			@include button__journey;
			margin-top: $twenty-gutter;
		}
	}

	.shipping-address-items {
		padding: $twenty-gutter;
		background-color: $gallery;

		.shipping-address-item {
			.shipping-address-item-wrapper {
				@include type__16;
			}

			.item-selection {
				margin-top: $twenty-gutter;

				button {
					@include button__default;
				}
			}

			&.selected-item {
				.shipping-address-item-wrapper {
					font-weight: $font-weight-semibold;
				}

				.item-selection {
					display: none;
				}
			}

			&:not(:last-of-type) {
				padding-bottom: $twenty-gutter;
				border-bottom: 1px solid $white;
				margin-bottom: $twenty-gutter;
			}
		}
	}

	.checkout-shipping-method {
		.no-quotes-block {
			background-color: $gallery;
			padding: rem(18) $twenty-gutter rem(19);
			color: $patina;
			text-align: center;
			line-height: 1.4;

			@include min-screen($screen__m) {
				padding: rem(22) $gutter rem(26);
			}
		}

		.loading-mask {
			width: 100%;
			display: flex;
			background: rgba(255, 255, 255, 0.8);

			.loader {
				position: relative;
			}
		}

		.select-method {
			@include min-screen($screen__l) {
				position: absolute;
				top: em(20);
			}
		}

		.table-checkout-shipping-method {
			min-width: auto;
			margin-bottom: $twenty-gutter;

			@include min-screen($screen__m) {
				margin-bottom: $gutter;
			}

			tbody {
				border-bottom: none;

				td {
					padding: 0;
				}
			}

			.row {
				display: grid;
				grid-template-columns: rem(50) auto rem(50);
				grid-template-rows: auto auto;
				grid-template-areas:
					"button title price"
					"button description price"
			;
				align-items: center;
				padding: rem(23) $page-padding-mobile;
				background-color: $mercury;
				border-bottom: 1px solid $white;
				cursor: pointer;
				border-top: 0;

				&:last-child {
					border-bottom: 0;
				}

				&.selected {
					border: 2px solid $shadow-green;
				}

				&.row-error {
					.col-error {
						grid-area: description;
					}
				}
			}

			.col-price {
				order: 3;
				flex: 1;
				text-align: right;
				grid-area: price;

				.price {
					@include price;
				}
			}

			.col-method {
				grid-area: title;
				text-transform: uppercase;
				@include type__14(
					$font-weight: $font-weight-semibold,
					$letter-spacing: 1px
				);

				&:first-child {
					grid-area: button;
				}

				label { // no class to use
					&:before {
						margin-right: $fifteen-gutter;
					}
				}
			}

			.col-carrier {
				// "select shipping method" is repeated here so removed
				display: none;
			}

			.col-description {
				grid-area: description;

				.field-tooltip {
					display: flex;

					.field-tooltip-action {
						display: none; // Not needed as content always 'expanded'
					}
				}
			}
		}

		button.shq-map-popup {
			background: none;
			border: none;

			&:hover {
				color: $shadow-green;
				text-decoration: none;
				opacity: $link-opacity;
			}
		}
	}

	.shipperhq_datepicker {
		display: flex;
		flex-direction: column;
		margin-bottom: $fifteen-gutter;

		.shq_datepicker {
			width: 100%;
		}

		.ui-datepicker-header {
			.ui-datepicker-month {
				margin-right: 0;
			}
		}

		.ui-datepicker-calendar {
			tr {
				.ui-state-active {
					background-color: $shadow-green;
				}
			}
		}

		.shq-calendar-box {
			@include button__default(
				$color: $body-font-color,
				$font-weight: $font-weight-semibold
			);
			position: relative;
			width: 100%;

			&:after {
				@include svg-background(
					$name: 'calendar-icon',
					$size: contain
				);
				bottom: 0;
				content: '';
				height: 20px;
				left: $fifteen-gutter;
				margin: auto 0;
				position: absolute;
				top: 0;
				width: 20px;
			}

			&:hover {
				&:after {
					@include svg-background(
						$name: 'calendar-icon-white',
						$size: contain
					);
				}
			}

			.date-select-title {
				font-weight: $font-weight-semibold;
				margin: 0 $fifteen-gutter;
			}

			.date-select-value {
				span {
					font-weight: $font-weight-normal;
				}
			}
		}

		.date-select-title,
		.date-select-value {
			display: inline-block;

			span {
				transition: color 0.3s ease-in-out;
			}
		}
	}

	.order-comment {
		.payment-option-inner {
			.field {
				.control {
					display: flex;
					flex-flow: column wrap;
					margin-bottom: $twenty-gutter;

					@include min-screen($screen__m) {
						margin-bottom: $gutter;
					}

					[id="delivery-comment-option-error"] {
						margin-top: $ten-gutter;
					}
				}

				.order-comment-input {
					@include type__14($font-weight: $font-weight-semibold);
					height: auto;
					text-transform: uppercase;
					padding: rem(7) $fifteen-gutter rem(9);
					border: 1px solid $mercury-light;
					background-size: 14px auto;
				}
			}
		}
	}

	.subscription-delivery {
		margin-bottom: $twenty-gutter;

		@include min-screen($screen__m) {
			margin-bottom: $gutter;
		}

		.description {
			p {
				max-width: none;
			}
		}
	}
}

.page-layout-checkout {
	.opc-sidebar {
		.table-totals {
			.value {
				color: $delivery-time-text-color;
			}

			.grand {
				.mark {
					color: $grand-total-text-color;
				}
			}
		}
	}

	// Delivery Address Modal Popup
	// Specific to the checkout as global modal styles need to be overidden
	.modal-popup.modal-slide {
		&._inner-scroll {
			.modal-inner-wrap {
				background-color: $white;
				min-height: auto;

				.telephone {
					border: 0;
					padding-top: 0;
					margin-top: 0;
					margin-bottom: $twenty-gutter;
				}

				div[name="shippingAddress.postcode"] {
					input {
						flex: 1 1 48%;

						@include min-screen($screen__m) {
							flex: 1 1 55%;
						}
					}

					.find-address {
						margin-left: $ten-gutter;
					}

					[id="getaddress_error_message"] { // no class
						flex: 1 1 100%;
					}
				}

				.modal-footer {
					margin-top: 0;
				}
			}
		}
	}
}
