/* -------------------------------------------------
 * -------------------------------------------------
 * video popup base styles
 * base styles taken from module less file
 * -------------------------------------------------
 * @author Fisheye
 */

.video-embed {
	position: relative;
	padding-bottom: 56.23%;
	height: 0;
	overflow: hidden;
	max-width: 100%;
	margin: 5px;
	background-color: #FFF;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;

	&.square { // 4:3 aspect ratio
		padding-bottom: 75%;
	}

	&:hover {
		cursor: pointer;
	}

	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 100;
		background: transparent;
	}

	.play {
		height: 72px;
		width: 72px;
		left: 50%;
		top: 50%;
		margin-left: -36px;
		margin-top: -36px;
		position: absolute;
		cursor: pointer;
		background: url('../images/png/play-button.png') no-repeat center center;
	}
}