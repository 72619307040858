/* -------------------------------------------------
 * -------------------------------------------------
 * Grid CSS with fallbacks to flexbox and floats if really
 * needed. Mobile first approach with @supportst
 *
 * --------------------------------------------------
 * @author Fisheye
 */


/* Notes:
 * 1. checkout pages will need top level class updating too
 * 2. Remember that new templates created will have new classes on body too
 */


/* ----------------------------------------------------------------------------
 * ----------------------------------------------------------------------------
 *
 * HOW THIS GRID WORKS:
 * 1. The $twenty-gutter value is set to the total distance between items
 *
 * 2. Each item is then set to have a padding of half the $twenty-gutter value so that
 *    when they are placed next to each other the $twenty-gutter width is essentially
 *    ($twenty-gutter / 2) * 2 i.e. the original $twenty-gutter value
 *
 * 3. Because each outer item (usually first/last child) doesn't sit next to
 *    another item. its padding is set to only half of what it should be.
 *    Therefore the main page sections (in this case header, main, footer) also
 *    have $twenty-gutter / 2 applied.
 *
 * 4. If the design requires more padding left and right of the screen then
 *    $twenty-gutter / 2 is also applied there.
 *
 * 5. Using $twenty-gutter keeps all spacing on all elements consistent and maintains
 *    a good rhythm
 *
 * 6. Breakout sections that need to be full width follow this technique
 *    http://codepen.io/tlattimore/pen/rLXPvx
 *    So that xml does not needing updating to change markup
 *
 * ----------------------------------------------------------------------------
 * ----------------------------------------------------------------------------
 * GRID Code written is heavily influenced by:
 * https://alistapart.com/article/learning-from-lego-a-step-forward-in-modular-web-design
 * https://www.lullabot.com/articles/unexpected-power-of-viewport-units-in-css
 * ----------------------------------------------------------------------------
 * ----------------------------------------------------------------------------
*/

// created to control overall padding etc on grid when class output can't be controlled
// Created so that we can add this to containers inside fullwidth Bluefoot areas
%row-container {
	width: $container-width-mobile;
	max-width: $container-width;
	padding-left: $page-gutter-mobile;
	padding-right: $page-gutter-mobile;

	@include min-screen($screen__m) {
		padding-left: $page-gutter;
		padding-right: $page-gutter;
	}
}


.fixed-nav {
	// class applied to the body when the nav / header is fixed
	.page-wrapper {
		// This creates space so that header does not overlap content when the header is taken out of content flow by being a fixed position
		padding-top: $header-mobile-height;

		@include min-screen($screen__m) {
			padding-top: $desktop-header-height;
		}
	}
}

// Header area only
.page-header {
	// no padding needed here as always full width in designs. If not it can be overwritten in child theme
	position: relative;
}

// This is usually the main container for content below the header. Does not need padding as most items on homepage has padding
.page-main {
	// widths here control whether to be 100% with max-width or a set width based on variables in _theme-variables.scss
	width: $container-width-mobile;
	max-width: $container-width;
	margin: 0 auto;
}

// full width rows
.full-width {
	.row-container {
		@extend %row-container; // adding padding here because the row is full width
	}
}

// specific bluefoot rows that can't have padding at the edge
.slider,
.poster {
	.row-container {
		padding-left: 0;
		padding-right: 0;
		max-width: $container-width-mobile;
	}
}

.container {
	max-width: $container-width;
}

.sidebar,
.main {
	// yep, need this. Remove padding from .main in child theme instead
	@extend %flex__item; // for ref: this uses these from _flex-grid.scss
}

.section {
	margin-top: $twenty-gutter; // margin-between sections
	margin-bottom: $twenty-gutter;
}

.page-layout-2columns-left,
.page-layout-2columns-landing {
	.columns,
	.main {
		padding: $category-columns-main-spacing-mobile;

		@include min-screen($screen__m) {
			padding: $category-main-spacing;
		}
	}

	.main {
		@include min-screen($screen__m) {
			padding-right: $category-main-spacing;
		}
	}
}

.cms-home {
	.column.main {
		padding: 0;
	}
}

// ----------------------------------------------------------------------------
// ----------------------------------------------------------------------------
// Start the actual grid with fall backs
// -------------------------------------------------------------
// Default - + The ultimate fallback for rubbish browsers
// -------------------------------------------------------------
// 1 column layout and mobile for all
.page-wrapper {
	display: block;
}

// make sure main page sections clear each other,
// because why wouldn't they??
.page-header,
.page-footer,
.columns {
	clear: both;
}

// -------------------------------------------------------------
// Default fallback
// -------------------------------------------------------------
@include min-screen($screen__ml) {
	// These are used on single product pages
	.product-info-main {
		float: right;
		width: $product-info-main-float-width-tablet;
	}

	.product.media {
		float: left;
		width: $product-info-media-float-width-tablet;
	}
}

@include min-screen($screen__l) {
	.product-info-main {
		float: right;
		width: $product-info-main-float-width;
	}

	.product.media {
		float: left;
		width: $product-info-media-float-width;
	}

	.sidebar-main,
	.sidebar-additional {
		width: $container-width-mobile; // we want the 100% percentage here until it hits max-width rather than a vw value as used in earlier breaks
	}
}

// -------------------------------------------------------------
// 2 column left fallback
// -------------------------------------------------------------
.page-layout-2columns-left,
.page-layout-2columns-landing {

	@include min-screen($screen__m) {
		.sidebar-main,
		.sidebar-additional {
			float: left;
		}

		.main {
			width: 100%;
			float: right;
		}
	}
}

// -------------------------------------------------------------
// 2 column right fallback
// -------------------------------------------------------------
.page-layout-2columns-right {

	@include min-screen($screen__l) {
		.sidebar-main,
		.sidebar-additional {
			float: right;
		}

		.main {
			width: calc(100% - #{$sidebar-max-width});
			float: left;
		}
	}
}

// -------------------------------------------------------------
// 3 column fallback
// -------------------------------------------------------------
.page-layout-3columns {
	@include min-screen($screen__l) {
		.main {
			width: calc(100% - (#{$sidebar-width * 2})); // two sidebars
			float: left;
		}

		.sidebar-left {
			float: left;
		}

		.sidebar-right {
			float: right;
		}
	}
}

// -------------------------------------------------------------
// -------------------------------------------------------------
// FLEXBOX
// -------------------------------------------------------------
@supports (display: flex) {

	.page-wrapper {
		display: flex;
		flex-direction: column;
	}

	.columns {
		display: flex;
		flex-direction: column;
		flex: 1;
	}

	.product-main-container {
		display: flex;
		flex-direction: row;
	}

	.main-container {
		display: flex;
		flex: 1 0 10%;
		flex-direction: column;
		order: 2;
	}

	.page-header,
	.page-footer {
		flex: none;
	}

	.main,
	.sidebar {
		flex: 1;
	}

	.main {
		.catalog-category-view &,
		.catalogsearch-result-index & {
			order: 1;
		}
		@include min-screen($screen__m) {
			order: 0;
		}
	}

	@include min-screen($screen__ml) {
		.columns {
			flex-direction: row;
		}

		.sidebar {
			flex: 0 0 $sidebar-width;
		}

		.product-info-main {
			flex: 1;
			order: 2;
		}

		.product.media {
			flex: 1;
			order: 1;
		}
	}

	@include min-screen($screen__l) {
		.sidebar {
			flex: 0 0 $sidebar-max-width;
		}
	} // -------------------------------------------------------------
	// 2 column left flexbox fallback
	// -------------------------------------------------------------
	.page-layout-2columns-left,
	.page-layout-2columns-landing {

		@include min-screen($screen__m) {
			.main {
				order: 2;
			}

			.sidebar {
				order: 1;
				padding: 0;
			}
		}
	} // -------------------------------------------------------------
	// 2 column right flexbox fallback
	// -------------------------------------------------------------
	.page-layout-2columns-right {

		@include min-screen($screen__m) {
			.main {
				order: 1;
			}

			.sidebar {
				order: 2;
			}
		}
	} // -------------------------------------------------------------
	// 3 column flexbox fallback
	// -------------------------------------------------------------
	.page-layout-3columns {
		@include min-screen($screen__m) {}
	}
}

// -------------------------------------------------------------
// -------------------------------------------------------------
// GRID 
// -------------------------------------------------------------
@supports (display: grid) {
	.columns {
		display: grid;
		grid-template-columns: 100%;
	}

	.product-main-container {
		display: grid;
		grid-template-columns: 100%;
		
		@include min-screen($screen__ml) {
			grid-template-columns: $product-info-main-grid-column-tablet $product-info-main-grid-column-tablet;
		}
	}

	.product-info-main {
		width: 100%;
	}

	.product.media {
		width: 100%;
	} 
	
	// -------------------------------------------------------------
	// 2 column left grid fallback
	// -------------------------------------------------------------
	.page-layout-2columns-left,
	.page-layout-2columns-landing {
		.main {
			width: 100%;
			padding-top: 0;
			padding-bottom: 0;

			@include min-screen($screen__m) {
				grid-column-start: 1;
			}
		}

		&.contact-index-index,
		&.cms-page-view,
		&.cms-no-route,
		&.customer-account-index,
		&body[class*="customer"],
		&body[class*="company"],
		&.quickorder-index-index,
		&.account,
		&.sales-guest-form,
		&.sales-guest-view,
		&.sales-guest-invoice {
			.main {
				@include min-screen($screen__m) {
					grid-column-start: 2;
					padding-left: $gutter;
				}
			}

			.columns {
				@include min-screen($screen__m) {
					grid-template-columns: $sidebar-width 1fr;
				}

				@include min-screen($screen__l) {
					grid-template-columns: $sidebar-max-width 1fr;
				}
			}
		}
	}
	
	// -------------------------------------------------------------
	// 2 column right grid fallback
	// -------------------------------------------------------------
	.page-layout-2columns-right {

		@include min-screen($screen__m) {
			.columns {
				grid-template-columns: 1fr $sidebar-width;
			}
		}

		@include min-screen($screen__l) {
			.columns {
				grid-template-columns: 1fr $sidebar-width;
			}
		}
	}
	
	// -------------------------------------------------------------
	// 3 column grid fallback
	// -------------------------------------------------------------
	.page-layout-3columns {
		@include min-screen($screen__l) {
			.columns {
				grid-template-columns: $sidebar-width 1fr $sidebar-width;
			}

			.main {
				width: auto;
			}
		}
	}
}
