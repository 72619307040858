.braintree-apple-pay-button {
	-webkit-appearance: -apple-pay-button;
	-apple-pay-button-type: plain;
	display: block;
	background-image: -webkit-named-image(apple-pay-logo-black);
	background-size: 100% calc(62%);
	background-repeat: no-repeat;
	background-color: black;
	background-position: 50% 50%;
	border-radius: 5px;
	transition: background-color 0.15s;
	cursor: pointer;
	margin-left: auto;
	margin-right: auto;
	height: 45px;
	min-height: 30px;
	max-height: 55px;
}

.block-minicart, .express-checkout-options {
	.braintree-apple-pay-button {
		margin-bottom: $twenty-gutter;
	}
}
