.checkout-onepage-success {
	.columns {
		padding: rem(11) $page-padding-mobile rem(22);

		@include min-screen($screen__m) {
			padding: rem(7) $page-padding $page-padding;
		}
	}

	.checkout-success-title-wrapper {
		background-color: $gallery;
		text-align: $checkout-success-message-text-align;
		color: $body-font-color;
		margin-bottom: $twenty-gutter;
		padding: $gutter $page-padding-mobile;

		@include min-screen($screen__m) {
			padding: rem(56) $gutter $sixty-gutter;
		}

		.page-title-wrapper {
			background: none;
		}

		.checkout-success-summary {
			@include type__17($color: $checkout-success-text-color);

			@include min-screen($screen__m) {
				@include type__16($color: $checkout-success-text-color);
			}

			strong {
				@include type__17(
					$color: $checkout-success-text-color,
					$font-weight: $font-weight-bold
				);
				display: block;
				margin-bottom: $five-gutter;

				@include min-screen($screen__m) {
					@include type__16(
						$color: $checkout-success-text-color,
						$font-weight: $font-weight-bold
					);
					margin-bottom: 0;
				}
			}

			.order-reference {
				margin-bottom: $twenty-gutter * 0.75;

				span {
					display: block;
				}
			}
		}

		[id="registration"] {
			background: $white;
			padding: rem(12) $fifteen-gutter $fifteen-gutter;
			margin-top: $twenty-gutter;

			@include min-screen($screen__m) {
				padding: $fifteen-gutter $twenty-gutter $twenty-gutter;
				max-width: rem(566);
				margin: $twenty-gutter auto 0 auto;
			}

			h3 {
				@include type__24(
					$font-family: $font-family-serif,
					$font-weight: $font-weight-normal
				);
				margin-bottom: rem(9);

				@include min-screen($screen__m) {
					margin-bottom: rem(7);
				}
			}

			.registration-benefits {
				display: none;
			}

			.button {
				@include button__journey(
					$font-weight: $font-weight-medium,
					$padding: rem(11) $twenty-gutter rem(13)
				);
				font-size: rem(18) !important; //Override button default size
				text-align: center;
				text-transform: uppercase;
				width: 100%;

				@include min-screen($screen__m) {
					padding-top: rem(9);
					padding-bottom: rem(12);
				}
			}
		}
	}

	.column.main {
		padding: 0;
	}

	// Create an account, delivery info, ordered items and payment details
	// ---------------------------------------------------------------------
	.checkout-success-order-account-info-wrapper {
		background: $gallery;
		margin-bottom: 0;
		margin-top: rem(17);
		display: flex;
		flex-wrap: wrap;

		@include min-screen($screen__m) {
			flex-flow: row wrap;
			margin-bottom: 0;
			margin-top: $gutter;
			padding: $fifteen-gutter $twenty-gutter $twenty-gutter;
		}

		// Registration Section - Please find code for
		// registration in _account-login.scss
		// -------------------------------------------------
		[id="registration"],
		.block-order-addresses,
		.need-help {
			flex: 0 1 100%;

			@include min-screen($screen__m) {
				flex: 0 1 auto; // Flex basis set to auto here because it does not work with box-sizing: border-box
				width: calc(50% - #{$checkout-success-page-gutter} / 2); // Set the width instead of flex-basis
			}

			input[type="submit"] {
				// this silent class has been created and put in _shame-child cos its sad...
				@include button__default;
			}
		}

		// Delivery Addresses Section
		// -------------------------------------------------
		.block-order-addresses,
		.need-help {
			display: block;

			@include min-screen($screen__m) {
				display: flex;
				flex-flow: row wrap;
				flex: 1 1 100%;
				padding-bottom: $ten-gutter;
			}

			@include min-screen($screen__ml) {
				flex: 1 1 calc(50% - #{$fifteen-gutter});
				padding-bottom: 0;
			}

			strong {
				display: block;
				margin-bottom: rem(13);

				@include min-screen($screen__m) {
					margin-bottom: $ten-gutter;
				}

				span {
					@include type__17($font-weight: $font-weight-normal);
					text-transform: uppercase;
					letter-spacing: 1px;
				}
			}

			.box-order-shipping-address {
				border-bottom: 1px solid $white;

				@include min-screen($screen__s) {
					padding: 0;
					margin: 0;
				}
			}

			.box-order-shipping-address,
			.box-order-billing-address {
				flex: 0 1 100%;
				padding: rem(18) $page-padding-mobile;

				@include min-screen($screen__s) {
					flex: 0 1 50%;
				}

				@include min-screen($screen__m) {
					padding: 0;
					border-bottom: 0;
				}

				address {
					@include type__address;
					font-weight: $font-weight-semibold;
					margin-bottom: 0;
					font-size: rem(16);
					line-height: normal;
				}
			}

			.box-order-billing-address {
				@include min-screen($screen__m) {
					padding: 0 0 0 $twenty-gutter;
				}
			}

			.order-delivery-date {
				margin-top: $ten-gutter;
			}

			.order-delivery-note {
				margin-top: $ten-gutter;

				.title {
					@include type__16;
					align-items: center;
					cursor: pointer;
					display: flex;

					&:before {
						@include icon__arrow-black;
						margin-right: $ten-gutter;
						height: 10px;
						width: 10px;
					}
				}

				.content {
					margin-top: $five-gutter;
				}
			}

			.contact-info {
				margin-top: $twenty-gutter;

				p:last-child {
					@include type__address;
				}
			}
		}

		// Register block
		// -------------------------------------------------
		[id="registration"] {
			margin: $twenty-gutter 0 0;
			background-color: $checkout-success-registration-background;
			padding: $twenty-gutter;

			@include min-screen($screen__m) {
				margin: 0;
				padding: $checkout-success-registration-inner-spacing;
			}

			h3 {
				@include type__19(
					$color: $white,
					$font-weight: $font-weight-bold
				);
				padding-bottom: $twenty-gutter;

				@include min-screen($screen__m) {
					padding-bottom: $checkout-success-registration-inner-spacing;
				}
			}

			ul {
				li {
					@include type__13(
						$color: $white,
						$font-weight: $font-weight-bold
					);

					&:last-child {
						padding-bottom: 0;
					}

					&:before {
						content: "";
						display: inline-block;
						width: 12px;
						height: 12px;
						margin-right: $checkout-success-registration-tick-spacing;
						@include svg-background(
							$name: tick-white,
							$size: contain
						);
					}
				}
			}

			a.action.primary {
				@include button__journey;
				display: block;
				text-align: center;
				margin-top: $twenty-gutter;
				width: 100%;

				@include min-screen($screen__m) {
					margin-top: em(25);
				}

				&:hover {
					opacity: 1;
				}
			}
		}

		// Register block
		// -------------------------------------------------
		.need-help {
			background: $white;
			display: block;
			text-align: center;
			padding: $twenty-gutter;
			margin: 0 rem(19) $fifteen-gutter;

			@include min-screen($screen__m) {
				padding: $sixty-gutter $gutter;
				margin: $five-gutter 0 0 0;
			}

			.box-title {
				@include type__24(
					$font-family: $font-family-serif,
					$letter-spacing: 1px,
					$font-weight: $font-weight-medium
				);
				margin-bottom: 0;

				@include min-screen($screen__m) {
					@include type__35(
						$font-family: $font-family-serif,
						$letter-spacing: 1px
					);
				}
			}

			.content {
				@include type__14(
					$font-weight: $font-weight-normal,
					$letter-spacing: 1px
				);
				padding-top: $ten-gutter;
				text-transform: uppercase;

				@include min-screen($screen__m) {
					@include type__22(
						$font-weight: $font-weight-normal,
						$letter-spacing: 1px,
						$line-height: 1.4
					);
					padding-top: rem(2);
				}

				a {
					text-decoration: underline;
				}
			}
		}
	}

	// Items Ordered Section
	// -------------------------------------------------
	.checkout-success-order-items-wrapper {
		@include min-screen($screen__m) {
 			display: flex;
 			flex-wrap: wrap;
 		}

 		.block-order-details-items {
 			@include min-screen($screen__m) {
	 			flex: 1.6;
	 		}
 		}

 		.block-order-totals {
			margin-top: rem(17);
			background-color: $gallery;
			width: 100%;
			padding: rem(17) rem(17) rem(18);

 			@include min-screen($screen__m) {
				 flex: 1;
				 margin-top: rem(45);
				 padding: rem(16) $twenty-gutter rem(18);
	 		}
 		}

		.order-title {
			@include type__28(
				$color: $body-font-color,
				$font-family: $font-family-serif
			);
			border-bottom: 1px solid $border-color-alt2;
			padding: 0 0 rem(14);
			display: block;
			text-align: center;

			@include min-screen($screen__m) {
				@include type__35(
					$font-family: $font-family-serif
				);
				text-align: left;
				padding-bottom: $ten-gutter;
			}

			> span {
				@extend %visuallyhidden; // hides number of items in basket
			}
		}

		.block-order-details-items {
			margin-bottom: 0;

			@include min-screen($screen__m) {
				margin-right: $gutter;
			}

			.table-order-items {
				margin-bottom: 0;
				overflow: hidden;
				max-width: 100vw;

				@include min-screen($screen__m) {
					border-bottom: 1px solid $silver-chalice;
				}

				tbody {
					border-bottom: $checkout-success-table-product-border;

					&:last-of-type {
						@include max-screen($screen__m - 1) {
							border-bottom: 0;

							tr {
								margin-bottom: 0;
							}
						}
					}

					tr { // product detail rows
						display: flex;
						align-items: center;
						width: 100%;
						margin: rem(16) 0;

						@include min-screen($screen__m) {
							margin: rem(25) 0;
						}

						.col {
							margin-left: $twenty-gutter;
						}

						.col,
						.cart-item-col {
							&.photo {
								display: block;
								margin-right: rem(17);

								@include min-screen($screen__m) {
									margin-right: rem(19);
								}

								img {
									width: rem(79);

									@include min-screen($screen__m) {
										width: rem(109);
									}
								}
							}

							&.name {
								flex: 1;
								margin-left: 0;

								.product {
									&.options {
										.toggle {
											font-weight: $font-weight-semibold;
											cursor: pointer;

											&:after {
												content: '';
												display: inline-block;
												height: 10px;
												width: 13px;
												margin-left: $five-gutter;

												@include svg-background(
													$name: 'arrow-down',
													$size: contain
												)
											}
										}

										.item-options {
											@include min-screen($screen__m) {
												padding-right: $twenty-gutter;
											}

											dd {
												margin-bottom: $five-gutter;
												font-weight: $font-weight-semibold;
												margin-top: 0;

												&:last-child {
													margin-bottom: 0;
												}
											}
										}

										&.active {
											.item-options {
												display: block;
												padding-top: rem(3);
											}

											.option-content {
												display: inline-block;
												margin-top: $five-gutter;

												.price {
													display: inline-block;
													margin-bottom: $ten-gutter;
												}
											}

											.toggle {
												&:after {
													@include svg-background('arrow-up');
												}
											}
										}
									}
								}
							}

							&.price {
								@extend %visuallyhidden; // hide product initial price
							}

							&.qty {
								text-align: right;

								.item {
									.title,
									.content {
										font-size: rem(16);
										font-weight: $font-weight-normal;
									}
								}
							}

							&.subtotal {
								text-align: right;
								margin: 0;

								@include min-screen($screen__m) {
									padding-left: $gutter;
								}

								@include min-screen($screen__l) {
									padding-left: rem(54);
								}

								.price {
									@include checkout-summary-price;
								}
							}

							&.name,
							&.qty {
								.item,
								.title {
									@include type__13($line-height: 1.45);
								}
							}

							.bundle-options {
								margin-top: $five-gutter;

								dt {
									display: block;
									font-weight: $font-weight-semibold;
								}

								.title,
								.price {
									@include type__13;
								}

								.title {
									margin-right: $five-gutter;
									font-weight: $font-weight-normal;
								}

								.price {
									font-weight: $font-weight-semibold;
								}
							}
						}

						.qty-subtotal-container {
							padding: 0 0 rem(6) $gutter;
							display: flex;
							flex-direction: column;

							@include min-screen($screen__m) {
								flex-direction: row;
								align-items: center;
								padding: 0 0 0 $gutter;
							}
						}
					}
				}
			}
		}
	}

	// Order Totals Section
	// -------------------------------------
	.block-order-totals {
		.subtotal,
		.shipping,
		.tax,
		.grand_total,
		.grand_total_incl,
		.discount,
		.gw_order,
		.giftcards {
			display: flex;
			justify-content: space-between;
			padding: rem(14) 0 0;

			@include min-screen($screen__m) {
				padding: rem(12) 0 0;
			}

			&:first-child {
				padding-top: 0;
			}

			.label,
			.detailed,
			.shipping-description {
				@include type__17;
				order: 1;
				text-transform: uppercase;
			}

			.price {
				@include type__17;
				@include checkout-summary-price;
				text-transform: uppercase; // for when an item is free
				order: 2;
			}

			.payment {
				order: 2;
				flex: 0 1 100%;
				text-align: right;
			}
		}

		.giftcards,
		.giftcards .price {
			@include type__17;
			text-transform: uppercase;

			.discount {
				padding: 0;

				.price {
					@include checkout-summary-price;
				}
			}
		}

		.grand_total {
			display: flex;
			flex-wrap: wrap;

			.label {
				margin-right: auto;
			}

			&:not(:last-child) {
				padding-top: 0;
			}

			// " you paid using" block
			.payment {
				flex: 0 1 100%;
				text-align: left;
				padding-bottom: 0;
				@include type__18;

				img {
					margin-left: $ten-gutter;
				}
			}
		}

		.tax {
			padding-top: $ten-gutter;
			padding-bottom: $ten-gutter;

			.details {
				@extend %visuallyhidden; // hides VAT
			}
		}

		.shipping {
			.label {
				display: flex;
				flex-direction: column; // delivery details on line underneath

				strong {
					@include type__13;
					padding-top: $ten-gutter;
					font-style: $checkout-success-totals-method-font-style;
				}
			}
		}

		// The tax total block cannot be removed via layout otherwise the other total block
		// values display incorrectly. Conversely, leaving this visible can cause confusion
		// to the user when calculating the totals, so this value is hidden specifically.
		.tax {
			display: none;
		}

		.shipping .label,
		.grand_total .payment {
			@extend %visuallyhidden;
		}

		.subscription-delivery {
			background: $mine-shaft;
			color: $white;
			font-weight: $font-weight-medium;
			padding: $twenty-gutter $ten-gutter;
			text-align: center;
			margin-top: $twenty-gutter;

			.title {
				p {
					@include type__27(
						$color: $white,
						$font-family: $font-family-serif,
						$font-weight: $font-weight-normal,
						$letter-spacing: 1px
					);
					margin-bottom: $ten-gutter;

					@include min-screen($screen__m) {
						@include type__22(
							$color: $white,
							$font-family: $font-family-serif,
							$font-weight: $font-weight-normal,
							$letter-spacing: 1px
						);
						margin-bottom: rem(3);
					}
				}
			}

			p {
				@include type__17(
					$color: $white,
					$font-weight: $font-weight-medium
				);

				@include min-screen($screen__m) {
					@include type__16(
						$color: $white,
						$font-weight: $font-weight-medium
					);
				}
			}
		}

		.block-order-details-gift-message {
			border-top: 1px solid $white;
			background-color: $gallery;
			padding-top: rem(16);
			margin-top: rem(18);

			.block-title {
				@include type__17;
				display: flex;
				justify-content: space-between;
				align-items: center;
				text-transform: uppercase;
				cursor: pointer;

				&:after {
					content: '';
					display: inline-block;
					height: 12px;
					width: 12px;

					@include svg-background(
						$name: 'plus',
						$size: 100% 100%
					);
				}
			}

			.block-content {
				display: none;
			}

			&.active {
				.block-title {
					&:after {
						@include svg-background('minus');
					}
				}

				.block-content {
					display: block;
					padding-top: $ten-gutter;

					.item-sender,
					.item-recipient {
						.label {
							padding-right: $five-gutter;
							font-weight: $font-weight-semibold;
						}
					}

					.item-recipient,
					.item-message {
						padding-top: $five-gutter;
					}
				}
			}
		}

		.giftcards {
			padding-bottom: $ten-gutter;
		}
	}

	// Continue Shopping Section
	// -------------------------------------------------
	.block-products-list {
		display: none;

		@include min-screen($screen__m) {
			display: block;
			border-top: 1px solid $black;
			margin-bottom: $twenty-gutter;
		}

		.block-title {
			text-align: center;
			margin: $twenty-gutter * 3 auto $twenty-gutter * 2 auto;
		}

		.product-items {
			&.widget-product-grid {
				display: flex;
				margin-bottom: $twenty-gutter * 2;

				.product-item {
					flex: 0 1 50%;

					@include min-screen($screen__m) {
						flex: 0 1 percentage(1/3);
					}

					@include min-screen($screen__l) {
						flex: 0 1 percentage(1/6);
					}
				}
			}
		}
	}

	.card-icons-wrapper {
		display: none;
	}
}
