.post-list {
	display: flex;
	flex-flow: column wrap;

	@include min-screen($screen__m) {
		flex-flow: row wrap;
		justify-content: center;
	}

	.slick-list {
		width: 100%;
	}

	.post-holder {
		border: none;
		border-radius: 0;
		float: none;
		margin: 0;
		padding: 0;
		width: 100%;

		@include min-screen($screen__m) {
			width: 50%;
		}

		@include min-screen($screen__l) {
			width: calc(#{percentage(1/3)});
		}

		@include min-screen($screen__xl) {
			width: 25%;
		}

		&:nth-last-of-type(2),
		&:last-of-type {
			@include max-screen($screen__l - 1) {
				margin-top: $gutter;
			}
		}
	}

	.post-img-wrapper {
		margin-bottom: $twenty-gutter;

		@include min-screen($screen__ml) {
			margin-bottom: rem(25);
		}

		a {
			background-position: center;
			background-size: cover;
			display: block;
			height: rem(230);

			@include min-screen($screen__xs) {
				height: rem(330);
			}

			@include min-screen($screen__l) {
				height: rem(245);
			}
		}

		img {
			height: 100%;
			object-fit: cover;
			max-height: rem(330);
			width: 100%;
		}
	}

	.post-categories {
		display: block;

		.label,
		a {
			@include type__14(
				$color: $boulder,
				$letter-spacing: 1px
			);
		}

		.label:after {
			@include svg-background(
				$name: "arrow-right-boulder",
				$size: 5px
			);
			content: "";
			display: inline-block;
			height: 20px;
			margin-left: $five-gutter;
			vertical-align: top;
			width: 15px;
		}
	}

	.post-title {
		margin-top: $five-gutter;

		a {
			@include type__19(
				$font-family: $font-family-serif,
				$letter-spacing: 1px
			);
			font-style: italic;

			@include min-screen($screen__ml) {
				@include type__24(
					$font-family: $font-family-serif,
					$letter-spacing: 1px
				);
			}
		}
	}

	.post-description {
		margin-top: $ten-gutter;

		.post-text-hld {
			min-height: unset;
			overflow-y: unset;

			img {
				display: none;
			}
		}

		p {
			@include type__15;

			@include min-screen($screen__ml) {
				@include type__16;
			}
		}
	}

	.slick-dots {
		text-align: center;
	}
}

.blog-widget-recent {
	.post-list {
		@include min-screen($screen__m) {
			flex-flow: row nowrap;
			justify-content: space-between;
		}

		.post-holder {
			@include min-screen($screen__m) {
				width: calc(#{percentage(1/2)} - #{$fifteen-gutter});
			}

			@include min-screen($screen__l) {
				width: calc(#{percentage(1/3)} - #{rem(25)});
			}

			@include min-screen($screen__xl) {
				width: calc(#{percentage(1/4)} - #{rem(25)});
			}
		}
	}
}
