// ------------------------------------------------------------
// ------------------------------------------------------------
// Recent orders table on dashboard and in recent orders (obvs)
// Stored Payment Methods Table
// ------------------------------------------------------------
// ------------------------------------------------------------
.account,
.sales-guest-view,
.sales-guest-invoice {
	table,
	.aw-sarp2-customer-subscriptions {
		margin-bottom: $twenty-gutter * 2.5;

		.col {
			width: 100%;
			vertical-align: middle;

			@include min-screen($account-table-desktop-breakpoint) {
				width: percentage(1/6);

				&.name {
					width: 50%;
				}

				&.sku {
					width: 15%;
				}

				&.price {
					width: 10%;
				}

				&.qty {
					width: 15%;
				}

				&.subtotal {
					width: 10%;
				}
			}
		}

		thead {
			background: $account-table-head-color;

			@include min-screen($account-table-desktop-breakpoint) {
				border-bottom: 1px solid $grey-lighter1;
			}

			th {
				padding: $item-gutter 0;
				display: block;

				@include min-screen($account-table-desktop-breakpoint) {
					display: table-cell;
				}

				&.actions {
					font-size: 0 !important; // Override parent CSS
				}
			}
		}


		tbody {
			tr {
				width: 100%;
			}

			td {
				padding: 0;
				margin: 0;

				@include min-screen($account-table-desktop-breakpoint) {
					display: table-cell;
					padding: $twenty-gutter 0;
				}

				&.actions {
					padding-bottom: $twenty-gutter;
					text-align: right !important; // Override parent CSS

					@include min-screen($account-table-desktop-breakpoint) {
						padding-bottom: 0;
						text-align: right;
					}

					.action {
						display: block;
						padding-left: $twenty-gutter;

						&:before {
							display: inline-block;
							content: "";
							height: 9px;
							width: 5px;
							@include svg-background(
								$name: 'arrow-right-black',
								$size: 5px
							);
						}

						span {
							padding-left: $five-gutter;
						}
					}

					.view {
						color: $body-font-color;

						&:after {
							display: none;
						}
					}

					.action.delete {
						&:hover {
							text-decoration: none;
						}
					}

					.action.order {
						display: none;
					}
				}

				&:before {
					margin-top: auto;
					margin-bottom: auto;
				}
			}
		}

		tfoot {
			tr {
				th,
				td.mark {
					// .mark is for giftcards
					text-align: right;
					font-weight: $font-weight-semibold;
					padding: $twenty-gutter $item-gutter / 2 0 0;
				}

				td {
					// 4px is a hack as th and td don't align but can't see where th has bottom padding :(
					padding: $twenty-gutter 0 4px 0;
					text-align: right;
				}
			}
		}

		// Specifically orders tables
		&.orders-recent,
		&.table-order-items,
		&.table-credit-cards,
		&.items-ordered,
		&.table.data-grid.data.table,
		&.data-grid,
		&.aw-sarp2-customer-subscriptions {

			// hide the table head completely on mobile as out of order visually, not needed and doesn't describe orders
			thead {
				display: none;
				background: $account-order-table-background-color;

				@include min-screen($account-table-desktop-breakpoint) {
					display: table-header-group; // forces thead to be full width
					tr {
						// forces each row inside to be full width
						display: table-row;

						th,
						th span {
							@include type__16($font-weight: $font-weight-semibold);
						}
					}
				}
			}

			tbody {
				tr {
					padding: $mobile-order-table-padding;
					border-bottom: 1px solid $grey-lighter1;

					&:last-child {
						border-bottom: 0;

						@include min-screen($screen__m) {
							border-bottom: 1px solid $grey-lighter1;
						}
					}

					@include min-screen($screen__m) {
						padding: $order-table-padding;
					}

					td,
					td > div {
						@include type__16();
						text-align: left;

						.price,
						.title,
						.content {
							@include type__16();
						}
					}
				}
			}

			td,
			.col,
			.data-grid-th {
				padding: $mobile-order-table-column-padding;

				@include min-screen($account-table-desktop-breakpoint) {
					padding: $account-table-inner-spacing;
				}

				&:first-child {
					padding-left: 0;
				}

				.item-options {
					margin-top: $ten-gutter;

					dt,
					dd {
						@include type__14($font-weight: $font-weight-light);
					}

					dt {
						font-weight: $font-weight-semibold;
						margin-bottom: $five-gutter;
					}

					dd {
						margin-bottom: $ten-gutter;

						&:last-child {
							margin-bottom: 0;
						}
					}
				}
			}
		}

		&.orders-recent,
		&.table-order-items,
		&.table-credit-cards,
		&.data-grid {
			tbody {
				@include min-screen($account-table-desktop-breakpoint) {
					display: table-row-group; // forces tbody back to fullwidth
				}

				tr {
					flex: 0 1 50%;

					&:first-child {
						padding-top: 0;
						//remove padding on first child as padding is correclty applied to parent
					}

					@include min-screen($account-table-desktop-breakpoint) {
						display: table-row;
						border-bottom: 1px solid $grey-lighter1;
					}
				}
			}
		}

		&.table-order-items {
			margin-top: auto;
			flex-direction: column;
			margin-bottom: $twenty-gutter;
			padding: $fifteen-gutter 0 0;

			@include min-screen($screen__m) {
				padding: 0;
			}

			tbody {
				td {
					&.col.actions {
						a {
							margin-right: auto;
							padding-left: 0;
						}

						&:before {
							display: none;
						}
					}
				}
			}

			tfoot {
				display: table-footer-group;
				align-self: flex-end;

				tr {
					display: table-row;

					th,
					td {
						display: table-cell;
					}

					td {
						padding-left: $twenty-gutter;
					}

					&.subtotal,
					&.shipping,
					&.grand_total_incl {
						@include type__17($font-weight: $font-weight-light);
						text-transform: uppercase;
						margin-bottom: $ten-gutter;
						letter-spacing: 1px;
					}

					&.grand_total_incl {
						.mark {
							strong {
								@include type__17($font-weight: $font-weight-light);
							}
						}
					}

					&.shipping {
						.mark {
							text-align: left;

						@include min-screen($screen__ml) {
								text-align: right;
							}
						}
					}
				}
			}

			.col.name {
				flex-wrap: wrap;

				strong {
					flex: 0 1 50%;
				}

				.item-options {
					margin-left: 50%;

					dd {
						font-size: 1rem;
					}

					@include min-screen($screen__ml) {
						margin-left: 0;
					}
				}
			}
		}

		&.items-ordered {
			tbody {
				border-bottom: none;

				tr {
					display: block;
					border-bottom: none;

					td {
						display: block;
					}
				}

				@include min-screen($screen__s) {
					border-bottom: 1px dashed $border-color;

					&:last-child {
						border-bottom: 1px solid $border-color;
					}

					tr {
						display: table-row;
						border-bottom: 1px dashed $border-color;

						td {
							display: table-cell;
						}
					}
				}
			}

			tfoot {
				border-top: 1px dashed $border-color;
				border-bottom: 1px solid $border-color;
				width: 100%;
				display: table;

				@include min-screen($screen__s) {
					display: table-footer-group;
				}
			}

			.col {
				&.name {
					padding-top: $item-gutter;
				}

				&.subtotal {
					padding-bottom: $item-gutter;
				}

				@include min-screen($account-table-desktop-breakpoint) {

					&.name {
						padding-top: $twenty-gutter;
					}

					&.subtotal {
						padding-bottom: $twenty-gutter;
						text-align: right;
					}
				}
			}
		}

		&.data-grid.table {
			tr:first-child {
				padding-top: 0;
			}

			td,
			td.data-grid-actions-cell {
				width: 100%;
				text-align: right;

				@include min-screen($screen__ml) {
					width: auto;
					text-align: left;
				}
			}

			tr.data-grid-tr-no-data {
				border-bottom: 1px solid $border-color;

				td {
					text-align: center;
					display: block;

					@include min-screen(992px) {
						display: table-cell;
					}

					&:before {
						display: none;
					}
				}
			}
		}

		&.table-credit-cards {
			tbody {
				tr {
					td {
						padding-left: 0;

						&.paypal-account {
							display: flex;
							align-items: center;

							@include min-screen($account-table-desktop-breakpoint) {
								display: table-cell;
							}
						}
					}

					td.col.actions {
						button.action.delete {
							@extend %cancel-button;
							padding: 0;

							&:hover {
								background: none;
								border: 0;
							}

							span {
								@include type__16(
									$color: $black,
									$font-weight: $font-weight-normal
								);
							}
						}
					}
				}
			}
		}
	}

	/*
	* general responsive table layout
	* ------------------------------------------
	* apply flex to the table then set all other table elements to display block
	* which forces them underneath each other. Then at $screen__m set them back to default
	* the thead is then removed on mobile and replaced with a :before appended to the td.
	*/

	table {
		display: flex;
		flex-wrap: wrap;

		@include min-screen($account-table-desktop-breakpoint) {
			display: table;
		}


		thead { // hide the thead on mobile and use &before to display it instead
			display: none;
		}

		tr,
		th,
		td,
		tbody,
		tfoot {
			display: block;
		}

		tbody {
			width: 100%;

			td {
				display: inline-flex;
				padding: $account-table-inner-spacing;
				text-align: left;

				&:before {
					content: attr(data-th);
					flex: 0 0 50%;
					text-align: left;
					font-weight: $font-weight-semibold;
				}
			}
		}

		// forces the table footer under the body on my orders page
		tfoot {
			flex: 0 1 100%;
		}

		// reset table elements to default for desktop

		@include min-screen($account-table-desktop-breakpoint) {
			tbody {
				display: table-row-group;

				tr {
					display: table-row;
				}

				td {
					display: table-cell;

					&:before {
						display: none;
					}
				}
			}

			thead {
				display: table-header-group;

				tr {
					display: table-row;
				}
			}

			tfoot {
				display: table-footer-group;

				tr {
					display: table-row;
				}

				th,
				td {
					display: table-cell;
				}
			}
		}
	}
}

table.table-credit-cards {
	padding: $fifteen-gutter 0 0;

	@include min-screen($screen__m) {
		padding: 0;
	}

	tbody {
		width: 100%;

		tr {
			td {
				padding-left: 0;
				padding-right: 0;

				@include min-screen($screen__ml) {
					padding-left: $twenty-gutter;
					padding-left: $twenty-gutter;

					&:first-child {
						padding-left: 0;
					}
				}
			}

			td.col.card-number {
				span {
					margin-right: $saved-card-account-table-card-number-spacing;
					text-transform: capitalize;
				}
			}
		}
	}
}

.account {
	.table-order-items,
	.data-grid {
		margin-bottom: 0; // remove the bottom margin that is applied to all tables so pagination sits with the table
	}

	.table-order-items {
		.item-parent {
			border-bottom: 0;
			padding-bottom: 0;
		}

		.options-label {
			border-bottom: 0;

			.col {
				padding: 0;
			}

			&:nth-last-child(2) {
				border-bottom: 1px solid $grey-lighter;

				.col {
					padding-bottom: $fifteen-gutter;
				}
			}
		}

		.item-options-container {
			border-bottom: 0;
		}
	}
}

// not really table styling but these are pagination controls added to the bottom of a table so kept here
.account {
	.toolbar.bottom {
		border: 0;

		.pager {
			flex: 0 1 100%;

			.toolbar-amount {
				display: $account-pagination-toolbar-amount-display;
			}
		}

		.limiter {
			display: flex;
			flex: 0 1 100%;
			position: relative;

			@include max-screen($screen__m - 1) {
				flex-wrap: wrap;
			}

			.limiter-label,
			.label {
				display: none;
			}

			.limiter-options {
				@include select-style-three;

				@include min-screen($screen__m) {
					flex: 0 1 auto;
					width: $toolbar-sorter-width;
				}
			}

			.limiter-text {
				order: $toolbar-amount-flex-order;
				display: $toolbar-toolbar-amount-display-setting;
				@include type__17(
					$color: $toolbar-amount-color,
					$font-family: $font-family-serif,
					$letter-spacing: 1px
				);
				font-style: italic;
				text-transform: capitalize;

				@include max-screen($screen__m - 1) {
					flex: 1 0 100%;
					order: 5;
					padding: $fifteen-gutter $fifteen-gutter $five-gutter;
					text-align: center;
				}

				@include min-screen($screen__m) {
 					padding: $ten-gutter;
					text-align: left;
				}
			}
		}
	}
}

// Reward Points
.table-reward-history {
	tbody {
		tr {
			td {
				width: 100%;

				@include min-screen($screen__ml) {
					width: auto;
				}
			}
		}
	}
}

// B2B Specific Tables
.company-users-index,
.company-role-index {
	// Yuck selector to match core styles
	&.account table.data-grid tbody td.col.actions {
		display: flex;
		flex-direction: row;

		@include min-screen($screen__ml) {
			flex-direction: column;
			align-items: flex-start;
		}

		&:before {
			display: none;
		}

		.action {
			@include link__underline;
			padding-left: 0;
			margin-right: $twenty-gutter;

			@include min-screen($screen__ml) {
				margin-right: 0;
			}
		}
	}
}

.account {
	.table-wrapper.my-credit-cards {
		.data.table-credit-cards {
			tr {
				th {
					padding-top: 0;
				}
			}
		}
	}

	.table-wrapper.orders-history {
		.data.history {
			tr {
				th {
					padding-top: 0;
				}
			}
		}
	}
}

.sales-guest-view {
	[id="my-orders-table"] {
		tfoot {
			tr.subtotal,
			tr.shipping {
				display: flex;
				justify-content: space-between;

				@include min-screen($screen__ml) {
					display: table-row;
				}
			}
		}
	}
}

// Account page manage subscription
.account.aw_sarp2-profile_edit-index {
	[id="maincontent"] > div.columns {
		padding-bottom: 0;
	}

	.aw-sarp2__actions {
		margin-top: $twenty-gutter;

		@include min-screen($screen__m) {
			margin-top: 0;
		}

		[data-role="aw-sarp2-cancel-button"] {
			@include button__journey;
		}
	}

	.table-wrapper.aw-sarp2-customer-subscriptions {
		.table {
			tbody {
				text-align: left;

				th {
					padding: 0 0 $five-gutter;
					font-weight: $font-weight-semibold;
				}

				tr {
					border-bottom: solid 1px $grey-lighter1;
					padding: $twenty-gutter 0;
					display: flex;
					flex-direction: column;
					position: relative;

					.product-options {
						display: flex;
						flex-direction: column;

						.title {
							margin-top: $five-gutter;

							span {
								@include type__16;
								@include link__underline;
								cursor: pointer;
							}
						}

						.content {
							display: none;

							.active & {
								display: flex;
							}
						}

						.option {
							.label {
								display: none;
							}

							.value {
								@include type__14;
							}
						}
					}
				}

				td {
					display: block;
					text-align: left;
					padding: 0;

					&:before {
						content: attr(data-th);
						flex: 0;
					}

					@include min-screen($screen__ml) {
						&:before {
							content: attr(data-th);
							flex: 0 0 50%;
							text-align: left;
						}
					}

					.started {
						padding-top: rem(2);
					}

					.actions {
						text-align: left;

						@include min-screen($screen__m) {
							flex: 1;
							text-align: right;
						}
					}

					a.action {
						@include type__16($font-weight: $font-weight-thin);
						display: block;
						padding-left: 0;
						margin-top: $fifteen-gutter;

						@include min-screen($screen__m) {
							position: absolute;
							right: 0;
							bottom: $twenty-gutter;
							margin-top: 0;
						}

						&:before {
							@include svg-background(
								$name: 'arrow-right-black',
								$size: contain
							);
							content: "";
							display: inline-block;
							height: 9px;
							width: 5px;
							margin-right: $five-gutter;
						}
					}
				}
			}
		}

		.credit-card {
			.card-number,
			.expiration-date {
				padding-bottom: $ten-gutter;
			}

			.card-type {
				display: flex;

				@include min-screen($screen__ml) {
					display: block;
				}
			}
		}
	}
}

.account.aw_sarp2-profile-index {
	.table {
		thead {
			th {
				&:not(:last-child) {
					padding-right: $ten-gutter;
				}
			}
		}

		tbody {
			td {
				padding-left: 0;
				display: flex;
				justify-content: space-between;

				@include min-screen($screen__ml) {
					display: table-cell;
				}
			}

			td.actions {
				.action {
					display: flex;
					align-items: center;
				}
			}
		}

		.col {
			width: auto;
		}
	}
}

.aw_sarp2-profile_edit-plan {
	.aw-sarp2-product-subscription-options,
	.fieldset {
		padding-top: $ten-gutter;
	}

	.block.subscription-details {
		.block-title {
			padding-top: $fifteen-gutter;
			border-top: solid 1px $grey-lighter1;
		}
	}

	.subscription-btn {
		@include button__journey;
		display: inline-block;
	}

	.aw-sarp2-product-subscription-options {
		.details-sub {
			margin-bottom: $five-gutter;

			.details-field {
				margin-bottom: $five-gutter;
				font-weight: $font-weight-semibold;

				.details-value {
					&:first-child(1) {
						margin-bottom: $ten-gutter;
					}
				}
			}
		}
	}
}

