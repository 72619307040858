/* -------------------------------------------------
 * Product Variables
 * -------------------------------------------------
 * Table of contents
 * 1. - 
 * 2. - 
 *	2a. - 
 * -------------------------------------------------
 * @author Fisheye
 */

// -------------------------------------------------
// 1. Product Page Layout
// -------------------------------------------------

$product-page-main-background-color: $gallery; // Main product area background (media & info sidebar)

// Media (Gallery) vars
$product-media-padding: $twenty-gutter; // Grid column padding of product media
$product-media-grid-column: auto; // Grid column width of product media 

// Product info main vars
$product-info-main-padding: $twenty-gutter; // Grid column padding of product info sidebar
$product-info-main-grid-column: 566px; // Grid column width of product info sidebar
$product-info-main-grid-column-tablet: 50%; // Interim 50% applied to tablet
$product-info-main-float-width: $product-info-main-grid-column; // The width of the main info area for float fallbacks
$product-info-main-float-width-tablet: calc(50% - #{$item-gutter * 1.5}); // The width of the main info area for float fallbacks
$product-info-media-float-width: calc(100% - #{$product-info-main-grid-column}); // The width of the media area for float fallbacks
$product-info-media-float-width-tablet: calc(50% - #{$item-gutter * 1.5}); // The width of the media area for float fallbacks

// -------------------------------------------------
// 1. Product Title
// -------------------------------------------------

$product-title-color: $silver-chalice; // Product title color
$product-title-weight: $font-weight-bold; // Product title font weight

// -------------------------------------------------
// 2. Product Info Area
// -------------------------------------------------

$product-info-main-background-color: $concrete; // Background colour of the containing info area
$product-sku-weight: $font-weight-bold; // Product SKU font weight 

// Add to area (Button & QTY)
$product-addto-background: $grey-light; // Background of add to area (Containing button and qty input)
$product-addto-padding: $ten-gutter $fifteen-gutter $twenty-gutter; // padding of add to area 
$product-addto-padding-desktop: $fifteen-gutter $gutter $gutter; // padding of add to area
$product-addto-margin-top: rem(16); // Margin above add to area
$product-addto-qty-padding-right: $twenty-gutter;
 
$product-info-main-additional-margin-top: $ten-gutter; // Margin above the additional info area (Houses review stars, SKU and other info (e.g. a custom attribute)
$product-info-main-price-margin-top: rem(13); // Margin above the product price container

// Product stock
$product-info-stock-padding: 0 $twenty-gutter $twenty-gutter;
$product-info-stock-color: $white;
$product-info-stock-background-color: $blaze-orange;
$product-info-stock-margin-top: 0;
$product-info-stock-align: right; // Alignment of stock text
$product-info-stock-text-transform: capitalize;
$product-info-stock-available-icon-name: van-white;
$product-info-stock-unavailable-icon-name: cross;
$product-info-stock-icon-width: 26px;
$product-info-stock-icon-height: 15px;
$product-info-stock-icon-margin-right: $ten-gutter;

// Product lead time
$product-info-lead-time-message-display: flex;
$product-info-lead-time-message-color: $white;
$product-info-lead-time-message-font-weight: $font-weight-semibold;
$product-info-lead-time-margin-top: $item-gutter;
$product-info-lead-time-message-justify-content: center;
$product-info-lead-time-message-dispatch-date-font-weight: $font-weight-bold;
$product-info-lead-time-message-cutoff-date-font-weight: $font-weight-bold;
$product-info-lead-time-svg-name: van;
$product-info-lead-time-svg-size: em(20);
$product-info-lead-time-svg-display: block;
$product-info-lead-time-svg-margin-right: em(5);
$product-info-lead-time-svg-background-size: contain;

// Product links (Wishlist, compare, social)
$product-links-mobile-flex: 0 1 percentage(1/2); // Flex value for link items.
$product-links-flex: 0 1 percentage(1/3); // Flex value for link items.
$product-links-item-margin-top: $ten-gutter / 2; // Margin top of each product link item
$product-links-margin: $twenty-gutter - $product-links-item-margin-top 0 0; // Margin top of entire links container
$product-links-wishlist-icon-name: heart;
$product-links-requisition-icon-name: heart; /* need an icon for this */
$product-links-compare-icon-name: star;
$product-links-social-icon-name: share;
$product-links-delivery-icon-name: van;
$product-links-icon-width: rem(17); // Width of ALL link icons
$product-links-icon-height: rem(17); // Height of ALL link icons
$product-links-icon-margin-right: $ten-gutter; // Margin between icon and text
$product-links-social-margin-top: $ten-gutter; // Margin above social icons
$product-links-social-icons-margin-right: rem(7); // Margin right between each social media icon 
$product-links-social-icons-dropdown: true; // Enables / disables show hiding of social icons 
$product-info-icon-breakpoint: 560px; // halfway between s and xs
$product-requisition-dropdown-background: $white;

// Review 
$product-info-review-summary-display: flex;
$product-info-review-summary-label-spacing: $item-gutter;
$product-info-review-summary-margin-top: $ten-gutter;
$product-info-review-summary-actions-display: flex;
$product-info-review-summary-actions-justify-content: space-between;
$product-info-review-summary-actions-margin-top: rem(2);
$product-info-review-summary-actions-font-style: italic;

// -------------------------------------------------
// 3. Product additional info / Product Tabs
// -------------------------------------------------

// Upsell Settings 
$product-info-upsell-flex: 0 1 100%;
$product-info-upsell-product-items-display: flex;
$product-info-upsell-product-item-flex: 1; 

// Viewed Products 
$products-viewed-product-items-display: flex;
$products-viewed-product-item-flex: 1; 
$products-viewed-product-padding: em(65) 0;

// Configurable Settings
$product-tabs-horizontal: false; // Set the product tabs to a horizontal layout. True to enable, false to disable. Tweak breakpoints below

// Breakpoints
$product-tabs-breakpoints: (
	mobile: $screen__m, // Max-width breakpoint to display mobile accordion tabs
	desktop-vertical: $screen__m, // Min-width breakpoint to display desktop vertical style tabs
	desktop-horizontal: $screen__l, // Min-width breakpoint to display desktop horizontal style tabs
);

// Mobile accordion
$product-tabs-accordion-container-border: 0; // Borders above and below accordion container
$product-tabs-accordion-items-border: 1px dashed $outer-space; // Borders above each accordion item. Sometimes dashed!
$product-tabs-accordion-items-padding: $twenty-gutter 0; // Padding of accordion items

// General Styles
$product-tabs-background: $grey-lightest; // Tabs container background colour
$product-tabs-padding: $fifteen-gutter 0; // Tabs container padding
$product-tabs-padding-desktop: $row-gutter * 1.8 0; // Tabs container padding
$product-tabs-title-display: block;
$product-tabs-title-display-desktop: block;
$product-tabs-title-weight: $font-weight-bold; // Tabs heading font weight 
$product-tabs-title-color: $brand-accent; // Tabs heading font weight 
$product-tabs-width: 64em; // Max width of tabs area
$product-tabs-content-margin-top: em(33) auto 0; // Margin above the actual tabs & content container
$product-tabs-review-list-display: none; // hidden by default

// Horizontal Settings
$product-tabs-horizontal-tab-height: 56px;
$product-tabs-horizontal-tab-height-active: 56px; // Usually 1px more than the tab height (To allow the active border to sit on top of the content border
$product-tabs-horizontal-tab-padding: rem(18) rem(12) rem(12); // Padding for tab activator
$product-tabs-horizontal-tab-border: 1px solid $mercury; // Border around each tab
$product-tabs-horizontal-content-border-top: 1px solid $mercury; // Border above the content area
$product-tabs-horizontal-content-padding-top: $twenty-gutter;

$product-tabs-horizontal-active-tab-background: $white; // Background colour of the active tab
$product-tabs-horizontal-active-tab-color: $brand-accent; // Font colour of the active tab
$product-tabs-horizontal-active-tab-border-bottom: 1px solid $product-tabs-background; // Bottom border of active tab - Used to allow a continuous background between tab and content. Colour should usually be the same as the content background

// Review Settings 
$product-tabs-review-item-margin: $twenty-gutter;
$product-tabs-review-item-width: 100%;
$product-tabs-review-title-border: 1px solid $border-color;
$product-tabs-review-label-padding: $item-gutter; // spacing between label and rating

$product-tabs-review-toolbar-background: transparent;
$product-tabs-review-toolbar-justification: flex-end; // justify-content: flex-end etc.
$product-tabs-review-toolbar-limiter-width: 300px;
$product-tabs-review-toolbar-limiter-text-flex: 0 1 40%;
$product-tabs-review-toolbar-limiter-text-spacing: $item-gutter;
$product-tabs-review-toolbar-limiter-label-display: none;

// -------------------------------------------------
// 3. Product Swatches
// ------------------------------------------------

$product-swatch-text-padding: rem(9) rem(11) rem(8);
$product-swatch-text-margin: 0 $ten-gutter $ten-gutter 0;
$product-swatch-text-border: 1px solid $silver;
$product-swatch-text-background-color: $white;
$product-swatch-text-active-background-color: $white;
$product-swatch-text-active-color: $red-ribbon;
$product-swatch-text-active-border: 2px solid $red-ribbon;
$product-swatch-colour-height: $gutter;
$product-swatch-colour-min-width: rem(40);
$product-swatch-option-padding-bottom: $ten-gutter;
$product-swatch-option-padding-top: $twenty-gutter;

// -------------------------------------------------
// 4. Bundles Products
// ------------------------------------------------

// Product options padding
$product-bundle-options-background-color: $alabaster; // Background colour for the product options section
$product-bundle-options-back-button-margin-bottom: $gutter; // Margin below the "Go back to product details" button
$product-bundle-options-max-width: 64em; // Max width of the product options section
$product-bundle-options-padding: $gutter;
$product-bundle-options-padding-mobile: $twenty-gutter $fifteen-gutter;
$product-bundle-summary-padding: $gutter;
$product-bundle-summary-padding-mobile: $fifteen-gutter;
$product-bundle-options-flex: 0 1 60%;
$product-bundle-options-summary-flex: 0 1 40%;
$product-bundle-options-field-option-padding-top: $gutter;
$product-bundle-options-field-option-border-top: 1px solid $border-color;
$product-bundle-options-field-choice-margin-top: $small-gutter;
$product-bundle-options-field-qty-martin-top: $gutter;
$product-bundle-options-price-box-margin-top: $gutter;
$product-bundle-options-summary-bundle-item: $twenty-gutter;

// -------------------------------------------------
// 5. Custom Options
// -------------------------------------------------

$product-custom-options-sample-image-width: rem(70);
$product-custom-options-sample-image-height: rem(70);
$product-custom-options-sample-image-width-desktop: rem(74);
$product-custom-options-sample-image-height-desktop: rem(74);
