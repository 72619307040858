/* -------------------------------------------------
 * -------------------------------------------------
 * Button Styles
 * -------------------------------------------------
 * @author Fisheye
 */

/* Default button
 * -------------------------------------------------
 * Transparent background with body font color border & text
 */

@mixin button__default(
	$height: rem(40),
	$padding: $button-default-padding,
	$background-color: $button-default-background,
	$border: $button-default-border,
	$border-radius: $button-default-radius,
	$color: $button-default-color,
	$font-weight: $button-default-weight
) {
	min-height: $height;
	padding: $padding;
	background-color: $background-color;
	border: $border;
	border-radius: $border-radius;
	text-align: center;
	@include transition(all 0.3s ease-in-out);
	cursor: pointer;

	&,
	span {
		display: inline-block;
		@include type__14(
			$color: $color,
			$font-weight: $font-weight,
			$letter-spacing: 1px,
			$line-height: 1
		);
		text-transform: uppercase;
	}

	&:hover,
	&:focus,
	&:active {
		background-color: $button-default-background-hover;
		border: $button-default-border-hover;
		opacity: 1;
		color: $button-default-color-hover;

		span {
			color: $button-default-color-hover;
		}
	}
}

/* Journey Button
 * -------------------------------------------------
 * Shadow Green color background with white text / opposite on hover
 */

@mixin button__journey(
	$height: rem(40),
	$padding: $button-journey-padding,
	$background-color: $button-journey-background,
	$border: $button-journey-border,
	$border-radius: $button-journey-radius,
	$color: $button-journey-color,
	$font-weight: $button-journey-weight,
	$background-color-hover: $button-journey-background-hover,
	$border-hover: $button-journey-border-hover,
	$colour-hover: $button-journey-color-hover
) {
	min-height: $height;
	padding: $padding;
	background-color: $background-color;
	border: $border;
	border-radius: $border-radius;
	text-align: center;
	@include transition(all 0.3s ease-in-out);
	cursor: pointer;

	&,
	span {
		display: inline-block;
		@include type__14(
			$color: $color,
			$font-weight: $font-weight-normal,
			$letter-spacing: 1px,
			$line-height: 1
		);
		text-transform: uppercase;
	}

	&:hover,
	&:focus {
		background-color: $background-color-hover;
		border: $border-hover;
		opacity: 1;
		color: $colour-hover;

		span {
			color: $colour-hover;
		}
	}
}

/* Address button styles
 * --------------------------------------------------------------
 * Light grey button with blue text and no rounded edges 
 * Can be found on account & checkout pages
 */

@mixin button__address(
	$padding: $ten-gutter $twenty-gutter,
	$background-color: $silver,
	$border-radius: 0
) {
	padding: $padding;
	background-color: $background-color;
	border-radius: $border-radius;

	&:hover,
	&:focus,
	&:visited {
		background-color: darken($background-color, 12%);
	}

	span {
		@include type__14($color: $silver-chalice);
	}
}

/* Pagination Button
 * -------------------------------------------------
 * Pagination buttons used on category pages
 */

@mixin button__pagination(
	$background-color: $button-pagination-background,
	$padding: $button-pagination-padding,
	$color: $button-pagination-color,
) {
	display: block;
	background-color: $background-color;
	padding: $padding;
	height: $button-pagination-height; // added to stop button height varying moving as they scale
	margin: 0 1px; // the spacing between pagination buttons which don't neeed to be scaled
	width: $button-pagination-width;
	text-align: center;
	border-radius: $button-pagination-radius;

	&.next,
	&.previous {
		background-color: $button-pagination-arrow-background;
		@include icon__arrow-white(
			$width: $button-pagination-width,
			$height: $button-pagination-height
		);
	}

	&.next {
		border-top-right-radius: $button-pagination-arrow-radius;
		border-bottom-right-radius: $button-pagination-arrow-radius;
	}

	&.previous {
		background-image: url('../images/svg/arrow-left-white.svg'); // Not used svg-background here as only replacing the image
		border-top-left-radius: $button-pagination-arrow-radius;
		border-bottom-left-radius: $button-pagination-arrow-radius;
	}

	span {
		@include type__14(
			$font-family: $body-font-family,
			$color: $color,
			$line-height: 1.78,
			$font-weight: $font-weight-normal
		);
	}
}

/* QTY Buttons
 * -------------------------------------------------
 * QTY ticker buttons
 * Default width/heights are for category & minibasket
 * Product page width/height can be changed when including the mixin
 */

@mixin button__qty(
	$width: $button-qty-width,
	$height: $button-qty-height,
	$padding: $button-qty-padding,
	$background-color: $button-qty-background,
	$border: $button-qty-border,
	$border-radius: $button-qty-radius,
	$color: $button-qty-color,
	$font-weight: $button-qty-weight
) {
	@include type__18(
		$color: $color,
		$font-weight: $font-weight-normal,
		$line-height: 1.3
	);
	width: $width;
	height: $height;
	padding: $padding;
	background-color: $background-color;
	border: $border;

	&.qty-minus {
		@include svg-background(
			$name: 'minus',
			$size: 10px 2px
		);
		background-position: center right;
		border-top-left-radius: $border-radius;
		border-bottom-left-radius: $border-radius;
	}

	&.qty-plus {
		@include svg-background(
			$name: 'plus',
			$size: 10px 10px
		);
		background-position: center left;
		border-top-right-radius: $border-radius;
		border-bottom-right-radius: $border-radius;
		text-align: left;
	}

	&:hover,
	&:focus {
		background-color: $button-qty-background-hover;
		border: $button-qty-border-hover;
		opacity: 1;
	}
}

/* Cancel button styles
 * --------------------------------------------------------------
 * Completely removes button styles
 */

%cancel-button {
	background: none;
	border: 0;
}

/* Cancel button styles
 * --------------------------------------------------------------
 * Completely removes button styles 
 */

%cancel-button {
	background: none;
	border: 0;
}

// /* Close and remove icon styles
//  * --------------------------------------------------------------
//  * - called action as sometimes can be links, sometimes buttons
//  */

@mixin action-w-icon__cross-black(
	$color: $body-font-color
) {
	@include icon__cross-black;
	@include type__18($color: $color);

	&:before {
		display: none;
	}

	&:after {
		margin-left: em(7);
	}
}

/* Page Builder Primary Button
 * --------------------------------------------------------------
 */
.pagebuilder-button-primary {
	@include button__default;
}

/* Submit / Subscribe inline button styles
 * --------------------------------------------------------------
 * Form submit inline button 
 */

@mixin form-submit-inline-button(
	$height: 100%,
	$width: 35px,
	$background-color: transparent
) {
	height: $height;
	width: $width;
	padding: 0;
	background-color: $background-color;
	@include svg-background(
		$name: "arrow-right",
		$size: 100% 100%
	);
	border: none;
}
