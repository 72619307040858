/* -------------------------------------------------
 * -------------------------------------------------
 * Product Page
 *
 * Specific layout settings not covered in
 * _flex-grid.scss or _grid.scss
 *
 * -------------------------------------------------
 * @author Fisheye
 */

.catalog-product-view {
	.main {
		padding: 0;
	}

	.product-main-container {
		@extend %row-container;
		background-color: $product-page-main-background-color;
		display: grid;
		grid-template-areas: "info" "media" "price";
		margin-bottom: 0;
		padding: $gutter $fifteen-gutter 0;

		@include min-screen($screen__ml) {
			grid-template-areas: "media info" "media price";
			padding: 0;
		}
	}

	.product-additional-container {
		padding: $gutter $fifteen-gutter;
		position: relative;

		@include min-screen($screen__ml) {
			padding: $sixty-gutter $gutter;
		}

		&:before {
			@include min-screen($screen__ml) {
				content: "";
				display: block;
				height: 106px;
				width: 106px;
				@include svg-background(
					$name: 'cheese-icon',
					$size: 60%
				);
				background-color: $white;
				border-radius: 50%;
				margin: auto;
				position: absolute;
				top: -53px;
				right: 0;
				left: 0;
			}
		}

		.product-info-wrapper {
			display: flex;
			flex-flow: column wrap;

			@include min-screen($screen__ml) {
				justify-content: space-between;
				flex-flow: row wrap;
			}

			> div {
				@include min-screen($screen__ml) {
					flex-basis: calc(50% - #{$fifteen-gutter});
				}
			}

			.description-wrapper {
				@include min-screen($screen__ml) {
					display: block;
					flex-grow: 1;
				}
			}

			.product-tab-content-wrapper {
				@include max-screen($screen__ml - 1) {
					margin-top: $gutter;
				}

				@include min-screen($screen__ml) {
					margin-left: $gutter;
				}
			}

			&.show-more {
				.description-wrapper {
					.product {
						&.description {
							max-height: 100%;

							&:after {
								opacity: 0;
								pointer-events: none;
							}
						}
					}
				}
			}

			.read-more-button-wrapper {
				text-align: center;
				flex: 1 1 100%;
				margin-top: $gutter;

				.read-more {
					@include button__default;
				}
			}
		}
	}

	.product.media {
		grid-area: media;
		margin-top: $twenty-gutter;
		min-height: calc(100vw - #{$gutter});

		@include min-screen($screen__ml) {
			margin: 0;
			min-height: 50vw;
		}
	}

	.product-info-main {
		grid-area: info;

		@include min-screen($screen__ml) {
			align-self: end;
		}
	}

	.product-price-action {
		grid-area: price;
		margin: $fifteen-gutter 0 0;

		@include min-screen($screen__ml) {
			margin: 0;
			padding: rem(6) $gutter $fifteen-gutter;
		}
	}

	.product-additional-content {
		.pagebuilder-column {
			padding-bottom: 0;
		}

		.image-column {
			img {
				@include min-screen($screen__m) {
					max-width: rem(840) !important; // Override inline Pagebuilder style
				}
			}
		}
	}

	.product.info {
		h3 {
			@extend %visuallyhidden;
		}
	}

	&.product-rolling-subscription {
		.product-main-container {
			.product-add-form {
				[id="product_addtocart_form"] { // No class
					display: flex;
					flex-direction: column;

					.aw-sarp2-product-subscription-options {
						order: 1;
					}

					.product-options-wrapper {
						order: 2;
					}

					.product-options-bottom {
						order: 4;
					}
				}
			}
		}
	}

	// Feefo widget tab that floats to the left side of the screen
	// Targetting the Feefo tag itself is intentional due to there being no attributes or class available
	feefowidget-container-floating-freefo {
		@extend %visuallyhidden;
	}

	&.page-layout-subscription,
	&.page-layout-gift-subscription {
		.product-price-action {
			.product-add-form {
				.product-options-bottom {
					.field {
						&.qty {
							@extend %visuallyhidden;
						}
					}
				}
			}
		}
	}

	.product-aside-container {
		.product-main-content {
			.product-selections-carousel {
				.product-item {
					.product-item-info {
						.product-item-details {
							.dietary-tooltip-wrapper {
								margin-top: 0;
							}

							.product-short-desc {
								overflow: hidden;
								text-overflow: ellipsis;
								display: -webkit-box;
								-webkit-line-clamp: 3;
								-webkit-box-orient: vertical;

								@include min-screen($screen__xs) {
									-webkit-line-clamp: 6;
								}

								@include min-screen($screen__s) {
									-webkit-line-clamp: 8;
								}

								@include min-screen($screen__ml) {
									-webkit-line-clamp: 5;
								}

								@include min-screen($screen__ll) {
									-webkit-line-clamp: 8;
								}

								@include min-screen($screen__xxl) {
									-webkit-line-clamp: 5;
								}

								@media screen and (min-width: 1680px) {
									-webkit-line-clamp: 7;
								}
							}
						}
					}
				}
			}

			.why-subscribe {
				.cms-banner {
					.pagebuilder-column-group {
						// Select all direct children of the container (The flex children)
						&> * {
							@include min-screen($screen__m) {
								flex-basis: 100% !important; // Override global setting in _flex-grid.scss
							}
						}
					}
				}
			}
		}
	}
}

