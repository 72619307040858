// Visual hide items so screen readers can still see them
// -------------------------------------------------
%visuallyhidden {
	margin: 0;
	padding: 0;
	width: 0;
	height: 0;
	overflow: hidden;
	clip: rect(0 0 0 0);
	clip: rect(0, 0, 0, 0);
	position: absolute;
}

%cancel-visuallyhidden {
	margin: auto;
	padding: auto;
	width: initial;
	height: initial;
	overflow: auto;
	clip: initial;
	position: relative;
}

// Break out of conatiner to make full-wdith
// -------------------------------------------------
%fullwidth {
	position: relative;
	left: 50%;
	transform: translate(-50%, 0);
	width: 100vw;
	max-width: 100vw;
}

%cancel-fullwidth {
	left: inherit;
	transform: inherit;
	width: inherit;
	max-width: inherit;
}

%fullwidth-mobile {
	position: relative;
	left: 50%;
	transform: translate(-50%, 0);
	width: 100vw;
	max-width: 100vw;

	@include min-screen($screen__l) {
		left: inherit;
		transform: inherit;
		width: inherit;
		max-width: inherit;
	}
}

// Vertical Center - table > table-cell
// -------------------------------------------------
%table-center {
	display: table;
	height: 100%;
	width: 100%;
}

%table-cell-center {
	display: table-cell;
	height: 100%;
	vertical-align: middle;
	width: 100%;
}

// Radio and Checkboxes psuedo elements
// -------------------------------------------------
%radio-unchecked-before {
	&:before {
		background-image: url("../images/svg/radio_unchecked.svg");
		background-repeat: no-repeat;
		background-size: 31px 31px;
		content: "";
		cursor: pointer;
		display: inline-block;
		height: 31px;
		margin-right: 15px;
		vertical-align: middle;
		width: 31px;
	}
}

%radio-unchecked-after {
	&:after {
		background-image: url("../images/svg/radio_unchecked.svg");
		background-repeat: no-repeat;
		background-size: 31px 31px;
		content: "";
		cursor: pointer;
		display: inline-block;
		height: 31px;
		margin-right: 13px;
		vertical-align: middle;
		width: 31px;
	}
}

%checkbox-unchecked-before {
	&:before {
		background-image: url("../images/svg/checkbox_unchecked.svg");
		background-repeat: no-repeat;
		background-size: 21px 31px;
		content: "";
		cursor: pointer;
		display: inline-block;
		height: 31px;
		margin-right: 15px;
		vertical-align: middle;
		width: 31px;
	}
}

%checkbox-unchecked-after {
	&:after {
		background-image: url("../images/svg/checkbox_unchecked.svg");
		background-repeat: no-repeat;
		background-size: 31px 31px;
		content: "";
		cursor: pointer;
		display: inline-block;
		height: 31px;
		margin-right: 13px;
		vertical-align: middle;
		width: 31px;
	}
}
