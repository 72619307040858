/* -------------------------------------------------
 * -------------------------------------------------
 * Ajax Cart - Added to Basket Popup
 *
 * TODO: Test this. Copied directly from Urban Gifts
 *
 * --------------------------------------------------
 * @author Fisheye
 */

.modal-popup.block-basket-preview {
	@include max-screen($screen__m - 1) {
		padding-top: rem(100);
	}

	.modal-inner-wrap {
		background-color: $basket-popup-background-color;
		box-shadow: none;

		@include max-screen($screen__m - 1) {
			overflow-y: visible !important; // Because of specificity
		}

		@include min-screen($screen__m) {
			margin: rem(130) auto auto;
			max-width: $basket-popup-inner-model-max-width;
		}

		.modal-header {
			border-bottom: 0;
			padding: $basket-popup-model-header-spacing;
			position: relative;

			.action-close {
				@include max-screen($screen__m - 1) {
					padding-right: rem(25);
					position: absolute;
					right: $twenty-gutter;
					bottom: calc(100% + #{$ten-gutter});
				}

				&:after {
					@include max-screen($screen__m - 1) {
						height: rem(24);
						width: rem(24);
						background-color: $white;
						background-size: $ten-gutter;
						border-radius: 50%;
						margin: auto;
						position: absolute;
						top: 0;
						right: -$five-gutter;
						bottom: 0;
					}
				}

				span {
					@include max-screen($screen__m - 1) {
						@include type__18($color: $white);
					}
				}
			}

			.modal-title {
				position: relative;
				padding-left: rem(25);
				@include type__16($font-weight: $font-weight-normal);

				&:before {
					@include svg-background(
						$name: 'added-to-basket-tick',
						$size: 100% auto
					);
					background-position: center;
					left: 0;
					position: absolute;
					content: '';
					width: 18px;
					height: 20px;
				}
			}
		}

		.modal-content {
			background-color: $basket-popup-background-color;
			padding: 0;
		}

		// product image and details text
		// ------------------------

		.block-basket-preview {
			position: relative;

			.basket-preview-overlay {
				background-color: $basket-popup-image-overlay-color;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				z-index: 1;
			}

			&.bundle {
				.product-container {
					.block-popup-item-options {
						.block-popup-item-option {
							.option-value  {
								.price { // Need to be REALLY specific to override existing styling
									// Client does not wish to show bundle option pricing.
									// We can't modify the value output as the same value is used in the backend.
									display: none;
								}
							}
						}
					}
				}
			}
		}

		.basket-popup-product-details {
			display: flex;
			align-items: center;
			flex-flow: row wrap;
			width: 100%;
			padding: $basket-popup-product-details;
			position: relative;
			z-index: 2;

			> svg {
				width: 44px;
				height: 44px;
			}

			.st0 {
				fill: none;
				stroke: $white;
			}

			.st1 {
				fill: $white;
				stroke: $white;
			}

			.messages {
				flex: 0 1 auto;
				padding: $basket-popup-detail-message-spacing;
				order: 1;
				flex: 0 1 100%;
			}

			.message-text {
				display: none; // the default magento message that we replace
			}

			.basket-popup-title {
				flex: 0 1 auto;
				padding: $basket-popup-detail-title-spacing;

				span {
					@include type__19(
						$color: $basket-popup-product-details-color,
						$font-weight: $font-weight-bold
					);

					@include text-crop;
				}
			}

			.product-container {
				display: $basket-popup-product-info-container-display-setting;
				flex: 0 1 auto;
				align-items: center;
			}

			.product-name {
				display: $basket-popup-detail-name-display;
				margin-bottom: $fifteen-gutter;
				@include type__18($color: $basket-popup-product-details-color);

				@include min-screen($screen__m) {
					@include type__16($color: null);
				}
			}
		}

		.basket-delivery-upsell {
			border: 0;
			border-bottom: solid 1px $white;
			background-color: transparent;

			.upsell-message,
			.upsell-message-alt {
				@include type__19(
					$color: $basket-popup-product-details-color,
					$font-weight: $font-weight-normal,
					$letter-spacing: 1px,
					$line-height: $basket-popup-upsell-msg-line-height
				);

				@include min-screen($screen__ml) {
					@include type__17(
						$color: null,
						$font-weight: null,
						$letter-spacing: null
					);
				}

				strong {
					font-weight: $font-weight-semibold;
					white-space: nowrap;
				}
			}
		}

		// continue btns
		// ------------------------
		.block-basket-preview-popup {
			.page.messages {
				margin: 0 $twenty-gutter;
			}

			.actions {
				display: flex;
				flex-flow: row wrap;
				padding: $basket-popup-actions-container-spacing;
				background: $popup-background-color;
				justify-content: center;

				@include min-screen($screen__s) {
					display: flex;
					flex-flow: row wrap;
					align-items: flex-start;
				}

				.cart,
				.continue {
					@include button__journey(
						$padding: rem(3) $twenty-gutter $five-gutter,
						$background-color-hover: transparent
					);
					margin: $basket-popup-proceed-buttons-spacing;
					flex: $basket-popup-proceed-buttons-flex-grow $basket-popup-proceed-buttons-flex-shrink $basket-popup-proceed-buttons-flex-basis;
					max-width: $basket-popup-proceed-button-width; // controls width of button
					margin-bottom: 0;

					@include min-screen($screen__m) {
						flex: 1 1;
					}

					span {
						@include type__19(
							$color: $white,
							$font-weight: $font-weight-medium,
							$letter-spacing: 1px
						);

						@include min-screen($screen__m) {
							@include type__17(
								$color: null,
								$font-weight: null,
								$letter-spacing: 1px
							);
						}
					}
				}

				@include max-screen($screen__m - 1) {
					.continue {
						order: 1;
						margin-top: $twenty-gutter;
					}
				}

				@include min-screen($screen__m) {
					.continue {
						margin-right: $ten-gutter;
					}

					.cart {
						margin-left: $ten-gutter;
					}
				}
			}

			.secondary-actions {
				background-color: $popup-background-color;
				padding: $basket-popup-secondary-actions-container-spacing;

				.express-checkout {
					text-align: center;

					.express-checkout-title {
						padding-bottom: $twenty-gutter;
						display: block;

						span {
							@include type__13($font-weight: $font-weight-bold);
						}

						.product-quantity {
							span {
								font-weight: $font-weight-normal;
							}
						}
					}

					.extra-actions-text {
						display: block;
						padding-bottom: $minicart-extra-action-spacing;
					}

					.extra-actions-container { // paypal options container
						display: flex;
						align-items: center;
						background-color: $popup-background-color;
					}

					.paypal {
						flex: 0 1 100%;

						.zoid-outlet {
							width: 100% !important;
						}
					}
				}
			}
		}

		.block-popup-item {
			display: flex;
			border-bottom: solid 1px $white;

			.img-container {
				flex: 0 0 rem(119);
				width: rem(119);
				position: relative;

				@include min-screen($screen__ml) {
					flex: 0 0 rem(103);
				}

				img {
					object-fit: cover;
				}
			}

			.product-container {
				width: 100%;
				display: flex;
				align-items: center;
				padding: $twenty-gutter;

				.product-name {
					flex: 0 1 100%;
				}

				.product-price,
				.product-quantity,
				.block-popup-item-options {
					span {
						@include type__16(
							$color: $basket-popup-product-details-color,
							$font-weight: $font-weight-semibold
						);
					}
				}

				.product-price {
					flex: 1;
					padding: $basket-popup-detail-price-spacing;
				}

				.product-price,
				.product-quantity {
					order: 1;
				}

				.product-quantity {
					display: $basket-popup-detail-qty-display;
					padding: $basket-popup-detail-qty-spacing;
					margin-top: $five-gutter;

					span {
						@include type__16($color: $basket-popup-product-details-color);
					}
				}

				.product-price {
					display: $basket-popup-detail-price-display;
					text-align: right;
				}

				.block-popup-item-options {
					flex: 0 0 100%;
					display: flex;
					flex-flow: column;

					.show-options {
						font-weight: $font-weight-semibold;
						cursor: pointer;

						&:after {
							content: '';
							display: inline-block;
							height: 10px;
							width: 13px;
							margin-left: $five-gutter;

							@include svg-background(
								$name: 'arrow-down',
								$size: contain
							);
						}
					}

					&.active {
						.show-options {
							&:after {
								@include svg-background('arrow-up');
							}
						}

						.block-popup-item-option {
							display: block;
							margin-top: $five-gutter;
						}
					}

					.block-popup-item-option {
						display: none;

						.bundle-option-label {
							@include type__14($font-weight: $font-weight-semibold);
							margin-bottom: $five-gutter;

							.price {
								display: inline-block;
							}
						}
					}
				}

				.option-seperator {
					padding-right: $item-gutter / 2;
				}
			}

			&.pinned {
				.block-popup-item-options.pinned {
					flex: 0 1 auto;

					.block-popup-item-option {
						display: block;
					}

					.option-label {
						@extend %visuallyhidden;
					}

					.option-value {
						margin: 0;
						padding-right: $twenty-gutter;
						font-weight: $font-weight-semibold;
					}
				}

				.product-quantity {
					margin-top: 0;
				}
			}
		}
		// you may also like block (crosssell)
		// ------------------------

		.block-cross-sell-products {
			display: flex;
			flex: 1;
		}

		.block-basket-crosssell {
			display: flex;
			flex-wrap: wrap;
			padding: $gutter calc(#{$fifteen-gutter} / 2);
			background-color: $basket-popup-crosssell-background-color;

			@include min-screen($screen__m) {
				padding: $twenty-gutter $fifteen-gutter;
			}

			.block-title {
				flex: 1 100%;
				padding: 0 calc(#{$fifteen-gutter} / 2);
				margin-bottom: $ten-gutter;
				text-align: center;

				@include min-screen($screen__m) {
					padding: 0 $fifteen-gutter;
				}

				strong {
					@include type__22(
						$font-family: $font-family-serif,
						$font-weight: $font-weight-normal
					);
					font-style: italic;
					text-transform: capitalize;

					@include min-screen($screen__m) {
						@include type__24(
							$font-family: null,
							$font-weight: null
						);
					}
				}
			}

			.crossSellContainer {
				display: flex; // flex applied to align content vertically but can also be used to order content
				flex: 1 1 auto;
				flex-flow: row wrap;
				margin-top: $twenty-gutter;

				> a {
					flex: 0 0 100%;
					display: block;
					@include type__16;
				}

				.product-price,
				.product-dietary-tooltips {
					align-self: flex-end;
					margin-top: $fifteen-gutter;
					flex: 1 1 50%;

					@include min-screen($screen__ml) {
						margin-top: $twenty-gutter;
					}
				}

				.product-dietary-tooltips {
					text-align: right;
				}

				.dietary-tooltip-wrapper {
					justify-content: flex-end !important; // Override tooltip styles
					margin-top: 0 !important; // Override tooltip styles
				}

				.priceEach {
					@extend %visuallyhidden;
				}

				> form {
					 width: 100%;
					 align-self: flex-end;

					 button {
						@include button__journey;
						display: $category-product-listing-button-display;
						width: 100%;
						margin-top: $fifteen-gutter;
					}
				}
			}

			.block-cross-sell-product {
				display: flex;
				flex: 0 0 50%;
				flex-direction: column;
				padding: $five-gutter calc(#{$fifteen-gutter} / 2);
				position: relative;

				@include min-screen($screen__m) {
					padding: $ten-gutter $fifteen-gutter;
				}
			}

			.img-container {
				position: relative;
			}

			img {
				width: 100%;
			}
		}
	}

	.basket-popup-loading {
		text-align: center;
		padding: $twenty-gutter 0;
	}
}

.catalog-product-view {
	// needs to be more specific
	.block-basket-preview.modal-slide {
		left: 0;
		overflow-y: auto;
		padding-left: 0;
		padding-right: 0;

		.modal-inner-wrap {
			background-color: $basket-popup-background-color;
		}
	}
}

.modals-overlay {
	background-color: $overlay-color-alt;
}

// need to be slightly more specific
.block-basket-preview {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

	&.modal-slide {
		.modal-inner-wrap {
			border: 0;
			height: auto;
			max-height: 100%;
			box-shadow: none;
		}
	}
}

aside.modal-popup.block-basket-preview {
	.modal-inner-wrap[class] {
		.modal-content {
			overflow-y: visible;
		}
	}
}

// Gift card specific
// ------------------------

.page-product-giftcard {
	.block-basket-preview {
		.modal-inner-wrap {
			.block-basket-preview-popup {
				.basket-popup-product-details {
					.block-popup-item-options {
						.block-popup-item-option {
							width: 100%;

							.option-label {
								@include type__14($font-weight: $font-weight-bold);

								&:first-of-type {
									@extend %cancel-visuallyhidden;
								}
							}

							.option-value {
								@include type__14;
							}
						}
					}
				}
			}
		}
	}
}
