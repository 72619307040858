// -------------------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------------------
//  All code in this file should contain fixes for IE11 ONLY! Any other fixes for specific browsers
//  should go in their respecitve file. Fixes for browsers all browsers should go in _defend.scss
//  @author Fisheye
// -------------------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------------------

.ie11 {
	.page-layout-checkout .header.content .checkout-header-security svg {
		width: em(16); // Encol-pricesures the checkout header secure text doesn't spill onto two lines
	}

	form .fieldset .field {
		display: block; // Stops the inputs and labels from collapsing into one another
	}

	.opc-wrapper .checkout-shipping-method .table-checkout-shipping-method tbody td {
		&:nth-child(3) {
			padding-left: 50%; // Ensures there is a space between delivery price & method on checkout
		}
	}

	//Login/Create an Account
	.login-container {
		.block {
			@include min-screen($screen__ml) {
				max-width: calc(50% - #{$account-login-create-spacing / 2});
				// even though the basis is set correctly it wraps on ie as it gets confused by the padding/margin
				// setting the max-width to the actual with of the block (calculated by flex: basis) fixes this
			}
		}
	}

	//Account Dashboard top-links
	.account-top-links {
		.block {
			padding: $ten-gutter 0;

			@include min-screen($screen__m) {
				flex-basis: calc(50% - #{$twenty-gutter * 2});
				//same basis issue as above, gutter is set to * 2 on calc to compensate for left and right padding
				padding: rem(15);
			}

			@include min-screen($screen__xl) {
				flex-basis: calc(25% - #{$twenty-gutter * 2});
			}

			h3 {
				margin-bottom: $five-gutter;
			}
		}
	}

	.account.wishlist-index-index {
		.actions-toolbar {
			.primary {
				.action {
					max-width: calc(#{percentage(1/3)} - #{$twenty-gutter});
					//same basis issue as above
				}
			}
		}
	}

	.login-container {
		.block-content {
			flex: 0 1 auto;

			.form-login {
				.field {
					display: block;
					flex-wrap: nowrap;
					// stop fields wrapping on ie
				}
			}

			ul {
				margin-bottom: $fifteen-gutter;
			}
		}
	}

	// added to basket
	// ----------------------
	aside.modal-popup {
		&.block-basket-preview {
			.modal-inner-wrap {
				.block-cross-sell-products {
					width: 100%;
					flex: auto;

					.block-cross-sell-product {
						flex: 1 1 50%;
					}
				}
			}

			[class].modal-inner-wrap {
				.modal-content {
					overflow-y: auto;
				}
			}
		}
	}

	.sections.nav-sections {
		left: 0;
		transform: none;
	}

	.page-multiple-wishlist {
		.products-grid {
			.product-item {
				padding: 0;

				.product-item-inner {
					padding: 0 $item-gutter;
				}
			}
		}
	}

	.account {
		&.customer-address-index {
			.block-addresses-default,
			.block-addresses-list {
				display: inline-block;
				width: 45%;

				.box-title {
					margin-top: $twenty-gutter;
				}

				.block-content {
					flex-wrap: nowrap;
				}
			}

			.block-addresses-list {
				.block-content {
					display: block;
				}
			}
		}

		.customer-account-dashboard {
			.block {
				flex: auto;

				@include min-screen($screen__ml) {
					flex: 100%;
				}

				.block-content {
					display: block;

					@include min-screen($screen__m) {
						display: flex;
					}
				}

			}
		}
	}

	.product-info-stock-sku {
		.lead-time-message {
			&:before {
				background-size: calc(#{$product-info-lead-time-svg-size} - 1px);
				// really dirty hack, cropped on IE and the only way I can make it sit right is taking a px off the size
				background-position: center;
			}
		}
	}

	body.company-account-index .page-main {
		.columns {
			.column.main {
				.block-company-profile-account,
				.block-company-profile-address,
				.block-company-profile-contacts {
					@include min-screen($screen__m) {
						flex-basis: auto;
						width: calc(50% - #{$fifteen-gutter});
					}

					@include min-screen($screen__l) {
						flex-basis: auto;
						width: calc(33.333333% - #{$fifteen-gutter});
					}
				}

				.block-company-profile-contacts {
					@include min-screen($screen__m) {
						width: 100%;
					}

					@include min-screen($screen__l) {
						width: calc(33.333333% - #{$fifteen-gutter});
					}
				}
			}
		}
	}

	/* Global Header */
	.customer-welcome,
	.customer-help-center,
	.customer-trade-links {
		&.active {
			.currency-name,
			.minicart-title,
			.customer-title,
			.quickorder-title,
			.header-link-title {
				@include min-screen($screen__m) {
					margin-bottom: 15px;
				}

				@include min-screen($screen__xxl) {
					margin-bottom: 0;
				}
			}
		}
	}

	.header.content {
		.icons-block {
			align-items: flex-start;

			.minicart-wrapper {
				display: flex;

				.minicart-container {
					flex: none;
				}
			}

			ul.header.items {
				flex: 0 1 25%;

				.customer-name {
					&:hover {
						z-index: 151;
					}
				}
			}

			.block.block-minicart {
				.minicart-content-wrapper {
					.block-title {
						.action {
							margin-left: auto;
						}
					}
				}
			}
		}
	}

	// ----------------------
	// Footer
	// ----------------------
	.page-footer {
		.footer-row-one {
			.block.instagram {
				&,
				.pagebuilder-column-group {
					min-height: 1px; // To remove extra height
					flex-wrap: wrap;
				}
			}

			.block {
				&.instagram {
					.pagebuilder-column-group {
						.pagebuilder-column {
							@include max-screen($screen__ml - 1) {
								flex-basis: 50%;
							}
						}
					}
				}
			}

			.block.newsletter {
				.content {
					.control {
						input {
							@include svg-background(
								$name: "envelope-icon",
								$size: 24px !important
							);
							background-position: rem(15) center;
						}
					}

					.actions {
						.subscribe {
							height: 10px !important; // To override standard height size
							width: 8px !important; // To override standard height size
							margin-top: $twenty-gutter;
						}
					}
				}
			}
		}

		.footer-row-two {
			.additional-info {
				[data-content-type="image"] {
					min-height: 1px; // To remove extra height
				}
			}
		}
	}

	.checkout-cart-index {
		.cart {
			&.items {
				&.table {
					.block-cart-item {
						.cart-item-row {
							.details {
								@include min-screen($screen__m) {
									flex: 1 1 auto;
									max-width: calc(55% - #{rem(185)});
								}
							}

							.field.qty {
								@include min-screen($screen__m) {
									margin-right: auto;
								}
							}
						}
					}
				}
			}
		}
	}
	// ----------------------
	// Blog
	// ----------------------
	.blog-category-index,
	.blog-search-result {
		.columns .blog__post-list {
			.blog__post-container {
				display: block;
			}

			.blog__post-item {
				@include min-screen($screen__m) {
					flex: none;
					width: percentage(1/2);
				}

				@include min-screen($screen__l) {
					flex: none;
					width: percentage(1/3);
				}

				@include min-screen($screen__xl) {
					flex: none;
					width: percentage(1/4);
				}

				.blog__post-title {
					min-height: rem(80);
				}
			}
		}
	}

	.blog-category-index,
	.blog-post-view {
		.main {
			width: 100%;
		}
	}

	.blog-page-list {
		.columns {
			.post-list {
				.post-description {
					p {
						max-height: 60px;
						overflow: hidden;
						word-wrap: break-word;
					}

					.post-text-hld {
						max-height: 100px;
						overflow: hidden;
					}
				}
			}
		}
	}

	// ----------------------
	// CMS blocks
	// ----------------------
	.cms-explore {
		.pagebuilder-column-group {
			&,
			[data-content-type="banner"] {
				min-height: 1px; // To remove the extra height
			}
		}
	}

	.cms-explore.col-12 {
		display: block !important;
	}

	.cms-seo-block {
		[data-content-type="text"] {
			@include min-screen($screen__ml) {
				width: 65%;
			}
		}
	}

	.cms-blog {
		.blog-widget {
			min-height: 1px; // To remove the extra height

			.post-list {
				.post-description {
					.post-text-hld {
						min-height: auto;
						overflow-y: visible;
					}
				}
			}
		}
	}

	.video-image-gallery {
		.pagebuilder-column-group,
		> [data-content-type="image"] {
			max-width: 50%;

			@include min-screen($screen__m) {
				max-width: percentage(1/3);
			}

			@include min-screen($screen__ll) {
				max-width: percentage(1/4);
			}

			.video-image {
				display: block !important;

				[data-content-type="modal"] {
					top: 0;
					bottom: 0;
					right: 0;
					left: 0;
					display: flex;
					align-items: center;
				}
			}
		}
	}

	.navigation-main-ul .js-search-results, .mobile-menu .js-search-results {
		@include min-screen($screen__m) {
			left: calc(50% - #{rem(235)})
		}
	}

	.navigation {
		.pagebuilder-column {
			flex-wrap: wrap;

			.display-children {
				max-width: 50px;
				flex: 0 1 50px;
				margin-right: 0;
				padding-right: $twenty-gutter;

				@include min-screen($screen__l) {
					margin-right: 0;
					padding-right: $twenty-gutter;
				}
			}
		}
	}

	.page-header {
		.header.content {
			.icons-block {
				.block-search {
					width: rem(55);
				}
			}
		}

		.nav-sections {
			.navigation {
				.submenu {
					.pagebuilder-column-group,
					[data-content-type="banner"] {
						min-height: 1px;
					}
				}
			}
		}
	}

	// ----------------------
	// Cms Shop Landing
	// ----------------------
	.accordion-text-content {
		.pagebuilder-column-group {
			.accordion,
			.description-text {
				@include min-screen($screen__m) {
					max-width: 50%;
				}
			}
		}
	}

	.further-contact-info {
		.contact-info {
			[data-content-type="text"] {
				max-width: 100%;

				strong {
					text-align: center;
					width: 100%;
				}
			}
		}
	}

	// ----------------------
	// Category
	// ----------------------
	.catalog-category-view .columns {
		display: flex;
		flex-direction: column;

		.subcategories-cms-column {
			order: 0;
		}

		.sidebar.sidebar-main {
			order: 1;
			padding: 0;
		}

		.column.main {
			order: 2;
		}

		.toolbar-top {
			@include max-screen($screen__m - 1) {
				width: 45%;
				padding: 0;
			}
		}
	}

	.sidebar-main .block.filter .filter-content .filter-options .filter-options-item {
		@include min-screen($screen__m) {
			flex: auto;
			width: 33.333%;
			max-width: 33.333%;
		}

		@include min-screen($screen__l) {
			flex: auto;
			width: calc(15% + #{rem(10)});
			max-width: calc(25%);
		}

		.page-with-filter & {
			align-self: flex-end;
		}
	}

	.category-list-wrapper .category-list-item {
		@include min-screen($screen__m) {
			flex: none;
			width: percentage(1/3)
		}

		@include min-screen($screen__l) {
			flex: none;
			width: percentage(1/4);
		}

		@include min-screen($screen__ll) {
			flex: none;
			width: percentage(1/5);
		}

		@include min-screen($screen__xl) {
			flex: none;
			width: percentage(1/6);
		}

		@include min-screen($screen__xxl) {
			flex: none;
			width: percentage(1/8);
		}
	}

	.product-listing {
		flex: none;
		width: percentage(1/2);

		@include min-screen($screen__m) {
			width: percentage(1/3)
		}

		@include min-screen($screen__l) {
			flex: none;
			width: percentage(1/4);
		}

		@include min-screen($screen__ll) {
			flex: none;
			width: percentage(1/5);
		}

		@include min-screen($screen__ml) {
			width: percentage(1/5)
		}
	}

	// ----------------------
	// Checkout
	// ----------------------
	.checkout-index-index,
	.page-layout-checkout {
		.header.content {
			height: 69px;

			.logo {
				display: inline-block;

				a {
					margin: auto;
				}
			}

			.checkout-header-security,
			div[id="progress-bar"] {
				@include min-screen($screen__ml) {
					flex: 0 1 40%;
				}
			}

			div[id="progress-bar"] {
				.opc-progress-bar {
					.opc-progress-bar-item {
						@include min-screen($screen__m) {
							flex-wrap: wrap;
							justify-content: center;
						}

						@include min-screen($screen__xl) {
							flex-wrap: nowrap;
							justify-content: flex-start;
						}
					}
				}
			}
		}

		.checkout-container {
			.opc-wrapper {
				.methods-shipping {
					.row {
						display: flex;

						.col-price {
							order: 3;
							display: block;
							flex: 0 1 auto;
						}

						.col-method:first-of-type {
							flex: 0 1 auto;
						}

						.col-method {
							padding: 0;
							order: 1;
							display: flex;
							flex: 1;

							&[id="label_method_flatrate_flatrate"] {
								order: 2;
								flex: 1 1 auto;
							}

							label:before {
								background-size: 27px 27px;
							}
						}

						.col-description {
							display: flex;
							flex: 1;
							order: 2;
							padding-left: rem(16);
						}
					}
				}

				.discount-code,
				.userewardpoints {
					.actions-toolbar {
						button:after {
							margin-top: 9px;
						}
					}
				}

				.payment-method[id="payment-method-braintree-paypal-vault"] {
					.choice.field {
						.label {
							display: inline-block;
						}
					}
				}

				.checkout-shipping-address {
					.action-show-popup {
						border: 0;
					}

					.shipping-address-item {
						width: calc(50% - 16px);
						margin-right: $gutter;
						flex: 0 1 auto;

						&:nth-child(even) {
							margin-right: 0;
						}
					}
				}
			}
		}
	}

	.checkout-onepage-success {
		.checkout-success-order-account-info-wrapper {
			.block-order-addresses,
			.need-help {
				flex-flow: nowrap;
			}
		}

		.checkout-success-order-items-wrapper {
			.block-order-totals {
				@include min-screen($screen__m) {
					width: 35%;
				}

				.shipping {
					.shipping-description {
						width: 100%;
					}
				}
			}
		}
	}

	// ----------------------
	// Static / Account Layout
	// ----------------------
	body[class*="customer"],
	body[class*="company"],
	.contact-index-index,
	.quickorder-index-index,
	.account,
	.sales-guest-form,
	.sales-guest-view,
	.sales-guest-invoice {
		.columns {
			@include min-screen($screen__m) {
				display: flex;
			}
		}

		.main {
			order: 2;
			flex: 1 1 100%;
			padding-left: 0;

			@include min-screen($screen__m) {
				padding-left: $twenty-gutter;
			}
		}

		.sidebar-main {
			flex: 1 1 33.33%;
			max-width: $sidebar-max-width;
		}
	}

	.cms-page-view {
		.columns {
			min-height: 1px;

			@include min-screen($screen__m) {
				display: -ms-grid;
				-ms-grid-columns: auto 1fr;
				-ms-grid-rows: 1fr;
			}
		}

		.main {
			@include min-screen($screen__m) {
				-ms-grid-column: 2;
				grid-column: 2;
				-ms-grid-row: 1;
				grid-row: 1;
			}
		}

		.sidebar-main {
			@include min-screen($screen__m) {
				max-width: rem(250);
			}

			@include min-screen($screen__l) {
				max-width: $sidebar-max-width;
			}
		}
	}

	// ----------------------
	// PayPal Review Page
	// ----------------------
	.braintree-paypal-review {
		.paypal-review-items,
		.block-order-details-view {
			flex: auto;
			width: 50%;
		}
	}

	// ----------------------
	// Product Pages
	// ----------------------
	.catalog-product-view,
	.bundle-options-container {
		.product-main-container {
			position: relative;

			@include min-screen($screen__ml) {
				display: -ms-grid;
				-ms-grid-columns: 1fr 1fr;
			}

			.product.media,
			.product-info-main {
				width: 100%;
			}

			.product-info-main {
				@include min-screen($screen__ml) {
					-ms-grid-column: 2;
				}
			}
		}

		.strapline-container {
			display: inline-block;
			width: 100%;
		}

		.block-viewed-products-grid {
			li.product-item {
				.product-item-info {
					flex: 0 1 auto;
					width: auto;
					margin-right: $gutter;
				}
			}
		}
	}

	.catalog-product-view {
		.product-price-action {
			.product-info-addto {
				.product-add-form {
					.box-tocart {
						.field.qty {
							flex: 1 1 rem(120);
						}
					}
				}

				.product-options-wrapper {
					.options-list {
						.field {
							@include min-screen($screen__m) {
								max-width: calc(#{percentage(1/3)} - #{rem(7)});
							}

							@include min-screen($screen__ml) {
								max-width: 100%;
							}

							@include min-screen($screen__ll) {
								max-width: calc(#{percentage(1/3)} - #{rem(7)});
							}
						}
					}
				}

				.aw-sarp2-product-subscription-options {
					.aw-sarp2-subscription__options-list {
						.field {
							@include min-screen($screen__ll) {
								max-width: 50%;
							}

							@include min-screen($screen__xxl) {
								max-width: percentage(1/3);
							}
						}
					}
				}
			}
		}
	}

	.cms-home {
		.cms-explore.col-12 {
			flex-direction: row !important; // To override pagebuilder column styles
		}
	}

	.block.related {
		.product-item-details {
			&.product {
				.price-box {
					flex: 1 1 auto !important; // Wouldnt override
				}
			}
		}
	}

	// ----------------------
	// CMS Shop Banners
	// ----------------------

	.cms-shop-banners {
		flex-direction: row !important; // Override pagebuilder style

		.pagebuilder-column {
			display: block !important; // Override pagebuilder style
		}

		div[data-content-type="banner"] {
			&,
			> div,
			.pagebuilder-banner-wrapper,
			.pagebuilder-overlay,
			.pagebuilder-poster-content {
				height: auto;
			}
		}

		.pagebuilder-banner-wrapper {
			display: block;
		}
	}

	.cms-heading {
		[data-content-type="heading"] {
			flex: 0 1 auto;
		}

		[data-content-type="text"] {
			width: 65%;
			margin: auto;
		}
	}

	.account {
		&.magento_reward-customer-info {
			.column {
				&.main {
					padding-left: $twenty-gutter;
				}
			}
		}
	}

	.account {
		form {
			.fieldset {
				.field {
					flex-direction: row !important;
				}
			}
		}
	}

	.form.reward-settings {
		.fieldset {
			display: flex;
			flex-wrap: wrap;
			flex: 1 1 100%;

			br {
				display: none;
			}

			.legend {
				flex: 1 1 100%;
				display: block;
			}

			.field.choice {
				flex: 1 1 50%;
			}
		}
	}

	.search-autocomplete {
		.smile-elasticsuite-autocomplete-result {
			.autocomplete-list {
				dd {
					flex: 1 1 14%;

					a {
						display: block;
					}

					.product-item {
						display: flex;
						flex: 0 1 auto;
						flex-direction: column;
					}

					&.button-wrapper {
						button {
							height: rem(40);
						}
					}
				}
			}
		}
	}

	// Widget product carousel
	.block-products-list {
		.widget-product-carousel {
			.product-item-info {
				display: block;
			}
		}
	}

	.page-layout-subscriptions-landing {
		.columns {
			.category-cms.default {
				> div[data-content-type="row"]:nth-child(2) {
					> div[data-element="inner"]:first-of-type {
						display: block !important; // Override Pagebuilder inline style
					}
				}
			}
		}
	}

	.cms-text-slider {
		height: rem(58);
	}

	.cms-bg-content-block {
		.pagebuilder-column-group {
			display: flex;
			flex-direction: column;
			flex-wrap: nowrap;

			@include min-screen($screen__m) {
				flex-direction: row;
			}
		}
	}

	form,
	.form,
	.gift-options-content {
		fieldset,
		.fieldset {
			.field {
				select {
					padding-left: rem(12);
					background-size: 14px;
				}
			}
		}
	}

	.product-item-info {
		.product-item-inner {
			.uom-denomination {
				select {
					background-size: 11px 7px; // Have to declare this twice as it keeps reseting to standard styles, not compatible with IE
					background-position: 100% 11px;

					@include min-screen($screen__m) {
						background-size: 11px 7px;
						background-position: 100% 13px;
					}
				}
			}
		}
	}

	.cms-shops-and-cafes {
		.cms-heading {
			display: block !important; // To override pagebuilder flex styles
		}
	}
}
