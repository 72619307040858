/* -------------------------------------------------
 * -------------------------------------------------
 * JS Tree Styles
 *
 * JS Tree is found in the B2B extension for account
 * area pages (Such as compant structure page)
 * 
 * Styles here are ported from the Luma theme with
 * changes made on top
 * -------------------------------------------------
 * @author Fisheye
 */

.jstree-node,
.jstree-children,
.jstree-container-ul {
	display: block;
	list-style-type: none;
	list-style-image: none;
	margin: 0;
	padding: 0;
}

.jstree {
	> ul > li {
		background: none;

		&:not(.root-collapsible)>.jstree-icon.jstree-ocl {
			display: none;
		}

		&:not(.jstree-leaf)>.jstree-icon {
			display: inline-block;
			text-decoration: none;
			left: rem(-1);
			position: relative; 
			top: rem(6);
			z-index: 5;
		}

		> .jstree-icon {
			padding-right: rem(15);
		}

		> a.company-admin {
			margin-left: rem(-2);
			padding-bottom: rem(4);

			> .jstree-icon {
				margin-right: $ten-gutter;
				position: relative;
				z-index: 5;

				&:before {
					width: 25px;
					height: 25px;
				}
			}
		}
	}

	> ul ul li {
		background: none;
		border-left: 1px dashed $silver-chalice;
		margin-left: rem(24);
		padding: rem(8) 0;
		position: relative;

		&.jstree-leaf {
			display: flex;
		}

		&.jstree-last {
			background: none;
			border-left: none;
			left: rem(1);
			padding-bottom: 0;
			position: relative;

			&:after {
				border-left: 1px dashed $silver-chalice;
				content: '';
				height: rem(23);
				left: rem(-1);
				position: absolute;
				top: 0;
			}
		}

		> .jstree-icon.jstree-ocl {
			position: relative;

			&:after {
				border-top: 1px dashed $silver-chalice;
				content: '';
				height: rem(1);
				position: absolute;
				right: rem(23);
				left: rem(1);
				top: rem(11);
				width: rem(13);
			}
		}
		
		&.jstree-leaf >.jstree-icon.jstree-ocl {
			background: none;
			display: inline;
			padding-right: rem(37);

			&:after {
				right: 0;
				top: rem(17);
				width: 100%;
			}
		}
	}

	li:not(.jstree-leaf)>.jstree-icon {
		display: inline-block;
		text-decoration: none;
		left: rem(-1);
		position: relative;
		top: rem(6);
		z-index: 5;

		&:before {
			content: '';
			vertical-align: middle;
			display: inline-block;
			overflow: hidden;
		}
	}
	
	li {
		> .jstree-icon {
			padding-right: rem(15);
		}

		&.jstree-open,
		&.jstree-closed {
			> .jstree-icon {
				&:before {
					cursor: pointer;
					position: relative;
					left: rem(14);
					width: 22px;
					height: 24px;
					@include svg-background(
						$name: 'chevron-circle-up',
						$size: contain
					);
				}
			}
		}

		&.jstree-closed {
			> .jstree-icon {
				&:before {
					background-image: url('../images/svg/chevron-circle-down.svg');
				}
			}
		}

		a {
			padding: rem(4) $ten-gutter;
	
			@include min-screen($screen__m) {
				position: relative;
			}

			> .jstree-themeicon-custom {
				margin-left: 10px;
				margin-right: rem(7);
			}

			> .jstree-icon.icon-customer {
				&:before {
					width: 18px;
					height: 19px;
				}
			}
		}
	}

	a {
		> .jstree-icon {
			display: inline-block;
			text-decoration: none;
			background: none;

			&:before {
				content: '';
				@include svg-background(
					$name: 'account-icon-grey',
					$size: contain
				);
				width: 25px;
				height: 25px;
				display: inline-block;
				vertical-align: middle;
			}

			&.icon-company {
				&:before {
					background-image: url('../images/svg/users.svg');
					width: 17px;
					height: 18px;
				}
			}
		}
	}

	&.jstree-draggable a {
		display: inline-block;
		text-decoration: none;

		&:before {
			content: "";
			cursor: pointer;
			display: inline-block;
			vertical-align: middle;
			width: 19px;
			height: 18px;
			@include svg-background(
				$name: 'grip-vertical',
				$size: contain
			);
		}

		&.jstree-clicked {
			&:before {
				background-image: url('../images/svg/grip-vertical-white.svg');
			}
		}
	}
	
	// Styling for the js tree checkboxes, used on the roles and permisions page
	// Styles mostly copied directly from the Luma theme!
	.jstree-anchor {
		.jstree-checkbox {
			@include svg-background(
				$name: 'checkbox_unchecked',
				$size: contain
			);
			height: 16px;
			width: 16px;
			margin: 5px 8px -2px 4px;
			position: relative;
			flex: 0 0 auto;

			&:before {
				display: none;
			}

			+ .jstree-themeicon {
				display: none;
			}
		}

		&.jstree-clicked {
			> .jstree-checkbox {
				background-image: url('../images/svg/checkbox_checked.svg');
			}
		}
	}
}

.jstree-anchor {
	color: black;
	margin: 0;
	padding: 0 rem(4) 0 rem(1);
	vertical-align: top;
	display: inline-flex;
}

.block-dashboard-company {
	.jstree .jstree-clicked {
		background-color: $brand-accent;
		color: $white;

		&:focus {
			opacity: 1;
		}

		>.jstree-icon.icon-customer {
			&:before {
				background-image: url('../images/svg/account-icon-white.svg');
			}
		}
	}
}
