/// Icons attached to psuedo elements
/// @param {String} $width [20px] - Icon width
/// @param {String} $height [20px] - Icon height
/// @param {String} $background [arrow-down.svg] - icon file name without file path
/// @example scss
/// .down {
///		&:after {
///			@insert psuedoicon;
///		}
/// }
@mixin pseudoicon($width: 20px, $height: 20px, $background: "in-stock.svg") {
	content: "";
	width: $width;
	height: $height;
	background: url("../images/svg/" + $background);
	background-repeat: no-repeat;
	margin: 0 auto;
}

@mixin fonticon($fontsize: $icon-font-size,
$lineheight: $icon-font-line-height) {
	-webkit-font-smoothing: antialiased;
	font-size: $fontsize;
	line-height: $lineheight;
	color: $icon-font-color;
	content: "";
	font-family: "icons-blank-theme";
	margin: 0;
	display: inline-block;
	font-weight: normal;
	overflow: hidden;
	speak: none;
	text-align: center;
	vertical-align: top;
}

// $icon-font-size: 2.1875rem; // 35px when base is 16. 35 % 16
// $icon-font-line-height: 1.875rem; // 30px when base is 16. 30 % 16