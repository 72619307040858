/* -------------------------------------------------
 * -------------------------------------------------
 * General Styles specific to Category page
 *
 * Most styles should be in relevant stylesheet i.e. product-listing.scss
 *
 * -------------------------------------------------
 * @author Fisheye
 */

// title container for top, bottom and search results
// ------------------------------------------------

.catalog-category-view,
.catalogsearch-result-index {
	.columns,
	.main,
	.notice {
		padding: 0; // Not required here on Category Pages, only on .column.main
	}

	&.fixed-nav {
		.page-title-wrapper {
			padding: rem(81) $fifteen-gutter $gutter;

			@include min-screen($screen__m) {
				padding: rem(81) $gutter rem(56);
			}
		}
	}

	// -------------------------------------------------
	// Category CMS container
	// -------------------------------------------------

	.subcategories-cms-column {
		padding-top: rem(6);

		@include min-screen($screen__m) {
			padding-top: rem(42);
		}

		.sidebar {
			&.sidebar-main {
				@include max-screen($screen__m - 1) {
					width: 100%;
					margin-left: 0;
					float: none;
				}

				.block.filter {
					margin: 0 $page-padding-mobile rem(26);

					&.active {
						margin: 0;

						.filter-content {
							width: 100%;
						}
					}
				}

				.filter-title {
					strong {
						padding-left: 0;

						&:before {
							display: none;
						}
					}
				}
			}
		}

		.category-cms {
			~ .section-heading {
				display: none;
			}
		}
	}

	.flex__row {
		justify-content: flex-start;
		align-items: stretch;
	}

	.cms-brands {
		padding-top: rem(42);
		padding-bottom: rem(42);

		@include min-screen($screen__m) {
			padding-top: rem(74);
			padding-bottom: rem(73);
		}

		.pagebuilder-buttons {
			display: flex;
		}

		li {
			margin-right: rem(35);

			.pagebuilder-image-wrapper {
				width: rem(80);

				@include min-screen($screen__m) {
					width: rem(130);
				}
			}
		}

		span {
			white-space: normal;
			max-width: rem(150);
			margin-top: rem(12);
		}

		.scroll-navigation-wrapper .previous-arrow {
			background-position: left;
		}

		.scroll-navigation-wrapper .next-arrow {
			background-position: right;
		}
	}

	.page-title-wrapper {
		&:before {
			content: "";
			background-color: rgba($color: #000000, $alpha: 0.3);
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			pointer-events: none;
		}

		.breadcrumbs {
			li {
				&:after {
					@include svg-background(
						$name: "arrow-right-white",
						$size: contain
					);
				}

				a,
				strong {
					color: $white;
				}

				&:nth-last-child(2) {
				 	&::after {
						@extend %visuallyhidden;
				 	}
				 }

				 &:last-child {
					@extend %visuallyhidden;
				}
			}
		}

		.page-title {
			order: 1;
			z-index: 1;

			span {
				@include type__45(
					$line-height: 1,
					$color: $white,
					$font-family: $font-family-sans-serif-alt
				);
				text-transform: uppercase;

				@include min-screen($screen__m) {
					@include type__52(
						$color: null,
						$font-family: null,
						$line-height: 1
					);
				}

				em {
					font-style: normal;
				}
			}
		}

		.category-description {
			margin-top: $ten-gutter;
			order: 2;
			z-index: 1;

			@include min-screen($screen__m) {
				margin-top: $twenty-gutter;
			}

			[data-content-type="text"] {
				@include type__15($color: $white);
				@include transition(all 0.4s ease-in-out);
				max-height: rem(55);
				-webkit-mask-image: linear-gradient(to bottom, #{$black} 75%, transparent 100%);
 				mask-image: linear-gradient(to bottom, black 75%, transparent 100%);
				overflow: hidden;

				@include min-screen($screen__m) {
					@include type__16($color: $white);
				}

				@include min-screen($screen__ml) {
					max-width: rem(1150);
					margin: 0 auto !important; // Override inline style
				}
			}

			[data-content-type="buttons"] {
				margin-top: $gutter;

				.pagebuilder-button-primary {
					padding: 0;
					border: none;
					min-height: none;

					span {
						@include button__default(
							$border: 1px solid $white,
							$color: $white
						);

						&:hover,
						&:focus {
							color: $mine-shaft;
							background-color: $white;
							border-color: $white;
						}
					}
				}
			}

			&.read-more {
				[data-content-type="text"] {
					max-height: rem(500);
					-webkit-mask-image: linear-gradient(to bottom, #{$black} 100%, transparent 0%);
					mask-image: linear-gradient(to bottom, #{$black} 100%, transparent 0%);
				}
			}
		}

		.js-search-wrapper {
			background: none;
			flex: 1 1 100%;
			padding-top: 0;
			padding-bottom: 0;
			margin-top: rem(18);
			margin-bottom: rem(4);

			@include min-screen($screen__m) {
				margin-top: rem(8);
				margin-bottom: $fifteen-gutter;
			}

			.js-search-container {
				width: auto;
			}
		}
	}

	// category description container
	.category-content-info {
		.category-subtitle {
			padding-top: $category-subtitle-spacing-mobile;

			@include min-screen($screen__m) {
				padding-top: $category-subtitle-spacing;
			}
		}

		.category-short-description {
			padding-top: $category-description-spacing-mobile;

			@include min-screen($screen__m) {
				padding-top: $category-description-spacing;
			}
		}
	}

	.toolbar-top,
	.toolbar-bottom {
		margin-right: $fifteen-gutter;
		margin-left: $fifteen-gutter;

		@include min-screen($screen__ml) {
			margin-right: $gutter;
			margin-left: $gutter;
		}
	}

	.toolbar-top {
		@include max-screen($screen__m - 1) {
			width: calc(50% - #{rem(8)});
			margin: $fifteen-gutter 0;
			float: right;
		}

		@include min-screen($screen__m) {
			margin-top: $gutter;
		}

		.toolbar {
			@include max-screen($screen__m - 1) {
				padding: 0;
				background-color: transparent;
			}

			.toolbar-amount,
			.pages {
				@include max-screen($screen__m - 1) {
					display: none;
				}
			}

			.toolbar-sorter {
				@include max-screen($screen__m - 1) {
					margin: 0 $fifteen-gutter 0 0;
				}

				.sorter-options {
					@include type__12($letter-spacing: 1px);

					@include max-screen($screen__m - 1) {
						padding: $fifteen-gutter rem(25) $fifteen-gutter 0;
						background-position: right center;
						background-size: $fifteen-gutter;
						border-color: $mercury;
						border-style: solid;
						border-width: 1px 0;
						position: relative;
						text-transform: uppercase;
					}

					@include min-screen($screen__xs) {
						@include type__17($letter-spacing: 1px);
					}
				}
			}
		}
	}

	.toolbar-bottom {
		margin-bottom: $gutter;

		@include min-screen($screen__m) {
			margin-bottom: rem(70);
		}

		.toolbar {
			.toolbar-sorter {
				@include max-screen($screen__m - 1) {
					display: none;
				}
			}
		}
	}

	&.page-layout-subscriptions-landing {
		.subcategories-cms-column {
			padding-top: $gutter;

			@include min-screen($screen__m) {
				padding-top: rem(42);
			}

			> .section-heading,
			.sidebar {
				// Cannot be removed via XML without removing the entire CMS category container
				@extend %visuallyhidden;
			}
		}
	}

	// Page title padding causing issues on design of category product pages

	.page-title-wrapper {
		.page-title {
			padding: 0;
		}
	}
}

.catalogsearch-result-index {
	.page-title-wrapper {
		background-image: url("../images/search-results-banner.jpg");
		background-repeat: no-repeat;
	}

	.toolbar-top {
		+ .block {
			@extend %visuallyhidden;
		}
	}
}

// ----------------------------------------------------------------------------
// Top Level Category
// Contains Category Grid, Promotional Banners and Popular Products
// ----------------------------------------------------------------------------
.category-list-wrapper {
	display: flex;
	flex-wrap: wrap;
	background-color: $silver-chalice;
	padding: $page-padding $page-padding-mobile;

	@include min-screen($screen__m) {
		padding: $page-padding;
	}

	.category-list-item {
		flex: 1 1 100%;
		background-color: $white;
		border-bottom: 1px solid $silver-chalice;

		@include min-screen($screen__m) {
			flex: 0 1 percentage(1/3);
			border-right: 1px solid $silver-chalice;
			text-align: center;

			&:last-child {
				border-bottom: 0;
				border-right: 0;
			}
		}

		@include min-screen($screen__l) {
			flex: 0 1 percentage(1/4);
		}

		@include min-screen($screen__ll) {
			flex: 0 1 percentage(1/5);
		}

		@include min-screen($screen__xl) {
			flex: 0 1 percentage(1/6);
		}

		@include min-screen($screen__xxl) {
			flex: 0 1 percentage(1/8);
		}

		a { // no class available & flex needed here to make image & text inline on mobile
			display: flex;
			align-items: center;
			padding: $ten-gutter $fifteen-gutter;

			@include min-screen($screen__m) {
				flex-wrap: wrap;
				justify-content: center;
				padding: rem(25);
			}

			.category-image {
				margin-right: $fifteen-gutter;
				flex: 0 1 31px;

				@include min-screen($screen__m) {
					margin-bottom: $fifteen-gutter;
					margin-right: 0;
					flex: 1 1 100%;
				}

				.category-list-item-image {
					opacity: 0.5;
				}

				img {
					width: auto;
					height: em(45);

					@include min-screen($screen__m) {
						height: em(130);
					}
				}
			}

			.category-list-item-title {
				@include type__13($font-weight: $font-weight-normal);
				flex: 1 1 100%;
				position: relative;
				display: flex;
				align-items: center;

				&:after {
					content: '';
					display: inline-block;
					position: absolute;
					right: 0;
					height: em(19);
					width: em(19);
					@include svg-background('arrow-right');
					background-size: contain;
				}

				@include min-screen($screen__m) {
					@include type__15($font-weight: $font-weight-semibold);
					text-transform: uppercase;
					display: inline-block;

					&:after {
						display: none;
					}
				}
			}
		}
	}
}

// ----------------------------------------------------------------------------
// Subscriptions Category Pages
// ----------------------------------------------------------------------------

.page-layout-subscriptions-landing {
	.page-title-wrapper {
		padding-top: rem(90);
		background-position: top;

		@include min-screen($screen__m) {
			padding-top: rem(124);
		}

		.category-description {
			@include min-screen($screen__m) {
				margin-top: rem(49);
			}
		}
	}

	&.fixed-nav {
		.page-title-wrapper {
			padding-top: rem(82);

			@include min-screen($screen__m) {
				padding-top: rem(90);
			}
		}
	}
}
