/* -------------------------------------------------
 * -------------------------------------------------
 * Header styles for checkout
 *
 * -------------------------------------------------
 * @author Fisheye
 */

.page-layout-checkout {
	.page-header {
		background-color: $checkout-header-background;
		top: 0;
		z-index: 2;
		padding-top: 0;
	}

	.checkout-panel-wrapper {
		background-color: $header-panel-background;
		padding: $ten-gutter;

		.countdown-container { // add this class on the PB row on .fish
			justify-content: center;
			flex-direction: row !important; // override inline pagebuilder style of column

			.countdown-clock {
				text-align: center;
				text-transform: uppercase;
				@include type__13(
					$color: $header-panel-color,
					$font-weight: $font-weight-semibold
				);

				@include min-screen($screen__ml) {
					@include type__15(
						$color: $header-panel-color,
						$font-weight: $font-weight-semibold
					);
				}

				.time-remaining {
					color: $red-ribbon;
				}
			}

			a {
				display: none;
				text-transform: none;
				margin-left: rem(7);
				@include type__13(
					$color: $white,
					$font-weight: $font-weight-semibold
				);

				@include min-screen($screen__ll) {
					display: inline-block;
				}
			}
		}
	}

	.header.content {
		// Not nested in .page-header to make it easier to override in child theme
		// this should take its styles from _header.scss, class has been chained to match
		height: auto;
		padding: $checkout-header-mobile-padding;
		background-color: $checkout-header-background;
		border-bottom: $checkout-header-border;

		@include min-screen($screen__m) {
			padding: $checkout-header-padding;
			flex-wrap: nowrap;
		}

		div[id="progress-bar"] {
			display: flex; // for vertical alignment
			flex: 1 1 100%;
			justify-content: center;
			order: $checkout-mobile-header-steps-flex-order;
			padding: $ten-gutter $fifteen-gutter 0;
			border-top: $checkout-header-border;
			margin-top: rem(12);

			@include min-screen($screen__m) {
				align-items: center;
				flex: $checkout-progress-bar-flex;
				justify-content: flex-start;
				order: $checkout-header-steps-flex-order;
				padding: 0;
				border: 0;
				margin-top: 0;
			}

			@include min-screen($screen__ll) {
				flex: 0 1 25%;
			}

			.opc-progress-bar {
				display: flex;
				justify-content: space-between;
				width: 100%;

				@include min-screen(430px) {
					max-width: rem(385);
				}

				@include min-screen($screen__m) {
					justify-content: flex-start;
					max-width: none;
				}

				.opc-progress-bar-item {
					color: $checkout-steps-color;
					display: flex;
					align-items: center;
					text-transform: uppercase;

					@include min-screen($screen__m) {
						flex-wrap: wrap;
						justify-content: center;
					}

					@include min-screen($screen__ll) {
						flex-wrap: nowrap;
						justify-content: flex-start;
					}

					&:before {
						display: inline-block;
						height: $checkout-steps-height;
						width: $checkout-steps-width;
						background-color: $gallery;
						border-radius: 50%;
						margin-right: rem(6);
						text-align: center;
						font-style: italic;

						@include type__17(
							$font-family: $font-family-serif,
							$line-height: 1.85
						);

						@include min-screen($screen__m) {
							margin-right: rem(7);

							@include type__20(
								$font-family: null,
								$line-height: $checkout-steps-line-height
							);
						}
					}

					&:not(:last-child) {
						@include min-screen($screen__m) {
							margin-right: $twenty-gutter;
						}

						@include min-screen($screen__ll) {
							margin-right: rem(28);
						}
					}

					span {
						@include type__14(
							$color: $checkout-steps-color,
							$letter-spacing: 1px
						);

						@include min-screen($screen__m) {
							margin-top: rem(4);
						}

						@include min-screen($screen__ll) {
							margin-top: 0;

							@include type__17(
								$color: null,
								$letter-spacing: 1px
							);
						}
					}

					&:first-of-type {
						&:before {
							content: "1";
						}
					}

					&:nth-of-type(2) {
						&:before {
							content: "2";
						}
					}

					&:nth-of-type(3) {
						&:before {
							content: "3";
						}
					}

					&._active {
						&:before {
							background-color: $shadow-green;
							color: $white;
						}

						span {
							color: $shadow-green;
						}
					}

					&._complete {
						opacity: 0.4;

						&:before {
							content: "";
							background-color: $shadow-green;
							@include svg-background('completed-step');
						}
					}
				}
			}
		}

		.checkout-header-security {
			display: flex; // for vertical align
			align-items: center;
			flex: $checkout-secure-flex;
			justify-content: flex-end;
			order: $checkout-mobile-header-secure-flex-order;

			@include type__18(
				$color: $checkout-secure-color,
				$font-family: $font-family-serif,
				$letter-spacing: 1px
			);

			@include max-screen($screen__m - 1) {
				padding-right: $fifteen-gutter;
			}

			@include min-screen($screen__m) {
				flex: 0 1 30%;

				@include type__24(
					$color: $checkout-secure-color,
					$font-family: $font-family-serif,
					$letter-spacing: 2px
				);
			}

			@include min-screen($screen__ll) {
				order: $checkout-header-secure-flex-order;
				flex: 0 1 25%;

				@include type__35(
					$color: $checkout-secure-color,
					$font-family: $font-family-serif,
					$letter-spacing: 2px
				);
			}

			em {
				padding-right: $five-gutter;

				@include min-screen($screen__ll) {
					padding-right: rem(8);
				}
			}

			svg {
				position: relative;
				fill: none;
				height: 19px;
				margin-right: rem(4);
				stroke: $checkout-secure-color;
				stroke-width: 13px;
				width: 13px;

				@include min-screen($screen__l) {
					height: 24px;
					width: 19px;
					bottom: rem(1);
					margin-right: $checkout-secure-icon-margin-right;
				}
			}
		}

		.logo {
			flex: $checkout-logo-flex;
			justify-content: flex-start;
			order: $checkout-mobile-header-logo-flex-order;

			@include max-screen($screen__m - 1) {
				padding-left: $fifteen-gutter;
			}

			@include min-screen($screen__m) {
				justify-content: center;
				order: $checkout-header-logo-flex-order;
				flex: 0 1 auto;
			} // Center align the logo - nested because no class on output
			// we do not want this to affect any other links in the header

			@include min-screen($screen__ll) {
				flex: $checkout-logo-flex;
			}

			a {
				display: block;
				height: auto;
				width: 100%;
				padding: 0;
				margin: 0;

				@include max-screen($screen__ml - 1) {
					text-align: left;
				}

				img {
					width: rem(194);

					@include min-screen($screen__m) {
						width: rem(245);
					}

					@include min-screen($screen__l) {
						width: rem(273);
					}
				}
			}
		}
	}

	.page-title-wrapper {
		display: none;
	}
}

// Specific feefo checkout header styles
.feefo-block-display {
	div {
		.feefo-block {
			background-color: $gallery;
			display: flex;
			justify-content: center;
			height: 30px;
			align-items: center;

			.icon,
			.reviews {
				&:before {
					display: block;
					content: "";
					height: 20px;
					margin-right: $five-gutter;
				}
			}

			.icon {
				display: flex;

				&:before {
					width: 60px;
					@include svg-background(
						$name: 'feefo',
						$size: contain
					);
				}
			}

			.reviews {
				@include type__16(
				    $color: $boulder,
				    $font-family: $font-family-serif
				);
				font-style: italic;
				display: flex;
				align-items: center;

				&:before {
					width: 90px;

					@include svg-background(
						$name: 'feefo-stars',
						$size: contain
					);
				}
			}
		}
	}
}
