/* -------------------------------------------------
 * -------------------------------------------------
 * Checkout cart layout grid
 *
 * -------------------------------------------------
 * @author Fisheye
 */

.checkout-cart-index {
	.page-main {
		position: relative;
	}

	.cart-container {
		padding: 0;
	}

	.page-title-wrapper {
		a {
			@include type__15;
			display: inline-block;
			padding-left: $fifteen-gutter;
			position: relative;
			margin-top: $ten-gutter;

			&:before {
				content: "";
				display: block;
				height: 9px;
				width: 5px;
				@include svg-background(
					$name: 'arrow-right-black',
					$size: 5px
				);
				position: absolute;
				top: 6px;
				left: 0;
			}

			@include min-screen($screen__m) {
				@include type__16;
				margin-top: rem(18);
			}
		}
	}

	.cart-checkout-top {
		width: 100%;
		position: relative;

		.checkout-methods-items {
			@include min-screen($screen__m) {
				display: none;
			}

			.item {
				.paypal {
					display: none;
					margin-top: $fifteen-gutter;
				}
			}

			.primary {
				margin: $fifteen-gutter 0;
				@include button__journey;

				@include max-screen($screen__m - 1) {
					width: 100%;
				}

				span {
					padding-left: $gutter;
					position: relative;
					@include type__18(
						$color: $white,
						$font-weight: $font-weight-semibold,
						$letter-spacing: 1px
					);
					text-transform: uppercase;

					&:before {
						content: "";
						display: block;
						height: rem(23);
						width: rem(16);
						@include svg-background(
							$name: "padlock-icon-white",
							$size: contain
						);
						margin: auto;
						position: absolute;
						top: 0;
						bottom: 0;
						left: 0;
					}
				}

				&:hover,
				&:focus {
					span {
						&:before {
							@include svg-background(
								$name: "padlock-icon-shadow-green",
								$size: null
							);
						}
					}
				}
			}

			.braintree-apple-pay-button {
				-webkit-appearance: -apple-pay-button;
				-apple-pay-button-type: plain;
				display: block;
				width: 200px;
				min-height: 40px;
				border: 1px solid black;
				background-image: -webkit-named-image(apple-pay-logo-black);
				background-size: 100% calc(62%);
				background-repeat: no-repeat;
				background-color: black;
				background-position: 50% 50%;
				border-radius: 5px;
				padding: 2px;
				margin: auto;
				transition: background-color 0.15s;
				cursor: pointer;
			}
		}
	}

	.checkout-cart-content {
		@include min-screen($screen__ml) {
			display: flex;
			justify-content: space-between;
		}
	}

	.columns {
		.column.main {
			padding: $fifteen-gutter;

			@include min-screen($screen__m) {
				padding: $gutter;
			}
		}
	}

	.column {
		&.cart-column-one {
			@include min-screen($screen__ml) {
				width: calc(70% - #{$gutter});
			}

			.form-cart {
				// actual cart contents
				width: 100%;

				.main.actions {
					padding: 0;
				}

				.action.clear {
					display: flex;
					align-items: center;
					border: 0;
					padding: 0;
					@include type__13($font-weight: $font-weight-bold);
					margin: $twenty-gutter 0;
					background: 0;

					@include min-screen($screen__m) {
						margin-bottom: 0;
					}

					&:before {
						content: '';
						display: inline-block;
						width: 27px;
						height: 24px;
						@include svg-background('empty-basket');
						background-size: contain;
						margin-right: $ten-gutter;
					}
				}
			}

			.cart__promo__container {
				//gift cards etc underneath
				display: block;
				width: 100%;
				clear: both;

				@include min-screen($screen__m) {
					display: flex;
				}

				.block {
					@include min-screen($screen__m) {
						flex-basis: 50%;
					}
				}
			}
		}

		&.cart-column-two {
			@include min-screen($screen__ml) {
				width: 30%;
			}
		}
	}
} // closes .checkout-cart-index
