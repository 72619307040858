/* -------------------------------------------------
 * -------------------------------------------------
 * search field within the mobile menu
 *
 * --------------------------------------------------
 * @author Fisheye
 */

.nav-sections {
	.minisearch {
		padding: $minisearch-spacing;
		background-color: $minisearch-background-color;
		position: relative;

		.label {
			@extend %visuallyhidden;
		}

		.input-text {
			height: rem(59);

			@include input-style-three(
				$padding: $fifteen-gutter $forty-gutter rem(11) $fifteen-gutter,
				$background-color: $gallery
			);

			&::placeholder {
				opacity: 1;
				@include type__19(
					$color: $field-placeholder-color,
					$font-family: $font-family-serif,
					$letter-spacing: 1px
				);
				text-transform: capitalize;
			}
		}

		.actions {
			position: absolute;
			top: 0;
			right: $minisearch-search-icon-right;
			bottom: 0;
			margin: auto;
			display: flex;
			align-items: center;

			.action.search {
				background-color: $minisearch-button-background;
				border: 0;
			}

			.search-submit {
				@include max-screen($screen__m - 1) {
					height: 19px;
					width: 19px;
				}
			}
		}

		.control {
			position: relative; // stops the search results list growing too large
		} // search-autocomplete results - moved to _search-results.scss

		.product-image-box {
			min-width: em(45);
			padding-right: $item-gutter;
		}
	}
}
