/* -------------------------------------------------
 * -------------------------------------------------
 * CMS Statis Page specific Styling
 * Usually populated through bluefoot
 *
 * -------------------------------------------------
 * @author Fisheye
 */

// -------------------------------------------------
// CMS Layout
// -------------------------------------------------

.contact-index-index,
.cms-page-view,
.cms-no-route {
	.page-main {
		display: flex;
		flex-direction: column;
		padding: 0;
	}

	.columns {
		order: 3;
		width: 100%;
		margin: 0 auto;
		padding: 0 $page-gutter $gutter $page-gutter; // top spacing not needed as coming from cms menu

		@include min-screen($screen__m) {
			padding: $gutter $page-padding $fifty-gutter;
			grid-template-columns: auto;
		}

		.main {
			padding: 0;

			@include min-screen($screen__m) {
				padding-left: $gutter;
			}
		}

		.sidebar-main {
			padding: 0;

			&:empty {
				margin: 0;
			}
		}

		.trustpilot-widget {
			margin-top: rem(22);

			@include min-screen($screen__m) {
				margin-top: rem(34);
			}
		}
	}

	.breadcrumbs {
		display: none;
	}

	&.page-layout-1column {
		.columns {
			&,
			.main {
				padding: 0;
			}
		}
	}

	&.cms-shops-and-cafes,
	&.cms-bath-shop,
	&.cms-belgravia-shop,
	&.cms-warehouse-shop {
		.page-title-wrapper {
			padding: rem(11);

			@include min-screen($screen__m) {
				padding: $twenty-gutter;
			}

			@include min-screen($screen__ml) {
				padding: rem(27);
			}

			@include min-screen($screen__l) {
				padding: $gutter;
			}
		}
	}

	&.cms-bath-shop,
	&.cms-belgravia-shop,
	&.cms-warehouse-shop {
		.page-title-wrapper {
			@include max-screen($screen__s - 1) {
				margin-top: $ten-gutter;
			}
		}

		.breadcrumbs {
			display: block;
			position: absolute;
			z-index: 1;
			width: 100%;
			padding-top: rem(65); // Enormous amount of padding required due to the header being fixed

			@include min-screen($screen__m) {
				padding-top: rem(104);
			}

			li {
				&:after {
					@include svg-background('arrow-right-white');
				}

				a,
				strong {
					color: $white;
				}
			}
		}

		.accordion-text-content,
		.further-contact-info,
		.meet-the-team,
		.video-image-gallery {
			padding: rem(31) $page-padding-mobile rem(34);

			@include min-screen($screen__m) {
				padding: rem(63) $gutter rem(58);
			}

			> [data-content-type="text"] {
				p {
					@include type__33($font-family: $font-family-serif);

					@include min-screen($screen__m) {
						@include type__52($font-family: $font-family-serif);
					}
				}
			}
		}

		.video-image-gallery {
			padding-left: $fifteen-gutter / 2;
			padding-right: $fifteen-gutter / 2;

			@include min-screen($screen__m) {
				padding-left: calc(#{$gutter} - #{$fifteen-gutter});
				padding-right: calc(#{$gutter - $fifteen-gutter});
			}
		}

		.further-contact-info {
			padding-top: 0;
			padding-bottom: 0;
		}
	}
}

// -------------------------------------------------
// 404 page
// -------------------------------------------------

.cms-no-route {
	.column {
		dt {
			padding-bottom: $twenty-gutter;
		}

		.disc {
			list-style-type: decimal;
			padding-left: $gutter;
		}
	}
}

// -------------------------------------------------
// CMS Menu Sidebar
// -------------------------------------------------

.cms-menu-nav {
	h4 {
		margin: 0 $twenty-gutter $fifteen-gutter;
		@include type__17($letter-spacing: 1px);
		text-transform: uppercase;
	}

	nav {
		&.cms-menu-nav {
			padding: $twenty-gutter 0;
			background-color: $gallery;
		}
	}

	div[data-content-type="buttons"] {
		padding: 0 $twenty-gutter;

		[data-content-type="button-item"] {
			width: 100%;

			&:not(:last-of-type) {
				margin-bottom: $fifteen-gutter;
			}
		}

		&:not(:last-of-type) {
			padding-bottom: $twenty-gutter;
			border-bottom: 1px solid $white !important; // To override pagebuilder styles
			margin-bottom: $twenty-gutter;
		}
	}

	.pagebuilder-button-link {
		display: block;
		padding-left: $fifteen-gutter;
		position: relative;
		@include type__16($line-height: 1);
		text-align: left;

		&:before {
			content: "";
			display: block;
			height: 9px;
			width: 5px;
			@include svg-background(
				$name: "arrow-right",
				$size: contain
			);
			margin: auto;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
		}

		[data-element="link_text"] {
			display: block;
		}
	}

	&.cms-menu-mobile {
		order: 2;
		border-top: 1px solid $grey-lighter;
		border-bottom: 1px solid $grey-lighter;
		margin: $twenty-gutter $page-gutter;

		.title {
			display: flex;
			align-items: center;
			padding: $fifteen-gutter 0;
			cursor: pointer;

			&:after {
				content: '';
				display: inline-block;
				height: 10px;
				width: 10px;
				@include svg-background('plus');
				background-size: contain;
			}

			strong {
				@include type__14($font-weight: $font-weight-semibold);
				flex: 1;
				text-transform: uppercase;
			}
		}

		.active {
			.title {
				&:after {
					@include svg-background('minus');
					background-size: contain;
				}
			}
		}
	}
}

// -------------------------------------------------
//  Accordion
// -------------------------------------------------

[data-content-type="accordion"] {
	border-top: 1px solid $gallery;
	border-bottom: 1px solid $gallery;

	[data-content-type="accordion-item"] {
		border-bottom: 1px solid $gallery !important; // To override pagebuilder styles

		.accordion-item-title {
			padding: $fifteen-gutter 0;
			cursor: pointer;

			h3 {
				padding-right: $twenty-gutter;
				margin: 0;
				position: relative;
				@include type__13($letter-spacing: 1px);
				text-align: left;
				text-transform: uppercase;

				@include min-screen($screen__m) {
					@include type__14(
						$font-weight: $font-weight-semibold,
						$letter-spacing: 1px
					);
				}

				&:after {
					content: "";
					display: block;
					height: 10px;
					width: 10px;
					@include svg-background(
						$name: "plus"
					);
					margin: auto;
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
				}
			}
		}

		.accordion-item-content {
			padding-bottom: $fifteen-gutter;
		}

		[data-content-type="text"] {
			p {
				margin-top: 0;
				margin-bottom: $twenty-gutter;

				@include min-screen($screen__m) {
					@include type__16;
				}

				&:last-of-type {
					margin-bottom: 0;
				}
			}
		}

		&.active {
			.accordion-item-title {
				h3 {
					&:after {
						@include svg-background(
							$name: "minus",
							$size: 10px
						);
					}
				}
			}
		}
	}
}


// -------------------------------------------------
// Main Page Content
// -------------------------------------------------

.cms-no-route {
	.column {
		padding: 0;

		.breadcrumbs {
			display: none;
		}

		h1,
		h2,
		h3,
		h4,
		h5 {
			text-transform: uppercase;
		}

		h2,
		h3,
		h4,
		h5 {
			// not h1s as they should already have margin bottom
			margin-bottom: $twenty-gutter;
		}

		h3 {
			@include type__22($font-weight: $font-weight-bold);

			@include min-screen($screen__m) {
				@include type__30($font-weight: $font-weight-bold);
			}
		}

		strong {
			font-weight: $font-weight-semibold;
		}

		hr {
			margin: 0 0 $twenty-gutter 0;

			@include min-screen($screen__m) {
				margin: $twenty-gutter 0 $gutter 0;
			}
		}

		p,
		li,
		dt,
		dd {
			@include type__13;
			text-transform: initial;
		}

		p {
			font-weight: $font-weight-normal;
			margin-bottom: $twenty-gutter;

			&:last-child {
				margin: 0;
			}
		}

		li {
			font-weight: $font-weight-bold;
			margin-bottom: $twenty-gutter;
		}

		a {
			@include link__underline;
			font-weight: $font-weight-light;
		}

		img {
			margin-bottom: $twenty-gutter;
			width: 100%;

			@include min-screen($screen__m) {
				margin-bottom: rem(32);
			}
		}
	}

	.why-us-text {
		p {
			font-weight: $font-weight-semibold;

			&:before {
				content: '';
				display: inline-block;
				height: 14px;
				width: 14px;
				margin-right: $ten-gutter;
				vertical-align: text-top;
				@include svg-background(
					$name: tick,
					$size: contain
				);
			}
		}
	}

	.delivery-note {
		margin: $twenty-gutter 0;

		@include min-screen($screen__m) {
			margin: $gutter 0;
		}
	}

	.disc {
		li {
			@include type__13;
		}
	}

	table {
		margin-bottom: $gutter;

		td {
			@include type__13;
		}

		tr {
			border-bottom: 1px solid $silver-chalice;
		}

		strong {
			font-weight: $font-weight-semibold;
			display: block;
			width: 100%;
		}
	}

	// This section matches all of the text-links in _text-links but needs to only be applied
	// to links with the class on the ul on CMS pages so copied here
	.row {
		ul.text-links {
			list-style-type: none;
			margin: 0;
			padding: 0;
			text-align: center;

			li {
				display: inline-block;
				font-weight: $font-weight-semibold;
				line-height: 1rem; // custom line-height because it affects the underline
				border-bottom: solid 1px $border-color;
				margin: $twenty-gutter $twenty-gutter 0 $twenty-gutter;

				@include min-screen($screen__m) {
					margin: 0 $twenty-gutter;
				}

				&:first-child {
					border-bottom: solid 1px $body-background-color;

					&:hover {
						border-bottom: solid 1px $body-background-color;
					}
				}

				a {
					font-weight: $font-weight-normal;
					line-height: 1rem; // custom line-height because it affects the underline
				}
			}
		}
	}

	&.cms-faqs {
		.column.main {
			max-width: $container-contained-width;
			margin: 0 auto;
		}
	}
}

// Specific to the Request a Brochure Page
.cms-request-a-brochure {
	.column {
		img {
			width: auto;
		}

		.order-link {
			margin-top: $gutter;
		}
	}
}

.shipping-table {
	overflow-x: auto;
}
