/* --------------------------------------------------
 * --------------------------------------------------
 * Brand new flex grid approach for page builder
 * Can be used for anything that needs flex, including banners, product listings, content containers, etc!
 *
 *
 * See CORE theme documentation for more information
 * --------------------------------------------------
 * @author Fisheye
 */

.pagebuilder-column-group {
	flex-wrap: wrap;

	// Only wrap at this breakpoint, because Magento outputs width values instead of flex-basis!
	@include min-screen($screen__m) {
		flex-wrap: nowrap;
	}

	// Select all direct children of the container (The flex children)
	&> * {
		flex-basis: 100%;
		
		@include min-screen($screen__m) {
			flex-basis: auto;
		}
	}
}
