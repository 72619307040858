/* -------------------------------------------------
 * -------------------------------------------------
 * Yotpo reviews for product page
 *
 * TODO: Test this. Copied directly from Urban Gifts
 *
 * -------------------------------------------------
 * @author Fisheye
 */

.yotpo.yotpo-main-widget {
	width: 100%;

	.label {
		color: $body-font-color;
	}

	.error-box {
		background-color: $tooltip-background;
		@include type__16;
		@include type-setting(0);
		padding: $twenty-gutter * 2;
	}

	.yotpo-display-wrapper {
		@include min-screen($screen__m) {
			position: relative;
			bottom: em(17);
		}

		.yotpo-small-box {
			padding-top: 0;
		}

		.yotpo-regular-box {

			&.yotpo-bottomline-empty-state {
				border-top: 0;
				margin-right: 0;
				margin-left: 0;

				.yotpo-stars-and-sum-reviews {

					.yotpo-stars {
						z-index: 2;

						.yotpo-icon {
							color: $svg-icon-color-hover; //technically not on hover
						}
					}
				}

				.yotpo-default-button {
					z-index: 2;
				}
			}
		}
	}

	.write-review-wrapper {
		z-index: 1;

		.yotpo-header,
		.write-review-content {

			.y-label {
				@include type-setting(-1);
				color: $body-font-color;
			}

			.stars-wrapper {

				.yotpo-icon {
					color: $svg-icon-color-hover;
				}
			}
		}
	}

	.write-review.yotpo-regular-box {
		border-top: 0;
	}

	.write-review-content {

		.form-element {

			.y-input {
				min-height: em(48);
				height: 100%;
				@include type-setting(0);
			}

			.yotpo-text-box {
				@include input-style-one;
			}
		}
	}

	.write-review {
		.yotpo-footer {
			.socialize-wrapper,
			.name-input,
			.form-element {
				.y-label {
					@include type-setting(0);
					color: $body-font-color;
				}
			}

			.yotpo-or {
				@include type-setting(-1);
				color: $body-font-color;
			}

			.connect-wrapper {
				padding-left: 0;
				width: 100%;
				.form-element {
					padding-right: 0;
				}
			}

			.socialize-wrapper {
				position: relative;

				.y-label {
					padding: $item-gutter 0;
				}

				.socialize {
					float: none;
					width: 100%;
					div {
						margin-right: 0;
						width: 100%;
					}
				}

				.yotpo-icon-btn.yotpo-default-button {
					padding: 0;
					border-radius: 50%;
					width: 35px; // px required to stop warping on ipad
					height: 35px;
					margin: 0 $twenty-gutter * 2/2;
					position: relative;
					background-color: $icon-font-color-focus;

					.yotpo-icon::before {
						// position: absolute;
						margin: 7px; // ems not stable enough
						line-height: 2.3rem;
					}

					.yotpo-icon-twitter::before {
						font-family: FontAwesome !important; // overwrites an important
						content: "\f099";
					}

					@include min-screen($screen__m) {
						.yotpo-icon::before {
							top: em(8);
							right: em(8);
						}
					}
				}
			}

			.connect-wrapper {
				.name-input {
					.y-label {
						margin-top: $twenty-gutter;
						@include min-screen($screen__m) {
							margin-top: 0;
						}
					}
				}

				.name-input,
				.email-input {
					.y-label {
						padding: $item-gutter 0;
					}
					.y-input {
						min-height: em(48);
						height: 100%;
						@include type-setting(0);
					}
				}
			}

			.yotpo-or {
				text-align: left;
				margin-top: 0;
			}
		}
	}

	.yotpo-no-reviews {
		.yotpo-first-review {
			display: none;
			margin-top: $twenty-gutter * 1.5;
		}

		.write-review-wrapper {
			.write-review {
				padding-bottom: $twenty-gutter;

				.yotpo-default-button {
					@extend %input-type-button;
					@include min-screen($screen__m) {
						width: 30%;
					}
				}

				.write-review-content,
				.form-element {
					width: 100%;

					.y-input {
						width: 100%;
						margin-right: 0;
						padding: $item-gutter;
					}
				}
			}
		}
	}

	.yotpo-first-review-content {
		margin-top: $twenty-gutter * 1.5;

		.yotpo-default-button {
			@extend %input-type-button;
		}
	}

	.yotpo-thank-you {
		z-index: 3;
		border-color: $border-color;
		span {
			color: $body-font-color;
		}
		.yotpo-thankyou-header {
			span {
				color: $body-font-color;
			}
		}
	}
}
