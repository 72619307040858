/* -------------------------------------------------
 * -------------------------------------------------
 * search results auto complete
 *
 * --------------------------------------------------
 * @author Fisheye
 */

.search-autocomplete {
	z-index: 10000;
	background-color: $body-background-color;
	position: absolute;
	width: auto !important; // this overwrites a js fixed width
	right: 0;
	left: 0;
	min-width: auto;
	overflow: auto;
	max-height: 70vh; // the max height is needed in order for an overflow to be registered so it is scrollable
	border-top: 1px solid $gallery;

	@include min-screen($screen__m) {
		z-index: 40;
	}

	@include min-screen($screen__ll) {
		width: auto !important; // Override JS fixed width
	}

	.smile-elasticsuite-autocomplete-result {
		display: flex;
		flex-direction: column; // reversed to show category first
		padding: 0 $fifteen-gutter;
		position: relative;
		cursor: default; // override pointer cursor on entire suggest box

		@include min-screen($screen__ml) {
			padding: 0 $gutter;
		}

		&:empty {
			display: none; // Hide the results element when there are no results
		}

		.autocomplete-list {
			display: flex;
			flex: 0 1 auto;
			flex-wrap: wrap;
			padding: $twenty-gutter 0;

			@include min-screen($screen__ml) {
				padding: $gutter 0;
			}

			.autocomplete-list-title {
				flex: 1 0 calc(100% - #{$fifteen-gutter});
				padding-bottom: $fifteen-gutter;
				margin: 0 rem(7.5);
				@include type__16($letter-spacing: 1px);
				text-align: center;
				text-transform: uppercase;

				@include min-screen($screen__ml) {
					flex-basis: calc(100% - #{$gutter});
					margin: 0 $fifteen-gutter;
				}

				&:last-child {
					margin-bottom: 0;
				}
			}

			dd {
				flex: 0 0 50%;
				padding: $fifteen-gutter rem(7.5);
				position: relative;

				&:before {
					content: "";
					display: block;
					height: 1px;
					background-color: $gallery;
					position: absolute;
					top: 0;
					right: 0;
					left: 0;
				}

				@include min-screen($screen__m) {
					flex-basis: calc(100% / 3);
				}

				@include min-screen($screen__ll) {
					flex-basis: calc(100% / 6);
				}

				&,
				> a,
				.product-item,
				.f-fix {
					display: flex;
				}

				> a {
					flex-direction: column;
				}

				.f-fix {
					flex-wrap: wrap;
					justify-content: space-between;

					.price-from {
						display: flex;
						flex-direction: column-reverse;
					}

					.old-price {
						display: block;
					}
				}

				&:first-of-type,
				&:nth-of-type(2) {
					&:before {
						display: none;
					}
				}

				&:nth-of-type(3) {
					&:before {
						@include min-screen($screen__m) {
							display: none;
						}
					}
				}

				&:nth-of-type(4),
				&:nth-of-type(5),
				&:nth-of-type(6) {
					&:before {
						@include min-screen($screen__ll) {
							display: none;
						}
					}
				}

				&:nth-of-type(odd) {
					&:before {
						@include max-screen($screen__m - 1) {
							left: rem(7.5);
						} 
					}
				}

				&:nth-of-type(even) {
					&:before {
						@include max-screen($screen__m - 1) {
							right: rem(7.5);
						}
					}
				}

				&:nth-of-type(3n) {
					&:before {
						@include screen($screen__m, ($screen__ll - 1)) {
							right: rem(7.5);
						}
					}
				}

				&:nth-of-type(4n) {
					&:before {
						@include screen($screen__m, ($screen__ll - 1)) {
							left: rem(7.5);
						}
					}
				}

				&:nth-of-type(6n) {
					&:before {
						@include min-screen($screen__ll) {
							right: $fifteen-gutter;
						}
					}
				}

				&:nth-of-type(6n + 1) {
					&:before {
						@include min-screen($screen__ll) {
							left: $fifteen-gutter;
						}
					}
				}

				&.button-wrapper {
					flex: 0 0 100%;
					justify-content: center;
					padding-bottom: 0;
					
					button {
						@include button__default;
					}
				}
			}

			.product-image-box {
				padding: 0;

				img {
					width: 100%;
				}
			}

			.product-item {
				flex: 1 0 auto;
				margin-top: $twenty-gutter;
			}

			.product-primary,
			.product-secondary {
				flex: 0 0 100%;
			}

			.product-secondary {
				display: flex;
				align-items: flex-end;
				margin-top: $fifteen-gutter;
			}

			.product-name {
				@include type__16;
				text-transform: capitalize;
			}

			.minimal-price-link { // tier pricing duplicate price
				display: none;
			}

			.price-to {
				display: none;
			}

			.category-mini-crumb {
				margin-right: $five-gutter;
				flex: 0 0 auto;
			}

			&.product-list {
				padding-top: 0;
				margin: 0 rem(-7.5);
		
				@include min-screen($screen__ml) {
					margin-right: -$fifteen-gutter;
					margin-left: -$fifteen-gutter;
				}

				.autocomplete-list-title {
					@extend %visuallyhidden;
				}
			}

			&.category-list {
				padding-bottom: $sixty-gutter;
				background-color: $gallery;
				margin-right: -$fifteen-gutter;
				margin-left: -$fifteen-gutter;
				
				@include min-screen($screen__ml) {
					margin-right: -$gutter;
					margin-left: -$gutter;
				}

				.autocomplete-list-title,
				dd {
					flex-basis: calc(100% - #{$gutter});
					margin: 0 $fifteen-gutter;
	
					@include min-screen($screen__ml) {
						flex-basis: calc(100% - #{$sixty-gutter});
						margin: 0 $gutter;
					}
				}

				dd {
					justify-content: center;
					padding: $ten-gutter 0;

					> a {
						@include type__16;
						text-transform: capitalize;
						flex-direction: row;
						flex-wrap: wrap;
						justify-content: center;
					}
				}
			}
		}
	}
}
