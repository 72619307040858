/* -------------------------------------------------
 * -------------------------------------------------
 * General Styles specific to account area
 *
 * Most styles should be in relevant stylesheet i.e. buttons.scss
 *
 * -------------------------------------------------
 * @author Fisheye
 */

 // sets all of the main gutters
body[class*="customer"],
body[class*="company"],
.quickorder-index-index,
.account,
.sales-guest-form,
.sales-guest-view,
.sales-guest-invoice {
	&.customer-account-createpassword {
		.page-title-wrapper {
			text-transform: lowercase;

			em {
				text-transform: capitalize;
			}
		}
	}

	// -------------------------------------------------
	// Change Payment Details
	// This appears when you click on the 'edit' link
	// on the 'My Subscriptions' page
	// -------------------------------------------------
	&.aw_sarp2-profile_edit-payment {
		.step-title {
			// hide duplicate title used elsewhere
			@extend %visuallyhidden;
		}

		.select-payment-method {
			@include type__22($font-family: $font-family-serif);
			padding-bottom: $gutter;
		}

		.checkout-payment-method {
			.payment-method-container {
				display: flex;
				flex-direction: column;

				.actions-toolbar {
					margin: 0;
				}

				.payment-method-braintree {
					order: 2;
				}

				.braintree-credit-card-types {
					img {
						// This can't be removed via template because it shares the same KO template as the
						// checkout & other areas of the site where payment details are required. These images
						// are required in the checkout & other areas of the site, but not on this page.
						@extend %visuallyhidden;
					}
				}

				[id="co-transparent-form-braintree"] {
					.field {
						[for="braintree_cc_number"] {
							position: static;
						}
					}
				}
			}

			.payment-method-title {
				margin-bottom: 0;

				.label {
					flex: 0 1 100%;
					align-items: center;
					padding: rem(27) $twenty-gutter;
					background-color: $grey-medium1;
					border-bottom: 1px solid $white;

					span {
						@include type__14($font-weight: $font-weight-semibold);
						text-transform: uppercase;
						order: 1;
						padding-right: $ten-gutter;
						flex: 1;
					}
				}

				.credit-card-types {
					display: flex;
					margin-top: 0;
					order: 3;
					margin-left: auto;

					.item {
						margin-right: $ten-gutter;

						&:last-child {
							margin-right: 0;
						}
					}
				}

				.payment-icon,
				img {
					order: 3;
					width: 38px;
					margin-left: auto;

					@include min-screen($screen__m) {
						width: 45px;
						height: rem(28);
					}
				}

				.actions-toolbar {
					margin: 0;
				}
			}

			.payment-method {
				.payment-method-content {
					// dropdown content hidden by default
					display: none;
				}

				&._active {
					.payment-method-title {
						border: 2px solid $shadow-green;
					}

					.payment-method-content {
						display: block;
						padding: $gutter 0;

						.label {
							position: absolute;
							z-index: 1;
						}

						.field {
							.field-tooltip {
								.field-tooltip-action {
									span {
										// hide tooltip text in place of icon
										@extend %visuallyhidden;
									}
								}

								.field-tooltip-content {
									left: auto;
									right: 0;

									&:before {
										left: auto;
										right: rem(13);
									}
								}
							}
						}

						.paypal-button {
							iframe {
								z-index: 1;
							}
						}
					}
				}

				.braintree-credit-card-selected {
					// hide duplicate card icon - Cannot be removed as in use elsewhere
					display: none;
				}

				.hosted-control {
					@include input-style-one;
					width: 100%;
					padding: 0 $fifteen-gutter;
					height: rem(42);

					&.braintree-hosted-fields-invalid:not(.braintree-hosted-fields-focused) {
						& ~ .hosted-error {
							height: auto;
							opacity: 1;
						}
					}

					iframe {
						position: relative;
						top: 0;
						padding-top: rem(14);
					}
				}

				.hosted-error {
					clear: both;
					height: 0;
					opacity: 0;
					margin: 0;
				}

				.actions-toolbar {
					.checkout {
						display: none; // Cannot be removed from template due to shared usage in checkout
						align-items: center;
						justify-content: center;

						&:before {
							@include svg-background(
								$name: 'padlock-white',
								$size: 100% 100%
							);
							content: '';
							display: block;
							height: 23px;
							width: 23px;
							margin-right: rem(4);
						}

						span {
							@include type__18($color: $white);
						}

						.place-order {
							&:after {
								content: '-';
								margin: 0 rem(4);
							}
						}

						&:hover,
						&:focus {
							&:before {
								@include svg-background(
									$name: 'padlock-green',
									$size: 100% 100%
								);
							}

							span {
								color: $shadow-green;
							}
						}
					}
				}
			}
		}

		.actions-toolbar {
			.primary {
				// Specific to this button, not to affect other actions. Specific enough to override existing styles.
				[id="profile_save_payment_button"] {
					align-items: center;
					display: flex;
					justify-content: center;

					&:before {
						@include svg-background(
							$name: 'padlock-white',
							$size: 100% 100%
						);
						content: '';
						display: block;
						height: 23px;
						width: 23px;
						margin-right: rem(4);
					}

					span {
						@include type__18($color: $white);
					}

					&:hover,
					&:focus {
						&:before {
							@include svg-background(
								$name: 'padlock-green',
								$size: 100% 100%
							);
						}

						span {
							color: $shadow-green;
						}
					}
				}
			}
		}
	}

	.page-main {
		.actions {
			button.action.primary {
				@include button__journey();
				margin-top: $twenty-gutter;
			}
		}

		.columns {
			width: 100%;
			margin: 0 auto;
			padding: 0 $page-gutter $gutter $page-gutter; // top spacing not needed as coming from cms menu

			@include min-screen($screen__m) {
				padding: $gutter $page-padding $fifty-gutter;
			}

			.sidebar-main {
				padding: 0;
			}
		}
	}

	.main {
		padding: 0 0 $twenty-gutter;

		address {
			.postcode {
				text-transform: uppercase;
			}
		}
	}

	.block-title,
	.legend,
	.legend span {
		@include type__35($font-family: $font-family-serif);
		border-bottom: 0;
		padding-bottom: $fifteen-gutter;

		strong {
			@include type__35($font-family: $font-family-serif);
			display: flex;
		}
	}

	.block-title {
		strong {
			@include type__35(
				$color: $black,
				$font-family: $font-family-serif,
				$font-weight: $font-weight-normal,
				$letter-spacing: 0.7px
			)
		}
	}

	.actions-toolbar {
		margin-top: $account-actions-toolbar-margin-top;

		@include min-screen($screen__m) {
			margin-top: $ten-gutter;
		}

		.action {
			display: inline-block;
			@include type__16;
			margin-top: $account-inner-spacing;
		}

		.primary {
			display: block;

			.action {
				@include button__journey;
				width: 100%;

				span {
					@include type__14(
						$color: $button-journey-color,
						$letter-spacing: 1px,
						$font-weight: $font-weight-normal,
					);
				}
			}
		}
	}

	.manage-company-accounts {
		.actions-toolbar {
			.primary {
				display: inline-block;
			}
		}
	}

	.login-secured-message {
		@include secure__text;
		padding: 0 0 $twenty-gutter;
		background-color: $white;
		margin: 0 auto;
		width: 100%;

		@include min-screen($screen__m) {
			padding: 0 $gutter $gutter;
			max-width: $screen__m;
		}
	}

	.fieldset {
		.password {
			.control {
				div {
					display: $account-password-strength-indicator-display;
				}
			}
		}

		.newsletter {
			justify-content: center;

			@include min-screen($screen__ml) {
				align-items: center;
			}

			.label {
				display: inline-block;
				min-width: unset;
				flex-basis: auto;
			}
		}
	}

	.block.block-balance-giftcard {
		.block-content {
			a {
				@include link__underline;
			}
		}
	}

	// Giftcard Page
	.form-giftcard-redeem {
		.actions-toolbar {
			display: flex;

			.primary,
			.secondary {
				button {
					@include button__journey;
				}
			}

			.primary {
				@include min-screen($screen__l) {
					// creates space on desktop
					margin-right: $twenty-gutter;
				}
			}

			.secondary {
				margin-top: 0;
				border: 0;
			}
		}
	}
}

.account.newsletter-manage-index {
	.form-newsletter-manage {
		fieldset {
			.field {
				label {
					flex-basis: auto;
				}
			}
		}

		.actions-toolbar {
			margin-top: 0;

			.primary {
				display: none;
				// remove save button as saves automatically when checkbox is clicked
			}
		}
	}
}

.uk-wholesale-export {
	.row-full-width-inner {
		max-width: 1280px;
		margin: 0 auto;
		width: 100%;
		padding: 0 $fifteen-gutter;

		@include min-screen($screen__m) {
			padding: 0 $gutter;
		}

		@include min-screen($screen__ll) {
			padding: 0 $fifteen-gutter;
		}

		p {
			@include type__14(
				$font-weight: $font-weight-semibold,
			);
			padding: $fifteen-gutter 0;
			border-top: $account-uk-wholesale-border;
			border-bottom: $account-uk-wholesale-border;
			position: relative;
			margin: $ten-gutter 0;

			@include min-screen($screen__l) {
				margin: $forty-gutter auto;
			}

			span {
				@include type__16(
					$font-family: $font-family-serif,
					$color: $boulder
				);
				font-style: italic;
				text-transform: none;

				@include max-screen($screen__m - 1) {
					display: block;
				}
			}

			&:before {
				content: '';
				height: 11px;
				width: 9px;
				position: absolute;
				right: 0;
				top: 50%;
				transform: translateY(-50%);

				@include svg-background(
					$name: arrow-right,
					$size: contain
				);
			}
		}
	}
}

.account {
	.message.info {
		&.empty {
			span {
				@include type__16($font-weight: $font-weight-semibold);
			}
		}
	}
}

.customer-account-forgotpassword,
.customer-account-createpassword,
.sales-guest-form,
.sales-guest-view,
.sales-guest-invoice,
.customer-account-create,
.customer-account-login {
	[id="maincontent"] {
		&.page-main {
			.columns {
				padding-top: 0;
			}
		}
	}
}
