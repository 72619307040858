/* ------------------------------------------------
 * ------------------------------------------------
 * Checkout Payment Methods
 * ------------------------------------------------
 */

.checkout-container .opc-wrapper {
	.checkout-payment-method {
		.form.payments {
			> .fieldset {
				> br {
					display: none;
				}
			}
		}

		legend {
			@extend %visuallyhidden;
		}

		.payment-methods {
			// less specific than actual payment methods below as we want this on all payment methods
			&.items {
				.payment-group {
					position: relative;

					&.default {
						&:nth-child(2) {
							.step-title {
								@extend %visuallyhidden;
							}
						}
					}

					.required {
						&:after {
							content: "*";
							color: $field-required-input-color;
						}
					}

					.payment-method {
						.payment-method-content {
							display: none;
							margin-bottom: $twenty-gutter;

							@include min-screen($screen__m) {
								margin-bottom: $checkout-methods-outer-spacing;
							}

							.zoid-outlet {
								z-index: 1 !important;
							}

							.paypal-button {
								width: 270px !important;
								margin: auto;
								height: auto !important;
							}

							.billing-address-same-as-shipping-block {
								padding: 0;
								margin: 0;

								label {
									margin: 0;
									padding-left: 0;
									width: 100%;

									span {
										@include type__16($font-weight: $font-weight-bold);
										text-transform: capitalize;
									}
								}

								label[for='billing-address-same-as-shipping-companycredit'] { // no identifying class
									@include min-screen($screen__m) {
										padding: 0;
									}
								}
							}

							.field-select-billing {
								margin: $twenty-gutter 0;

								.select {
									padding: $select-padding;
								}
							}

							.field.type {
								display: none; // Shows card type, not usually on design but could be used in the future
							}

							.required {
								&:after {
									display: none; // Isn't usually on designs but it could be used in the future
								}
							}

							.billing-address-details {
								margin-top: $fifteen-gutter;

								&:empty {
									margin-top: 0;
								}

								@include type__16(
									$line-height: 1.25,
									$font-weight: $font-weight-semibold
								);

								.action-edit-address {
									@extend %visuallyhidden;
									@include button__default;
									margin-top: $checkout-field-spacing;
								}
							}
						}

						&._active {
							.zoid-outlet {
								margin-top: $twenty-gutter;

								@include min-screen($screen__m) {
									margin-top: $gutter;
								}
							}

							.saved-card-details {
								span {
									color: $white;
								}
							}

							.payment-method-content {
								display: block;

								.edit-address {
									@extend %visuallyhidden;

									&:before {
										content: "";
										height: rem(10);
										display: block;
									}
								}

								.payment-method-billing-address {
									.address-label {
										@extend %visuallyhidden;
									}

									.checkout-billing-address {
										margin-bottom: rem(23);

										@include min-screen($screen__m) {
											margin-bottom: $twenty-gutter;
										}

										.fieldset {
											.field-select-billing {
												.label {
													@extend %visuallyhidden;
												}
											}

											&.address {
												.field {
													&.choice {
														padding-left: 0;
														padding-top: 0;
													}
												}
											}
										}
									}
								}
							}
						}

						.payment-icon {
							height: rem(25);
							width: 20px; // Using em or rem values cause icons to blur
							min-width: 38px;
							margin: auto 0;

							@include min-screen($screen__m) {
								width: 45px;
								height: rem(28);
							}
						}
					}

					.field.choice {
						position: relative;
						padding: rem(32) rem(23);

						@include min-screen($screen__m) {
							padding: $twenty-gutter;
						}

						.label {
							flex: 1;
							align-items: center;
							padding-left: 0;

							&:before {
								margin-right: rem(9);
								position: relative;
							}

							span {
								flex: 1;
								display: inline;
								text-transform: uppercase;
								@include type__16($font-weight: $font-weight-normal);
							}
						}

						&.payment-method-title {
							.label {
								span {
									@include type__14(
										$letter-spacing: 1px,
										$font-weight: $font-weight-semibold
									);
								}
							}
						}

						.field-tooltip.toggle {
							.field-tooltip-action {
								display: none; // remove duplicated tooltip icon
							}
						}
					}
				}
			}

			.form .fieldset .field.choice .label {
				flex: 1;
			}

			.select-payment-method {
				display: none;
			}
		}

		.credit-card-types {
			width: 100%;
			margin-top: $item-gutter;

			@include min-screen($screen__s) {
				display: inline;
				margin-top: 0;
			}

			.item {
				width: 32px; // Using either an em or rem value causes the icons to blur
				height: auto;
				display: inline-block;

				@include min-screen($screen__m) {
					width: 45px;
				}

				&:first-child {
					order: 2;
				}

				&:last-child {
					order: 3;
				}
			}
		}

		.payment-method-container {
			// contains all of the payment options. Excludes step title and Select Payment Method Text
			.payment-method {
				// class applied to each payment method
				border-bottom: 1px solid $white;

				&:last-of-type {
					margin-top: 0;
					margin-bottom: $twenty-gutter;
					border-bottom: 0;

					@include min-screen($screen__m) {
						margin-bottom: $checkout-methods-outer-spacing;
					}
				}

				.payment-method-title {
					margin: 0;
					padding-bottom: $checkout-methods-outer-spacing;
					background-color: $gallery;
				}

				.available-credit {
					order: 1;
					align-self: center;
					margin-left: rem(53);

					@include min-screen($screen__ml) {
						margin-left: 0;
					}

					strong,
					span {
						@include type__13;
					}
				}

				&._active {
					.payment-method-title {
						margin-bottom: rem(22);
						border: 2px solid $shadow-green;

						@include min-screen($screen__m) {
							margin-bottom: $checkout-methods-outer-spacing;
						}

						.label {
							span {
								color: $white;
							}
						}

						.available-credit {
							strong,
							span {
								color: $white;
							}
						}
					}
				}

				.payment-method-title,
				.payment-method-content {
					.cvv {
						._with-tooltip {
							@include min-screen($screen__m) {
								min-width: 60%;
							}
						}
					}
				}

				// card verification field - has tooltip
				.ccard>.field.cvv>.control {
					padding-right: 0;

					.field-tooltip {
						top: em(7);
						left: auto;
						right: em(5);
					}
				}
			}

			.payment-method-braintree {
				// braintree specific styles (in case you couldn't tell), this class is applied to the same div as .payment-method
				.payment-method-content {
					.fieldset {
						display: block;
						width: 100%;

						.field-select-billing {
							> .label {
								@extend %visuallyhidden;
							}

							.control {
								margin: 0;
							}
						}

						.choice.field {
							padding: 0;

							span {
								text-transform: inherit;
							}
						}

						.billing-address-form {
							&:before {
								content: "Billing Address";
								display: block;
								height: 20px;
								margin-bottom: $twenty-gutter;
							}
						}
					}

					form[id="co-transparent-form-braintree"] { // needs to be specific to braintree form
						.card-information {
							.card-number {
								padding-bottom: rem(16);

								@include min-screen($screen__m) {
									padding-bottom: $twenty-gutter;
								}
							}
						}

						.security.row {
							padding-bottom: 0;

							.field {
								&.number {
									margin-bottom: rem(17);

									@include min-screen($screen__m) {
										margin-bottom: rem(19);
									}

									.control {
										margin-bottom: 0;
									}
								}

								&.cvv {
									.control {
										flex: 0 1 100%;
									}
								}

								.hosted-date-wrap {
									display: flex;
									flex-wrap: wrap;
									justify-content: space-between;

									.hosted-date {
										margin: 0;
										width: calc(50% - (#{$checkout-field-spacing} / 2));
									}

									.hosted-error {
										flex: 0 1 100%;
									}
								}
							}
						}

						.field {
							margin-bottom: rem(17);

							@include min-screen($screen__m) {
								margin-bottom: $twenty-gutter;
							}

							&.number:nth-of-type(2),
							&.cvv {
								@include min-screen($screen__ml) {
									width: calc(50% - 10px);
									margin-bottom: 0;
								}
							}

							.field-tooltip-action {
								bottom: rem(7);

								span {
									@extend %visuallyhidden;
								}
							}

							.credit-card-types,
							.braintree-card-control img {
								display: none;
							}

							&.number:nth-of-type(2) {
								float: left;
							}

							&.cvv {
								float: right;

								@include max-screen($screen__ml - 1) {
									margin-bottom: 0;
								}

								.control {
									margin-bottom: 0;
								}
							}

							&.choice {
								display: inline-block;
								padding: 0;
								margin: $twenty-gutter 0 0;
								clear: both;

								.label {
									padding-left: 0;

									span {
										text-transform: capitalize;
									}
								}
							}

							.hosted-control {
								position: relative;
								padding: 0 $fifteen-gutter;
								height: rem(42);
							}

							iframe {
								position: relative;
								top: 0;
								padding-top: rem(14);
							}
						}
					}

					.actions-toolbar {
						margin-top: $twenty-gutter;
					}
					// This are all styling form elements outputted by Braintree. They are nested inside .field>.control but the nesting would be insane!
					.hosted-control {
						@include input-style-one;
						width: 100%;
						padding: $field-padding;
						height: $checkout-methods-hosted-field-height;

						&.hosted-date {
							width: 45%;

							&:first-of-type {
								margin-right: 25px; // this is the gap between the two boxes on expiration from Braintree
								&:after {
									right: -15px;
								}
							}
						}

						&.hosted-cid {
							// needs repeating to make it mor specific
							@include input-style-one;
							width: 100%;
							padding: $field-padding;
						}

						&.braintree-hosted-fields-invalid:not(.braintree-hosted-fields-focused) {
							& ~ .hosted-error {
								height: auto;
								opacity: 1;
							}
						}
					}
				}

				.hosted-error {
					clear: both;
					height: 0;
					opacity: 0;
					margin: 0;
				}
			}
		}

		.payment-methods {
			&.items {
				.payment-group {
					position: relative;

					div[id="payment-method-braintree-paypal-vault"] { // specific to saved PayPal payment method
						.field.choice {
							.label {
								&:before {
									flex: 0 0 auto;
								}

								> span {
									word-break: break-all;
									flex: 0 1 auto;
								}
							}
						}
					}

					div[id="payment-method-braintree-paypal"] { // no class
						[for="braintree_paypal"] {
							img {
								padding-left: 51px; // Push the image out of view
								@include svg-background('paypal');
							}
						}

						&._active {
							margin-bottom: 0;

							.payment-method-content {
								display: flex;
								flex-direction: column;
								margin-bottom: 0; // Cancel generic margin, as only not required on PayPal

								@include min-screen($screen__m) {
									margin-bottom: rem(11);
								}

								.paypal-button {
									width: 100% !important; // Override iFrame inline width
									text-align: center;
									margin-bottom: $ten-gutter;
								}

								.messages {
									order: 2;
								}

								.choice {
									padding: 0;
									margin-bottom: 1.25rem;

									.label {
										padding-left: 0;

										span {
											text-transform: capitalize;
										}
									}
								}
							}
						}
					}

					&.vault {
						.payment-method {
							.payment-method-title {
								padding: rem(27) $twenty-gutter;

								@include min-screen($screen__m) {
									padding: $fifteen-gutter $twenty-gutter;
								}

								img {
									margin-left: auto;
								}
							}

							&:last-child {
								margin-bottom: rem(22);

								@include min-screen($screen__m) {
									margin-bottom: rem(29);
								}
							}

							&._active {
								.payment-method-content {
									margin: $twenty-gutter 0;

									@include min-screen($screen__m) {
										margin: $gutter 0;
									}
								}
							}
						}
					}

					.required {
						@include min-screen($screen__l) {
							position: absolute;
						}
					}

					.payment-method-container {
						.required {
							position: relative;
						}
					}
				}
			}
		}

		.form {
			.fieldset {
				.subscribe-wrapper {
					display: none !important; // remove 'Subscribe to Newsletter' - inline style of dislay: block;
				}
			}
		}

		.payment-option-content {
			.form-discount {
				display: flex;

				.payment-option-inner {
					flex: 1;

					.control {
						margin-bottom: 0;
					}
				}

				.primary {
					width: rem(20);
				}
			}
		}
	}

	.checkout-payment-method {
		.payment-method {
			.payment-method-title.field.choice {
				padding: $twenty-gutter;
				display: flex;
				flex-direction: row;

				.saved-card-label {
					flex: 0 1 em(150);

					@include min-screen($screen__s) {
						flex: 0 1 em(172);
					}

					@include min-screen($screen__m) {
						flex: 0 1 em(120);
					}

					@include min-screen($screen__l) {
						flex: 0 1 em(160);
					}
				}

				.saved-card-details {
					display: flex;
					flex-direction: column;

					&.label {
						margin-left: $twenty-gutter;
					}

					span {
						@include type__13($font-weight: $font-weight-semibold);
						padding: 0;
						flex: 0 auto;
						margin-right: $five-gutter;

						&:nth-child(odd) {
							font-weight: $font-weight-normal;
						}
					}
				}

				.saved-card-info {
					width: 100%;
					display: flex;

					> span {
						order: 0;
						align-self: center;

						@include min-screen($screen__m) {
							max-width: rem(60);
						}

						@include min-screen(855px) {
							max-width: none;
						}
					}

					.card-details {
						flex: 1;
						text-align: right;
						padding-right: $twenty-gutter;

						span.info-title,
						span.info-value { // span required to override more specific generic styles
							@include type__16($font-weight: $font-weight-normal);
							text-transform: capitalize;
						}

						span.info-value {
							font-weight: $font-weight-semibold;
						}
					}

					.payment-icon {
						min-height: auto;
					}
				}

				label {
					display: flex;
					align-items: center;
					margin-bottom: 0;

					img {
						order: 3;
					}

					> span {
						@include type__14;
						order: 1;
						padding-right: $ten-gutter;
						flex: 0 1 em(110);

						@include min-screen($screen__s) {
							flex: 0 1 em(135);
						}

						@include min-screen($screen__m) {
							flex: 0 1 em(80);
						}

						@include min-screen($screen__l) {
							flex: 0 1 em(123);
						}
					}

					ul {
						order: 2;
						width: auto;
					}

					.credit-card-types {
						display: flex;
						margin-top: 0;

						.item {
							margin-right: $ten-gutter;

							img {
								min-width: 35px;
							}

							&:last-child {
								margin-right: 0;
							}
						}
					}
				}

				label[for='companycredit'] { // no class available to target pay with credit option
					flex: 0 1 auto;

					@include min-screen($screen__ml) {
						flex: 0 1 rem(250);
						max-width: rem(250);
					}

					> span {
						flex: 0 1 auto;

						@include min-screen($screen__s) {
							flex: 0 1 auto;
						}

						@include min-screen($screen__m) {
							flex: 0 1 auto;
						}

						@include min-screen($screen__l) {
							flex: 0 1 auto;
						}
					}
				}
			}

			.payment-method-content {
				.actions-toolbar {
					.action {
						&.primary.checkout,
						.place-order {
							display: flex;
							justify-content: center;
							align-items: center;
						}

						.place-order {
							&::before {
								@include svg-background(
									$name: 'padlock-white',
									$size: 100% 100%
								);
								content: '';
								display: block;
								height: 23px;
								margin-right: rem(4);
								width: 23px;
							}

							&::after {
								content: ' - ';
								margin: 0 rem(4);
							}
						}

						&:hover {
							.place-order {
								&:before {
									@include svg-background(
										$name: 'padlock-green',
										$size: 100% 100%
									);
								}
							}
						}
					}
				}

				.checkout-agreements-block {
					.gift-card-information {
						@extend %visuallyhidden;
					}
				}
			}
		}

		.billing-address-form {
			._required {
				._with-tooltip {
					input {
						width: 100%;
					}
				}
			}

			.fieldset {
				&.address {
					.field {
						&:last-child {
							margin-bottom: 0;
						}
					}

					.street {
						.field {
							&:last-child {
								margin-bottom: 0;
							}
						}
					}
				}
			}
		}
	}

	// Checkout success
	// -------------------------------------------------
	.checkout-success-title-wrapper {
		text-align: center;
		background-color: $checkout-success-banner-background;
		margin-top: $twenty-gutter;

		.checkout-success-summary {
		}
	}

	.checkout-success-order-account-info-wrapper {
		div[id="registration"] {
			// "make ordering even faster in future" 3rd col
			border: solid 1px $border-color;
		}

		.block-order-totals {
			background-color: $body-background-color;
			padding: $twenty-gutter;

			.grand_total {
				background-color: $body-background-color;
				color: $grand-total-success-text-color;
			}
		}

		.block-order-addresses {
			padding: $twenty-gutter;
			display: flex;
			border: solid 1px $border-color;

			@include min-screen($screen__m) {
				.box-order-billing-address {
					border-left: solid 1px $border-color;
					padding-left: $twenty-gutter;
				}

				.box-order-shipping-address {
					padding-right: $twenty-gutter;
				}
			}
		}

		.block-order-totals {
			padding: $twenty-gutter;
		}

		div[id="registration"] {
			padding: $twenty-gutter; // input[type="submit"]
			input[type="submit"] {
				@extends %input-style-four;
			}
		}
	}
}
