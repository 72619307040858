/* -------------------------------------------------
 * -------------------------------------------------
 * Page Titles
 *
 * These can be found on most pages, including the
 * basket page and category pages
 *
 * --------------------------------------------------
 * @author Fisheye
 */

 .page-title-wrapper {
	background-color: $gallery;
	text-align: center;
	padding: rem(33) $page-padding-mobile;

	@include min-screen($screen__m) {
		padding: $sixty-gutter $gutter;
	}

	.cms-page-view:not(.page-layout-cms-full-width) & {
		padding: 0;
	}

	.customer-account-login &,
	.customer-account-create & {
		padding: rem(32) $page-padding-mobile $fifteen-gutter;

		@include min-screen($screen__m) {
			padding: rem(58) $gutter rem(25);
		}
	}

	.checkout-onepage-success & {
		padding: 0;
	}

	.catalog-category-view &,
	.catalogsearch-result-index & {
		display: flex;
		flex-direction: column;
		justify-content: center;
		min-height: 240px;
		background-size: auto 558px;
		background-attachment: fixed;
		background-position: 45% 532px;
		position: relative;

		@include min-screen($screen__m) {
			height: 350px;
			background-size: cover;
			background-position: center;
			background-attachment: local;
		}
	}

	.sales-guest-view &,
	.sales-order-view & {
		.page-title {
			padding-bottom: $five-gutter;
		}
	}

	.page-title {
		@include type__33(
			$font-family: $font-family-serif,
			$letter-spacing: 1px,
			$line-height: 1
		);

		@include min-screen($screen__m) {
			@include type__52(
				$font-family: null,
				$letter-spacing: 2px,
				$line-height: 1
			);
		}

		.catalog-product-view & {
			padding: 0;
		}

		.checkout-onepage-success & {
			padding-bottom: rem(12);

			@include min-screen($screen__m) {
				padding-bottom: $twenty-gutter;
			}
		}

		span {
			span {
				font-style: italic;
			}
		}

		.base {
			.account.customer-account-index & {
				color: $body-font-color;
				display: block;
				font-size: rem(33);
				font-family: $font-family-serif;
				text-transform: capitalize;

				@include min-screen($screen__m) {
					margin-bottom: 0;
					font-size: rem(52);
				}

				> span {
					color: $shadow-green;
					font-size: rem(33);
					font-style: normal;
					font-family: $font-family-serif;
					text-transform: capitalize;
					letter-spacing: 1px;

					@include min-screen($screen__m) {
						font-size: rem(52);
						letter-spacing: 2px;
					}
				}
			}

			.page-layout-subscriptions-landing & {
				@include min-screen($screen__m) {
					@include type__85(
						$line-height: 1,
						$color: $white,
						$letter-spacing: 5px
					);
				}
			}

			.company-profile-index & {
				line-height: 1.8;
			}
		}
	}
}
