/* -------------------------------------------------
 * -------------------------------------------------
 * Summary totals
 *
 * -------------------------------------------------
 * @author Fisheye
 */

// -------------------------------------------------
// 3. Cart summary, prices, etc
// -------------------------------------------------

.cart-summary {
	padding: $twenty-gutter 0;
	background: $basket-summary-background;
	margin-top: $fifteen-gutter;

	@include min-screen($screen__m) {
		margin-top: $twenty-gutter;
	}

	.summary.title {
		@extend %visuallyhidden; // Hide the summary title
	}

	> div {
		padding-right: $fifteen-gutter;
		padding-left: $fifteen-gutter;

		@include min-screen($screen__m) {
			padding-right: $twenty-gutter;
			padding-left: $twenty-gutter;
		}
	}

	.totals.sub {
		@extend %visuallyhidden; // KO, cannot be removed via layout
	}

	.block.shipping {
		margin-top: $twenty-gutter;

		.title {
			@extend %visuallyhidden;
			padding-right: $twenty-gutter;
			position: relative;
			cursor: pointer;

			&:after {
				content: "";
				height: rem(10);
				width: rem(10);
				margin: auto;
				position: absolute;
				top: 0;
				bottom: 0;
				right: 0;
				@include svg-background(
					$name: "plus",
					$size: contain
				);
			}

			strong {
				@include type__17($letter-spacing: 1px);
				text-transform: uppercase;
			}
		}

		.content {
			margin-top: $fifteen-gutter;

			form {
				.fieldset {
					.legend {
						&,
						+ br {
							display: none;
						}
					}

					.item-title {
						display: flex;
						flex: 0 1 100%;
						margin-bottom: $form-label-spacing;

						&:after {
							display: flex;
							align-self: center;
							padding-left: em(5);
						}

						@include min-screen($screen__ml) {
							flex: 1 0 $account-form-label-width-tablet;
						}

						@include min-screen($screen__ll) {
							flex: 0 1 $account-form-label-width-desktop;
						}

						span {
							align-self: center;
							@include type__14;
						}
					}

					.item-options {
						&,
						.field,
						.label {
							&:last-of-type {
								margin-bottom: 0;
							}
						}

						.label {
							@include type__14;
						}

						.price {
							margin-left: auto;
							@include price;
						}
					}
				}
			}
		}

		&.active {
			.title {
				&:after {
					@include svg-background("minus");
				}
			}
		}
	}

	.cart-totals {
		table {
			&.totals {
				.totals-tax,
				.grand.excl {
					@extend %visuallyhidden; // KO, cannot be removed via layout
				}
			}

			tr {
				th,
				td {
					padding: 0;
				}

				th {
					text-align: left;

					&,
					strong {
						@include type__17($letter-spacing: 1px);
						text-transform: uppercase;
					}
				}

				td {
					text-align: right;
				}

				&.giftwrapping {
					.amount {
						font-weight: $font-weight-semibold;
					}
				}

				.aw-sarp2-subscription-label {
					@extend %visuallyhidden; // 'You Pay Now' label not required
				}

				&.giftcard {
					.delete {
						span {
							@extend %visuallyhidden;
						}
					}

					.amount {
						width: 60px;

						.discount {
							.price {
								display: flex;
								justify-content: flex-end;
								flex-wrap: nowrap;
							}
						}
					}
				}
			}
		}
	}

	.ppcreditcalc-container {
		margin-top: $twenty-gutter;

		.ppcreditcalc-legal {
			margin-top: $fifteen-gutter;

			p {
				&:not(:last-of-type) {
					margin-bottom: $ten-gutter;
				}
			}
		}
	}

	.checkout-methods-items {
		margin-top: $twenty-gutter;
		text-align: center;

		.item {
			.action {
				// Multiple Addresses
				&.multicheckout {
					@extend %visuallyhidden;
				}
			}

			.primary {
				@include button__journey;
				width: calc(100% - #{$gutter});

				@include min-screen($screen__m) {
					width: calc(100% - #{$forty-gutter});
				}

				span {
					padding-left: $gutter;
					position: relative;
					@include type__18(
						$color: $white,
						$font-weight: $font-weight-semibold,
						$letter-spacing: 1px
					);
					text-transform: uppercase;

					&:before {
						content: "";
						display: block;
						height: rem(23);
						width: rem(16);
						@include svg-background(
							$name: "padlock-icon-white",
							$size: contain
						);
						margin: auto;
						position: absolute;
						top: 0;
						bottom: 0;
						left: 0;
					}
				}

				&:hover,
				&:focus {
					span {
						&:before {
							@include svg-background(
								$name: "padlock-icon-shadow-green",
								$size: null
							);
						}
					}
				}
			}

			.express-checkout {
				padding: $twenty-gutter $fifteen-gutter 0;
				border-top: 1px solid $white;
				margin-top: $twenty-gutter;

				@include min-screen($screen__m) {
					padding-right: $twenty-gutter;
					padding-left: $twenty-gutter;
				}

				.express-checkout-title {
					@include type__19(
						$font-family: $font-family-serif,
						$letter-spacing: 1px
					)
				}
			}
		}
	}

	&-payment-list {
		display: flex;
		justify-content: center;
		margin-top: 1.25rem;

		svg {
			width: 45px;
			height: 27px;
		}
	}
}
