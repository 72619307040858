/* -------------------------------------------------
 * Error Variables
 * -------------------------------------------------
 *	1 Text
 *
 *	2 Borders
 *
 *	3 Spacing
 *	3.1 Main body text
 * 	3.2 Main body all
 *	3.3 between Social Media Buttons
 *
 *	4 Button Sizes
 * 
 * -------------------------------------------------
 * @author Fisheye
 */

//text colors
$error-header-color: $white;
$error-phone-color: $brand-accent;
$error-blue-background: $picton-blue;

//borders
$error-text-lines: .1px solid $error-header-color;
$error-home-button-border: 1px solid $error-header-color;
$error-button-hover-border: 1px solid $error-header-color;

//spacing
	//main body text
$error-paragraph-spacing: em(5);
$error-home-button-padding: em(8);
	//main body all
$error-title-spacing-top: $sixty-gutter;
$error-title-spacing-bottom: $gutter;
$error-footer-spacing: $forty-gutter;
	//between buttons
$error-social-media: em(0);

//button sizes
$error-social-icons-width: rem(40);
$error-social-icons-height: rem(40);
