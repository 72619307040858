/* -------------------------------------------------
 * -------------------------------------------------
 * General Tables Styling
 *
 * Used mostly for bluefoot content. things like size guides,
 * Delivery options etc.
 *
 * TODO: Add responsiveness applied to account tables?
 * -------------------------------------------------
 * @author Fisheye
 */

.bluefoot-textarea,
.delivery-returns-modal {
	table {
		margin-top: $item-gutter;

		tbody {
			border-bottom: none;

			tr:nth-child(odd) {
				background-color: $container-background-color-alt;
			}

			th,
			td {
				text-align: left;
				padding-left: $item-gutter * 1.5;
				padding-right: $item-gutter * 1.5;
			}
			th {
				@include type__16;
				font-weight: $font-weight-bold;
			}
		}
	}
}
