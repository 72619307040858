/* -------------------------------------------------
 * -------------------------------------------------
 * Layout and general styles for checkout
 *
 * -------------------------------------------------
 * @author Fisheye
 */

.page-layout-checkout {
	display: flex;
	flex-direction: column;
	min-height: 100vh;

	.checkout-container {
		.authentication-wrapper {
			display: none;
		}

		@include min-screen($screen__m) {
			.opc-estimated-wrapper {
				display: none;
			}
		}

		&.welcome-step {
			.opc-estimated-wrapper {
				display: none;
			}
		}

		&:not(.welcome-step) {
			@include min-screen($screen__m) {
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
			}

			> [data-role="checkout-messages"]{
				flex: 0 1 100%;
			}

			.opc {
				&-wrapper {
					@include min-screen($screen__m) {
						flex: 1;
						width: calc(50% - #{$gutter});
						padding-right: $gutter;
						border-right: $checkout-methods-border;
						margin-right: $gutter;
					}

					@include min-screen($screen__ml) {
						flex: 0 1 57%;
						width: calc(60% - #{$gutter});
					}

					@include min-screen($screen__ll) {
						flex: 0 1 64%;
						width: calc(62% - #{$gutter});
					}
				}

				&-sidebar {
					@include min-screen($screen__m) {
						flex: 1;
					}
				}
			}
		}
	}

	&.multishipping-checkout-addresses,
	&.multishipping-checkout-shipping,
	&.multishipping-checkout_address-newshipping,
	&.multishipping-checkout-billing,
	&.multishipping-checkout-overview {
		.main {
			padding: rem(22) $page-padding-mobile;
		}
	}

	.main {
		padding: 0; // Remove unrequired global gutters

		@include min-screen($screen__m) {
			padding: rem(48) $page-padding;
		}

		.opc-wrapper {
			padding: rem(22) $page-padding-mobile;

			@include min-screen($screen__m) {
				padding: 0;
			}
		}
	}

	.opc {
		&-wrapper {
			width: 100%;
		}

		.current-user-info {
			display: flex;
			justify-content: space-between;
			margin: rem(6) 0 $twenty-gutter;

			@include min-screen($screen__m) {
				margin: $five-gutter 0 rem(27);
				justify-content: flex-start;
			}

			.content {
				display: block;
				padding-left: rem(37);
				position: relative;
				margin-right: rem(11);

				@include min-screen($screen__m) {
					margin-right: rem(18);
				}

				&:before {
					content: "";
					display: block;
					height: $gutter;
					width: $gutter;
					background-color: $shadow-green;
					@include svg-background(
						$name: "account-icon-white",
						$size: $fifteen-gutter
					);
					border-radius: 50%;
					margin: auto;
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
				}

				span {
					@include type__15(
						$color: $acapulco,
						$font-weight: $font-weight-semibold
					);

					@include min-screen($screen__m) {
						@include type__16(
							$color: null,
							$font-weight: null
						);
					}
				}

				.current-user {
					position: relative;
					bottom: rem(1);
				}
			}

			.action {
				@include type__15($line-height: 1);
				text-transform: capitalize;
				position: relative;
				bottom: rem(1);
				cursor: pointer;

				@include min-screen($screen__m) {
					@include type__16($line-height: 1);
				}

				&:before {
					content: "";
					height: 9px;
					width: 5px;
					display: inline-block;
					margin-right: rem(8);

					@include svg-background(
						$name: "arrow-right-mine-shaft",
						$size: contain
					);
				}

				&:hover,
				&:focus {
					color: $shadow-green;

					&:before {
						@include svg-background(
							$name: "arrow-right-shadow-green",
							$size: null
						);
					}
				}
			}
		}

		.block {
			&-welcome-step {
				width: 100%;
				max-width: rem(750);
				padding: rem(18) rem(21) $twenty-gutter rem(21);
				background-color: $gallery;
				margin: 0 auto;

				@include min-screen($screen__m) {
					padding: rem(33) rem(28) rem(28);
				}

				.step-title {
					font-style: italic;
					margin-bottom: rem(8);

					@include type__33(
						$font-family: $font-family-serif,
						$letter-spacing: 2px
					);

					@include min-screen($screen__m) {
						margin-bottom: $gutter;
						text-align: center;

						@include type__52(
							$font-family: $font-family-serif,
							$letter-spacing: 2px
						);
					}
				}

				.welcome-additional {
					.express-checkout {
						text-align: center;

						.express-checkout-title {
							@include type__19(
								$font-family: $font-family-serif,
								$letter-spacing: 1px
							);
							font-style: italic;
							text-transform: capitalize;
						}
					}
				}
			}
		}
	}

	@supports (display: flex) {
		@include max-screen($screen__m - 1) {
			.checkout-container {
				display: flex;
				flex-direction: column;
			}

			.opc-wrapper {
				order: 2;
			}

			.opc-sidebar {
				order: 1;
			}
		}
	}

	.subscriptions-notice {
		order: 4;
		margin-top: $twenty-gutter;
	}
}

//
// ordered items list styling (success page)
//-----------------
.checkout-success-order-items-wrapper {
	.order-title {
		display: none;

		@include min-screen($screen__m) {
			display: block;
		}

		span {
			// color: $count-color;
			color: $order-success-count-color;
		}
	}

	.table-wrapper {
		.table-order-items {
			tr {
				vertical-align: top;
				@include min-screen($screen__m) {
					vertical-align: middle;
				}
			}

			.photo {
				padding: 0;
				display: table-cell;
			}

			.name {
				display: inline-block;
				font-weight: $font-weight-normal !important; // Override defaul CSS
				font-size: rem(16) !important; // Override defaul CSS
				padding: 0;
				text-align: left;

				@include min-screen($screen__m) {
					display: block;
				}
			}

			.price,
			.qty,
			.subtotal {
				display: block;
				padding: 0;
				text-align: left;
			}

			.col {
				&.qty {
					// more specific to override other stylng
					text-align: left;
				}
			}

			.subtotal {
				.title {
					@extend %visuallyhidden;
				}
			}
			.name {
				.item-options {
					@include min-screen($screen__m) {
						dd {
							margin-top: $twenty-gutter;
						}
					}
				}

				dt {
					// usually a title saying "color" or "size"
					// if i use lib-visibility-hidden(); it still creates a margin which pushes content out of vertical aligment
					display: none;
				}
			}
		}
	}
}
