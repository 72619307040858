/* -------------------------------------------------
 * Components Variables
 * -------------------------------------------------
 * Table of contents
 * 1. - Tooltip Variables
 * 2. - Promo messages Variables
 * 3. - Toolbar Variables
 * 	3a - Toolbar flex orders
 *  3b. Toolbar Content Display Settings
 * 4. - Product Upsells
 * 5. - Cookie Consent
 * -------------------------------------------------
 * @author Fisheye
 */

// -------------------------------------------------
// 1. Tooltip Variables
// -------------------------------------------------
// Global controls for forms. If one form uses an alternate style, put it in a specfic file

$form-flex-direction: column; // Sets the direction. For labels to sit by the side of input, set to row
$form-label-flex: 0 1 100%; // Set to 0 1 100% when using flex-direction column and 0 1 30% for row
$form-input-flex: 0 1 100%; // Set to 0 1 70% when using flex-direction column and 0 1 30% for row
$form-input-flex-desktop: 0 1 80%;
$form-label-spacing: $item-gutter;

// -------------------------------------------------
// 2. Promo messages Variables
// -------------------------------------------------
// Global controls for promo messages (E.g. spend X more for free delivery)
// Specific styles must go in the relevant scss file

$promo-message-border: none;
$promo-message-color: $white;
$promo-message-inner-spacing: $ten-gutter $five-gutter;
$promo-message-text-transform: uppercase;
$free-delivery-message-margin-top: $item-gutter;

// -------------------------------------------------
// 3. Toolbar Variables
// -------------------------------------------------

$toolbar-container-spacing-mobile: $ten-gutter;
$toolbar-container-spacing: $ten-gutter $gutter;
$toolbar-container-border: 1px solid $black;
$toolbar-sorter-label-position-top: rem(12); // this is equal to field-padding as the label is set to position absolute to appear as if it is inside a select box
$toolbar-sorter-label-position-left: rem(14); // in some cases there may be a slight difference in size between field-padding and these vars as labels render differently to selects
$toolbar-sorter-option-left-spacing: em(65); // this makes enough space for the label to fit in the select
$toolbar-select-width: 222px; // this defines the flex basis of the selects
$toolbar-sorter-spacing: $twenty-gutter;// applied to the sort by select when on mobile to provide spacing
$toolbar-pagination-spacing: $twenty-gutter;
$toolbar-sorter-width: rem(320);
$toolbar-sorter-action-width: em(30);
$toolbar-sorter-action-height: em(30);

// -------------------------------------------------
// 3a. Toolbar Variables
// -------------------------------------------------

$toolbar-amount-flex-order: 2;
$toolbar-pagination-flex-order: 4;
$toolbar-limiter-flex-order: 3;
$toolbar-sorter-flex-order: 1;

// -------------------------------------------------
// 3b. Toolbar Content Display Settings
// -------------------------------------------------

 $toolbar-pagination-display-setting: flex; // pagination block either PLEASE NOTE - to make it visibile use display; flex
 $toolbar-toolbar-amount-display-setting: block;

// -------------------------------------------------
// 4. Breadcrumb Variables
// -------------------------------------------------

$breadcrumbs-display: block;
$breadcrumbs-display-desktop: block;
$breadcrumbs-border-bottom: 0;
$breadcrumbs-background-color: transparent;
$breadcrumbs-padding: $five-gutter $fifteen-gutter;
$breadcrumbs-padding-desktop: rem(9) $gutter;

// -------------------------------------------------
// 4. Product Upsells
// -------------------------------------------------

// Breakpoints
$product-upsells-breakpoints: (
	show-three: $screen__m, // Breakpoint for showing three products
	show-four: $screen__l, // Breakpoint for showing four products
	show-all: $screen__ll, // Breakpoint for showing all products
);

//General Styles
$upsells-container-padding: $gutter 0; // Padding of upsells container
$upsells-container-padding-desktop: $sixty-gutter 0; // Padding of upsells container
$upsells-title-alignment: center; // Text alignment of product upsells title
$upsells-title-color: $nobel; // Text colour of upsells block title
$upsells-title-font-weight: $font-weight-extrabold; // Font weight of upsells block title
$upsells-qty-display: none; // QTY ticker display
$upsells-item-padding: $twenty-gutter; // Padding around upsell list items

// -------------------------------------------------
// 5. Cookie Consent
// -------------------------------------------------

$cookie-content-inner-wrap-margin-top: $forty-gutter;
$cookie-content-header-padding: $twenty-gutter;
$cookie-content-header-border-bottom: 1px solid $border-color;
$cookie-content-title-text-transform: uppercase;
$cookie-content-footer-padding: $twenty-gutter;
$cookie-content-button-margin: $item-gutter;
$cookie-content-button-display: block;
$cookie-content-submit-button-width: auto;
$cookie-content-submit-button-mobile-display: block;
$cookie-content-submit-button-desktop-float: left;
$cookie-content-info-button-mobile-float: left;
$cookie-content-info-button-desktop-float: none;
$cookie-content-back-button-mobile-display: block;
$cookie-content-back-button-desktop-display: inline-block;
$cookie-content-consent-type-margin-bottom: $twenty-gutter;
$cookie-content-consent-text-margin-top: $item-gutter;
$cookie-content-consent-text-padding-left: calc(30px + #{$item-gutter}); // Total width of the checkboxes plus margin

// -------------------------------------------------
// 5. Modals
// -------------------------------------------------
$modal-slide__first__indent-left: 0;
