/* -------------------------------------------------
 * -------------------------------------------------
 * Countdown Clock Styling
 * -------------------------------------------------
 * @author Fisheye
 */

.pagebuilder-countdown-clock,
.product-countdown {
	.shipping-info-link span {
		@include type__13(
			$color: $white,
			$font-weight: $font-weight-medium
		);
	}

	.more {
		display: none;
		text-transform: none;

		@include min-screen($screen__m) {
			display: inline-block;
			margin-left: rem(8);
		}
	}
}

.countdown-clock {
	display: inline-block;
	color: $header-panel-color;
	margin: 0 rem(7);

	.time-remaining {
		color: $red-ribbon;
	}
}
