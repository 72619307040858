// loyalty points - gift card (Payment page)
//--------------------------------

.checkout-container .opc-wrapper {
	.additional-payment-options {
		display: flex;
		flex-flow: column wrap;

		@include min-screen($screen__xl) {
			flex-flow: row nowrap;
		}
	}

	.payment-option {
		position: relative;
		display: flex;
		flex-direction: column;
		align-self: baseline;
		width: 100%;
		border-bottom: 1px solid $mercury-light;
		padding: $checkout-promo-inner-spacing 0;
		text-transform: uppercase;

		@include min-screen($screen__xl) {
			flex: 1;
			padding: $checkout-promo-inner-spacing;
			border-top: 1px solid $mercury-light;
		}

		&:first-child {
			border-top: 1px solid $mercury-light;
		}

		&:not(:first-child) {
			@include min-screen($screen__xl) {
				border-left: 1px solid $mercury-light;
			}
		}

		.payment-option-title {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin: 0;
			padding: 0;
			cursor: pointer;

			> span {
				@include type__14(
					$font-weight: $font-weight-semibold,
					$line-height: 1
				);
			}

			&:after {
				content: '';
				display: inline-block;
				height: 10px;
				width: 10px;

				@include svg-background(
					$name: 'plus',
					$size: 100% 100%
				);
			}
		}

		&:first-child {
			&._active {
				.payment-option-content {
					padding-left: 0;
				}
			}
		}

		&._active {
			.payment-option-title {
				border-bottom: 0;

				&:after {
					@include svg-background('minus');
				}
			}

			.payment-option-content {
				margin: 0;
				padding-top: $checkout-promo-inner-spacing;
			}
		}
	}

	.giftcardaccount,
	.discount-code,
	.userewardpoints,
	.customerbalance {
		&.choice {
			background-color: $trust-builder-background;
		}

		.payment-option-inner {
			width: 100%;

			label {
				display: none;
			}

			.field {
				margin: 0;

				// Custom breakpoint used for Promo Code & Gift Card toggles as this is the point where
				// 'Enter Promo Code' breaks onto the next line/ becomes too squashed & unreadable
				@include min-screen($screen__xl) {
					margin-bottom: rem(3);
				}
			}
		}

		.payment-option-content {
			margin-top: rem(14);

			.messages {
				.message {
					div {
						text-transform: none;
					}
				}

				.message-success {
					div { // no class or id
						margin-bottom: $ten-gutter;
						@include type__13(
							$color: $dell,
							$font-weight: $font-weight-semibold
						);
					}
				}
			}

			form {
				padding: 0;
				border-bottom: 0;
				display: flex;
				position: relative;

				.control {
					display: flex;
					flex-wrap: wrap;

					.input-text {
						@include input-style-one;
						flex: 1 1 100%;
						padding-right: $forty-gutter;

						&::placeholder {
							@include type__16($font-weight: $font-weight-normal);
							text-transform: inherit;
						}

						&.mage-error {
							margin: 0;
						}
					}
				}

				.actions-toolbar {
					button {
						@extend %cancel-button;
						@include svg-background(
							$name: "arrow-right-mine-shaft",
							$size: auto 14px
						);
						height: 14px;
						width: 14px;
						padding: 0;
						position: absolute;
						right: rem(14);

						span {
							@extend %visuallyhidden;
						}

						&.action-apply {
							&:after {
								content: '';
								display: inline-block;
								position: relative;
								height: 12px;
								width: 12px;
								top: rem(5);
								@include svg-background('arrow-right-mine-shaft');
							}
						}

						&.action-cancel {
							&:after {
								content: '';
								display: inline-block;
								position: relative;
								height: 12px;
								width: 12px;
								top: rem(5);

								@include svg-background(
									$name: "cross",
									$size: rem(7)
								);
							}
						}
					}
				}

				.secondary {
					display: none; // Not normally used but could be useful in the future
				}
			}
		}
	}

	.discount-code {
		.payment-option-content {
			form {
				.actions-toolbar {
					.primary {
						width: 0; // Remove the space after the promo code field
					}

					button {
						height: $gutter * 1.33;

						&.action-apply {
							&:after {
								display: none; // Hide the duplicated arrow image
							}
						}
					}
				}
			}
		}
	}

	.giftcardaccount {
		padding-right: 0;
	}

	.discount-code {
		padding-left: 0;
	}

	.rewardpoints {
		margin-bottom: $checkout-promo-outer-spacing;

		.payment-option-title {
			@include type__30;
		}

		.payment-option-content {
			display: $checkout-promo-reward-point-content-display;

			@include min-screen($screen__l) {
				white-space: $checkout-promo-reward-point-content-white-space;
			}

			.payment-option-inner,
			.actions-toolbar {
				display: $checkout-promo-reward-point-toolbar-display;
				width: $checkout-promo-reward-point-width;
			}

			.payment-option-inner {
				width: $checkout-promo-reward-point-inner-width;
			}

			.actions-toolbar {
				width:  100%;
				margin-left: $checkout-promo-reward-point-toolbar-left-margin;

				@include min-screen($screen__l) {
					width: $checkout-promo-reward-point-toolbar-width;
				}

				.primary {
					button {
						@include button__default;
						margin-top: $item-gutter;

						@include min-screen($screen__l) {
							margin-top: 0;
							display: $checkout-promo-reward-point-inner-button-display;
							margin-left: $checkout-promo-reward-point-inner-button-margin-left;
						}
					}
				}
			}
		}
	}

	.userewardpoints {
		.actions-toolbar {
			margin-top: $checkout-promo-outer-spacing;
		}
	}

	// Store Credit
	// -----------------------
	.customerbalance {
		.payment-option-content {
			// This is to act as a toggle, to hide Store Credit content until
			// toggle is active.
			display: none;
			margin-bottom: 0;
		}

		&._active {
			.payment-option-content {
				// Show Store Credit content when active.
				display: block;
			}
		}

		.action-use {
			@include button__journey;
			@include type__22;
			margin-top: $fifteen-gutter;
			width: 100%;
		}
	}
}
