/* -------------------------------------------------
 * -------------------------------------------------
 * General layout of main check out steps
 *
 * -------------------------------------------------
 * @author Fisheye
 */

.opc-wrapper {
	// main list of steps in checkout
	.opc,
	.checkout-onepage-success {
		> li {
			.step-title {
				// repeated in checkout-payment-method to beat specificty
				margin-bottom: $fifteen-gutter;
				text-align: center;

				@include type__30(
					$font-family: $font-family-serif,
					$letter-spacing: 2px,
					$line-height: 1.06
				);
		
				@include min-screen($screen__m) {
					margin-bottom: rem(19);
					text-align: left;

					@include type__35(
						$font-family: null,
						$letter-spacing: 2px,
						$line-height: 1.06
					);
				}

				span {
					font-style: italic;
				}

				&._required {
					&:after {
						content: '*';
						display: inline-block;
						vertical-align: top;
						padding: em(3) 0;
						@include type__18(
							$color: $red-ribbon,
							$font-weight: $font-weight-semibold
						)
					}
				}
			}
		}

		.actions-toolbar {
			button.primary {
				@include button__journey();
				width: 100%;
	
				&,
				span {
					@include type__18(
						$color: null,
						$font-weight: null,
						$letter-spacing: null
					);
				}
			}
		}

		.secondary {
			display: flex;
			justify-content: center;
			margin-top: $twenty-gutter;

			@include min-screen($screen__m) {
				margin-top: rem(16);
			}

			.action {
				position: relative;
				@include type__15($line-height: 1);
				text-transform: capitalize;
				cursor: pointer;

				@include min-screen($screen__m) {
					@include type__16($line-height: 1);
				}

				&:before {
					content: "";
					display: inline-block;
					position: relative;
					height: 9px;
					width: 5px;
					margin-right: rem(3);

					@include min-screen($screen__m) {
						bottom: rem(1);
					}

					@include svg-background(
						$name: "arrow-right-mine-shaft",
						$size: contain
					);
				}

				&:hover,
				&:focus {
					color: $shadow-green;

					&:before {
						@include svg-background(
							$name: "arrow-right-shadow-green",
							$size: null
						);
					}
				}

				&:first-of-type {
					margin-right: rem(32);

					@include min-screen($screen__m) {
						margin-right: rem(28);
					}
				}
			}
		}

		.action-update,
		.action-cancel {
			@include button__default;
			margin-right: $twenty-gutter;
		}
	}
}
