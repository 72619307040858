/* -------------------------------------------------
 * -------------------------------------------------
 * Account Gift Card Page
 *
 * This is the page you are directed to from an email
 * providing you with a Gift Card Code
 * -------------------------------------------------
 * @author Fisheye
 */

.magento_giftcardaccount-customer-index {
	.giftcard-account {
		margin-top: $twenty-gutter;

		@include min-screen($screen__m) {
			margin-top: 0;
		}

		.form-giftcard-redeem {
			.field {
				margin-bottom: 0;
			}

			.actions-toolbar {
				flex-wrap: wrap;

				@include min-screen($screen__xs) {
					flex-wrap: nowrap;
				}

				* {
					width: 100%;

					@include min-screen($screen__xs) {
						width: auto;
					}
				}

				.secondary {
					@include min-screen($screen__xs) {
						margin-left: $twenty-gutter;
					}
				}
			}
		}
	}
}
