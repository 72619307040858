/* -------------------------------------------------
 * -------------------------------------------------
 * Frequently Bought page
 *
 * -------------------------------------------------
 * @author Fisheye
 */

.buy-again-list {
	padding: $fifteen-gutter 0 0;

	@include min-screen($screen__m) {
		padding: 0;
	}

	.product-listing-container {
		display: flex;
		flex-wrap: wrap;

		.product-listing {
			flex: 0 1 100%;
			padding-top: 0;

			@include min-screen($screen__xs) {
				flex: 0 1 50%;
			}

			@include min-screen($screen__l) {
				padding-top: 0;
				flex: 0 1 33.33%;
			}

			@include min-screen($screen__xl) {
				flex: 0 1 25%;
			}

			.product-item-info {
				display: flex;
				flex-flow: row wrap;

				.product-item-photo {
					flex: 1 1 100%;

					.image-wrapper {
						.product-image-wrapper {
							max-width: 250px;

							img {
								object-fit: contain;
								height: auto;
								width: 100%;
								padding: 0;

								@include min-screen($screen__xl) {
									padding: 0 0 $ten-gutter 0;
								}
							}
						}
					}
				}

				.product-item-details,
				.price-box {
					margin-top: 0;
					width: 100%;
				}

				.product-item-details {
					display: flex;
					flex-direction: column;
					justify-content: space-between;
				}

				.product-item-name {
					line-height: 1.5;
				}

				.product-item-inner {
					min-height: auto;
				}

				.product-item-details {
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					flex: 1 1 100%;

					.product-price-tooltip {
						display: flex;
						flex-wrap: wrap;
						flex: 1 1 100%;
						margin-top: rem(8);
						align-items: center;

						.dietary-tooltip-wrapper {
							flex: 1;
							justify-content: flex-end;
						}
					}
				}

				.product-item-name {
					line-height: 1.5;
				}

				.product-item-inner {
					min-height: auto;
				}
			}
		}

		.order-date {
			display: none;
		}

		a.product-item-link {
			flex: 1 1 100%;
			min-height: none;

			@include min-screen($screen__xs) {
				min-height: 50px;
			}
		}
	}
}
