/* -------------------------------------------------
 * Checkout Variables
 * -------------------------------------------------
 * Table of contents
 * 1. - Checkout Header Variables
 *	- 1a. - Paddings & Margins
 *	- 1b. - Flex Values
 *	- 1c. - Flex Orders
 *	- 1d. - Backgrounds & Colours
 *
 * 2. - Checkout Sidebar Variables
 * -------------------------------------------------
 * @author Fisheye
 */

// -------------------------------------------------
// 1. Checkout Header Variables
// -------------------------------------------------

// 1a. Checkout Step Counters
$checkout-steps-width: rem(30);
$checkout-steps-height: rem(30);
$checkout-steps-line-height: 1.6;
$checkout-progress-bar-flex: 0 1 30%;
$checkout-steps-color: $body-font-color;
$checkout-steps-inactive-color: 0.3;
$checkout-steps-border-width: 2px;
$checkout-steps-padding: 0 $page-gutter;
$checkout-steps-complete-icon-background-size: em(17);

// 1b. Header positions, widths, heights, margins, paddings
$checkout-header-height: 90px;
$checkout-header-padding: $fifteen-gutter rem(28) rem(14);
$checkout-header-mobile-padding: rem(13) 0 rem(11);
$checkout-mobile-header-height: 55px;
$checkout-secure-icon-margin-right: $five-gutter;

// 1c. Flex Values & Orders
$checkout-logo-flex: 0 1 50%; // Flex value e.g. 1 0 50%.
$checkout-secure-flex: 0 1 50%; // Flex value e.g. 1 0 50%.

$checkout-header-burger-flex-order: 1;
$checkout-header-logo-flex-order: 2;
$checkout-header-steps-flex-order: 1;
$checkout-header-secure-flex-order: 4;

$checkout-mobile-header-burger-flex-order: 1;
$checkout-mobile-header-logo-flex-order: 2;
$checkout-mobile-header-steps-flex-order: 3;
$checkout-mobile-header-secure-flex-order: 2;

// 1d. Background, colours and borders
$checkout-header-background: $white;
$checkout-secure-color: $body-font-color;
$checkout-secure-icon-fill: $silver-chalice;
$checkout-promo-background: $grey-lightest;
$checkout-success-banner-background: $brand-accent;
$checkout-success-background: $grey-lightest;
$checkout-success-order-totals-background: $grey-lightest;
$checkout-header-border: 1px solid $mercury-light;

// -------------------------------------------------
// 2. Checkout Sidebar Variables
// -------------------------------------------------

// 2a. Sidebar activator button on mobile
$checkout-sidebar-mobile-button-background: transparent; // Background of the button that activates the modal on mobile
$checkout-sidebar-mobile-button-padding: $twenty-gutter $small-gutter; // Padding of the button that activates the modal on mobile
$checkout-sidebar-mobile-qty-right: 40px; // Right position of the QTY count number
$checkout-sidebar-mobile-icon-right: 30px; // Right position of the SVG icon (Usually the bag icon, could also be a basket or trolley)
$checkout-sidebar-mobile-icon-top: 13px; // Top position o the SVG icon

// 2b. Sidebar spacing
$checkout-sidebar-totals-spacing: $twenty-gutter;

// 2c. Sidebar backgrounds, colours & borders
$checkout-sidebar-background-color: transparent;
$checkout-sidebar-price-color: $red-ribbon;

// -------------------------------------------------
// 3. Checkout Form Variables
// -------------------------------------------------

$checkout-form-spacing: rem(21); // General spacing around the form not covered by field spacing
$checkout-form-spacing-mobile: $twenty-gutter 0 rem(14); // General spacing around the form not covered by field spacing
$checkout-field-spacing: $gutter;
$checkout-field-spacing-mobile: $twenty-gutter;

// -------------------------------------------------
// 4. Misc Checkout Variables
// -------------------------------------------------

$checkout-step-title-color: $header-font-color;
$checkout-step-title-padding: $checkout-form-spacing 0;
$checkout-step-title-mobile-padding: $checkout-form-spacing-mobile 0;

// -------------------------------------------------
// 5. Checkout Layout Variables
// -------------------------------------------------

$checkout-main-width: 50%;
$checkout-sidebar-width: 50%;

// -------------------------------------------------
// 6. Checkout Footer Variables
// -------------------------------------------------

$checkout-footer-trust-icon-width: 49px;
$checkout-footer-trust-icon-height: 30px;
$checkout-footer-trust-icon-spacing: $ten-gutter; // Space below the trust building icon in the footer
$checkout-footer-trust-mobile-top-padding: $gutter; // Single value only (This is used for padding-top and padding-bottom)
$checkout-footer-trust-top-padding: $fifty-gutter; // Desktop version of the above
$checkout-footer-trust-side-padding: $item-gutter;
$checkout-footer-trust-row-background: $mercury;
$checkout-footer-payment-row-border: 1px solid $border-color;
$checkout-footer-payment-row-background: $mercury;
$checkout-footer-payment-row-text: 'Safe Shopping';
$checkout-footer-row-payment-padding: $small-gutter;
$checkout-footer-row-payment-mobile-padding: $ten-gutter;
$checkout-footer-phone-display-braces: true;

// -------------------------------------------------
// 7. Checkout Delivery Address Selection Variables
// -------------------------------------------------

$checkout-delivery-address-selected: $red-ribbon;
$checkout-delivery-address-deselected: $silver;
$add-new-delivery-address-color: $brand-accent;
$order-success-count-color: $brand-accent;
$checkout-delivery-address-spacing: $gutter; // The space (padding) around the address lines
$checkout-delivery-address-button-spacing: $twenty-gutter; // The space (padding) around the address lines
$checkout-delivery-address-selected-icon-width: em(17);
$checkout-delivery-address-selected-icon-height: em(13);

// -------------------------------------------------
// 8. Checkout Payment Methods Variables
// -------------------------------------------------

$checkout-methods-outer-spacing: $gutter; // The spacing (padding & margin) below between each payment method
$checkout-methods-inner-spacing: $gutter; // The inner spacing (padding) for payment methods
$checkout-methods-border: 1px solid $mercury-light;
$checkout-methods-address-indent: $checkbox-width + $radio-margin-right; // The left padding on the address for card payment method to indent under the checkbox. Should be a static px value totalling the checkbox width + margin
$checkout-methods-hosted-field-height: rem(40); // The height of fields within an iframe. E.g. braintree fields

// -------------------------------------------------
// 9. Checkout Promo Area Variables
// -------------------------------------------------

$checkout-promo-outer-spacing: $gutter; // Spacing (margin) between the promo methods
$checkout-promo-inner-spacing: rem(16); // Inner spacing (padding) for the promo boxes

// Reward Point Variables
// -------------------------------------------------
$checkout-promo-reward-point-content-display: block;
$checkout-promo-reward-point-content-white-space: nowrap;
$checkout-promo-reward-point-toolbar-display: inline-block;
$checkout-promo-reward-point-toolbar-left-margin: -4px; //remove whitespace
$checkout-promo-reward-point-toolbar-width: 35%;
$checkout-promo-reward-point-width: 100%;
$checkout-promo-reward-point-inner-width: 65%;
$checkout-promo-reward-point-inner-button-display: block;
$checkout-promo-reward-point-inner-button-margin-left: auto;

// -------------------------------------------------
// 10. Checkout Success Page Variables
// -------------------------------------------------

$checkout-success-page-gutter: $gutter; // General use gutter spacing for the page

// 10a. Checkout success message
$checkout-success-message-background: $silver-chalice; // Background colour of the success message banner
$checkout-success-message-inner-spacing: $gutter; // Spacing (padding) of the success message banner
$checkout-success-message-outer-spacing: $gutter; // Spacing on the outside of the message box (margin)
$checkout-success-message-text-align: center; // Text alignment for the success message banner
$checkout-success-heading-color: $mine-shaft; // Font color of order success heading
$checkout-success-text-color: $mine-shaft; // Font color of order success heading

// 10b. Checkout success address area
$checkout-success-address-border: 1px solid $silver-chalice; // The borders used for the address areas
$checkout-success-address-inner-spacing: $gutter 0 rem(25); // Inner spacing for the address section (padding)
$checkout-success-address-inner-spacing-mobile: $twenty-gutter 0; //Inner spacing for the address section on mobile (padding)

// 10c. Checkout success registration block
$checkout-success-registration-background: $gallery;
$checkout-success-registration-inner-spacing: $gutter; // Spacing (padding) of the success message banner
$checkout-success-registration-tick-spacing: em(10); // Spacing (margin) between the tick and the list item text

// 10d. Checkout success products table
$checkout-success-table-border: 1px solid $silver-chalice; // Used for all borders in the table area except the dividing border between products
$checkout-success-table-product-border: 1px solid $border-color-alt2; // Used for the dashed border which divides products
$checkout-success-table-title-spacing: $gutter; // Inner spacing for order success products table
$checkout-success-table-inner-spacing: $forty-gutter 0; // Inner spacing for order success products table

// 10e. Checkout success totals table
$checkout-success-totals-inner-spacing: $twenty-gutter;
$checkout-success-totals-method-font-style: normal; // The font style of the checkout method (E.g. flat rate, card, paypal)
