/* -------------------------------------------------
 * -------------------------------------------------
 * Specific Styling to Blog Categories
 *
 * Most styles should be in relevant stylesheet i.e. buttons.scss
 *
 * -------------------------------------------------
 * @author Fisheye
 */

// -------------------------------------------------
// Blog Category & Post Pages
// Styles that are required on both blog pages
// -------------------------------------------------
.blog-page-list {
	.breadcrumbs {
		@extend %visuallyhidden;
	}

	.columns {
		display: flex;

		@include min-screen($screen__m) {
			flex-direction: column;
		}

		@include min-screen($screen__l) {
			flex-direction: row;
		}

		// -----------------------------------------------
		// Blog post list items displayed on category page
		// & related blog post styles on article page
		// -----------------------------------------------
		.post-list {
			.post-holder {
				padding: 0 0 $fifteen-gutter;
				border-bottom: 1px solid $mercury;
				margin: 0 0 $fifteen-gutter;

				&:nth-child(n+12) {
					border-bottom: 0;
				}

				@include min-screen($screen__m) {
					padding: 0 $fifteen-gutter $gutter;
					margin-bottom: $gutter;

					&:nth-child(n+11) {
						border-bottom: 0;
					}
				}

				@include min-screen($screen__l) {
					&:nth-child(n+10) {
						border-bottom: 0;
					}
				}

				@include min-screen($screen__xl) {
					&:nth-child(n+9) {
						border-bottom: 0;
					}
				}

				&:nth-of-type(odd) {
					@include max-screen($screen__l - 1) {
						padding-left: 0;
					}
				}

				&:nth-of-type(even) {
					@include max-screen($screen__l - 1) {
						padding-right: 0;
					}
				}

				&:nth-of-type(3n + 1) {
					@include screen($screen__l, ($screen__xl - 1))  {
						padding-left: 0;
					}
				}

				&:nth-of-type(3n) {
					@include screen($screen__l, ($screen__xl - 1))  {
						padding-right: 0;
					}
				}

				&:nth-of-type(4n + 1) {
					@include min-screen($screen__xl)  {
						padding-left: 0;
					}
				}

				&:nth-of-type(4n) {
					@include min-screen($screen__xl)  {
						padding-right: 0;
					}
				}
			}

			.post-categories {
				margin-bottom: $five-gutter;

				.label,
				a {
					@include type__14(
						$color: $boulder
					);
				}

				.label {
					align-items: baseline;
					display: inline-flex;

					&:after {
						height: rem(9);
						width: rem(5);
					}
				}
			}

			.post-description {
				p {
					display: -webkit-box;
					-webkit-line-clamp: 3;
					-webkit-box-orient: vertical;
					overflow: hidden;

					&:not(:first-of-type) {
						display: none;
					}
				}

				// Adding style consistencies here - due to transfer of blog posts and original m1 formatting
				.post-text-hld {
					display: -webkit-box;
					-webkit-line-clamp: 3;
					-webkit-box-orient: vertical;
					overflow: hidden;

					blockquote {
						margin: 0;
					}

					h1 {
						@include type__20;
						line-height: normal;
					}

					h2 {
						@include type__19;
						line-height: normal;
					}

					h3 {
						@include type__18;
						line-height: normal;
					}

					p {
						@include type__16;
						text-align: left !important; //Override previous inline styles when they added blog posts
					}

					strong {
						font-weight: normal !important;
					}
				}
			}
		}
		// ----------------------------
		// End of styles for post lists
		// ----------------------------
	}
}
