/* -------------------------------------------------
 * -------------------------------------------------
 * Products when in basket
 *
 * This should be reusable in chekout summary too.
 *
 * -------------------------------------------------
 * @author Fisheye
 */

.checkout-cart-index {
	.cart.items.table {
		@include min-screen($screen__m) {
			margin-top: $forty-gutter;
		}

		.block-cart-item {
			border-bottom: $checkout-success-table-product-border;
			position: relative;

			&.updating,
			&.removing {
				&::after {
					@extend %element-loader;
					content: '';
				}

				.cart-item-row {
					opacity: 0.2;
					pointer-events: none;
				}
			}

			.cart-item-row {
				display: flex;
				justify-content: space-between;
				padding: $fifteen-gutter 0;
				position: relative;

				@include max-screen($screen__m - 1) {
					align-items: center;
					flex-wrap: wrap;
				}

				@include min-screen($screen__m) {
					padding: $twenty-gutter 0;
				}

				.col,
				.cart-item-col {
					&.photo,
					&.details,
					&.cart-item-action,
					&.qty,
					&.price,
					&.subtotal {
						@include min-screen($screen__m) {
							display: flex;
							align-items: center;
						}
					}

					&.photo {
						order: 0;

						@include max-screen($screen__m - 1) {
							flex: 0 1 rem(80);
						}

						@include min-screen($screen__m) {
							flex: 0 1 rem(105);
						}

						.product-image-container,
						.product-image-wrapper {
							display: block;

							@include max-screen($screen__m - 1) {
								width: rem(80) !important; // Important to override inline styles
							}
						}

						.product-image-wrapper {
							padding-bottom: 0 !important; // Important to override inline padding
						}

						.product-image-photo {
							width: 100%;
						}
					}

					&.details {
						order: 1;
						flex-wrap: wrap;
						align-self: center;

						@include max-screen($screen__m - 1) {
							flex: 0 1 calc(100% - #{rem(160)});
							padding: 0 $gutter 0 $fifteen-gutter;
						}

						@include min-screen($screen__m) {
							flex: 0 1 calc(100% - 45% - #{rem(185)});
							padding-right: $ten-gutter;
							padding-left: $twenty-gutter;
						}

						.product-item-name {
							@include type__15;

							@include min-screen($screen__m) {
								@include type__16;
							}
						}

						dd {
							font-weight: $font-weight-semibold;
						}

						.cart-item-info,
						> a {
							flex: 0 1 100%;

							.item-options {
								@include min-screen($screen__m) {
									padding-right: $twenty-gutter;
								}

								.toggle {
									.show-options {
										font-weight: $font-weight-semibold;
										cursor: pointer;

										&:after {
											content: '';
											display: inline-block;
											height: 10px;
											width: 13px;
											margin-left: $five-gutter;

											@include svg-background(
												$name: 'arrow-down',
												$size: contain
											);
										}
									}
								}

								&.active {
									.option-content {
										display: inline-block;
										margin-top: $five-gutter;

										.price {
											display: inline-block;
											margin-bottom: $ten-gutter;
										}
									}

									.show-options {
										&:after {
											@include svg-background('arrow-up');
										}
									}
								}

								dt {
									@include type__14;
									font-weight: $font-weight-semibold;
								}

								.option-content {
									display: none;
								}
							}
						}
					}

					&.cart-item-action {
						flex: 0 1 rem(80);
						order: 2;
						text-align: right;

						@include min-screen($screen__m) {
							order: 5;
						}

						.action-edit,
						.action-towishlist {
							display: none;
						}

						.action-delete {
							display: inline-block;

							button,
							span {
								padding: 0 0 0 rem(23);
								background: none;
								border: none;
								border-radius: 0;
								position: relative;
								@include type__13($font-weight: $font-weight-normal);
								text-transform: uppercase;

								&:before {
									content: "";
									display: block;
									height: rem(18);
									width: rem(17);
									@include svg-background(
										$name: "trashcan-icon",
										$size: contain
									);
									margin: auto;
									position: absolute;
									top: 0;
									bottom: 0;
									left: 0;
								}
							}
						}
					}

					&.qty,
					&.price,
					&.subtotal {
						@include max-screen($screen__m - 1) {
							margin-top: $fifteen-gutter;
						}

						@include min-screen($screen__m) {
							flex: 0 1 15%;
							padding: 0 $ten-gutter;
							position: relative;
						}

						&:before {
							@include min-screen($screen__m) {
								position: absolute;
								top: -$fifty-gutter;
								left: $ten-gutter;
							}
						}
					}

					&.qty {
						order: 4;

						@include max-screen($screen__m - 1) {
							flex-grow: 1;
							padding-right: $gutter;
						}

						@include min-screen($screen__m) {
							align-items: unset;
							flex-direction: column;
							justify-content: center;
							order: 3;
						}

						&:before {
							@include min-screen($screen__m) {
								content: attr(data-th);
								display: block;
								margin-bottom: $five-gutter;
								@include type__16(
									$font-weight: $font-weight-semibold
								);
							}
						}

						.label {
							display: none;
						}

						.control {
							display: flex;

							@include max-screen($screen__m - 1) {
								justify-content: flex-end;
							}
						}

						input {
							@include input-style-five;
							line-height: 0; // vertically align input on safari
						}

						.cart-product-actions {
							display: none;
							width: 100%;
							margin-top: $five-gutter;
							text-align: right;
							padding-right: $ten-gutter;

							@include min-screen($screen__m) {
								text-align: left;
								margin-left: $ten-gutter;
								padding-right: 0;
							}

							.update {
								@include button__journey(
									$height: auto,
									$padding: rem(2) rem(7) rem(7)
								);

								span {
									@include type__13(
										$color: $white,
										$line-height: 1
									);
								}
							}
						}
					}

					&.price,
					&.subtotal {
						&:before {
							content: attr(data-th);
							display: block;
							margin-bottom: $five-gutter;
							@include type__16(
								$font-weight: $font-weight-semibold
							);
						}
					}

					&.price {
						order: 3;

						@include min-screen($screen__m) {
							order: 2;
						}
					}

					&.subtotal {
						order: 5;

						@include min-screen($screen__m) {
							order: 4;
						}

						.label {
							display: none;
						}
					}

					.gift-options-cart-item {
						@extend %visuallyhidden;
					}
				}
			}

			&:not(:first-of-type) {
				.cart-item-row {
					.col,
					.cart-item-col {
						&.qty,
						&.price,
						&.subtotal {
							&:before {
								@include min-screen($screen__m) {
									display: none;
								}
							}
						}
					}
				}
			}

			&:first-of-type {
				@include min-screen($screen__m) {
					border-top: $checkout-success-table-product-border;
				}
			}
		}
	}
}
