/* -------------------------------------------------
 * -------------------------------------------------
 * Gift wrap
 * -------------------------------------------------
 * @author Fisheye
 */

.gift-services-container {
	.current-message {
		background-color: $gallery;
		padding: $twenty-gutter;
		margin-top: $ten-gutter;

		.gift-summary-wrapper > {
			.title {
				@include type__16($font-weight: $font-weight-semibold);
				margin-bottom: $ten-gutter;
				text-transform: uppercase;
			}
		}
	}
}

.gift-message-summary {
	.title {
		font-weight: $font-weight-semibold;
	}

	.message-pre {
		display: block;
		margin-top: $ten-gutter;
		white-space: pre-line;
		word-break: break-all;
	}

	&:not(:last-child) {
		margin-bottom: $ten-gutter;
	}
}
