/* -------------------------------------------------
 * -------------------------------------------------
 * Product upsells
 *
 * -------------------------------------------------
 * @author Fisheye
 */

.block-products-list,
.block.related,
.block.upsell,
.block-viewed-products-grid {
	padding: $upsells-container-padding;

	@include min-screen($screen__m) {
		padding: $upsells-container-padding-desktop;
	}

	.block-title {
		strong {
			display: block;
			text-align: center;
			text-transform: uppercase;
			padding-bottom: rem(36);
			@include text-crop;

			@include type__22(
				$color: $upsells-title-color,
				$font-weight: $upsells-title-font-weight
			);

			@include min-screen($screen__m) {
				padding-bottom: rem(77);

				@include type__35(
					$color: $upsells-title-color,
					$font-weight: $upsells-title-font-weight
				);
			}
		}
	}

	.block-actions {
		display: none; // remove 'check items to add to basket' text
	}

	.upsell-categories {
		display: none; // remove 'components' text
	}

	.products-grid {
		@include max-screen($screen__ml - 1) {
			margin-right: rem(-7.5);
			margin-left: rem(-7.5);
		}

		@include min-screen($screen__ml) {
			margin-right: -$fifteen-gutter;
			margin-left: -$fifteen-gutter;
		}

		.product-item {
			@include max-screen($screen__ml - 1) {
				padding: 0 rem(7.5);
			}

			@include min-screen($screen__ml) {
				padding: 0 $fifteen-gutter;
			}
		}

		.product-item-info {
			flex-direction: column;
			flex-wrap: nowrap;

			.product-item-photo {
				display: block;

				span {
					display: block;
					height: auto;
				}

				.product-image-container {
					width: 100% !important; // To override inline styles
				}

				.product-image-photo {
					width: 100%;
				}

				.product-short-desc {
					@include max-screen($screen__ll - 1) {
						display: none;
					}

					@include min-screen($screen__ll) {
						display: flex;
						align-items: center;
						padding: $gutter;
						background: rgba($color: $mine-shaft, $alpha: 0.7);
						margin: auto;
						position: absolute;
						top: 0;
						right: 0;
						bottom: 0;
						left: 0;
						@include type__17(
							$color: $white,
							$font-family: $font-family-serif,
							$letter-spacing: 1px
						);
						font-style: italic;
						opacity: 0;
						@include transition(opacity 0.4s ease-in-out);
					}
				}

				&:hover,
				&:focus {
					&,
					.product-short-desc {
						opacity: 1;
					}
				}
			}
		}

		.product-item-details {
			margin-top: $twenty-gutter;
			text-align: left;
			display: flex;
			flex-wrap: wrap;
			min-height: rem(100);
			flex: 1 1 100%;
			justify-content: space-between;

			.product-item-name {
				margin: 0 0 $fifteen-gutter;

				@include min-screen($screen__ll) {
					min-height: rem(40);
				}

				a {
					@include type__17;
					text-transform: capitalize;

					@include min-screen($screen__ml) {
						@include type__16;
					}
				}
			}

			.product-item-inner {
				display: none;
			}
		}

		.slick-dots {
			text-align: center;
		}
	}
}

.block-products-list {
	padding: $small-gutter 0 $gutter;

	@include min-screen($screen__m) {
		padding: $upsells-container-padding-desktop;
	}

	.slick-track {
		display: flex !important; // Override inline style
	}

	.slick-slide {
		height: auto;

		> div {
			height: 100%;
		}
	}

	.product-item {
		padding: $fifteen-gutter rem(12);
		height: 100%;

		@include min-screen($screen__m) {
			height: auto;
		}
	}
}


// -------------------------------------------------
// Recently Viewed Products
// -------------------------------------------------

.block.block-viewed-products-grid {
	padding: rem(36) $fifteen-gutter / 2;

	@include min-screen($screen__m) {
		padding: rem(70) $fifteen-gutter;
	}

	.product-item {
		display: flex;
		justify-content: center;
		padding: 0;

		@include max-screen($screen__xs - 1) {
			display: flex !important; // Overrides a style found above, which itself overrides inline styles
		}

		.product-item-info {
			padding: 0 $fifteen-gutter / 2;
			flex: $products-viewed-product-item-flex;

			@include min-screen($screen__m) {
				padding: 0 $fifteen-gutter;
			}

			&:nth-child(n+4) {
				display: none; // Show first three products

				@include min-screen($screen__l) {
					&:nth-child(n+7) {
						display: flex; // Show first four products
					}
				}

				@include min-screen($screen__xxl) {
					display: flex; // Show all products
				}
			}

			.product-item-photo {
				background-color: $white;

				.product-image-photo {
					margin: auto;
				}
			}

			.product-item-details {
				margin-top: $twenty-gutter;
				padding-top: $ten-gutter;
				min-height: rem(100);

				.product-item-name {
					margin: 0;
					margin-bottom: rem(9);
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
					overflow: hidden;
					min-height: rem(40);

					@include min-screen($screen__ll) {
						min-height: rem(40);
					}

					a {
						@include type__17;
						text-transform: capitalize;

						@include min-screen($screen__ml) {
							@include type__16;
						}
					}
				}

				.price-box {
					min-height: rem(40);
				}

				.description,
				.box-tocart {
					display: none; // Hide product description and add to cart elements
				}
			}
		}
	}
}

.block.upsell,
.block.crosssell,
.block.related {
	.product-item-details {
		display: flex;
		flex-flow: row wrap;

		&.product {
			.price-box {
				flex: 0 1 auto;

				.price-container {
					.price-label {
						padding-right: $five-gutter;
					}
				}

				.field.choice.related {
					display: none;
				}
			}

			.dietary-tooltip-wrapper {
				margin: 0;
				display: flex;
				align-items: flex-end;
				position: absolute;
				top: 0;
				right: 0;
			}

			.price-box,
			.dietary-tooltip-wrapper {
				margin: 0;
				display: flex;
				height: rem(25);
				flex-direction: row;
				justify-content: flex-start;
				align-items: center;
			}

			.product-item-name {
				a {
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
					overflow: hidden;
				}
			}
		}
	}
}


// -------------------------------------------------
// Up Sell Products
// -------------------------------------------------

.block.upsell {
	border: none;

	h2 {
		margin-bottom: rem(26);
	}

	.products {
		&.products-grid {
			padding: 0 $ten-gutter 0 calc(#{$fifteen-gutter} / 2);
			margin: 0;

			@include min-screen($screen__m) {
				padding: 0 $fifteen-gutter;
			}

			.product-items {
				flex-direction: column;
				display: none; // Hide until page loaded

				&.slick-initialized {
					display: flex; // Unhide when page loaded & slick initialised
				}
			}

			.slick-track {
				&,
				.slick-slide,
				.slick-slide > div {
					display: flex;
				}

				.slick-slide {
					height: auto;
					float: none;
				}

				.product-item {
					display: flex !important; // To override inline styles
				}
			}
		}

		.product-item-details {
			margin-top: $twenty-gutter;
			text-align: left;

			.product-item-name {
				margin: 0;
				margin-bottom: rem(9);

				@include min-screen($screen__ll) {
					min-height: rem(40);
				}

				a {
					@include type__15;
					text-transform: capitalize;

					@include min-screen($screen__ml) {
						@include type__16;
					}
				}
			}

			.dietary-tooltip-wrapper {
				position: relative;
			}

			.product-item-actions {
				width: 100%;
			}
		}
	}
}


// -------------------------------------------------
// Cross Sell Products
// Can be found on pages such as the basket
// -------------------------------------------------

.block.crosssell {
	margin-top: $gutter;

	@include min-screen($screen__m) {
		padding: $gutter;
		border: 1px solid $gallery;
	}

	.block-title {
		margin-bottom: $twenty-gutter;
		text-align: center;

		@include min-screen($screen__ml) {
			margin-bottom: $gutter;
		}

		strong {
			@include type__33(
				$font-family: $font-family-serif,
				$letter-spacing: 1px
			);

			@include min-screen($screen__ml) {
				@include type__35(
					$font-family: null,
					$letter-spacing: 1px
				);
			}

			span {
				font-style: italic;
			}
		}
	}

	.products-crosssell {
		@include max-screen($screen__ml - 1) {
			margin-right: rem(-7.5);
			margin-left: rem(-7.5);
		}

		@include min-screen($screen__ml) {
			margin-right: -$fifteen-gutter;
			margin-left: -$fifteen-gutter;
		}

		.product-item {
			@include max-screen($screen__ml - 1) {
				padding: 0 rem(7.5);
			}

			@include min-screen($screen__ml) {
				padding: 0 $fifteen-gutter;
			}
		}

		// style slider content before slider is fully loaded
		.product-items:not(.slick-initialized) {
			display: flex;

			.product-item {
				display: none;

				&:nth-child(-n+2) {
					display: block;
				}

				@include min-screen($screen__ml) {
					&:nth-child(3) {
						display: block;
					}
				}

				@include min-screen($screen__ll) {
					&:nth-child(4) {
						display: block;
					}
				}
			}
		}

		.slick-track {
			display: flex;
		}

		.slick-slide {
			height: auto;

			> div { // no classes
				height: 100%;
			}

			.product-item {
				display: block !important; // override inline styles
				height: 100%;
			}
		}

		.product-item-info {
			display: block;

			.product-item-photo {
				display: block;
				flex: 1;

				span {
					display: block;
				}

				.product-image-container {
					width: 100% !important; // To override inline styles
					display: flex;
					flex: 1 1 100%;

					.product-image-wrapper {
						flex: 1;
						height: auto;
					}
				}

				.product-image-photo {
					width: 100%;
				}
			}
		}

		.product-item-details {
			margin-top: $five-gutter;
			text-align: left;

			.product-item-name {
				margin: 0;
				margin-bottom: rem(9);
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				overflow: hidden;
				min-height: rem(40);

				a {
					@include type__15;
					text-transform: capitalize;
					min-height: auto;

					@include min-screen($screen__ml) {
						@include type__16;
					}
				}
			}

			.product-item-actions {
				width: 100%;
				display: flex;
				align-items: flex-end;

				.actions-primary {
					flex: 0 1 100%;

					form {
						min-height: rem(110);
						align-content: space-between;
						flex-direction: column;
						position: relative;
					}

					.tocart {
						// Override button height set within button__journey mixin
						margin-top: auto;
						height: 2.5rem;
					}
				}
			}
		}

		.slick-dots {
			margin-top: $gutter;
			text-align: center;
		}
	}
}

.block.related {
	.section-heading {
		margin-top: $gutter;
	}

	 .block-content.content {
		.products.wrapper.grid.products-related > ol > div > div {
			margin: auto;

			.qty-checkbox-wrapper {
				@extend %visuallyhidden;
			}
		}
	}
}

.block-products-list,
.block.related,
.block.upsell,
.block-viewed-products-grid,
.block.crosssell {
	[data-role="tocart-form"] {
		display: flex;
		flex-wrap: wrap;
	}

	.price-box {
		.uom-label {
			@extend %visuallyhidden;
		}
	}
}
