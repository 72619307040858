// -------------------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------------------
//  All code in this file should contain fixes for Edge ONLY! Any other fixes for specific browsers
//  should go in their respecitve file. Fixes for browsers all browsers should go in _defend.scss
//  @author Fisheye
// -------------------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------------------

@supports (-ms-ime-align: auto) {
	.form-newsletter-manage {
		fieldset {
			.field {
				label {
					flex-basis: auto;
				}
			}
		}
	}

	.product-info-stock-sku {
		.lead-time-message {
			&:before {
				background-size: $product-info-lead-time-svg-size;
				background-color: red;
			}
		}
	}

	.header.content {
		.header.items {
			@include min-screen($screen__m) {
				display: grid;
				grid-template-columns: repeat(3, auto);
			}
		}

		.icons-block {
			.customer-menu,
			.header-link-menu {
				@include min-screen($screen__m) {
					top: $header-desktop-content-height;
				}
			}
		}
	}

	// ----------------------
	// Checkout
	// ----------------------
	.checkout-index-index,
	.page-layout-checkout {
		.checkout-container {
			.opc-wrapper {
				.payment-group {
					.field.choice {
						.label:before {
							background-size: 28px 28px;
						}
					}
				}

				.checkout-shipping-address {
					.action-show-popup {
						border: 0;
					}
				}

				.methods-shipping {
					.row {
						.col-method {
							label:before {
								background-size: 27px 27px;
							}
						}
					}
				}

				.form-discount {
					.primary {
						width: 25px;
					}
				}
			}

			.table-totals {
				.totals {
					.amount {
						min-width: 140px;
					}
				}
			}
		}
	}
}
