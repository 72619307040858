/* -------------------------------------------------
 * Deals Variables
 * (Commonly used for Black Friday and other large promotions)
 * -------------------------------------------------
 * Table of contents
 * 1. - Deals Menu Styles
 *
 * -------------------------------------------------
 * @author Fisheye
 */

// -------------------------------------------------
// 1. Deals Menu Styles
// -------------------------------------------------

$deals-menu-item-background-color: $brand-accent; // Background colour of menu item in nav
$deals-menu-item-color: $white; // Background colour of menu item in nav

// -------------------------------------------------
// 2. Deals Global Countdown Styles
// -------------------------------------------------

$deals-global-countdown-promo-text-color: $brand-accent;
$deals-global-countdown-todays-deal-color: $black;
$deals-global-countdown-todays-deal-weight: $font-weight-bold;
$deals-global-countdown-time-background-color: $black;
$deals-global-countdown-time-color: $black;
$deals-global-countdown-time-padding: rem(7);
$deals-global-countdown-time-width: rem(45);
$deals-global-countdown-time-width-desktop: rem(54);
$deals-global-countdown-time-margin-left: rem(3);
$deals-global-countdown-padding: rem(11);
$deals-global-countdown-time-wrapper-padding-left: $twenty-gutter;
$deals-global-countdown-time-wrapper-padding-left-tablet: rem(50);
$deals-global-countdown-time-wrapper-padding-left-desktop: rem(127);
$deals-global-countdown-todays-deal-margin-left: $ten-gutter;

// -------------------------------------------------
// 3. Deals Percent Claimed Bar Styles
// -------------------------------------------------

$deals-percent-bar-background-color: $black; // Colour of the percent claimed progress bar
$deals-percent-bar-progress-background-color: $brand-accent; // Colour of the percent claimed progress bar
$deals-percent-bar-height: 7px; // Height of the percent claimed bar
$deals-percent-bar-margin-bottom: $ten-gutter / 2; // Height of the percent claimed bar

// -------------------------------------------------
// 4. Deals Category Page Styles
// -------------------------------------------------

$deals-category-title-color: $black; // Deals category title colour
$deals-category-title-margin-right: $small-gutter / 2; // Deals category titles margin right, for space between title and day
$deals-category-title-margin-right-desktop: $small-gutter; // Deals category titles margin right, for space between title and day
$deals-category-subtitle-color: $boulder; // Deals category subtitle (Day) colour
$deals-category-subtitle-weight: $font-weight-medium; // Deals category subtitle (Day) font weight
$deals-category-percentage-claimed-margin-top: $small-gutter; // Product page percentage claimed margin top
$deals-category-percent-bar-margin-bottom: rem(5);

// -------------------------------------------------
// 5. Deals Product Page Styles
// -------------------------------------------------

$deals-product-notice-background-color: $brand-accent; // Product page deals notice/message
$deals-product-notice-color: $black; // Product page deals notice/message colour
$deals-product-notice-padding: rem(12); // Product page deals notice/message padding
$deals-product-notice-padding-desktop: rem(6); // Product page deals notice/message padding desktop
$deals-product-notice-margin-top: $twenty-gutter; // Product page deals notice/message margin top
$deals-product-notice-margin-top-desktop: $twenty-gutter; // Product page deals notice/message margin top desktop
$deals-product-percentage-claimed-margin-top: $ten-gutter; // Product page percentage claimed margin top
$deals-product-percentage-claimed-margin-top-desktop: $ten-gutter; // Product page percentage claimed margin top
$deals-product-percentage-claimed-margin-bottom-desktop: $ten-gutter; // Product page percentage claimed margin bottom
