/* -------------------------------------------------
 * -------------------------------------------------
 * Main Sidebar
 *
 * This file should be for specific styling inside the sidebar only.
 * Please keep all actual layout in the correct file i.e. container, header width etc.
 *
 * -------------------------------------------------
 * @author Fisheye
 */

// Actual widths for this are set in grid as part of the main page layout
.sidebar-main {
	padding: 0;
	display: flex;
	position: relative;
	margin: $fifteen-gutter;

	@include min-screen($screen__m) {
		display: inline-block;
		margin: 0;
		width: 100%;
	}

	.catalog-category-view &,
	.catalogsearch-result-index & {
		@include max-screen($screen__m - 1) {
			width: calc(50% - #{rem(26)});
			float: left;
			margin: $fifteen-gutter 0 0 $page-padding-mobile;
		}
	}

	.filter-active & {
		margin-right: 0;
		margin-left: 0;
		padding: 0;

		@include max-screen($screen__m - 1) {
			width: 50%;
		}
	}

	.filter,
	.sorter {
		flex: 1 1 50%;
		border-top: 1px solid $border-color-alt2;
		border-bottom: 1px solid $border-color-alt2;

		@include min-screen($screen__m) {
			border: 0;
		}

		&.active {
			border: 0;
			padding: $fifteen-gutter;
			z-index: 11;
			background-color: white;

			@include min-screen($screen__m) {
				padding: 0;
			}

			.filter-title {
				border-top: 1px solid $shadow-green;
				border-bottom: 1px solid $shadow-green;
			}
		}

		.page-layout-2columns-landing & {
			flex: 1 1 100%;
		}
	}

	.toolbar-sorter {
		margin-left: $fifteen-gutter;

		&.sorter {
			@include min-screen($screen__m) {
				display: none;
			}
		}

		.sorter-label {
			display: none;
		}
	}

	.filter-title,
	.toolbar-sorter {
		display: flex;
		padding: $layered-nav-container-spacing-mobile;

		@include min-screen($screen__m) {
			padding: 0;
		}
	}

	.filter-title {
		padding-right: 0;
		padding-left: 0;
	}

	.sorter-options {
		flex: 1;
		padding: 0;
		background-color: transparent;
		background-position: right;
		background-size: 13px 17px;
		padding-right: $gutter;
		border: 0;
		@include type__18;
	}

	.block {
		// Mobile Filter By Toggle
		&.filter {
			margin: 0;
			flex: 1;

			.filter-options-content {
				display: none;
			}

			&.active {
				margin: 0 - $fifteen-gutter 0 0;

				@include min-screen($screen__m) {
					margin: 0;
				}

				.filter-content {
					padding: 0 $fifteen-gutter;

					@include max-screen($screen__m - 1) {
						position: absolute;
						left: 0;
						background-color: $white;
						padding: $fifteen-gutter $gutter $fifteen-gutter $page-padding-mobile;
					}

					@include min-screen($screen__ml) {
						padding: 0 $gutter;
					}

					.filter-options {
						display: flex;

						@include min-screen($screen__m) {
							padding-bottom: rem(13);
						}
					}
				}

				.filter-options-content {
					display: block;
				}
			}

			.filter-content {
				position: relative;
				width: 200%;

				@include min-screen($screen__m) {
					width: auto;
					padding: 0 $gutter;
				}

				.filter-current,
				.filter-clear {
					display: none; // removes filtered options & 'clear all' button
				}

				.filter-options {
					display: none;
					flex-wrap: wrap;

					@include min-screen($screen__m) {
						display: flex;
					}

					.filter-options-item {
						position: relative;
						flex: 1 1 100%;

						&:first-child {
							padding-left: 0;
						}

						&:last-child {
							padding-right: 0;

							@include min-screen($screen__m) {
								padding-right: $fifteen-gutter;
							}

							&.active {
								@include max-screen($screen__m - 1) {
									border-bottom: 1px solid $border-color-alt2;
								}
							}
						}

						@include min-screen($screen__m) {
							flex: 0 1 percentage(1/3);
							padding: $fifteen-gutter;
							margin-bottom: 0;
						}

						@include screen($screen__m, $screen__l - 1) {
							&:nth-child(3n+4) {
								padding-left: 0;
							}

							&:nth-child(3n+3) {
								padding-right: 0;
							}
						}

						@include min-screen($screen__l) {
							flex: 0 1 percentage(1/4);
						}

						@include screen($screen__l, $screen__ll - 1) {
							&:nth-child(4n+5) {
								padding-left: 0;
							}

							&:nth-child(4n+4) {
								padding-right: 0;
							}
						}

						@include min-screen($screen__ll) {
							flex: 0 1 percentage(1/5);

							&:nth-child(5n+6) {
								padding-left: 0;
							}

							&:nth-child(5n+5) {
								padding-right: 0;
							}
						}

						&.active {
							@include min-screen($screen__m) {
								box-shadow: 0 -3px 4px 0 rgba(0,0,0,0.3);
								padding: $twenty-gutter $twenty-gutter 0;
								z-index: 11;
								background-color: $white;
							}

							&:last-child {
								padding-bottom: 0;
							}

							&.price {
								.count {
									letter-spacing: rem(-1);
								}
							}

							.filter-options-title {
								border-bottom: 1px solid $border-color-alt2;
							}

							.filter-options-content {
								width: 100%;
								background-color: $white;

								@include min-screen($screen__m) {
									position: absolute;
									left: 0;
									box-shadow: 0 0 4px 0 rgba(0,0,0,0.3);
									top: 49px; // Static height to allow for absolute panels to align correctly
									z-index: -1; // Position active filter item box under block
									clip-path: inset(2px -4px -4px -4px);
									padding: $twenty-gutter; // 2px padding to stop first item from cutting due to the clip path needed to remove grey line
									margin: 0;
								}

								@include min-screen($screen__xl) {
									top: 54px;
								}

								.field.search {
									margin-top: rem(3);

									.page-layout-2columns-landing & {
										margin: 0;
									}
								}

								.custom-filter {
									&.price {
										display: flex;
										align-items: center;
										margin: $ten-gutter 0;

										@include min-screen($screen__m) {
											margin: rem(14) 0 0;
										}

										span {
											@include type__16;
										}

										input {
											margin: 0 $ten-gutter;
										}

										.action {
											@include button__default(
												$background-color: $shadow-green-dark,
												$border-radius: 0
											);

											padding: rem(4);
											@include type__16($color: $white);

											@include min-screen($screen__m) {
												padding: rem(4);
											}
										}
									}
								}

								.items,
								.swatch-attribute-options {
									margin: $five-gutter 0;

									@include min-screen($screen__m) {
										overflow: auto;
										margin: 0;
										max-height: rem(229);
									}

									.item,
									.swatch-option-link-layered .swatch-option {
										display: flex;
										background-color: $gallery;
										border-radius: 6px;
										margin-bottom: $five-gutter;
										padding: rem(12) $fifteen-gutter $ten-gutter;
										text-indent: 0;
										width: 100%;

										@include min-screen($screen__m) {
											background-color: $desert-storm;
											padding: rem(8) $twenty-gutter rem(7);
										}

										&:last-child {
											margin-bottom: 0;
										}

										a {
											margin-bottom: 0;
										}

										&.selected {
											background-color: $shadow-green;

											span {
												color: $white;
											}
										}
									}

									label,
									.swatch-option.text {
										@include type__16(
											$font-family: $font-family-name__base,
											$font-weight: $font-weight-normal
										);
										padding-left: 0;

										&:before {
											display: none; // remove unnecessary checkbox
										}

										span {
											flex: 0 1 auto;
											width: auto;
										}

										.price {
											@include type__15(
												$font-family: $font-family-name__base,
												$font-weight: $font-weight-normal
											);
										}

										.count {
											display: none;
										}
									}

									a {
										@include type__13($font-weight: $font-weight-normal);
										width: 100%;
										margin-bottom: $ten-gutter;

										&:last-child {
											margin-bottom: 0;
										}
									}

									.filter-count-label {
										@extend %visuallyhidden;
									}
								}

								.colour { // colour attributes have a slightly different layout
									.swatch-option-link-layered {
										display: flex;
										align-items: center;
										background-color: $white;
										border-radius: 30px;
										margin-bottom: $fifteen-gutter;
										padding: rem(9) $fifteen-gutter;

										&:last-child {
											margin-bottom: 0;
										}
									}

									.swatch-option.color,
									.swatch-option.image {
										height: 20px;
										width: 20px;
										margin-bottom: 0;
										border: 0;
										background-size: cover!important; // override inline styles
										border-radius: 0;
									}

									.swatch-label {
										@include type__13(
											$font-weight: $font-weight-normal
										);
										order: 2;
									}
								}

								.filter-details {
									display: flex;

									.actions {
										@include min-screen($screen__m) {
											padding: 0;
											position: absolute;
											bottom: 0;
											left: 0;
											width: 100%;
											z-index: 1;
										}

										.action-button {
											margin-bottom: $five-gutter;

											@include button__default(
												$padding: rem(12) rem(17),
												$border-radius: 0,
												$background-color: $shadow-green,
												$border: 1px solid $shadow-green,
												$color: $white
											);

											& {
												display: flex;
												justify-content: center;
												align-items: center;
											}

											@include min-screen($screen__m) {
												padding: rem(6) rem(17) rem(7);
												margin-bottom: 0;
											}
										}
									}
								}
							}
						}

						&.filter-active {
							.filter-options-title {
								position: relative;
								@include type__11(
									$font-weight: $font-weight-semibold,
									$letter-spacing: 0.06em
								);
								padding: rem(7) 0 rem(6);

								@include min-screen($screen__m) {
									padding: rem(8) 0 rem(6);
									border-top: 1px solid $shadow-green;
									border-bottom: 1px solid $shadow-green;
								}

								.info {
									display: flex;
									flex: 1;
									margin-top: $five-gutter;

									@include min-screen($screen__m) {
										margin-top: em(2);
									}

									.filters {
										@include type__11(
											$color: $shadow-green,
											$font-weight: $font-weight-semibold
										);
									}
								}
							}

							.filter-options-content {
								display: flex;
								flex-direction: column;

								@include min-screen($screen__m) {
									padding-bottom: rem(39);
									display: none; // Hide filter contents on load page load until inline display: flex; comes in
								}

								.items {
									.item {
										&:last-child {
											@include min-screen($screen__m) {
												margin-bottom: rem(21);
											}
										}
									}
								}

								.smile-es-range-slider {
									@include min-screen($screen__m) {
										margin-bottom: rem(21);
									}
								}

								.filter-details {
									order: 5;
								}

								.custom-filter {
									&.price {
										margin-top: 0;

										@include min-screen($screen__m) {
											margin: 0 0 rem(14);
										}
									}
								}
							}

							&.active {
								.filter-options-title {
									border-color: $border-color-alt2;
								}
							}
						}

						&.next_day_delivery {
							display: none;
						}

						@include max-screen($screen__m - 1) {
							&:last-child {
								.filter-options-title {
									border-bottom: 1px solid $border-color-alt2;
								}
							}
						}

						.filter-options-title,
						.pagebuilder-buttons {
							padding: rem(12) 0 rem(11);
							border-top: 1px solid $border-color-alt2;
							width: 100%;

							@include min-screen($screen__m) {
								padding: rem(8) 0 rem(9);
								border-top: 1px solid $border-color-alt2;
								border-bottom: 1px solid $border-color-alt2;
							}
						}
					}
				}

				.filter-clear {
					@include action-w-icon__cross-black;
					position: absolute;
					top: 0;
					right: 0;

					// stops clear all button overriding "shopping by" title
					@include min-screen($screen__m) {
						position: static;
					}

					@include min-screen($screen__l) {
						position: absolute;
					}
				}
			}
		}

		/*
		 * selected filter options
		 */

		.filter-current {
			.action.remove { // mark up is slightly different here as filter label and value are in separate spans
				position: relative;
				display: block;
				padding-bottom: $layered-nav-filter-option-bottom-spacing / 2; // as the checkbox is absolute we need to add a little more padding to make it equal
				clear: both;
			}

			.filter-label {
				width: auto;
				display: inline-block;

				&:before {
					position: relative; // set to position absolute so label text doesnt wrap underneath the icon
					left: $layered-nav-checkbox-icon-horizontal-position;
					top: $layered-nav-checkbox-icon-vertical-position; // vertically aligns the check box with label text
				}
			}

			.filter-value {
				display: inline-block;
			}
		}
	}

	// Main title at top of layered nav "Filter By"
	.filter-title {
		cursor: pointer;

		@include min-screen($screen__m) {
			display: none;
		}

		strong {
			@include type__12;

			display: inline-flex;
			align-items: center;
			text-transform: uppercase;
			width: 100%;

			&:before {
				content: '';
				display: inline-block;
				height: 12px;
				width: 16px;
				margin-right: $ten-gutter;
				@include svg-background(
					$name: filter,
					$size: contain
				);
			}

			&:after {
				@include svg-background(
					$name: "plus",
					$size: 11px 100%
				);
				content: '';
				width: $layered-nav-filter-toggle-width;
				height: $layered-nav-filter-toggle-height;
				margin-left: $ten-gutter;
				display: block;
				background-position: 100%;
				flex: 1;
			}

			@include min-screen($screen__xs) {
				@include type__18;
			}
		}
	}

	.active {
		.filter-title {
			strong {
				&:after {
					@include svg-background("minus");
					background-position: 100%;
				}
			}
		}
	}

	// filter search (usually disabled but base styles have been added)
	// ----------------
	.filter-search {
		width: 100%;
		margin-bottom: $layered-nav-filter-item-spacing;
	}

	// active filters block styles
	.filter-current-subtitle {
		@include type__13;
		display: block;
		padding-bottom: $layered-nav-filter-option-bottom-spacing;
	}

	.filter-label:after {
		content: ':';
	}

	// Title for each section in layered nav
	.filter-options-title {
		@include type__14($font-weight: $font-weight-semibold);
		text-transform: uppercase;
		cursor: pointer;
		position: relative;

		@include min-screen($screen__m) {
			min-height: rem(39);
		}

		&:after {
			content: "";
			width: $layered-nav-title-arrow-width;
			height: $layered-nav-title-arrow-height;
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			margin: auto;
			background-size: 13px 7px;
		}

		&[aria-expanded="true"]:after {
			@include svg-background("collapse-arrow");
		}

		&[aria-expanded="false"]:after {
			content: "";
			@include svg-background("expand-arrow");
		}
	}

	.item {
		// Nested seperately for readability and match markup
		position: relative;

		&:hover {
			opacity: $link-opacity;
			cursor: pointer;
		}

		&:last-child {
			padding-bottom: $layered-nav-filter-last-item-spacing;
		}
	}

	.actions, // show more link
	.swatch-option-link-layered {
		display: block;
		width: 100%;
	}

	// color swatches
	// --------------------

	.swatch-option-link-layered {
		align-items: center;

		.color {
			width: $layered-nav-color-swatch-height;
			height: $layered-nav-color-swatch-width;
			border: solid 1px $layered-nav-swatch-border;
		}

		.swatch-option {
			margin-right: $layered-nav-swatch-options-box-spacing;
		}
	}

	// price slider
	// --------------------
	// no classes available for price slider so styles applied by targeting the data-roles
	.smile-es-range-slider {
		@include max-screen($screen__m - 1) {
			padding: $five-gutter 0 rem(7);
		}

		div[data-role="from-label"],
		div[data-role="to-label"],
		div[data-role="message-box"] {
			@include type__14($font-weight: $font-weight-normal);
			margin-bottom: $ten-gutter;
		}

		div[data-role="from-label"] {
			float: left;
		}

		div[data-role="to-label"] {
			float: right;
		}

		div[data-role="from-label"],
		div[data-role="to-label"] {
			line-height: 1;
		}

		.ui-slider-horizontal {
			clear: both;
			margin: 0 $five-gutter $ten-gutter $five-gutter;
			// minus margins are used to position the slider dots which causes them to overlap this bar by adding
			// 5 px margin to the bar we stop the overlap

			.ui-slider-handle {
				background-color: $shadow-green;
			}
		}

		.primary.action {
			@include button__default;
			display: block;
			text-align: center;
			cursor: pointer;
		}
	}

	.block-subtitle.filter-subtitle,
	.account-nav .title {
		// .block-subtitle.filter-subtitle hides the "Shopping Options" title
		// .account-nav .title hides the "My Account" title
		display: none;
	}
	// nastiness added in purely to overwrite in Magento_LayeredNavigation common.scss :`(
	.filter-options .filter-options-content .item {
		margin-bottom: 0;
	}
}
