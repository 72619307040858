/* -------------------------------------------------
 *
 * This is styling for a Aheadworks/Aheadworks_Sarp2
 * -------------------------------------------------
 * @author Fisheye
 */

.aw_sarp2-profile_edit-plan {
	form {
		@include min-screen($screen__ml) {
			display: flex;
		}

		.products-grid {
			flex: 0 1 auto;
			text-align: center;
		}

		.aw-sarp2-product-subscription-options {
			text-align: center;
			flex: 1 1 auto;

			@include min-screen($screen__m) {
				margin-left: $fifteen-gutter;
			}

			.block-title {
				@include type__27;
				text-transform: uppercase;
			}

			.subscription-details {
				.block-content {
					text-align: left;
				}
			}

			button {
				width: 100%;
			}

			.aw-sarp2-subscription__wrapper-list {
				display: flex;
				flex-flow: column wrap;

				@include min-screen($screen__ll) {
					flex-flow: row wrap;
				}

				.field {
					flex: 1;
					margin-bottom: 0;
					background-color: $gallery;

					@include min-screen($screen__ml) {
						flex: auto;
					}

					@include min-screen($screen__ll) {
						flex: 0 1 percentage(1/2);
					}

					@include min-screen($screen__xxl) {
						flex: 0 1 percentage(1/3);
					}

					&:not(:first-child) {
						input {
							+ label {
								border-top: 1px solid $white;

								@include min-screen($screen__m) {
									border-left: 1px solid $white;
									border-top: 0;
								}

								@include min-screen($screen__ml) {
									border-top: 1px solid $white;
									border-left: 0;
								}

								@include min-screen($screen__ll) {
									border-left: 1px solid $white;
								}

								@include min-screen($screen__xxl) {
									border-top: 2px solid $gallery;
								}
							}
						}
					}

					input {
						+ label {
							padding: rem(11) $ten-gutter rem(9);
							display: flex;
							flex: 1;
							border-bottom: 2px solid $gallery;
							border-top: 2px solid $gallery;

							@include min-screen($screen__m) {
								padding: rem(14) $fifteen-gutter rem(12);
								justify-content: center;
							}

							@include min-screen($screen__ml) {
								justify-content: flex-start;
							}

							@include min-screen($screen__ll) {
								justify-content: center;
							}

							span {
								display: flex;
								align-self: center;
							}
						}

						&:checked {
							+ label {
								border: 2px solid $shadow-green;
								z-index: 1;
							}
						}
					}

					.label {
						display: flex;
						position: static;
						left: 0;

						span {
							text-transform: uppercase;
							@include type__14($font-weight: $font-weight-semibold);
						}
					}
				}
			}
		}
	}
}
