/// Clearfix hack on psuedo elements
/// @example scss
/// .some-div {
///		@include clearfix;
/// }
@mixin clearfix() {
	&:before,
	&:after {
		content: " ";
		display: table;
	}

	&:after {
		clear: both;
	}
}