/* -------------------------------------------------
 * -------------------------------------------------
 * Pagebuilder Tabs Component Styles
 * -------------------------------------------------
 * @author Fisheye
 */

div[data-role='tabs'] {
	.tabs-navigation {
		display: block;
		font-size: 0;
		margin: 0 0 -1px;
		padding: 0;
	
		li.tab-header {
			background: $pagebuilder-tabs-tab-background-color;
			border: $pagebuilder-tabs-tab-border;
			border-bottom: 0;
			display: inline-block;
			margin: 0;
			max-width: 100%;
			overflow-wrap: break-word;
			position: relative;
			word-wrap: break-word;
			z-index: 1;
			width: 100%;
			
			&:last-child {
				border-bottom: $pagebuilder-tabs-tab-border;
			}
			
			@include min-screen($screen__s) {
				width: auto;
				
				&:last-child {
					border-bottom: 0;
				}
			}
			
	
			&.ui-state-active {
				background: $pagebuilder-tabs-tab-background-color-active;
				z-index: 19;

				a.tab-title {
					color: $pagebuilder-tabs-tab-title-color-active;

					&:focus {
						opacity: 1;
					}
				}
			}
	
			a.tab-title {
				@include type__16(
					$color: $pagebuilder-tabs-tab-title-color,
					$font-weight: $pagebuilder-tabs-tab-title-font-weight
				);
				border-right: 0;
				cursor: pointer;
				display: block;
				padding: $pagebuilder-tabs-tab-title-padding;
				position: relative;
				white-space: normal;
			}
		}
	}

	.tabs-content {
		border-top: $pagebuilder-tabs-tab-content-border;
		overflow: hidden;
		position: relative;
		z-index: 9;
	}
}
