/* -------------------------------------------------
 * -------------------------------------------------
 * Social Media links in footer blocks
 *
 * These are usally added through Bluefoot but can
 * be added via widget instead
 *
 * --------------------------------------------------
 * @author Fisheye
 */

 .sociallinks {
	a span {
		@extend %visuallyhidden;
	}

	h2.main {
		@extend %visuallyhidden;
	}

	ul {
		display: flex;
		justify-content: flex-end;
	}

	li {
		padding: 0 $small-gutter / 2;
		margin: 0;

		&:last-child {
			padding-right: 0;
		}
		
		@include min-screen($screen__m) {
			padding: 0 $item-gutter / 3;
		}
	}
	
	.social-icon {
		&:before {
			content: "";
			display: block;
			width: rem(56);
			height: rem(55);
			background-size: contain;
			
			@include min-screen($screen__m) {
				width: 46px;
				height: 46px;
			}
		}

		&.icon-youtube {
			&:before {
				@include svg-background($name: youtube-icon);
			}
		}

		&.icon-facebook {
			&:before {
				@include svg-background($name: facebook-icon);
			}
		}
		
		&.icon-pinterest {
			&:before {
				@include svg-background($name: pinterest-icon);
			}
		}

		&.icon-instagram {
			&:before {
				@include svg-background($name: instagram-icon);
			}
		}

		&.icon-twitter {
			&:before {
				@include svg-background($name: twitter-icon);
			}
		}

		&.icon-pinterest {
			&:before {
				@include svg-background($name: pinterest-icon);
			}
		}
	}
}
