/* -------------------------------------------------
 * -------------------------------------------------
 * Typography
 *
 * -------------------------------------------------
 * @author Fisheye
 */


/* --------------------------------------------------
 * Type scales - Indecicies represents the
 * approximate PX value
 * -------------------------------------------------
 */

$type-scale: (
	10: 0.625rem,
	11: 0.675rem,
	12: 0.75rem,
	13: 0.8125rem,
	14: 0.875rem,
	15: 0.9375rem,
	16: 1rem,
	17: 1.063rem,
	18: 1.125rem,
	19: 1.188rem,
	20: 1.25rem,
	22: 1.375rem,
	24: 1.5rem,
	27: 1.655rem,
	28: 1.99rem,
	30: 1.875rem,
	33: 2.0625rem,
	35: 2.1875rem,
	40: 2.5rem,
	45: 2.8125rem,
	50: 3.06rem,
	52: 3.25rem,
	55: 3.4375rem,
	64: 3.92rem,
	73: 5.18rem,
	85: 5.3125rem,
	123: 7.525rem
);

// Easy REM calculator based on 16px base
// : https://offroadcode.com/rem-calculator/
@function type-scale($level) {
	@return map-get($type-scale, $level);
}

// Again, we can make a helper function:
@function line-heights($level) {
	@return map-get($line-heights, $level);
}

$base-line-height: 1;
// $base-line-height is always equal to the font size applied to

// For example:
// font-size: 16px;
// line-height: 1;

// Would give a line-height of 16px

// --------------------------------

// Calculating line-heights from px to unit-less values

// font-size: 16px;
// line-height: 26px;

// 16px is 1, so to find the decimal amount we need to find the percentage of what is left after 1 has been subtracted.

// Subtract the font size from the given line-height: 26 - 16 = 10px
// The decimal is the what's left as a percentage of the font-size
// 10 / 16 = 0.625 and move the decimal point 2 spaces = 62.5

// A line-height of 26px would equal 1.63 (rounded up from 62.5)

// 16, 19, 27 have been set for CORE V5
$line-heights: (
	10: 0.67,
	11: 1,
	12: 1.67,
	13: 1.67,
	14: 1.43,
	15: 1.33,
	16: 1.25,
	17: 1.18,
	18: 1.11,
	19: 1.84,
	20: 1,
	22: 0.91,
	24: 1.17,
	27: 1,
	28: 1,
	30: 0.67,
	33: 1.06,
	35: 1,
	40: 1,
	45: 0.27,
	50: 1,
	52: 0.67,
	55: 1.1,
	64: 1,
	73: 1,
	85: 0.25,
	123: 1
);

@mixin type-setting($level: 0, $line-height: null) {
	font-size: type-scale($level);
	@if $line-height != null {
		// If line height var value isn't null, apply the value
		line-height: $line-height;
	} @else {
		// If the var value is null, apply the default line-height for that font size
		line-height: line-heights($level);
	}
}

/* Type Styles - Mixin and defaults
 * -------------------------------------------------
 */

 @mixin type__10(
	$color: $body-font-color,
	$font-family: $body-font-family,
	$font-weight: $font-weight-semibold,
	$line-height: null,
	$letter-spacing: 0.02em
) {
	font-family: $font-family;
	letter-spacing: $letter-spacing;
	font-weight: $font-weight;
	color: $color;
	@include type-setting(10, $line-height);
}

 @mixin type__11(
	$color: $body-font-color,
	$font-family: $body-font-family,
	$font-weight: $font-weight-normal,
	$line-height: null,
	$letter-spacing: 1px
) {
	font-family: $font-family;
	letter-spacing: $letter-spacing;
	font-weight: $font-weight;
	color: $color;
	@include type-setting(11, $line-height);
}

 @mixin type__12(
	$color: $body-font-color,
	$font-family: $body-font-family,
	$font-weight: $font-weight-semibold,
	$line-height: null,
	$letter-spacing: 0.02em
) {
	font-family: $font-family;
	letter-spacing: $letter-spacing;
	font-weight: $font-weight;
	color: $color;
	@include type-setting(12, $line-height);
}

@mixin type__13(
	$color: $body-font-color,
	$font-family: $body-font-family,
	$font-weight: $font-weight-semibold,
	$line-height: null,
	$letter-spacing: 0.02em
) {
	font-family: $font-family;
	letter-spacing: $letter-spacing;
	font-weight: $font-weight;
	color: $color;
	@include type-setting(13, $line-height);
}

@mixin type__14(
	$color: $body-font-color,
	$font-family: $body-font-family,
	$font-weight: $font-weight-normal,
	$line-height: null,
	$letter-spacing: 0.02em
) {
	font-family: $font-family;
	letter-spacing: $letter-spacing;
	font-weight: $font-weight;
	color: $color;
	@include type-setting(14, $line-height);
}

@mixin type__15(
	$color: $body-font-color,
	$font-family: $body-font-family,
	$font-weight: $font-weight-normal,
	$line-height: null,
	$letter-spacing: 0.02em
) {
	font-family: $font-family;
	letter-spacing: $letter-spacing;
	font-weight: $font-weight;
	color: $color;
	@include type-setting(15, $line-height);
}

@mixin type__16(
	$color: $body-font-color,
	$font-family: $body-font-family,
	$font-weight: $font-weight-normal,
	$line-height: null,
	$letter-spacing: 0.02em
) {
	font-family: $font-family;
	letter-spacing: $letter-spacing;
	font-weight: $font-weight;
	color: $color;
	@include type-setting(16, $line-height);
}

@mixin type__17(
	$color: $body-font-color,
	$font-family: $body-font-family,
	$font-weight: $font-weight-normal,
	$line-height: null,
	$letter-spacing: 0.02em
) {
	font-family: $font-family;
	letter-spacing: $letter-spacing;
	font-weight: $font-weight;
	color: $color;
	@include type-setting(17, $line-height);
}

@mixin type__18(
	$color: $body-font-color,
	$font-family: $body-font-family,
	$font-weight: $font-weight-normal,
	$line-height: null,
	$letter-spacing: 0.02em
) {
	font-family: $font-family;
	letter-spacing: $letter-spacing;
	font-weight: $font-weight;
	color: $color;
	@include type-setting(18, $line-height);
}

@mixin type__19(
	$color: $body-font-color,
	$font-family: $body-font-family,
	$font-weight: $font-weight-medium,
	$line-height: null,
	$letter-spacing: 0.02em
) {
	font-family: $font-family;
	letter-spacing: $letter-spacing;
	font-weight: $font-weight;
	color: $color;
	@include type-setting(19, $line-height);
}

@mixin type__20(
	$color: $body-font-color,
	$font-family: $body-font-family,
	$font-weight: $font-weight-normal,
	$line-height: null,
	$letter-spacing: 0.02em
) {
	font-family: $font-family;
	letter-spacing: $letter-spacing;
	font-weight: $font-weight;
	color: $color;
	@include type-setting(20, $line-height);
}

@mixin type__22(
	$color: $body-font-color,
	$font-family: $body-font-family,
	$font-weight: $font-weight-semibold,
	$line-height: null,
	$letter-spacing: 0.02em
) {
	font-family: $font-family;
	letter-spacing: $letter-spacing;
	font-weight: $font-weight;
	color: $color;
	@include type-setting(22, $line-height);
}

@mixin type__24(
	$color: $body-font-color,
	$font-family: $body-font-family,
	$font-weight: $font-weight-medium,
	$line-height: null,
	$letter-spacing: 0.02em
) {
	font-family: $font-family;
	letter-spacing: $letter-spacing;
	font-weight: $font-weight;
	color: $color;
	@include type-setting(24, $line-height);
}

@mixin type__27(
	$color: $body-font-color,
	$font-family: $body-font-family,
	$font-weight: $font-weight-normal,
	$line-height: null,
	$letter-spacing: rem(2)
) {
	color: $color;
	font-family: $font-family;
	font-weight: $font-weight;
	letter-spacing: $letter-spacing;
	@include type-setting(27, $line-height);
}

@mixin type__28(
	$color: $white,
	$font-family: $font-family-serif,
	$font-weight: $font-weight-normal,
	$line-height: null,
	$letter-spacing: rem(2)
) {
	color: $color;
	font-family: $font-family;
	font-weight: $font-weight;
	letter-spacing: $letter-spacing;
	@include type-setting(28, $line-height);
}

@mixin type__30(
	$color: $body-font-color,
	$font-family: $body-font-family,
	$font-weight: $font-weight-normal,
	$line-height: null,
	$letter-spacing: 0.02em
) {
	font-family: $font-family;
	letter-spacing: $letter-spacing;
	font-weight: $font-weight;
	color: $color;
	@include type-setting(30, $line-height);
}

@mixin type__33(
	$color: $body-font-color,
	$font-family: $body-font-family,
	$font-weight: $font-weight-normal,
	$line-height: null,
	$letter-spacing: 0.02em
) {
	font-family: $font-family;
	letter-spacing: $letter-spacing;
	font-weight: $font-weight;
	color: $color;
	@include type-setting(33, $line-height);
}

@mixin type__35(
	$color: $body-font-color,
	$font-family: $body-font-family,
	$font-weight: $font-weight-normal,
	$line-height: null,
	$letter-spacing: 0.02em
) {
	font-family: $font-family;
	letter-spacing: $letter-spacing;
	font-weight: $font-weight;
	color: $color;
	@include type-setting(35, $line-height);
}

@mixin type__40(
	$color: $body-font-color,
	$font-family: $font-family-serif,
	$font-weight: $font-weight-normal,
	$line-height: null,
	$letter-spacing: 0.02em
) {
	font-family: $font-family;
	letter-spacing: $letter-spacing;
	font-weight: $font-weight;
	color: $color;
	@include type-setting(40, $line-height);
}

@mixin type__45(
	$color: $body-font-color,
	$font-family: $body-font-family,
	$font-weight: $font-weight-normal,
	$line-height: null,
	$letter-spacing: 0.02em
) {
	font-family: $font-family;
	letter-spacing: $letter-spacing;
	font-weight: $font-weight;
	color: $color;
	@include type-setting(45, $line-height);
}

@mixin type__50(
	$color: $body-font-color,
	$font-family: $body-font-family,
	$font-weight: $font-weight-normal,
	$line-height: null,
	$letter-spacing: rem(4)
) {
	font-family: $font-family;
	letter-spacing: $letter-spacing;
	font-weight: $font-weight;
	color: $color;
	@include type-setting(50, $line-height);
}

@mixin type__52(
	$color: $body-font-color,
	$font-family: $body-font-family,
	$font-weight: $font-weight-normal,
	$line-height: null,
	$letter-spacing: 0.02em
) {
	font-family: $font-family;
	letter-spacing: $letter-spacing;
	font-weight: $font-weight;
	color: $color;
	@include type-setting(52, $line-height);
}

@mixin type__55(
	$color: $body-font-color,
	$font-family: $font-family-sans-serif-alt,
	$font-weight: $font-weight-normal,
	$line-height: null,
	$letter-spacing: rem(2)
) {
	font-family: $font-family;
	letter-spacing: $letter-spacing;
	font-weight: $font-weight;
	color: $color;
	@include type-setting(55, $line-height);
}

@mixin type__64(
	$color: $white,
	$font-family: $font-family-serif,
	$font-weight: $font-weight-normal,
	$line-height: null,
	$letter-spacing: rem(4)
) {
	color: $color;
	font-family: $font-family;
	font-weight: $font-weight;
	letter-spacing: $letter-spacing;
	@include type-setting(64, $line-height);
}

@mixin type__73(
	$color: $white,
	$font-family: $font-family-sans-serif-alt,
	$font-weight: $font-weight-normal,
	$line-height: null,
	$letter-spacing: rem(4)
) {
	color: $color;
	font-family: $font-family;
	font-weight: $font-weight;
	letter-spacing: $letter-spacing;
	@include type-setting(73, $line-height);
}

@mixin type__85(
	$color: $body-font-color,
	$font-family: $font-family-sans-serif-alt,
	$font-weight: $font-weight-normal,
	$line-height: null,
	$letter-spacing: 0.02em
) {
	font-family: $font-family;
	letter-spacing: $letter-spacing;
	font-weight: $font-weight;
	color: $color;
	@include type-setting(85, $line-height);
}

@mixin type__123(
	$color: $white,
	$font-family: $font-family-sans-serif-alt,
	$font-weight: $font-weight-normal,
	$line-height: null,
	$letter-spacing: rem(7)
) {
	color: $color;
	font-family: $font-family;
	font-weight: $font-weight;
	letter-spacing: $letter-spacing;
	@include type-setting(123, $line-height);
}

@mixin type__address {
	@include type__16();
	word-wrap: break-word;
}

@mixin type__notes(
	$font-style: italic
) {
	// These are used for notes and upsells. specifically "Free deliveries on orders over £50" and checkout
	font-family: $body-font-family;
	letter-spacing: 0;
	font-style: $font-style;
	@include type-setting(16);
}

@mixin type__accent {
	color: $brand-accent;
}

/* Other Abstractions
 * -------------------------------------------------
 */

@mixin secure__text {
	text-align: center;
	justify-content: center;
	@include type__22(
		$font-weight: $font-weight-normal,
		$font-family: $font-family-serif
	);
	font-style: italic;
	display: flex;
	align-items: center;

	&:before {
		@include svg-background(
			$name: padlock,
			$size: contain
		);
		content: "";
		display: inline-block;
		width: 19px; // Px values to fix sizing bugs on certain browsers
		height: 26px;
		position: relative;
		margin-right: em(5);

		@-moz-document url-prefix() {
			background-size: contain;
			// firefox fix
		}
	}

	span {
		@include text-crop;
	}
}

@mixin underline {
	border-bottom: solid 1px $body-font-color;
	color: $body-font-color;
}

@mixin link__underline(
	$border-bottom: solid 1px $body-font-color,
	$color: $body-font-color
) {
	border-bottom: $border-bottom;
	color: $color;

	&:hover,
	&:focus,
	&:active {
		border-bottom: $border-bottom;
	}
}

// Cancel underline
// -------------------------------------------------
%cancel-underline {
	border: 0;

	&:hover,
	&:focus,
	&:active {
		border: 0;
	}
}

@mixin type__subtitle (
	$color: $black,
	$font-weight: $font-weight-thin,
	$letter-spacing: em(2)
) {
	color: $color;
	font-weight: $font-weight;
	text-transform: uppercase;
	letter-spacing: $letter-spacing;
}
