.account {
	form,
	.form {
		fieldset,
		.fieldset {
			.field {
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;

				@media (min-width: $screen__ml) {
					flex-direction: $account-form-flex-direction;
				}

				.label {
					flex: 0 1 100%;
					display: flex;

					&:after {
						display: flex;
						align-self: center;
						padding-left: em(5);
					}

					@include min-screen($screen__ml) {
						flex: 1 0 $account-form-label-width-tablet;
					}

					@include min-screen($screen__ll) {
						flex: 0 1 $account-form-label-width-desktop;
					}
				}

				.control {
					flex: 1 0 100%; //grow to fill available space

					@include min-screen($screen__ml) {
						flex: 0 1 $account-form-control-width-desktop; // needs to have an explicit width rather than auto on desktop as otherwise it will default
						margin-right: 0;
					}

					.input-text {
						margin-top: 0;
					}
				}

				&.newsletter {
					margin-top: $twenty-gutter;
					margin-bottom: 0;

					@include min-screen($screen__s) {
						margin-top: $gutter;
					}

					label {
						margin-bottom: 0;

						@include min-screen($screen__s) {
							margin-bottom: $ten-gutter;
						}

						span {
							width: auto;
							@include type__14($font-weight: $font-weight-medium);
						}
					}
				}

				&.newsletter {
					margin-top: $twenty-gutter;
					margin-bottom: 0;

					@include min-screen($screen__s) {
						margin-top: $ten-gutter;
					}

					label {
						margin-bottom: 0;

						@include min-screen($screen__s) {
							margin-bottom: $ten-gutter;
						}

						span {
							@include type__14($font-weight: $font-weight-thin);
						}
					}
				}
			}
		}
	}
}

// account details
.form-address-edit,
.customer-account-edit {
	.fieldset,
	.actions-toolbar {
		max-width: $account-form-max-width;
		margin-top: 0 !important; // Override parent CSS

		br {
			display: none; // random </br> coming into markup causing unnecessary additional spacing
		}

		.primary {
			display: block;
			width: 100%;
		}

		.secondary {
			margin-top: $account-form-spacing;
		}

		.field.choice .label {
			flex: 1 1 100%;
		}

		.field.choice {
			.field-tooltip {
				.action-help {
					top: -$five-gutter;
				}

				.field-tooltip-content {
					margin-top: 0;
					white-space: unset;

					@include min-screen($screen__m) {
						white-space: nowrap;
					}
				}
			}
		}

		.field:not(.required) {
			label {
				&:after {
					display: none;
				}
			}
		}

		.field {
			.search-bar {
				display: flex;
				flex-wrap: wrap;

				.action {
					@include button__default($padding: $ten-gutter);
					order: 1;
					flex: 0 1 38%;
					margin-left: $ten-gutter;
					border-radius: 0;

					@include min-screen($screen__m) {
						flex: 0 1 32%;
						margin-left: $twenty-gutter;
					}
				}

				.message {
					order: 3;
				}

				input {
					flex: 1;
					width: 75% !important; /* override an important from module styles */
				}
			}
		}
	}
}

.customer-account-edit {
	.form-edit-account {
		padding: $fifteen-gutter 0 0;

		@include min-screen($screen__m) {
			padding: 0;
		}
	}
}

//add new address
.form-address-edit {
	fieldset,
	.fieldset {
		.field {
			&.street {
				margin-bottom: 0;

				.label {
					display: flex !important; //Override another important property
				}

				.control {
					.field.primary {
						margin-bottom: 0;
					}
				}
			}

			.label {
				flex: 0 1 100%;
			}

			.control {
				flex: 0 1 100%;

				.nested {
					margin-top: $twenty-gutter;
				}
			}

			&.choice {
				display: inline-block;
				margin-bottom: $forty-gutter;
	
				label {
					left: 0 !important; //Override parent CSS
					width: 100%;
					
					span {
						@include type__16($font-weight: $font-weight-normal);
						margin-top: rem(-3); //Alignment checkbox
					}
				}
			}

			&.set { // all checkbox labels have this set class
				label {
					margin-bottom: 0;

					span {
						@include type__16;
					}
				}
			}

			div[id=cp_result_display] {
				margin-top: $fifteen-gutter;
				max-width: none;
			}

			&.zip.search-container {
				@include min-screen($screen__ml) {
					flex-direction: row;
				}
			}
		}
	}

	.field {
		&.zip {
			margin-bottom: 0;

			.control {
				position: relative;

				input[id="lookup-input"] {
					padding-top: $fifteen-gutter;
					padding-bottom: $five-gutter;
				}

				button[id="postcode-lookup"] {
					@include button__journey;
					padding-top: rem(13);
					position: absolute;
					right: 0;
				}

				div[id="manual-address-entry"] {
					margin-top: rem(18);
					margin-bottom: $twenty-gutter !important; // Override the margin set in the class .field.choice which is shared with another element
				}

				select[id="lookup-results"] {
					margin-bottom: $twenty-gutter;
				}
			}
		}

		.label {
			z-index: 1;

			span {
				@include type__11 (
					$font-weight: $font-weight-semibold,
					$letter-spacing: null,
					$line-height: 1,
					$color: $brand-accent
				);
				text-transform: uppercase;
				display: flex;
				transition: all 0.2s ease-in-out 0s;
			}
		}
	}
}

// Postcode lookup
.form-address-edit {
	.fieldset {
		.zip {
			.control {
				input[id="lookup-input"] {
					padding-top: $fifteen-gutter;
					padding-bottom: $five-gutter;
				}

				button[id="postcode-lookup"] {
					@include button__journey($border-radius: 0 3px 3px 0);
					min-height: 2.6rem;
					width: rem(170);
					position: absolute;
					top: 0;
					right: 0;

					&:disabled {
						width: auto;
					}
				}

				div[id="manual-address-entry"] {
					margin: rem(18) 0 0;
				}

				select[id="lookup-results"] {
					padding: rem(8) $fifteen-gutter rem(11);
				}
			}
		}
	}
}

.form-newsletter-manage {
	.field.choice .label {
		flex: 1 1 100%;
	}
}

// Forgotten & Reset Password
.customer-account-forgotpassword,
.customer-account-createpassword {
	.page-title-wrapper {
		.page-title {
			span {
				@include type__45(
					$font-family: $font-family-serif,
					$color: $black,
					$line-height: 1
				);
				text-transform: none;
			}
		}
	}

	.form {
		&.password {
			&.forget,
			&.reset {
				.actions-toolbar {
					.primary {
						span {
							@include type__18 (
								$color: $button-journey-color,
								$letter-spacing: 1px,
								$font-weight: $font-weight-normal
							);
						}
		
						&:hover {
							span {
								color: $shadow-green;
							}
						}
					}
				}

				.control {
					.mage-error {
						margin-top: 0;
					}
				}
			}
		}
	}

	.page-main {
		background-color: $gallery;
	}
}

.customer-account-forgotpassword,
.customer-account-createpassword {
	.columns {
		.main {
			.password.form {
				background-color: $account-forgotten-password-background-color;
				max-width: 768px;
				padding: $twenty-gutter;
				margin: auto;

				.fieldset,
				.actions-toolbar {
					background-color: $account-forgotten-password-background-color;
					padding-right: 0;
					padding-left: 0;
					margin-top: 0;
					max-width: $account-form-max-width;
					margin-left: auto;
					margin-right: auto;

					.primary {
						margin-top: 0;
						width: 100%;
						max-width: none;
					}

					.secondary {
						margin-bottom: 0;
						margin-left: auto;
						margin-right: auto;
						display: table;
						@extend %cancel-underline;

						a {
							@include type__16;
							@include link__underline
						}
					}
				}

				.label {
					@include min-screen($screen__s) {
						flex: 0 1 20%;
					}
				}

				.fieldset {
					padding-bottom: 0;
					// seamlessly stack the fieldset and account toolbar, not used variable's here as would assume wouldn't change

					.field.note {
						@include type__14($font-weight: $font-weight-thin);
					}
				}

				.actions-toolbar {
					padding-top: 0;
					// seamlessly stack the fieldset and account toolbar, not used variable's here as would assume wouldn't change
				}
			}
		}
	}
}

.form-newsletter-manage {
	padding: $fifteen-gutter 0 0;

	@include min-screen($screen__m) {
		padding: 0;
	}

	.fieldset {
		.field {
			margin-bottom: 0;

			&.choice {
				flex-wrap: nowrap;
				margin-bottom: $gutter;

				label {
					left: 0 !important; //Override parent CSS
					width: 100%;

					span {
						@include type__16($font-weight: $font-weight-normal);
					}
				}
			}
		}
	}
}

.form-edit-account {
	.fieldset {
		.field.choice {			
			display: inline-block;
			margin-bottom: $forty-gutter;

			label {
				left: 0 !important; //Override parent CSS
				width: 100%;
				
				span {
					@include type__16($font-weight: $font-weight-normal);
					margin-top: rem(-3); //Alignment checkbox
				}
			}
		}
	}
}
