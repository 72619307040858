/* -------------------------------------------------
 * -------------------------------------------------
 * JS Minisearch
 * -------------------------------------------------
 * @author Fisheye
 */

.js-search-container {
	position: relative;
	max-width: rem(383);
	margin: 0 $fifteen-gutter;
	z-index: 111; // to be on top of the overlay when it is triggered

	@include min-screen(449px) {
		margin: 0 auto;
	}
}

.js-search-wrapper {
	position: relative;
	background-color: $concrete;
	padding: $ten-gutter $fifteen-gutter rem(26);

	@include min-screen($screen__m) {
		padding-top: rem(19);
		padding-bottom: rem(33);
	}

	.modal-overlay {
		z-index: 110;
	}
}

.js-search-container {
	.label {
		@extend %visuallyhidden;
	}

	input {
		@include input-style-two;
		display: block;
		margin: 0 auto;
		position: relative;
		z-index: 10;
	}

	.js-search-close {
		display: none;
	}
}

// specific to category page search
// -----------------------------------
.js-search-outer-container {
	.js-search-container {
		z-index: 1;
	}

	&.active {
		.js-search-container {
			z-index: 130;
		}
	}
}

.js-search-results {
	display: none;

	&.active {
		display: block;
	}
}

.search-result-container {
	margin-bottom: $fifteen-gutter;

	&:last-child {
		margin-bottom: 0;
	}

	span {
		@include type__13(
			$color: $body-font-color
		);
	}
}

.js-search-results {
	position: absolute;
	max-width: rem(458);
	left: 0;
	right: 0;
	top: rem(125);
	background-color: $mercury;
	padding: $fifteen-gutter; // to match other search result boxes
	margin: rem(-58) $fifteen-gutter 0;
	z-index: 120;

	// Custom breakpoint
	@include min-screen(449px) {
		margin-left: auto;
		margin-right: auto;
	}

	&.active {
		display: block;
	}

	.no-results {
		color: $body-font-color;
	}
}

.js-search-result {
	text-align: left;

	&:last-child {
		margin-bottom: 0;
	}

	a {
		div {
			@include type__16;
			display: inline-block;

			&:after {
				@include svg-background(
					$name: 'arrow-right',
					$size: contain
				);
				content: "";
				display: inline-block;
				vertical-align: middle;
				width: rem(8);
				height: rem(13);
				margin: 0 $ten-gutter;
			}

			&:last-child {
				&:after {
					display: none;
				}
			}
		}
	}
}

// MegaMenu Nested Search
// -----------------------
.navigation-main-ul,
.mobile-menu {
	.js-search-container {
		margin: 0 auto;
	}

	.js-search-results {
		margin-left: rem(-16);
		margin-right: rem(-16);

		// Custom breakpoint
		@include min-screen(400px) {
			margin-left: auto;
			margin-right: auto;
		}

		@include min-screen($screen__m) {
			max-width: rem(458);
		}
	}

	.js-search-wrapper {
		background-color: $mega-menu-background-color;
		padding: 0 0 $gutter 0;
		flex: 0 1 100%;
		order: 2;
		position: relative;

		.modal-overlay {
			height: 100vh;
		}

		.input-container {
			.js-search {
				@include input-style-two(
					$background-color: $nobel
				);
				z-index: 130;
			}
		}
	}
}
