/* -------------------------------------------------
 * -------------------------------------------------
 * Product Sort By & Pagination toolbars
 *
 * -------------------------------------------------
 * @author Fisheye
 */


/* Toolbar used on category bottom and recent orders in accounts section
 * -------------------------------------------------
 */

.toolbar {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	padding: $toolbar-container-spacing-mobile;
	background-color: $toolbar-background-color;

	@include max-screen($screen__m - 1) {
		flex-wrap: wrap;
	}

	@include min-screen($screen__m) {
		justify-content: space-between;
	}

	.modes {
		display: none;
	}

	/*
	 * Showing x of y items text
	 * -----------------
	 */

	.toolbar-amount {
		order: $toolbar-amount-flex-order;
		display: $toolbar-toolbar-amount-display-setting;
		@include type__17(
			$color: $toolbar-amount-color,
			$font-family: $font-family-serif,
			$letter-spacing: 1px
		);
		font-style: italic;

		@include max-screen($screen__m - 1) {
			flex: 1 0 100%;
			order: 5;
			padding: $fifteen-gutter $fifteen-gutter $five-gutter;
			text-align: center;
		}

		@include min-screen($screen__m) {
			flex: 1 0 auto;
			padding: $ten-gutter;
			text-align: right;
		}
	}

	/*
	* Next Day Delivery Button
	* -------------------------
	*/

	.next-day-delivery {
		width: 100%;
		display: flex;
		justify-content: center;

		@include min-screen($screen__m) {
			width: auto;
		}

		&.button {
			@include button__journey($padding: $ten-gutter $twenty-gutter);

			@include min-screen($screen__m) {
				margin-right: $fifteen-gutter;
			}

			span {
				display: flex;
				align-items: center;
				@include type__14($color: $white);

				&:before {
					content: '';
					display: inline-block;
					height: 20px;
					width: 27px;
					margin-right: rem(7);
					@include svg-background(
						$name: van-white,
						$size: contain
					);
				}
			}
		}
	}

	/*
	 * Sort By - select field
	 * -----------------
	 */

	.toolbar-sorter {
		flex: 0 1 100%;
		margin-bottom: $toolbar-sorter-spacing;
		position: relative;

		@include min-screen($screen__m) {
			flex: 0 1 auto;
			width: $toolbar-sorter-width;
			margin-bottom: 0;
		}

		.sorter-label,
		.label {
			display: none;
		}

		.sorter-options {
			@include min-screen($screen__m) {
				@include select-style-three;
			}
		}

		.sorter-action {
			display: inline-block;
			width: $toolbar-sorter-action-width;
			height: $toolbar-sorter-action-height;

			span {
				@extend %visuallyhidden;
			}
		}
	}

	.limiter {
		order: 1;
		margin-right: $ten-gutter;
		margin-left: 0;

		@include min-screen($screen__m) {
			margin-left: $fifteen-gutter;
			margin-right: 0;
		}

		button.limiter-options {
			@include button__journey($padding: $five-gutter $twenty-gutter);
		}
	}

	&.toolbar-blog-posts {
		padding: 0;
		flex-wrap: nowrap;
		justify-content: center;

		.pages {
			padding: $toolbar-container-spacing-mobile;
			margin: auto;
		}
	}
}
