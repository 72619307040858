/* -------------------------------------------------
 * -------------------------------------------------
 * Generic gift wrapping styles (basket)
 * -------------------------------------------------
 * @author Fisheye
 */
.gift-wrapping-list {
	width: 100%;
}

.gift-wrapping-item,
.gift-wrapping-summary {
	align-items: center;
	cursor: pointer;
	display: flex;

	&:not(:last-child) {
		border-bottom: 1px solid $white;
		margin-bottom: $small-gutter;
		padding-bottom: $small-gutter;
	}
}

.gift-wrap-image-wrapper {
	margin-right: $small-gutter;
}

.gift-wrap-title-wrapper {
	.radio-wrapper {
		align-items: center;
		display: flex;
	}
}

.gift-wrap-price-wrapper {
	margin-left: auto;
}
