/* -------------------------------------------------
 * -------------------------------------------------
 * Some different breakpoint options for menus
 * -------------------------------------------------
 * -------------------------------------------------
 * @author Fisheye
 */

.page-header {
	// make sure we are more specific than the nasty fallbacks Mwahahahaha!
	// -------------------------------------------------
	// This bit styles general backgrounds, layout etc. only. all type styles remain specific to child themes
	.nav-sections {
		// nested for readability
		.navigation {
			.dt-width-third {
				flex: 0 1 100%;

				@include min-screen($screen__m) {
					flex: 0 1 percentage(1/3);
				}
			}

			.dt-width-fourth {
				flex: 0 1 100%;

				@include min-screen($screen__m) {
					flex: 0 1 percentage(1/4);
				}
			}

			.dt-width-fifth {
				flex: 0 1 100%;

				@include min-screen($screen__m) {
					flex: 0 1 percentage(1/5);
				}
			}

			.dt-width-sixth {
				flex: 0 1 100%;

				@include min-screen($screen__m) {
					flex: 0 1 percentage(1/6);
				}
			}

			.loading {
				// this class is added to the ul while it is loading so it can be hidden on load
				display: none;
			}
		}
	}
}