/* -------------------------------------------------
 * -------------------------------------------------
 * Hotspot Styles
 *
 * Styles ported from hotspots module
 * Styles with !important must NOT be changed
 *
 * -------------------------------------------------
 * @author Fisheye
 */

.imp-wrap {
    position: relative;
    width: auto;
    height: auto;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}

.hs-loader-wrap {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.imp-zoom-wrap > img {
    position: relative !important;
    z-index: 0 !important;
    width: 100% !important;
    height: 100% !important;
	margin: 0 !important;
	max-width: none !important;
	max-height: none !important;
    display: block !important;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}

.imp-zoom-outer-wrap {
    overflow: hidden;
}

#imp-fullscreen-wrap .imp-zoom-outer-wrap {
    overflow: visible;
}

#imp-fullscreen-wrap .imp-wrap {
    position: inherit;
}

.imp-zoom-wrap {
    transform: scale(1, 1) translate(0, 0);
    transform-origin: 0% 0%;
}

.imp-translate-wrap {}

.imp-shape-container {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
}

.imp-shape {
    cursor: pointer;
    pointer-events: all;
    transition: all 0.25s cubic-bezier(.55,0,.1,1);
    -moz-transition: all 0.25s cubic-bezier(.55,0,.1,1);
    -webkit-transition: all 0.25s cubic-bezier(.55,0,.1,1);

    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.imp-shape-spot, .imp-shape-rect, .imp-shape-oval, .imp-shape-text {
    position: absolute;
}

.imp-shape-text {
    cursor: default;
}

.imp-shape-spot {
    display: flex;
    justify-content: center;
    align-items: center;

    /* zoom scaling */
    transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
    -moz-transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
    -webkit-transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.imp-shape-spot-pin {
    transform-origin: 50% 100%;
}

.imp-spot-fontawesome-icon .fa {
    transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
    -moz-transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
    -webkit-transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
    @include svg-background(hotspot-icon); // Force hotspot icons to use custom icon
    width: $hotspot-icon-width;
    height: $hotspot-icon-height;

    &:before {
        display: none;
    }
}

.imp-shape-icon-shadow {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 0;
    transform: scale(1, 0.25);
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&amp;0.53+0,0.3+10,0.13+28,0.04+44,0+67 */
    background: -moz-radial-gradient(center, ellipse cover,  rgba(0,0,0,0.53) 0%, rgba(0,0,0,0.3) 10%, rgba(0,0,0,0.13) 28%, rgba(0,0,0,0.04) 44%, rgba(0,0,0,0) 67%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
    background: -webkit-radial-gradient(center, ellipse cover,  rgba(0,0,0,0.53) 0%,rgba(0,0,0,0.3) 10%,rgba(0,0,0,0.13) 28%,rgba(0,0,0,0.04) 44%,rgba(0,0,0,0) 67%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: radial-gradient(ellipse at center,  rgba(0,0,0,0.53) 0%,rgba(0,0,0,0.3) 10%,rgba(0,0,0,0.13) 28%,rgba(0,0,0,0.04) 44%,rgba(0,0,0,0) 67%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#87000000', endColorstr='#00000000',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}

.imp-shape img {
    width: 100%;
}

.imp-tooltip {
    display: none;
    position: absolute;
    z-index: 1;

    transition-property: none;
    -moz-transition-property: none;
    -webkit-transition-property: none;

    transition-duration: 0.2s;
    -moz-transition-duration: 0.2s;
    -webkit-transition-duration: 0.2s;

    transition-timing-function: cubic-bezier(.55,0,.1,1);
    -moz-transition-timing-function: cubic-bezier(.55,0,.1,1);
    -webkit-transition-timing-function: cubic-bezier(.55,0,.1,1);

    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.imp-tooltip.imp-tooltip-visible {
    opacity: 1 !important;
    z-index: 4;
    transform: scale(1, 1) !important;
    -moz-transform: scale(1, 1) !important;
    -webkit-transform: scale(1, 1) !important;
    background: $hotspot-text-background-color !important;
}

.imp-tooltip-plain-text {
    @include type__16;
    color: $body-font-color !important; // The color has to be set independently of the mixin to override JS color
}

.hs-arrow {
    position: absolute;
}

.hs-arrow-top {
    left: 50%;
    top: 0;
    margin-left: -10px;
    margin-top: -10px;
	width: 0;
	height: 0;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	border-bottom: 10px solid $hotspot-text-background-color !important;
}

.hs-arrow-bottom {
    left: 50%;
    top: 100%;
    margin-left: -10px;
    margin-top: 0;
	width: 0;
	height: 0;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	border-top: 10px solid $hotspot-text-background-color !important;
}

.hs-arrow-right {
    left: 100%;
    top: 50%;
    margin-left: 0;
    margin-top: -10px;
	width: 0;
	height: 0;
	border-top: 10px solid transparent;
	border-bottom: 10px solid transparent;
	border-left: 10px solid $hotspot-text-background-color !important;
}

.hs-arrow-left {
    left: 0;
    top: 50%;
    margin-left: -10px;
    margin-top: -10px;
	width: 0;
	height: 0;
	border-top: 10px solid transparent;
	border-bottom: 10px solid transparent;
	border-right: 10px solid $hotspot-text-background-color !important;
}

.imp-tooltip-buffer {
    position: absolute;
}

.imp-tooltip-buffer-top {
    left: 0;
    top: -20px;
    width: 100%;
    height: 20px;
}
.imp-tooltip-buffer-bottom {
    left: 0;
    top: 100%;
    width: 100%;
    height: 20px;
}
.imp-tooltip-buffer-left {
    left: -20px;
    top: 0;
    width: 20px;
    height: 100%;
}
.imp-tooltip-buffer-right {
    left: 100%;
    top: 0;
    width: 20px;
    height: 100%;
}

.imp-tooltip-buffer-top.imp-tooltip-buffer-large {
    left: 0;
    top: -60px;
    width: 100%;
    height: 60px;
}
.imp-tooltip-buffer-bottom.imp-tooltip-buffer-large {
    left: 0;
    top: 100%;
    width: 100%;
    height: 60px;
}
.imp-tooltip-buffer-left.imp-tooltip-buffer-large {
    left: -60px;
    top: 0;
    width: 60px;
    height: 100%;
}
.imp-tooltip-buffer-right.imp-tooltip-buffer-large {
    left: 100%;
    top: 0;
    width: 60px;
    height: 100%;
}

/* fullscreen tooltips */
.imp-fullscreen-tooltips-container {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    overflow-y: auto;
    z-index: 2147483647;
}
.imp-fullscreen-tooltips-container .imp-fullscreen-tooltip {
    display: none;
    
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    min-height: 100%;

    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;

    transition-property: none;
    -moz-transition-property: none;
    -webkit-transition-property: none;

    transition-duration: 0.2s;
    -moz-transition-duration: 0.2s;
    -webkit-transition-duration: 0.2s;

    transition-timing-function: cubic-bezier(.55,0,.1,1);
    -moz-transition-timing-function: cubic-bezier(.55,0,.1,1);
    -webkit-transition-timing-function: cubic-bezier(.55,0,.1,1);
}
.imp-fullscreen-tooltips-container .imp-fullscreen-tooltip.imp-tooltip-visible {
    opacity: 1 !important;
    z-index: 3;
    transform: scale(1, 1) !important;
    -moz-transform: scale(1, 1) !important;
    -webkit-transform: scale(1, 1) !important;
}
.imp-fullscreen-tooltips-container .imp-fullscreen-tooltip .imp-tooltip-close-button {
    position: absolute;
    right: 0;
    top: 0;
    width: 44px;
    height: 44px;
    font-size: 24px;
    line-height: 44px;
    text-align: center;
    color: white;
    cursor: pointer;
    z-index: 99;
}

/* fullscreen mode, button */
.imp-fullscreen-button {
    position: absolute;
    height: 44px;
    line-height: 44px;
    padding: 0 12px;
    background: white;
    border-radius: 5px;
    color: #222;
    z-index: 3;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.imp-fullscreen-button i {
    margin-right: 3px;
}
.imp-fullscreen-button-position-0 {
    left: 20px;
    top: 20px;
}
.imp-fullscreen-button-position-1 {
    left: 50%;
    top: 20px;
}
.imp-fullscreen-button-position-2 {
    right: 20px;
    top: 20px;
}
.imp-fullscreen-button-position-3 {
    right: 20px;
    bottom: 20px;
}
.imp-fullscreen-button-position-4 {
    left: 50%;
    bottom: 20px;
}
.imp-fullscreen-button-position-5 {
    left: 20px;
    bottom: 20px;
}
.imp-fullscreen-button-icon-only {
    width: 44px;
    text-align: center;
    font-size: 18px;
    padding: 0;
}
.imp-fullscreen-button-icon-only i {
    margin: 0;
}
#imp-fullscreen-wrap {
    position: fixed;
    z-index: 999999;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
body.imp-fullscreen-mode {
    overflow: hidden;
}

/* navigator */
.imp-ui {
    z-index: 1;
}
.imp-ui-navigator-root {
    position: absolute;
    left: 20px;
    bottom: 20px;
    z-index: 1;
    cursor: pointer;
    overflow: hidden;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}
.imp-ui-navigator-root:active {
    filter: none !important;
    -moz-filter: none !important;
    -webkit-filter: none !important;
}
.imp-ui-navigator-background-image-edgefill {
    z-index: 0;
    position: absolute;
    display: block;
    max-width: 150px;
    max-height: 150px;

    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
}
.imp-ui-navigator-background-image {
    z-index: 1;
    position: relative;
    display: block;
    max-width: 150px;
    max-height: 150px;

    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;

    -webkit-filter: blur(2px);
    -moz-filter: blur(2px);
    -o-filter: blur(2px);
    -ms-filter: blur(2px);
    filter: blur(2px);
}
.imp-ui-navigator-overlay {
    z-index: 2;
    position: absolute;
    background: black;
    width: 100%;
    height: 100%;
    opacity: 0.25;
    left: 0;
    top: 0;
}
.imp-ui-navigator-window-image {
    z-index: 3;
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    max-width: 150px;
    max-height: 150px;

    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
}
.imp-ui-zoom-button {
    position: absolute;
    right: 20px;
    bottom: 20px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    background: white;
    color: black;
    text-align: center;
    line-height: 30px;
    font-size: 12px !important;
    z-index: 1;
    border-radius: 5px;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
}
.imp-ui-zoom-button-zoom-in {
    bottom: 54px;
}


/* layers */
.imp-ui-layers-menu-wrap {
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 1;
}
.imp-ui-layer-switch {
    position: absolute;
    z-index: 2;
    left: 0;
    top: 0;
    width: 30px;
    text-align: center;
    height: 22px;
    line-height: 22px;
    cursor: pointer;
    border-radius: 5px 0 0 0;
}
.imp-ui-layer-switch-down {
    top: 22px;
    border-radius: 0 0 0 5px;
}
.imp-ui-layer-switch i {
    width: 100%;
}
.imp-ui-layer-switch:active {
    background: #0000001a;
}
.imp-ui-layers-select {
    z-index: 1;
    padding: 0 20px 0 40px !important;
    outline: none;
    -webkit-appearance: none;
    font-size: 14px;
    line-height: 44px !important;
    height: 44px !important;

    /* !important is necessary because of wp-admin styles */

    /* wordpress fixes */
    vertical-align: inherit;
    margin: 0;
    font-family: inherit;
    font-weight: inherit;
    box-shadow: none;
    background-color: rgb(248, 248, 248);
    color: black;
    outline-color: rgb(34, 34, 34);
    outline-style: none;
    outline-width: 0px;
    border: 1px solid rgb(166, 166, 166);
}

/* UI general */
.imp-ui-element {
    cursor: pointer;
    background: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    border: 1px solid #00000059;
    border-radius: 5px;
    font-family: sans-serif;
    font-size: 14px;
    box-sizing: content-box;
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
}
.imp-ui-element:active {
    filter: brightness(0.8);
    -moz-filter: brightness(0.8);
    -webkit-filter: brightness(0.8);
}
