/* -------------------------------------------------
 * -------------------------------------------------
 * Specific ie styling for banners
 * These mostly appear on the homepage and top level category pages
 * -------------------------------------------------
 * @author Fisheye
 */

/* FLEX BUGS IN IE11
 * Known bugs documented here: https://github.com/philipwalton/flexbugs
 * -------------------------------------------------
 */
.ie11 {
    .flex__column {
        padding: 0;
    }

    // To control height when multiple items in one column
    .banner-container {
        overflow: hidden;
    }

    // This is to make sure items inside .flex__column keep their sizing when
    // flex is applied to them too
    .banner-item {
        flex: 0 1 100%;
    }

    // Removes flex from inner container when large poster img is used
    .poster {
        .row-container {
            display: block;
        }
    }
}
// ----------------------------------------------------------------------------------
// ----------------------------------------------------------------------------------
