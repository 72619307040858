/* -------------------------------------------------
 * -------------------------------------------------
 * Base styles for general tables and tabular data
 *
 * As normal, only the style of the table should be here,
 * everything else should go in the correct stylesheets
 *
 * -------------------------------------------------
 * @author Fisheye
 */

table {
	border-collapse: collapse;
	width: 100%;

	caption {
		@extend %visuallyhidden;
	}

	thead {
		background-color: $container-background-color-alt;

		th {
			padding: $item-gutter;
			text-align: left;
		}
	}

	tbody {
		td {
			padding: $item-gutter;
			text-align: left;
		}
	}
}