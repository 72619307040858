/* -------------------------------------------------
 * Deals % claimed and countdown
 * Found on category and product pages
 * -------------------------------------------------
 * @author Fisheye
 */

.deals-percent-countdown {
	position: relative;
}

.countdown-event-label {
	@include type__16(
		$color: $brand-accent,
		$font-weight: $font-weight-bold
	);
	position: absolute;
	bottom: 0;
	right: 0;
}

.percentage-claimed {
	margin-top: $deals-product-percentage-claimed-margin-top;
	text-align: left;

	@include min-screen($screen__m) {
		margin-top: $deals-product-percentage-claimed-margin-top-desktop;
	}

	span {
		@include type__16();
	}
}

.percentage-claimed-bar {
	width: 100%;
	height: 7px;
	background-color: $deals-percent-bar-background-color;
	margin-bottom: $deals-percent-bar-margin-bottom;
}

.percentage-claimed-status {
	max-width: 100%; // Ensures the progress bar never exceeds its container
	background-color: $deals-percent-bar-progress-background-color;
	height: $deals-percent-bar-height;
}

// Product Pages Only
.catalog-product-view {
	.percentage-claimed {
		margin-bottom: $deals-product-percentage-claimed-margin-bottom-desktop;
	}

	.products-upsell {
		.percentage-claimed {
			margin-bottom: 0;
		}
	}
}

// Category Pages / Product listings Only
.catalog-category-view {
	.percentage-claimed {
		margin-top: $deals-category-percentage-claimed-margin-top;
	}

	.deals-percent-countdown {
		order: 6;
	}

	.percentage-claimed-bar {
		margin-bottom: $deals-category-percent-bar-margin-bottom;
	}
}

.product-item-details {
	.countdown-event-label {
		position: static;
		text-align: left;

		@include min-screen($screen__m) {
			position: absolute;
		}
	}
}

.product-item-info {
	.deals-percent-countdown {
		order: 5;
	}
}
