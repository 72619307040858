/* -------------------------------------------------
 * -------------------------------------------------
 * B2b Create Account changes
 *
 * Majority of styling is already done in Customer Account
 * This file is additions and amendments for when b2b
 * is enabled
 * -------------------------------------------------
 * @author Fisheye
 */

.company-account-create {
	.column.main {
		padding: rem(4) 0;

		@include min-screen($screen__m) {
			padding-top: 0;
			padding-bottom: $gutter;
		}
	}

	.form-create-account {
		fieldset.fieldset.info {
			border-bottom: 1px solid $concrete;
			margin-bottom: rem(12);
			padding-bottom: $item-gutter;

			@include min-screen($screen__m) {
				margin-bottom: rem(32);
				padding-bottom: rem(23);
			}

			&:last-of-type {
				border-bottom: 0;
				margin-bottom: 0;
				padding-bottom: 0;
			}

			.field.street {
				margin-bottom: rem(23);

				.control {
					margin-left: 0;
				}
			}

			.field.country_id {
				select {
					@include select-style-three;
				}
			}
		}

		fieldset {
			legend {
				@extend %cancel-visuallyhidden;
				@include underline();
				@include type__30();
				margin-left: 0;
				width: 100%;
				margin-bottom: $ten-gutter;
				border-bottom: 0;
				overflow: visible;

				@include min-screen($screen__m) {
					margin-bottom: $fifteen-gutter;
				}

				@include min-screen($screen__ml) {
					width: 80%;
				}

				@include min-screen($screen__ll) {
					width: 100%;
				}

				span {
					@include type__22($color: $account-top-link-title-color);
					text-transform: uppercase;

					@include min-screen($screen__m) {
						@include type__30($color: $account-top-link-title-color);
					}
				}
			}
		}

		.actions-toolbar {
			max-width: 50em;
			margin: 0 auto;

			.primary {
				button {
					margin-top: $five-gutter;
					padding: rem(13) $forty-gutter;
					width: 100%;

					@include min-screen($screen__m) {
						@include button__journey;
						margin-top: rem(13);
					}

					&:hover,
					&:focus,
					&:active {
						border: 0;
					}
				}
			}
		}
	}

	.page-title-wrapper {
		padding-top: $gutter;
		padding-bottom: rem(35);

		@include min-screen($screen__m) {
			padding-bottom: $forty-gutter;
		}

		a {
			@include button__default(
				$padding: rem(13) $forty-gutter
			);

			margin-left: auto;
			margin-right: auto;
			display: inline-block;
			margin-top: $small-gutter;

			@include min-screen($screen__m) {
				margin-top: $five-gutter;
				padding: rem(13) rem(80);
			}
		}
	}

	.company-account-intro {
		max-width: $account-form-max-width;
		margin-left: auto;
		margin-right: auto;

		.title {
			@include type__22($color: $account-top-link-title-color);
			text-transform: uppercase;
			display: block;
			margin-bottom: $small-gutter;

			@include min-screen($screen__m) {
				@include type__30($color: $account-top-link-title-color);
			}
		}

		p {
			@include type__13($font-weight: $font-weight-light)
		}

		.grid.benefits {
			padding: rem(22) 0 rem(25) 0;
			margin-bottom: rem(11);
			border-bottom: 1px solid $concrete;

			@include min-screen($screen__m) {
				margin-bottom: rem(32);
				padding-bottom: rem(36);
			}

			p {
				font-weight: $font-weight-bold;
				margin-bottom: rem(3);

				@include min-screen($screen__m) {
					margin-bottom: rem(18);
				}

				&:last-child {
					margin-bottom: 0;
				}

				&:before {
					display: inline-block;
					content: '';
					@include svg-background(
						$name: tick,
						$size: contain
					);
					width: em(18);
					height: em(15);
					margin-right: em(10);
				}
			}
		}
	}
}

/* Create Account - Confirmation Page */
body.company-account-index .page-main {
	.columns {
		padding: $fifteen-gutter;

		@include min-screen($screen__m) {
			padding: $gutter;
		}

		.column.main {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			flex-wrap: wrap;
			padding: 0;
			margin-top: rem(7);

			@include min-screen($screen__m) {
				margin-top: 0;
			}

			.intercom-container {
				display: none; // Script container, don't want it to show
			}

			.block-company-profile-account,
			.block-company-profile-address,
			.block-company-profile-contacts {
				flex: 0 1 100%;
				background: $grey-lightest;
				padding: $twenty-gutter;
				margin-bottom: $twenty-gutter;

				@include min-screen($screen__m) {
					flex-grow: 0;
					flex-shrink: 1;
					flex-basis: calc(50% - #{$fifteen-gutter});
				}

				@include min-screen($screen__l) {
					flex-grow: 0;
					flex-shrink: 1;
					flex-basis: calc(33.333333% - #{$fifteen-gutter});
					margin-bottom: 0;
				}

				&:last-child {
					margin-bottom: rem(7);

					@include min-screen($screen__m) {
						margin-bottom: 0;
					}
				}

				.block-title {
					@include type__19(
						$color: $nobel,
						$font-weight: $font-weight-semibold
					);
					margin-bottom: rem(16);

					@include min-screen($screen__m) {
						margin-bottom: rem(26);
					}
				}

				.box-content {
					strong,
					span {
						@include type__13($font-weight: $font-weight-normal);
					}

					strong {
						font-weight: $font-weight-bold;
					}
				}
			}

			.block-company-profile-contacts {
				@include min-screen($screen__m) {
					flex: 0 1 100%;
				}

				@include min-screen($screen__l) {
					flex-grow: 0;
					flex-shrink: 1;
					flex-basis: calc(33.333333% - #{$fifteen-gutter});
				}
			}
		}
	}
}
