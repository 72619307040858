/* -------------------------------------------------
 * -------------------------------------------------
 * Forms for checkout only
 * Most of this is should be definied in forms and inputs etc already
 *
 * -------------------------------------------------
 * @author Fisheye
 */

.checkout-container,
.page-layout-checkout._has-modal {
	form {
		.fieldset {
			.personal-details {
				@include min-screen($screen__m) {
					@include clearfix;
				}

				.field {
					@include min-screen($screen__m) {
						width: calc(50% - #{$ten-gutter});
						float: left;
					}

					&:nth-of-type(odd) {
						@include min-screen($screen__m) {
							margin-right: $ten-gutter;
						}
					}

					&:nth-of-type(even) {
						@include min-screen($screen__m) {
							margin-left: $ten-gutter;
						}
					}
				}
			}

			.field {
				&.visuallyhidden {
					@extend %visuallyhidden;
				}

				.label {
					z-index: 1;
				}

				.input-text {
					&.mage-error {
						margin-top: 0;

						~ .mage-error {
							line-height: 1;
						}
					}
				}

				.postcode-wrapper {
					position: relative;

					.postcode-lookup {
						@include button__journey($border-radius: 0 3px 3px 0);
						width: rem(170);
						height: 100%;
						position: absolute;
						top: 0;
						right: 0;

						&:disabled {
							width: auto;
						}
					}

					~ .field {
						&.choice {
							margin-top: rem(18);

							.label {
								span {
									text-transform: inherit;
								}
							}
						}
					}
				}

				&.street {
					margin-bottom: $fifteen-gutter;

					@include min-screen($screen__m) {
						margin-bottom: $twenty-gutter;
					}

					> .label {
						display: none;
					}

					.additional {
						&:last-child {
							margin-bottom: 0;
						}
					}

					&.visuallyhidden {
						@extend %visuallyhidden;
					}
				}

				&.choice {
					.label {
						span {
							text-transform: capitalize;
							@include type__16($font-weight: $font-weight-normal);
						}
					}
				}

				[id="getaddress_dropdown"] {
					margin-bottom: $fifteen-gutter;
					padding: rem(8) $fifteen-gutter rem(11);

					@include min-screen($screen__m) {
						margin-bottom: $twenty-gutter;
					}
				}
			}
		}

		&.form-login {
			.fieldset {
				.choice {
					@include min-screen($screen__m) {
						display: flex;
						justify-content: center;
						align-items: center;
					}
				}

				&.hidden-fields {
					~ .field-note {
						margin-top: -$five-gutter;
						text-align: center;
					}
				}
			}
		}

		&.form-shipping-address {
			.input-text {
				border: 1px solid $mercury;
			}

			[name="shippingAddress.country_id"] {
				label {
					@extend %visuallyhidden;
				}

				.control {
					.select {
						padding-top: $five-gutter;
						padding-bottom: $five-gutter;
						background-size: 14px auto;

						@include min-screen($screen__m) {
							padding: rem(8) $fifteen-gutter rem(11);
						}
					}
				}

				.country-wrapper {
					.country-value {
						@include type__15(
							$font-weight: $font-weight-semibold
						);

						@include min-screen($screen__m) {
							@include type__16(
								$font-weight: null
							);
						}
					}

					.edit-country {
						margin-left: $fifteen-gutter;
						position: relative;
						@include type__15($line-height: 1);
						text-transform: capitalize;
						cursor: pointer;

						@include min-screen($screen__m) {
							@include type__16($line-height: 1);
						}

						&:before {
							content: "";
							display: inline-block;
							height: 9px;
							width: 5px;
							margin-right: rem(8);

							@include svg-background(
								$name: "arrow-right-mine-shaft",
								$size: 100% 100%
							);
						}

						&:hover,
						&:focus {
							color: $shadow-green;

							&:before {
								@include svg-background(
									$name: "arrow-right-shadow-green",
									$size: null
								);
							}
						}
					}
				}

				select {
					border: 1px solid $mercury;
				}
			}

			[name="shippingAddress.postcode"] {
				margin-bottom: 0;

				[name="getaddress_input"],
				[name="postcode"] {
					padding-right: rem(185);
					border: 1px solid $mercury;
				}
			}

			.shipping-address {
				.address-rendered {
					display: flex;
					flex-direction: column;

					span {
						font-weight: $font-weight-semibold;
					}
				}
			}
		}
	}

	.address-fieldset {
		.postcode-wrapper {
			position: relative;

			.input-text {
				margin-bottom: 0;
			}
		}

		[name="shippingAddress.postcode"] {
			> .label {
				pointer-events: none;
			}
		}
	}

	.checkout-shipping-address {
		.addresses {
			.label {
				@include type__11(
					$color: $brand-accent,
					$font-weight: $font-weight-semibold
				);
				padding: $five-gutter rem(17);
				position: absolute;
				text-transform: uppercase;
				top: rem(22);
				left: rem(19);
			}

			.control {
				margin-bottom: rem(17);

				@include min-screen($screen__m) {
					margin-bottom: rem(13);
				}

				.select {
					padding-right: $fifty-gutter;
					background-size: rem(15) auto;
				}
			}

			.address-rendered {
				margin: 0;

				.postcode {
					text-transform: uppercase;
				}
			}

			.actions {
				margin-top: $twenty-gutter;

				@include min-screen($screen__m) {
					margin-top: rem(14);
				}
			}
		}

		.new-address-popup {
			@extend %visuallyhidden; // 'New Address' button - not on design + there are two other ways of adding a new address here
		}
	}

	.address-details {
		.shipping-address {
			.actions {
				span {
					position: relative;
					@include type__16;
					cursor: pointer;
					display: flex;
					align-items: center;

					&:before {
						content: "";
						height: 9px;
						width: 5px;
						position: relative;
						top: rem(1);
						margin-right: rem(7);
						@include svg-background(
							$name: "arrow-right-mine-shaft",
							$size: contain
						);
					}

					&:hover,
					&:focus {
						color: $shadow-green;

						&:before {
							@include svg-background(
								$name: "arrow-right-shadow-green",
								$size: null
							);
						}
					}
				}
			}
		}
	}
}

.page-layout-checkout._has-modal {
	[id="shipping-new-address-form"] {
		.address-details {
			.actions {
				margin: rem(12) 0 rem(14);

				@include min-screen($screen__m) {
					margin: rem(18) 0;
				}
			}

			.field {
				.field.choice {
					margin-bottom: $twenty-gutter;
				}
			}
		}
	}
}
