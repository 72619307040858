/* -------------------------------------------------
 * Account Variables
 * -------------------------------------------------
 * Table of contents
 * 1. - account Header Variables
 *	- 1a. - Paddings & Margins
 *	- 1b. - Flex Values
 *	- 1c. - Flex Orders
 *	- 1d. - Backgrounds & Colours
 *
 * 2. - Account login / create account Variables
 *	- 2a. - Paddings & Margins
 * 3. Account Forms
 * 4. Account Dashboard
 * 5. Account Wishlist
 * 6. Account Display Options
 * -------------------------------------------------
 * @author Fisheye
 */


// -------------------------------------------------
// 1. Account Header Variables
// -------------------------------------------------

$account-dropdown-username-color: $brand-accent; // Color of username on the dropdown when logged in

// -------------------------------------------------
// 2. Account Sidebar Variables
// -------------------------------------------------

$account-sidebar-background-color: transparent; // background colour of the account area sidebar
$account-sidebar-background-color-mobile: transparent; // background colour of the account area sidebar
$account-sidebar-help-margin-top-desktop: 0; // Margin top of help block in account sidebar
$account-sidebar-delimiter-margin: $twenty-gutter 0;

// -------------------------------------------------
// 2. Account login / create account Variables
// -------------------------------------------------

$account-login-block-background-color: $white; // login block background color
$account-create-block-background-color: $concrete; // create an account block background color. split into 2 vars as they are sometimes different
$account-create-account-form-background-color: $white; // create an account page form containers sometimes have colour backgrounds
$account-create-account-page-title-margin-top: $gutter;
$account-block-title-alignment: left;
$account-login-block-border: 1px solid $gray-nurse;
$account-create-block-border: 1px solid $gray-nurse;
$account-login-forgotten-password-after-display: true;
$account-login-forgotten-password-after-svg: arrow-right;
$account-login-forgotten-password-size: auto;
$account-login-create-background-color: $white;
$account-forgotten-password-background-color:  $white;
$account-login-secured-message-width: 768px;
$account-uk-wholesale-border: 1px solid #e6e6e6;

// -------------------------------------------------
// 2a. - Paddings & Margins
// -------------------------------------------------

$account-login-create-mobile-spacing: $twenty-gutter $twenty-gutter 0; // login and create an account block padding
$account-login-create-spacing: $gutter;
$account-login-create-header-spacing: $twenty-gutter;
$account-forgotten-password-spacing: rem(17) 0 rem(9) 0;
$account-create-list-items-spacing: $ten-gutter;
$account-create-list-icon-spacing: $fifteen-gutter;
$account-create-account-form-spacing-mobile: $twenty-gutter $small-gutter / 2;
$account-create-account-form-spacing: $gutter $small-gutter rem(54);
$account-inner-spacing: $item-gutter;

$account-main-spacing: $twenty-gutter;
$account-secure-text-spacing: $twenty-gutter 0 0 0; // spacing right left and bottom is correct from .main so only need a top margin

// -------------------------------------------------
// 2b. icon sizes
// -------------------------------------------------

$account-create-list-items-width: em(15);
$account-create-list-items-height: em(15);

// -------------------------------------------------
// 3. Account Forms
// -------------------------------------------------

$account-form-max-width: $form-max-width;

$account-form-label-width-tablet: 30%;
$account-form-label-width-desktop: 20%;
$account-form-control-width-desktop: 80%;
$account-form-spacing: $item-gutter;

$account-form-flex-direction: column; // Sets the direction. For labels to sit by the side of input, set to row

// -------------------------------------------------
// 4. Account Dashboard
// -------------------------------------------------

$account-top-link-block-spacing-mobile: $twenty-gutter;
$account-top-link-block-spacing: $row-gutter;
$account-top-link-column-spacing: $twenty-gutter;
$account-top-link-content-spacing: rem(25); // margin bottom for titles and text in each container
$account-top-link-title-color: $silver-chalice;
$account-box-actions-color: $brand-accent;
$account-title-wrapper-margin: 0 (-$small-gutter);
$account-title-wrapper-padding: $twenty-gutter;
$account-title-wrapper-background-color: $concrete;
$account-title-padding-left: 0;
$account-dashboard-info-margin-top: $gutter; // Margin top of account dashboard info area (Addresses, contact, newsletter)
$account-dashboard-info-margin-top-mobile: 0; // Margin top of account dashboard info area (Addresses, contact, newsletter)

// -------------------------------------------------
// 5. Account Wishlist
// -------------------------------------------------

$account-wishlist-product-item-tablet-width: percentage(1/2);
$account-wishlist-product-item-desktop-width: percentage(1/4);
$account-wishlist-product-item-border: 1px solid $border-color;
$account-wishlist-last-product-item-border: 0; // in most cases we dont need the border on the last child as the container has a border
$account-wishlist-product-item-inner-display: flex;
$account-wishlist-product-item-inner-wrap: wrap; //needed if above variable is set to flex

$account-wishlist-product-items-display: flex;
$account-wishlist-product-items-justify: flex-start;

$account-wishlist-comment-box-flex: 1 0 100%;
$account-wishlist-comment-box-margin: 0 0 $item-gutter 0;

$account-wishlist-toolbar-secondary-display: none;
$account-wishlist-toolbar-primary-display: flex;
$account-wishlist-toolbar-display: flex;
$account-wishlist-toolbar-action-margin-tablet: $item-gutter / 2;
$account-wishlist-toolbar-action-margin-desktop: $twenty-gutter;
$account-wishlist-toolbar-action-button-width: 100%;

/* Multiple Wishlist */

$account-multiple-wishlist-display: flex;
$account-multiple-wishlist-name-current-display: none;
$account-multiple-wishlist-product-item-checkbox-display: block;
$account-multiple-wishlist-title-display: none;

$account-multiple-wishlist-name-after-content: ':';
$account-multiple-wishlist-icon-size: em(15);

$account-multiple-wishlist-dropdown-toggle-z-index: 9;
$account-multiple-wishlist-dropdown-z-index: 10;

$account-multiple-wishlist-open-icon: arrow-down-brand-accent;
$account-multiple-wishlist-close-icon: arrow-up-brand-accent;

// -------------------------------------------------
// 6. Account Display options
// -------------------------------------------------

$account-wishlist-comment-box-label-display: none;
$account-wishlist-qty-field-display: none;
$account-password-strength-indicator-display: none;
$account-address-book-delete-default-display: none;
$account-pagination-limiter-label-display: none;
$account-pagination-toolbar-amount-display: none;

// -------------------------------------------------
// 7. Account Navigation
// -------------------------------------------------

$account-navigation-item-margin: 0 0 $item-gutter 0;

// -------------------------------------------------
// 8. Account Tables
// -------------------------------------------------

$account-table-inner-spacing: $fifteen-gutter 0 $fifteen-gutter $five-gutter;
$account-order-table-background-color: transparent;
$account-col-side-padding: 0;
$account-table-desktop-breakpoint: $screen__ml; // when should the shortened table be replaced by full width desktop table
$account-table-actions-padding: $ten-gutter 0 0; // Padding for actions on tables
$account-large-table-desktop-breakpoint: 1150px;
$account-table-pagination-select-max-width: em(200);
$saved-card-account-table-bottom-padding: $twenty-gutter;
$saved-card-account-table-card-number-spacing: em(5); //space between "Ending" and "1111" as by default are touching

$mobile-account-table-dividing-border: 1px solid $border-color;
$order-table-padding: 10px 0;
$mobile-order-table-padding: 12px 0 21px 0;
$mobile-order-table-column-padding: em(5) 0 em(5) 0;

//if we have a background then we need some padding so the items do not touch the edge
@if ($account-order-table-background-color!=transparent) {
	$account-col-side-padding: $account-table-inner-spacing;
}

// -------------------------------------------------
// 8. Account Address Book
// -------------------------------------------------

$account-address-book-desktop-gutter: $item-gutter;
$account-address-book-mobile-gutter: $twenty-gutter 0;
$account-address-book-block-addresses-default-gutter: 0 $account-address-book-desktop-gutter 0 0;
$account-address-book-block-addresses-list-gutter: 0 0 0 $account-address-book-desktop-gutter;
$account-address-book-block-toolbar-margin: 0; // Margin for "Add new address" button on address book page.

// -------------------------------------------------
// 9. Account Gift Card Registry
// -------------------------------------------------

$account-giftcard-actions-margin-top: $ten-gutter; // Margin above "Add New" button on account gift registry page

// -------------------------------------------------
// 10. Account Actions Toolbar
// -------------------------------------------------

$account-actions-toolbar-margin-top: $twenty-gutter; // Margin above the actions toolbar
