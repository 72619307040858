/* -------------------------------------------------
 * Object Variables
 * -------------------------------------------------
 * Table of contents
 * 1. - Tooltip Variables
 * -------------------------------------------------
 * @author Fisheye
 */

// -------------------------------------------------
// 1. Tooltip Variables
// -------------------------------------------------

$tooltip-content-padding: $fifteen-gutter;
$tooltip-arrow-color: $mine-shaft;
$tooltip-background: $mine-shaft;
$tooltip-text-color: $white;
$tooltip-icon-right: $ten-gutter;
$tooltip-icon-top: 2px;

// -------------------------------------------------
// 2. Spinner Variables
// ------------------------------------------------

$spinner-border-color: $white; // The (usually) grey coloured border that the spinner moves around
$spinner-color: $brand-accent; // The moving part of the spinner

// -------------------------------------------------
// 3. Button Variables
// -------------------------------------------------

// Big primary button, used for the journey
$button-journey-padding: rem(11) $twenty-gutter rem(13);
$button-journey-background: $shadow-green;
$button-journey-background-hover: $white;
$button-journey-border: 1px solid $shadow-green;
$button-journey-border-hover: $button-journey-border;
$button-journey-radius: 3px;
$button-journey-color: $white;
$button-journey-color-hover: $shadow-green;
$button-journey-weight: $font-weight-semibold;

// Secondary button, usually for actions
$button-default-padding: rem(11) $twenty-gutter rem(13);
$button-default-background: transparent;
$button-default-background-hover: $mine-shaft;
$button-default-border: 1px solid $mine-shaft;
$button-default-border-hover: $button-default-border;
$button-default-radius: 3px;
$button-default-color: $mine-shaft;
$button-default-color-hover: $white;
$button-default-weight: $font-weight-normal;

// Another secondary button, using the accent colour
$button-accent-background: $white;
$button-accent-padding: rem(12) rem(20);
$button-accent-radius: 0;
$button-accent-background-alt: $brand-accent;
$button-accent-color: $brand-accent;
$button-accent-color-alt: $white;

// Pagination buttons on category pages
$button-pagination-background: $angora;
$button-pagination-arrow-background: $shadow-green;
$button-pagination-current-background: $shadow-green;
$button-pagination-current-color: $white;
$button-pagination-padding: rem(7) rem(8) rem(9);
$button-pagination-radius: 0;
$button-pagination-arrow-radius: 3px;
$button-pagination-width: rem(39);
$button-pagination-height: rem(39);
$button-pagination-color: $body-font-color;

// QTY button, used for QTY tickers
$button-qty-background: $gallery;
$button-qty-background-hover: darken($button-qty-background, 12%);
$button-qty-width: rem(20);
$button-qty-height: rem(39);
$button-qty-padding: 0;
$button-qty-radius: 6px;
$button-qty-color: transparent;
$button-qty-border: 0;
$button-qty-border-hover: 0;
$button-qty-weight: $font-weight-bold;

// -------------------------------------------------
// 4. Input Variables
// -------------------------------------------------

// input-style-one
$field-color: $body-font-color;
$field-placeholder-color: $body-font-color;
$field-background-color: $white;
$field-border: 1px solid $mercury;
$field-border-hover: $field-border;
$field-border-focus: $field-border;
$field-border-radius: 3px;
$field-required-input-color: $body-font-color;
$field-color-focus: $body-font-color;
$field-padding: rem(8) $fifteen-gutter; // 10px 16px / 16 for em value


// input-style-two (For search and some other inputs)
$field-two-color: $body-font-color;
$field-two-background-color: $gallery;
$field-two-border: 0;
$field-two-border-hover: 0;
$field-two-border-focus: 0;
$field-two-border-radius: 3px;
$field-two-required-input-color: $body-font-color;
$field-two-color-focus: $body-font-color;
$field-two-padding: rem(14) $fifteen-gutter;
$field-two-placeholder-color: $body-font-color;
$field-two-placeholder-weight: $font-weight-normal;
$field-two-placeholder-font-style: normal;

// input-style-three 
$field-three-color: $body-font-color;
$field-three-background-color: $white;
$field-three-border: 0;
$field-three-border-hover: 0;
$field-three-border-focus: 0;
$field-three-border-radius: 0;
$field-three-required-input-color: $body-font-color;
$field-three-color-focus: $body-font-color;
$field-three-padding: $fifteen-gutter $twenty-gutter;
$field-three-placeholder-color: $body-font-color;
$field-three-placeholder-weight: $font-weight-medium;
$field-three-placeholder-font-style: italic;

// input-style-four 
$field-four-color: $body-font-color;
$field-four-background-color: $gallery;
$field-four-border: 0;
$field-four-border-hover: 0;
$field-four-border-focus: 0;
$field-four-border-radius: 3px;
$field-four-required-input-color: $brand-accent;
$field-four-color-focus: $body-font-color;
$field-four-padding: $ten-gutter $fifteen-gutter rem(12); // 10px 16px / 16 for em value
$field-four-placeholder-color: $body-font-color;
$field-four-placeholder-weight: $font-weight-normal;
$field-four-placeholder-font-style: normal;

// input-style-five (QTY)
$field-five-color: $body-font-color;
$field-five-background-color: $gallery;
$field-five-border: 0;
$field-five-border-hover: 0;
$field-five-border-focus: 0;
$field-five-border-radius: 0;
$field-five-placeholder-color: $body-font-color;
$field-five-width: rem(28);
$field-five-height: rem(40);
$field-five-padding: 0;

// select-style-one
$select-color: $silver-chalice;
$select-weight: $font-weight-normal;
$select-background-color: $white;
$select-border: 1px solid $silver;
$select-border-hover: 0;
$select-border-focus: 0;
$select-border-radius: 0;
$select-required-input-color: $brand-accent;
$select-color-focus: $brand-accent;
$select-padding: rem(8) $fifty-gutter rem(8) rem(14); // to match values of general input fields
$select-arrow-background-position: calc(100% - #{$twenty-gutter}) 50%;
$select-arrow-background-size: rem(10);

// select-style-two
$select-two-color: $silver-chalice;
$select-two-weight: $font-weight-semibold;
$select-two-background-color: transparent;
$select-two-border-top: 1px solid $silver;
$select-two-border-bottom: 1px solid $silver;
$select-two-border-left: 0;
$select-two-border-right: 0;
$select-two-border-hover: 0;
$select-two-border-focus: 0;
$select-two-border-radius: 0;
$select-two-required-input-color: $brand-accent;
$select-two-color-focus: $brand-accent;
$select-two-padding: rem(8) 0; // 10px 16px / 16 for em value
$select-two-arrow-background-position: 100% 50%;
$select-two-arrow-background-size: 22px 11px;

// select-style-three
$select-three-color: $mine-shaft;
$select-three-weight: $font-weight-semibold;
$select-three-background-color: $white;
$select-three-border: 1px solid $white;
$select-three-border-color-hover: $silver;
$select-three-border-focus: 0;
$select-three-border-radius: 3px;
$select-three-padding: $ten-gutter $fifteen-gutter;
$select-three-arrow-background-position: right $fifteen-gutter center;
$select-three-arrow-background-size: 13px;

// Misc
$form-hints-background: $grey-lightest;
$form-placeholder-color: $grey-lightest;
$checkbox-width: rem(25);
$checkbox-height: rem(25);
$radio-width: rem(26);
$radio-height: rem(26);
$radio-margin-right: $ten-gutter; // The spacing between a checkbox / radio button and its label.
$checkbox-margin-right: $ten-gutter; // The spacing between a checkbox / radio button and its label.

// -------------------------------------------------
// 5. Modal Variables
// -------------------------------------------------

$modal-gutter: rem(18) $fifteen-gutter rem(8); // Gutters for modals
$modal-header-border: 1px solid $border-color; // Border to divide header from content
$modal-footer-border: 1px solid $border-color; // Border to divide footer from content

// -------------------------------------------------
// 6. Price Variables
// -------------------------------------------------

$listing-price-color: $red-ribbon;
$listing-price-size: 19;
$listing-price-weight: $font-weight-bold;
$listing-price-letter-spacing: 0.06em;
$listing-price-background-color: transparent;
$listing-price-spacing: $ten-gutter;

$product-page-size: 29;
$product-page-weight: $font-weight-bold;
$product-page-letter-spacing: 0;
$product-page-transform: uppercase;
