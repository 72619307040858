/* -------------------------------------------------
 * -------------------------------------------------
 * Fotorama Gallery
 *
 * TODO: Test this. Copied directly from Urban Gifts
 *
 * -------------------------------------------------
 * @author Fisheye
 */

.product.media {
	position: relative;

	.page-title-wrapper {
		padding: $twenty-gutter * 1.5 0;
	}

	.fotorama__stage {
		@include screen($screen__m, ($screen__ml - 1)) {
			min-height: 910px;
		}

		@include min-screen(1500px) {
			min-height: 910px;
		}

		.fotorama__stage__frame {
			.fotorama__img {
				width: 100%;
				object-fit: cover;

				@include min-screen($screen__ml) {
					height: 100%;
				}
			}
		}
	}

	.fotorama__nav-wrap {
		position: absolute;
		right: 0;
		bottom: $fifteen-gutter;
		left: 0;

		@include min-screen($screen__m) {
			bottom: $twenty-gutter;
		}

		.fotorama__nav__shaft {
			.fotorama__nav__frame--dot {
				height: 10px;
				width: 10px;
				margin: 0 5px;

				.fotorama__dot {
					display: block;
					width: 10px;
					height: 10px;
					background-color: $gallery;
					border: none;
					border-radius: 50%;
					position: static;
					opacity: 0.4;
					cursor: pointer;
				}

				&.fotorama__active {
					.fotorama__dot {
						background-color: white;
						opacity: 1;
					}
				}
			}
		}
	}
}

.action-skip-wrapper,
.fotorama__caption {
	display: none;
}


/* Thubnail container
 * ---------------------------------
 */

.fotorama__zoom-icon {
	position: absolute;
	bottom: 10px;
	right: 10px;
	cursor: pointer;
	height: 40px;
	width: 40px;
	@include svg-background("zoom-icon"); // to be changed used for testing
	background-color: rgba(241, 241, 241, 0.5);
	background-size: 25px;
	display: block;
}

.fotorama--fullscreen {
	// icon hidden when zoom is selected
	.fotorama__zoom-icon {
		display: none;
	}
}

/* Full Screen
 * ---------------------------------
 */

.fotorama__fullscreen {
	.fotorama-item {
		// main body
		background-color: rgba( 241,
		241,
		241,
		0.94); // $container-background-color-alt but transparent
		.fotorama__zoom-in,
		.fotorama__zoom-out,
		.fotorama__fullscreen-icon {
			// all buttons on the page
			background-color: $container-background-color-alt;
		}

		&:before {
			display: none; // hide zoom icon when zoom page is active
		}
	}
}

// fotorama uses two spinner one is the stock magento loading block the other is the fotorama__spinner
// The gallery is attempting to use both of them which appear one after another but only one is required
.fotorama__spinner {
	display: none;
}
