/* -------------------------------------------------
 * -------------------------------------------------
 *  Layout and general styles for order summary sidebar
 *
 * -------------------------------------------------
 * @author Fisheye
 */

.checkout-index-index {
	// ---------------------------------------------------------------
	// Although technically not in the sidebar, this is what shows
	// the sidebar on mobile on click so should live here
	.opc-estimated-wrapper {
		position: relative;

		@include min-screen($screen__ml) {
			display: none;
		}

		.estimated-block {
			display: flex;
			justify-content: space-between;
			padding: $ten-gutter $page-padding-mobile;
			background-color: $alabaster;
		}

		.estimated-label {
			display: flex;
			padding-right: $twenty-gutter;
			position: relative;
			text-transform: uppercase;

			@include type__13(
				$font-weight: $font-weight-semibold
			);

			&:after {
				content: "";
				display: block;
				height: rem(10);
				width: rem(10);
				@include svg-background(
					$name: "plus",
					$size: contain
				);
				margin: auto;
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
			}
		}

		&.sidebar-open {
			.estimated-label {
				&:after {
					@include svg-background("minus");
				}
			}
		}

		.estimated-price {
			@include price;
		}

		.minicart-wrapper {
			position: absolute;
			width: 100%;
			top: 0;
			bottom: 0;

			button.action.showcart {
				// this button has the counter qty in and should take up the full
				// width of its area to make the whole thing clickable
				@extend %cancel-button;
				position: relative;
				width: 100%;
				height: 100%;
				background: transparent;
				border-color: transparent;
				border-style: none;

				.counter.qty {
					display: none; // remove quantity number on mobile Order Summary
				}
			}
		}
	}

	/* Main sidebar on desktop
 	 * -------------------------------------------------
 	 */
	.opc-sidebar {
		@include max-screen($screen__m - 1) {
			padding: $page-padding-mobile;

			&.mobile-hidden {
				display: none;
			}
		}

		.merged-cart-notice {
			padding: $gutter;
			background-color: $gallery;
			margin-bottom: $twenty-gutter;
			@include type__16($color: $patina);
			text-align: center;

			strong {
				font-weight: $font-weight-semibold;
			}

			a {
				color: $patina;
				text-decoration: underline;
			}
		}

		.modal-header {
			display: flex;
			justify-content: flex-end;

			@include min-screen($screen__m) {
				display: none;
			}

			.action-close {
				display: flex;
				align-items: center;
				margin: $twenty-gutter $twenty-gutter 0;

				&:after {
					@include svg-background('cross-circle-grey');
					height: em(27);
					width: em(27);
				}

				span {
					@include type__13;
				}
			}
		}

		.opc-block-summary {
			background-color: $checkout-sidebar-background-color;

			// -----------------------------------
			// Title and edit basket button at top
			.step-title {
				margin-bottom: $twenty-gutter;
				@include type__30(
					$font-family: $font-family-serif,
					$letter-spacing: 1px
				);

				@include max-screen($screen__m - 1) {
					display: none;
				}

				@include min-screen($screen__m) {
					@include type__35(
						$font-family: null,
						$letter-spacing: 2px
					);
					text-align: left;
				}

				span {
					font-style: italic;
				}
			}

			.button.action-edit {
				margin-bottom: $twenty-gutter;
				float: right;
				position: relative;
				@include type__15($line-height: 1);
				text-transform: capitalize;
				cursor: pointer;

				@include min-screen($screen__m) {
					@include type__16($line-height: 1);
					margin: rem(12) 0 0 $ten-gutter;
				}

				&:before {
					content: "";
					height: 9px;
					width: 5px;
					display: inline-block;
					position: relative;
					bottom: rem(1);
					margin-right: rem(3);

					@include svg-background(
						$name: "arrow-right-mine-shaft",
						$size: 100% 100%
					);
				}

				&:hover,
				&:focus {
					color: $shadow-green;

					&:before {
						@include svg-background(
							$name: "arrow-right-shadow-green",
							$size: null
						);
					}
				}
			}

			.items-in-cart {
				.title {
					display: none;
				}
			}

			.minicart-items-wrapper {
				.product-item {
					padding: $twenty-gutter 0;
					margin: 0;

					.product-item-inner {
						flex: 1;
						align-items: center;
					}

					.product {
						align-items: center;
						width: 100%;
						padding: 0;

						.product-item-name-block {
							display: flex;
							flex-wrap: wrap;
							align-items: center;
							flex: 1;
							justify-content: space-between;
							padding-right: $twenty-gutter;

							.product-item-name {
								flex: 0 1 65%;
								padding-right: 0;
								@include type__16;
							}
						}

						.details-qty {
							margin: 0;
							padding: 0 $twenty-gutter 0 $fifteen-gutter;
							text-align: right;
							justify-content: flex-end;

							.value {
								@include type__16;
							}
						}

						.subtotal {
							text-align: right;
						}

						.options {
							padding: 0;

							.toggle {
								cursor: pointer;

								&:after {
									content: '';
									display: inline-block;
									height: 8px;
									width: 13px;
									margin-left: rem(4);

									@include svg-background(
										$name: 'arrow-down',
										$size: 100% 100%
									);
								}

								span {
									font-weight: $font-weight-semibold;
								}
							}

							&.active {
								.toggle {
									&:after {
										@include svg-background(
											$name: 'arrow-up',
											$size: 100% 100%
										);
									}
								}
							}

							.content {
								margin-top: rem(3);

								.item-options {
									dd,
									dt {
										@include type__14;
										font-weight: $font-weight-semibold;
									}

									dd {
										margin-bottom: $five-gutter;

										&:last-child {
											margin-bottom: 0;
										}
									}

									dt {
										margin-bottom: 0;
									}
								}
							}
						}
					}
				}
			}

			table.table-totals {
				display: block;
				border-top: 1px solid $mercury-light;
				padding-top: rem(13);

				tbody {
					display: flex;
					flex-direction: column;
					border-bottom: none;

					.totals {
						.mark,
						.title {
							text-transform: uppercase;
						}

						&:first-of-type {
							th,
							td {
								padding: 0;
							}
						}

						.price,
						.amount {
							@include type__17($font-weight: $font-weight-semibold);
							text-transform: uppercase;
						}

						.amount {
							vertical-align: top;
						}

						&.shipping.incl {
							.value {
								display: block;
								@include type__16($font-weight: $font-weight-semibold);
								text-transform: none;
							}
						}

						&.balance {
							.action {
								@extend %cancel-button;
								@include link__underline;
								padding: 0;
							}
						}
					}

					.totals-tax-details {
						display: none;
					}

					.grand {
						order: 4;
						border-top: 1px solid $mercury-light;
						margin-top: rem(17);

						&.totals.excl {
							@extend %visuallyhidden;
						}

						th,
						td {
							padding-top: rem(13);
						}
					}
				}

				th {
					width: 100%;
					text-align: left;

					&,
					span,
					strong {
						@include type__17;
					}
				}

			   .totals.discount {
					span.discount.coupon {
						@extend %visuallyhidden;
					}
			   }

				th,
				td {
					line-height: 1.4; // custom in order to stop wrapped labels becoming squashed
					padding: rem(8) 0 0 0;
				}

				td {
					text-align: right;
					min-width: rem(160);
				}

				// -----------------------------------
				// <div> between <tr>'s containing shipping address on step 2
				.shipping-information {
					width: 100%;
					margin-top: rem(9);
					order: 3;

					.ship-to {
						display: block;
						width: 100%;
						padding: 0;

						.shipping-information-title {
							@extend %visuallyhidden;
						}

						.shipping-information-content {
							margin-bottom: 0;

							@include type__16(
								$line-height: 1.25,
								$font-weight: $font-weight-semibold
							);

							.postcode {
								text-transform: uppercase;
							}
						}

						button {
							@extend %cancel-button;
							@extend %visuallyhidden; // Hide Edit Delivery button in checkout sidebar
							@include link__underline;
							padding: 0;
							margin-top: rem(9);
							margin-bottom: rem(3);
							text-transform: capitalize;
						}
					}
				}

				.totals-tax-summary {
					order: 4;
				}
			}

			.basket-delivery-upsell {
				display: none; // remove delivery upsell block (KO used, so cannot remove via layout)
			}
		}
	}

	.opc-sidebar,
	.opc-estimated-wrapper {
		.subscriptions-notice {
			order: 5;
			margin-top: $twenty-gutter;
		}
	}
}
