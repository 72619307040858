/* --------------------------------------------------
 * --------------------------------------------------
 * Promotional messages, used on product & basket
 * --------------------------------------------------
 * @author Fisheye
 */

.free__delivery,
.basket-delivery-upsell {
	width: 100%;
	padding: $promo-message-inner-spacing;
	background-color: $mine-shaft;
	border: $promo-message-border;
	text-align: center;

	> p,
	.upsell-message,
	.upsell-message-alt {
		@if $basket-promo-text-crop == true {
			@include text-crop;
		}

		@include type__16($color: $promo-message-color);
		text-transform: $promo-message-text-transform;

		@include min-screen($screen__m) {
			@include type__18($color: null);
		}

		strong {
			font-weight: $font-weight-semibold;
		}
	}
}
