/* -------------------------------------------------
 * -------------------------------------------------
 * Creating customer account page
 *
 * -------------------------------------------------
 * @author Fisheye
 */

.form-create-account { 
	width: $container-width-mobile;
	max-width: $container-width;
	padding: $twenty-gutter;
	background-color: $account-create-account-form-background-color;
	margin: 0 auto;
	
	@include min-screen($screen__m) {
		width: 768px;
		padding: $gutter;
	}

	.block-title {
		strong {
			@include type__30;
			text-transform: uppercase;
		}
	}

	.fieldset { // form field containers
		max-width: $account-form-max-width;
		margin: 0 auto;

		&.info {
			br {
				display: none;
			}
		}

		&.create.info {
			.block-title {
				@include min-screen($screen__s) {
					margin-bottom: rem(17);
				}
			}
		}
	}

	.fieldset {
		.newsletter {
			.label {
				min-width: 100%;

				@include min-screen($screen__s) {
					justify-content: center;
				}
			}
		}
	}

	.info {
		.block-title {
			@include type__30;
		}
	}

	.account {
		.block-title {
			@extend %visuallyhidden;
		}

		br {
			display: none;
		}
	}

	.legend {
		@extend %visuallyhidden;
	}

	.actions-toolbar {
		max-width: $account-form-max-width;
		margin: auto;

		.primary {
			display: block;

			.action {
				width: 100%;
				display: block;
			}
		}

		.secondary { // back button usually hidden in designs
			@extend %visuallyhidden;
		}
	}
}

.customer-account-create,
.sales-guest-form {
	.form-create-account {
		.block-title {
			strong {
				@include type__30;
			}
		}

		.fieldset {
			.control {
				flex: $form-input-flex;

				@include min-screen($screen__ll) {
					flex: $form-input-flex-desktop;
				}
			}
		}

		.actions-toolbar .primary {
			.action span {
				@include type__18 (
					$color: $button-journey-color,
					$letter-spacing: 1px,
					$font-weight: $font-weight-normal,
				);
			}
		}
	}

	.form-create-account {
		.block-title {
			display: none;
		}

		.name-fields-wrapper {
			@include min-screen($screen__m) {
				display: flex;

				.field-name-firstname {
					margin-right: $twenty-gutter;
				}
			}
		}

		.field {
			&.field-name-firstname,
			&.field-name-lastname {
				@include min-screen($screen__m) { 
					width: calc(50% - 10px);
					float: left;
				}
			}

			&.field-name-lastname {
				float: right;
			}
		}
	}

	.page-main {
		background-color: $gallery;
	}
}

.sales-guest-form {
	.fieldset {
		.legend {
			@extend %cancel-visuallyhidden;
			margin-left: 0;
			width: 100%;
			overflow: hidden;

			> span {
				@include type__30;
				text-transform: uppercase;
			}
		}
	}

	.breadcrumbs {
		display: none;
	}
}

.sales-guest-form {
	.form-orders-search { 
		width: $container-width-mobile;
		max-width: $container-width;
		padding: $twenty-gutter;
		background-color: $account-create-account-form-background-color;
		margin: 0 auto;
		
		@include min-screen($screen__m) {
			width: 768px;
			padding: $gutter;
		}
	
		.block-title {
			strong {
				@include type__30;
				text-transform: uppercase;
			}
		}
	
		.fieldset { // form field containers
			max-width: $account-form-max-width;
			margin: 0 auto;
	
			&.info {
				br {
					display: none;
				}
			}
	
			&.create.info {
				.block-title {
					@include min-screen($screen__s) {
						margin-bottom: rem(17);
					}
				}
			}
		}
	
		.fieldset {
			.newsletter {
				.label {
					min-width: 100%;
	
					@include min-screen($screen__s) {
						justify-content: center;
					}
				}
			}
		}
	
		.info {
			.block-title {
				@include type__30;
			}
		}
	
		.account {
			.block-title {
				@extend %visuallyhidden;
			}
	
			br {
				display: none;
			}
		}
	
		.legend {
			@extend %visuallyhidden;
		}
	
		.actions-toolbar {
			max-width: $account-form-max-width;
			margin: auto;
	
			.primary {
				display: block;
	
				.action {
					width: 100%;
					display: block;
				}
			}
	
			.secondary { // back button usually hidden in designs
				@extend %visuallyhidden;
			}
		}
	}
}
