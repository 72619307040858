/* -------------------------------------------------
 * Basket Variables
 * -------------------------------------------------
 * Table of contents
 * 1. - Basket title variables
 * 2. - Basket layout variables
 * -------------------------------------------------
 * @author Fisheye
 */

// -------------------------------------------------
// 1. Basket title variables
// -------------------------------------------------

$basket-title-gutter: 0 $page-padding; // Basket page title gutter (outside, left and right)
$basket-title-gutter-mobile: 0 $page-padding-mobile; // Basket page title gutter (outside, left and right)
$basket-title-inner-spacing: $forty-gutter 0; // Baner page title spacing (padding)
$basket-title-inner-spacing-mobile: $twenty-gutter 0; // Baner page title spacing (padding)
$basket-title-color: $boulder; // Basket page title colour
$basket-title-border: 1px solid $white; // Basket page title border colour

// -------------------------------------------------
// 2. Basket layout variables
// -------------------------------------------------

$basket-col-one-width: 50%; // Width of basket column one
$basket-col-two-width: 50%; // Width of basket column two
$basket-col-gutter: $gutter; // Gutter for basket columns
$basket-border: 1px solid $silver-chalice; // Dividing border for columns

// -------------------------------------------------
// 3. Basket Items Table variables
// -------------------------------------------------

$basket-items-name-spacing: $small-gutter; // Basket items spacing for product names
$basket-items-qty-spacing: $gutter; // Basket QTY spacing 
$basket-items-qty-order: 0; // Basket qty flex order
$basket-items-price-spacing: $twenty-gutter; // Basket price spacing
$basket-items-price-order: 1; // Basket price flex order
$basket-items-actions-order: 2; // Basket actions (edit, remove) flex order
$basket-items-breakpoint: $screen__ml; // Breakpoint for when the layout of basket items switches to desktop

// -------------------------------------------------
// 4. Basket promotion areas
// -------------------------------------------------

$basket-promo-spacing-vertical: $twenty-gutter; // Inner spacing for promo areas (Top & Bottom)
$basket-promo-spacing-horizontal: 0; // Inner spacing for promo areas (Left & right)
$basket-promo-color: $mine-shaft; // Colour of promo header text
$basket-promo-border: 1px solid $gallery; // Border dividing promo boxes
$basket-promo-icon-size: rem(10); // Width & height of plus / minus icons
$basket-promo-input-width: 100%; // Width of input field. Sometimes less than 100%
$basket-promo-choice-spacing: $small-gutter; // Space between each promo checkbox
$basket-promo-price-spacing: $small-gutter; // Space between price and other elements
$basket-promo-actions-vertical-spacing: $small-gutter; // Spacing above buttons
$basket-promo-actions-horizontal-spacing: $small-gutter; // Spacing between buttons
$basket-promo-text-crop: false;

// -------------------------------------------------
// 5. Basket summary
// -------------------------------------------------

$basket-summary-background: $gallery;
$basket-summary-totals-vertical-spacing: $twenty-gutter; // Spacing above and below totals
$basket-summary-button-vertical-spacing: $gutter; // Spacing above basket journey buttons
$basket-summary-card-icons-vertical-spacing: $twenty-gutter; // Spacing above card icons
$basket-summary-total-names-weight: $font-weight-extrabold; // Font weight of the total names
$basket-summary-total-prices-weight: $font-weight-bold; // Font weight of the total prices

$basket-summary-paypal-button-background: #009dde; // if a different PayPal button is used this needs to be changed (currently light blue option)
$basket-summary-paypal-button-max-height: em(45);

$qty-input-width: em(25);  // changes based on design
$qty-input-height: em(30); // changes based on design

// -------------------------------------------------
// 6. Basket estimates
// -------------------------------------------------

$basket-estimates-note-vertical-spacing: $small-gutter; // Spacing above notes

// -------------------------------------------------
// 7. Basket help
// -------------------------------------------------

$basket-help-title-vertical-spacing: $small-gutter; // Basket help title spacing
$basket-help-times-font-style: italic; // Basket help title font style 

// -------------------------------------------------
// 8. Basket promo msg (Spend X for free delivery)
// -------------------------------------------------

$basket-promo-message-outer-spacing: $twenty-gutter; // Margin top of promo message
$basket-promo-message-top: em(-103); // Top position of promo message

// -------------------------------------------------
// 7. Minibvasket Variables
// -------------------------------------------------

$minibasket-qty-label-display: none;
$minibasket-edit-link-display: none;
$minibasket-paypal-button-max-height: em(49);
