/* -------------------------------------------------
 * -------------------------------------------------
 * How many products in the basket
 *
 * -------------------------------------------------
 * @author Fisheye
 */

.minicart-wrapper {
	.minicart-container {
		position: relative;
		width: $header-icons-width;
		
		@include min-screen($screen__m) {
			flex-basis: 100%;
		}

		svg {
			@include min-screen($screen__ll) {
				margin: 0 auto 1px;
			}
		}
	}

	.action.showcart {
		span.counter.qty {
			bottom: 0;
			position: relative;
			right: 0;

			@include min-screen($screen__m) {
				position: absolute;
			}

			.counter-number {
				@include type__16(
					$font-weight: $font-weight-semibold,
				);
				background: $minicart-count-bubble-background;
				border-radius: 100px;
				color: $minicart-count-bubble-color;
				display: block; //needed for positioning to work
				height: $minicart-count-bubble-height;
				text-align: center;
				width: $minicart-count-bubble-width;

				@include min-screen($screen__s) {
					height: $minicart-count-bubble-height-tablet;
					margin-left: $five-gutter;
					width: $minicart-count-bubble-width-tablet;
				}

				@include min-screen($screen__xl) {
					width: $minicart-count-bubble-width-desktop;
					height: $minicart-count-bubble-height-desktop;
				}
			}

			.counter-label {
				@extend %visuallyhidden;

				~ .loading-mask {
					background: none;
				}
			}
		}
	}
}
