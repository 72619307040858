/* -------------------------------------------------
 * -------------------------------------------------
 * Trustbuilder blocks in header and footer areas
 * This is styling for a Fisheye/Bluefoot CMS module needed on every site
 * -------------------------------------------------
 * @author Fisheye
 */

.trust-building-header {
	background: rgba($color: $wild-sand, $alpha: 0.95);
	box-shadow: 0 2px 1px rgba($color: $black, $alpha: 0.13);
	z-index: 0;

	.trust-building-header-content {
		padding: 0;
	}

	.full-width {
		> .row-container {
			padding: 0;
		}
	}

	.trust-building-header-column {
		width: 100%;
		display: block;
	}
}

.trust-building-content {
	display: $trustbuilder-display-mobile;

	@include min-screen($screen__m) {
		display: $trustbuilder-display-desktop;
	}

	.cms-block.trustbuilder {
		background-color: $trustbuilder-background;
		padding: $trustbuilder-inner-spacing-vertical $trustbuilder-inner-spacing-horizontal;
	}

	.trustbuilder-item {
		display: block;
		text-align: center;
		padding-top: 0;
		padding-bottom: 0;

		@if ($trust-builder-border-display==true) {
			border-right: $trustbuilder-item-border;
		}

		&:nth-child(2) {
			display: none;
		}

		&:last-child {
			border-right: 0;
		}

		@include min-screen($screen__m) {
			&:nth-child(2) {
				display: block;
			}
		}
	}

	.trustbuilder-item,
	.trustbuilder-item span {
		@include type__16($color: $trustbuilder-color);
	}

	.trustbuilder-item span {
		font-weight: $trustbuilder-title-weight;
	}

	// ul {
	// 	display: flex;
	// 	justify-content: space-between;

	// 	li {
	// 		display: block;
	// 		text-align: center;
	// 		color: $trust-builder-color; // control dropping to two items instead of 3 with two lines
	// 		&:first-child {
	// 			@include min-screen($screen__l) {
	// 				text-align: left;
	// 			}
	// 		}

	// 		&:last-child {
	// 			display: none;

	// 			@include min-screen($screen__l) {
	// 				display: block;
	// 				text-align: right;
	// 			}
	// 		}

	// 		&:nth-child(2) {
	// 			// text-align: right;
	// 			@include min-screen($screen__l) {
	// 				text-align: center;
	// 			}
	// 		}
	// 	}
	// }
}
