/* -------------------------------------------------
 * -------------------------------------------------
 * Global modal styling
 * -------------------------------------------------
 * @author Fisheye
 */

.modal-popup {
	background-color: $overlay-color-alt;
	padding: $modal-gutter;

	.modal-inner-wrap {
		max-width: em(800);
		box-shadow: none;
		border: solid 1px $border-color;
		margin: 0 auto;
	}

	.modal-header {
		display: flex;
		justify-content: space-between;
		padding: $modal-gutter;

		@include min-screen($screen__m) {
			padding: $twenty-gutter $twenty-gutter rem(12);
		}

		h1 {
			padding: 0;
			border: none;
			@include type__17(
				$font-weight: $font-weight-medium,
				$letter-spacing: 1px
			);
			text-transform: uppercase;
		}

		.action-close {
			@extend %cancel-button;
			padding: 0;
			margin: 0;
			display: inline-block;

			@include min-screen($screen__m) {
				&:after {
					content: "";
					display: inline-block;
					width: 10px;
					height: 10px;
					@include svg-background('cross');
					margin-left: rem(3);
				}
			}
		}
	}

	.modal-content {
		padding: $ten-gutter $fifteen-gutter $twenty-gutter;

		@include min-screen($screen__m) {
			padding: $five-gutter $twenty-gutter $ten-gutter;
		}

		.modal-content {
			padding: 0;
		}

		.field.choice {
			margin: 0;
		}

		.search-container {
			.field {
				&:last-child {
					margin-bottom: 0;
				}
			}
		}

		.enter-address-manually {
			@include link__underline;
			@include type__13;
			text-transform: capitalize;
			cursor: pointer;
		}
	}

	.modal-footer {
		padding: rem(7) $fifteen-gutter rem(22);
		text-align: right;

		@include min-screen($screen__m) {
			padding: rem(14) $twenty-gutter rem(21);
		}

		.action,
		.action-accept {
			@include button__default;
			margin-left: $ten-gutter;
		}

		.action-dismiss {
			@include button__default($padding: $button-default-padding);
			display: inline;
			border: 0;
		}
	}

	&.gift-services {
		.modal-header {
			padding: rem(18) rem(18) $twenty-gutter rem(19);

			.modal-title {
				@include type__17;
				text-transform: uppercase;
				display: flex;
				align-items: center;

				&:before {
					content: '';
					display: inline-block;
					height: 21px;
					width: 36px;
					margin-right: rem(3);

					@include svg-background(
						$name: 'giftwrap-icon',
						$size: 100% 100%
					)
				}
			}
		}

		.modal-inner-wrap {
			@include min-screen($screen__m) {
				width: rem(586);
			}
		}

		.modal-content {
			padding: 0;
		}

		.gift-wrapping,
		.gift-wrapping-summary {
			padding: rem(18) rem(19) $twenty-gutter;
			background-color: $gallery;

			.gift-options-title {
				@extend %visuallyhidden;
			}

			.gift-options-content {
				display: flex;
				align-items: center;

				.gift-wrapping-item {
					img {
						width: rem(83);
					}
				}

				.gift-wrapping-title,
				.gift-wrapping-name {
					margin-left: $fifteen-gutter;
					display: flex;
					flex: 1;

					.gift-wrapping-name {
						flex: 1;
					}
				}
			}
		}

		.gift-wrapping-summary {
			cursor: auto;

			img {
				width: rem(83);
			}

			.gift-wrapping-summary-title {
				font-weight: $font-weight-semibold;
				margin-right: $five-gutter;
				text-transform: capitalize;
			}
		}

		.gift-message {
			padding: rem(17) rem(19) 0;

			.gift-options-title {
				text-align: center;
				margin-bottom: $fifteen-gutter;

				span {
					font-style: italic;

					@include type__24(
						$font-family: $font-family-serif,
						$font-weight: $font-weight-normal,
						$letter-spacing: 1px
					);
				}
			}

			.gift-options-content {
				.field {
					&.text {
						> .label {
							background-color: $white;
							width: calc(100% - 18px);
							left: rem(1);
							padding-left: rem(14);
						}
					}
				}
			}
		}

		.gift-summary-wrapper {
			padding: 0 rem(19);
		}

		.actions-toolbar {
			padding: 0 $twenty-gutter $twenty-gutter;

			.secondary {
				display: flex;
				flex: 1;
				flex-flow: column wrap;
				align-items: center;
				justify-content: center;

				.action-update {
					@include button__journey;
					width: 100%;
				}

				.action-cancel,
				.action-edit {
					margin-top: rem(14);
				}

				.action-delete {
					margin-top: $ten-gutter;
				}

				.action-cancel,
				.action-edit,
				.action-delete {
					@extend %cancel-button;

					&:before {
						content: '';
						display: inline-block;
						position: relative;
						bottom: rem(1);
						margin-right: rem(2);

						@include form-submit-inline-button(
							$width: 8px,
							$height: 9px
						);
					}
				}
			}
		}
	}
}

.modal-popup.modal-slide:not(.block-basket-preview) {
	.modal-inner-wrap[class] {
		.modal-content {
			background-color: $body-background-color;
		}

		.page-layout-checkout & {
			background-color: $body-background-color;
			min-height: auto;
		}
	}
}

/* this is added to the body tag when the minicart is modal-open
 * this stops weird scrolly issues on mobile because both basket
 * contents and the body are trying to scroll
 */

.modal-open,
.search-modal-open {
	height: 100vh;
	overflow: hidden;
	padding-right: 15px; // prevent layout shift when scrollbar is removed
}
