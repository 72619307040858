/* -------------------------------------------------
 * -------------------------------------------------
 * Gift Card specific styling
 *
 * -------------------------------------------------
 * @author Fisheye
 */

//actual giftcard product page
.catalog-product-view {
	.product-info-main {
		.product-info-addto {
			fieldset.giftcard-amount {
				width: 100%;

				.field {
					flex-direction: column;
				}
			}

			.giftcard.form {
				fieldset.giftcard {
					.field {
						flex-direction: column;
					}
				}
			}
		}
	}
}
