// ------------------------------------------------------------
// ------------------------------------------------------------
// Trade company credit page
// Most styles for this page are from account tables
// ------------------------------------------------------------
// ------------------------------------------------------------

.company_credit-history-index {
	.credit-balance-list {
		display: none;
	}

	.limiter-options {
		margin-right: 0;
		max-width: none;
	}
}
