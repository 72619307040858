/* -------------------------------------------------
 * -------------------------------------------------
 * Multishipping / multi-address checkout
 *
 * -------------------------------------------------
 * @author Fisheye
 */

// Generic multishipping page styling for all routes
body[class^='multishipping-checkout'] {
	header.page-header {
		.header.content {
			.logo {
				flex: 0 1 100%;

				a {
					text-align: center;
				}
			}
		}
	}

	.no-display {
		display: none;
	}

	.box-title {
		.action {
			@include button__default;
			margin-left: $ten-gutter;
		}
	}
}

// Seperate styles for multishipping checkout page
// Separate page for multiple addresses login
.multishipping-checkout-login {
	.main {
		@include min-screen($screen__ml) {
			max-width: 1250px;
			margin: 0 auto;
			width: 100%;
		}
	}

	.page-title-wrapper {
		padding-bottom: 0;

		@include min-screen($screen__m) {
			padding-bottom: $five-gutter;
		}
	}

	.page-main {
		background-color: $gallery;

		.actions-toolbar {
			.primary {
				display: block;

				.action {
					@include button__journey;
					width: 100%;

					span {
						@include type__18(
							$color: $button-journey-color,
							$letter-spacing: 1px,
							$font-weight: $font-weight-semibold,
						);
					}
				}
			}
		}

		.columns {
			width: 100%;
			margin: 0 auto;
			padding: 0 $page-gutter $gutter $page-gutter; // top spacing not needed as coming from cms menu

			@include min-screen($screen__m) {
				padding: $gutter $page-padding $fifty-gutter;
			}

			.sidebar-main {
				padding: 0;
			}
		}

		.main {
			padding: 0 0 $twenty-gutter;
		}
	}

	.login-container {
		.block-title {
			strong {
				@include type__35(
					$color: $black,
					$font-family: $font-family-serif,
					$font-weight: $font-weight-normal
				);
				text-align: center;
			}
		}
	}

	.block {
		.block-title {
			margin: 0 auto;

			@include min-screen($screen__m) {
				margin: 0;
			}
		}

		.block-title,
		.legend,
		.legend span {
			@include type__35($font-family: $font-family-serif);
			border-bottom: 0;
			padding-bottom: $fifteen-gutter;

			strong {
				@include type__35($font-family: $font-family-serif);
				display: flex;
			}
		}
	}

	.login-secured-message {
		@include secure__text;
		padding: 0 0 $twenty-gutter;
		background-color: $white;
		margin: 0 auto;

		@include min-screen($screen__m) {
			max-width: $account-login-secured-message-width;
			padding-bottom: $gutter;
		}
	}
}

// Separate page for multiple addresses
.multishipping-checkout-addresses  {
	.multicheckout {
		.title {
			font-size: rem(25);
			padding-bottom: $twenty-gutter;

			strong {
				$font-weight: $font-weight-normal;
			}
		}

		.table-wrapper {
			.table {
				thead {
					background-color: $white;
					border-bottom: 1px solid $gallery;
   					margin-bottom: 1.25rem;
				}

				tbody {
					tr {
						border-bottom: 1px solid $gallery;

						td.qty {
							.qty {
								width: rem(40);
							}
						}

						td.col.address {
							.field.address {
								.label {
									@extend %visuallyhidden;
								}
							}
						}
					}
				}
			}
		}

		.actions-toolbar {
			display: block;
			justify-content: space-between;
			padding: $forty-gutter 0;

			@include min-screen($screen__ml) {
				display: flex;
			}

			.primary {
				margin-bottom: $twenty-gutter;

				@include min-screen($screen__ml) {
					margin-bottom: 0;
				}

				button.action {
					@include button__journey;
					margin-right: $ten-gutter;
				}

				a.action.back {
					color: $acapulco;
				}
			}

			.secondary {
				display: flex;

				button {
					@include button__journey;

					&.action.update {
						margin-right: $twenty-gutter;
					}
				}
			}
		}
	}
}

// Separate page for multiple shipping
.multishipping-checkout-shipping {
	.column.main {
		.form.multicheckout.shipping {
			.block-shipping {
				.block-title {
					border-bottom: 1px solid $gallery;
					padding: $twenty-gutter 0;
				}

				.block-content {
					display: flex;
					flex: 1 1 100%;
					flex-wrap: wrap;

					.box-shipping-address,
					.box-shipping-method,
					.box-items {
						flex: 1 1 100%;
						padding-top: $twenty-gutter;

						@include min-screen($screen__xs) {
							flex: 1 1 50%;
						}

						@include min-screen($screen__ml) {
							flex: 1 1 33.33%;
						}

						.gift-message {
							.choice {
								.label {
									> span {
										@include type__16;
									}
								}
							}

							.action-gift {
								margin-bottom: $twenty-gutter;
							}

							.gift-wrapping {
								.nested {
									display: flex;
									align-items: center;
									justify-content: space-between;
									padding-top: $twenty-gutter;

									img {
										flex: 0 1 rem(84);
										width: rem(84); // override inline styles
									}

									.price-box {
										text-align: right;
									}
								}
							}
						}
					}

					.box-content {
						padding-top: $twenty-gutter;

						thead {
							background-color: $white;
							border-bottom: 1px solid $gallery;
						}

						.options-items {
							.action {
								margin-bottom: $twenty-gutter;
							}
						}
					}

					.box-shipping-method { // Different layout - had to manually add checkbox styles
						.methods-shipping {
							.field.choice {
								margin-left: rem(-15);

								label {
									display: flex;
									align-items: center;
									position: relative;

									.price {
										margin-left: $ten-gutter;
										@include price;
									}
								}
							}
						}
					}
				}
			}

			.actions-toolbar {
				display: block;
				align-items: center;
				padding-top: $twenty-gutter;

				@include min-screen($screen__xs) {
					display: flex;
				}

				.primary {
					button.action {
						@include button__journey;
						margin-right: $ten-gutter;
					}
				}

				.secondary {
					a.action.back {
						color: $acapulco;
					}
				}
			}
		}
	}
}

// Separate page for enter a billing details
.multishipping-checkout-billing {
	.box-content {
		margin-top: $twenty-gutter;
	}

	.actions-toolbar {
		display: block;
		align-items: center;
		padding-top: $twenty-gutter;


		@include min-screen($screen__xs) {
			display: flex;
		}

		.primary {
			button.action {
				@include button__journey;
				margin-right: $ten-gutter;
			}
		}

		.secondary {
			a.action.back {
				color: $acapulco;
			}
		}
	}
}

//Review order page styles
.multishipping-checkout-overview {
	.block-billing,
	.block-shipping {
		.block-title {
			border-bottom: 1px solid $gallery;
			padding-bottom: $ten-gutter;
		}

		.block-content {
			padding-top: $ten-gutter;
			display: flex;
			flex-wrap: wrap;
			flex: 1 1 100%;

			.title {
				flex: 1 1 100%;
			}

			.box-billing-address,
			.box-shipping-address,
			.box-billing-method,
			.box-shipping-method {
				flex: 1 1 50%;
			}

			.box-items {
				flex: 1 1 100%;
			}
		}
	}

	.block-shipping {
		padding-top: $forty-gutter;

		.box-content {
			padding-bottom: $gutter;

			thead {
				background-color: $white;
				border-bottom: 1px solid $gallery;
				margin-bottom: 1.25rem;
			}

			th.item {
				padding: $five-gutter 0;
			}

			tfoot {
				background-color: $gallery;

				.mark {
					text-align: right;
				}

				.amount {
					padding-left: $ten-gutter;
				}
			}
		}
	}

	.checkout-review {
		.actions-toolbar {
			display: block;
			align-items: center;
			padding-top: $twenty-gutter;

			@include min-screen($screen__xs) {
				display: flex;
			}

			.primary {
				button.action {
					@include button__journey;
					margin-right: $ten-gutter;
				}
			}

			.secondary {
				a.action.back {
					color: $acapulco;
				}
			}
		}
	}

	form.form {
		.block-billing,
		.block-shipping {
			.block-content {
				.box-title {
					a.action.edit {
						span {
							font-weight: 400 !important; // Override other form styles
							margin-left: $five-gutter;
						}
					}
				}
			}
		}
	}
}
// Form styles to edit address - styles taken from edit address account page
.multishipping-checkout_address-editshipping,
.multishipping-checkout_address-newshipping {
	.fieldset {
		.legend {
			span {
				@include type__35($font-family: $font-family-serif);
				border-bottom: 0;
				padding-bottom: $fifteen-gutter;
			}
		}
	}

	.actions-toolbar {
		margin-top: $account-actions-toolbar-margin-top;

		@include min-screen($screen__m) {
			margin-top: $ten-gutter;
		}

		.secondary {
			display: inline-block;
			@include type__16;
			@include link__underline;
			margin-top: $account-inner-spacing;
		}

		.primary {
			display: block;

			.action {
				@include button__journey;
				width: 100%;

				span {
					@include type__18(
						$color: $button-journey-color,
						$letter-spacing: 1px,
						$font-weight: $font-weight-normal,
					);
				}
			}
		}
	}
}

.multishipping-checkout-success {
	text-align: center;

	h3 {
		@include type__35($font-family: $font-family-serif);
		border-bottom: 0;
		padding: $ten-gutter 0;
	}

	.actions-toolbar {
		margin-top: $account-actions-toolbar-margin-top;

		@include min-screen($screen__m) {
			margin-top: $ten-gutter;
		}

		.secondary {
			display: inline-block;
			@include type__16;
			@include link__underline;
			margin-top: $account-inner-spacing;
		}

		.primary {
			display: block;

			.action {
				@include button__journey;

				span {
					@include type__18(
						$color: $button-journey-color,
						$letter-spacing: 1px,
						$font-weight: $font-weight-normal,
					);
				}
			}
		}
	}
}
