/* -------------------------------------------------
 * -------------------------------------------------
 * Footer accordion to show hide columns of content on mobile
 *
 * -------------------------------------------------
 * @author Fisheye
 */

// this has been created so that the correct em conversion can be used in calc()
$top: em(12.5);

.mob-footer-info-tab {
	order: 2;
	padding: 0 $fifteen-gutter;
	border-top: 1px solid $gallery;

	.mobile-accordion-title {
		padding: $fifteen-gutter 0;
		position: relative;
		@include type__17(
			$font-weight: $font-weight-medium,
			$letter-spacing: 1px
		);
		text-transform: uppercase;

		&:after {
			content: "";
			display: block;
			height: em(12);
			width: em(12);
			margin: auto;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			@include svg-background(
				$name: $footer-accordion-icon-expand,
				$size: contain
			);
		}

		&.open {
			&:after {
				@include svg-background(
					$name: $footer-accordion-icon-collapse,
					$size: contain
				);
			}
		}
	}
}
