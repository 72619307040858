// ---------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------
// SVG background images with PNG and retina fallback
// Taken from: http://zerosixthree.se/8-sass-mixins-you-must-have-in-your-toolbox/
// ---------------------------------------------------------------------------------
// This mixin depends on Modernizr and creates a bit more work for you
// when creating images for your site, but it’s really worth it in the end.
// You need one .svg file, that will serve as the default background image.
// You’ll also need a regular .png that serves as a fallback for
// non-svg-supporting browsers. And last you need a twice as large .png
// as a second fallback to retina screens.
// All in all you need this:
// pattern.svg
// pattern.png
// pattern@2x.png
// Usage:
// body {
//   @include svg-background('pattern');
// }
// ------------------------------------------------------------------
// ------------------------------------------------------------------
$image-path: "../images/svg/" !default;
$fallback-extension: "png" !default;
$retina-suffix: "@2x";

@mixin svg-background($name, $size:false) {
	background-image: url(#{$image-path}/#{$name}.svg);
	background-repeat: no-repeat;
	background-position: center;
	@if ($size) {
		background-size: $size;
	}
	.no-svg & {
		background-image: url(#{$image-path}/#{$name}.#{$fallback-extension});

		@media only screen and (-moz-min-device-pixel-ratio: 1.5),
		only screen and (-o-min-device-pixel-ratio: 3/2),
		only screen and (-webkit-min-device-pixel-ratio: 1.5),
		only screen and (min-device-pixel-ratio: 1.5) {
			background-image: url(#{$image-path}/#{$name}#{$retina-suffix}.#{$fallback-extension});
		}
	}
}
