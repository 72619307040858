/* -------------------------------------------------
 * -------------------------------------------------
 * Magento Global Messages
 *
 * -------------------------------------------------
 * @author Fisheye
 */

/* Default messages throughout the website
 * -------------------------------------------------
 */

.page.messages {
	max-width: $message-max-width;
	margin: 0 $page-gutter;

	@include min-screen($screen__m) {
		margin: 0 $gutter;
	}

	.message {
		padding: $fifteen-gutter $ten-gutter;
		display: flex;
		align-items: center;

		&:first-of-type {
			margin-top: $twenty-gutter;
		}

		&:last-of-type {
			margin-bottom: $twenty-gutter;
		}

		&:before {
			content: "";
			margin-right: $five-gutter;
			flex: 0 0 auto;
		}

		> div {
			@include type__16($font-weight: $font-weight-bold);
		}

		&.error {
			background-color: $error-message-background;
			@include type__13(
				$font-weight: $font-weight-semibold,
				$color: $error-message-color
			);

			&:before {
				@include svg-background(
					$name: error,
					$size: contain
				);
				width: em(18);
				height: em(18);
			}
		}

		&.info {
			background-color: $info-message-background;
			@include type__13(
				$font-weight: $font-weight-semibold,
				$color: $info-message-color
			);

			&:before {
				@include svg-background(
					$name: info,
					$size: contain
				);
				width: em(18);
				height: em(18);
			}
		}

		&.notice {
			background-color: $notice-message-background;
			@include type__13(
				$font-weight: $font-weight-semibold,
				$color: $notice-message-color
			);

			&:before {
				@include svg-background(
					$name: info,
					$size: contain
				);
				width: em(18);
				height: em(18);
			}
		}

		&.success {
			background-color: $success-message-background;
			@include type__13(
				$font-weight: $font-weight-semibold,
				$color: $success-message-color
			);

			&:before {
				@include svg-background(
					$name: success,
					$size: contain
				);
				width: em(25);
				height: em(25);
			}
		}

		&.warning {
			background-color: $warning-message-background;
			@include type__13(
				$font-weight: $font-weight-semibold,
				$color: $warning-message-color
			);

			&:before {
				@include svg-background(
					$name: warning,
					$size: contain
				);
				width: em(18);
				height: em(18);
			}
		}
	}
}

/* Random messages not caught by defaults
 * -------------------------------------------------
 * used in checkout when postcode doesn't look right
 */

.message {
	&[ generated="true"] {
		@include type__notes;
		text-align: center;
		margin-top: $item-gutter;

		@include type__13(
			$font-weight: $font-weight-semibold,
			$color: $form-notices-color
		);
	} // used in account area when adding / editng an address
	&.info {
		@include type__notes;
		margin-bottom: $item-gutter / 2;
		@include type__13($font-weight: $font-weight-semibold);
	}

	&.error,
	&.message-error {
		margin-top: $ten-gutter;
		@include type__13(
			$font-weight: $font-weight-semibold,
			$color: $form-notices-color
		);
	}

	&.warning {
		margin-top: $item-gutter;

		@include type__13(
			$font-weight: $font-weight-semibold,
			$color: $form-notices-color
		);
	}

	&.notice {
		padding-top: $ten-gutter;

		@include type__13(
			$color: $form-notices-color,
			$font-weight: $font-weight-semibold
		);
	}
}

.mage-error {
	&[ generated="true"] {
		text-transform: initial;

		@include type__13(
			$color: $form-notices-color,
			$font-weight: $font-weight-semibold
		);
	}

	.search-subtext {
		padding: 0;

		@include type__13(
			$color: $form-notices-color,
			$font-weight: $font-weight-semibold
		);
	}
}

/* need help block that appears in basket / checkout /
 * account areas etc on some sites
 * -------------------------------------------------
 */

.block.need-help {
	padding: $gutter $fifteen-gutter;
	background-color: $gallery;
	margin-top: $fifteen-gutter;
	text-align: center;

	@include min-screen($screen__m) {
		padding: $fifty-gutter $gutter;
		margin-top: $twenty-gutter;
	}

	h2 {
		margin-bottom: $ten-gutter;

		@include type__28(
			$color: $body-font-color,
			$letter-spacing: 1px
		);

		@include min-screen($screen__m) {
			margin-bottom: rem(7);

			@include type__35(
				$font-family: null,
				$letter-spacing: 1px
			);
		}
	}

	.content {
		@include type__16($letter-spacing: 1px);
		text-transform: uppercase;

		@include min-screen($screen__m) {
			@include type__22(
				$font-weight: $font-weight-normal,
				$letter-spacing: 1px
			);
		}

		a {
			text-decoration: underline;
			opacity: 1;
		}
	}
}

/* Additional checkout notices
 * -------------------------------------------------
 */

.opc-wrapper .form-login .fieldset,
.opc-wrapper .form-shipping-address .fieldset,
.checkout-shipping-address {
	.field-note {
		justify-content: center;
		margin-bottom: rem(18);

		@include min-screen($screen__m) {
			text-align: center;
		}

		span {
			@include type__15(
				$color: $patina,
				$font-weight: $font-weight-medium
			);

			@include min-screen($screen__m) {
				@include type__16(
					$color: null,
					$font-weight: null
				);
			}
		}
	}
}

.page-layout-checkout {
	.messages {
		.error {
			display: flex;
			padding: $five-gutter;
			background-color: $error-message-background;
			margin: 0 0 $twenty-gutter;
			@include type__13(
				$font-weight: $font-weight-semibold,
				$color: $error-message-color
			);

			&:before {
				content: '';
				display: block;
				position: relative;
				top: 2px;
				@include svg-background(
					$name: error,
					$size: contain
				);
				width: em(18);
				height: em(18);
				margin-right: $ten-gutter;
			}

			div { // no class or id
				margin: 0;
			}
		}
	}

	[id="getaddress_error_message"] {
		margin-top: $fifteen-gutter;
		display: block;
		color: $error-message-color;
		font-weight: $font-weight-semibold;
	}
}

.free-delivery {
	width: 100%;
	padding: em(15) em(20);
	border: 1px solid $border-color;
	font-weight: $font-weight-bold;
	text-transform: uppercase;
	text-align: center;
}

/* Product page messages
 * -------------------------------------------------
 */

.product-main-container {
	.page.messages {
		width: 100%;

		.message {
			width: 100%;
			margin-top: 0;
			margin-bottom: $item-gutter;
		}
	}

	.product-info-addto {
		.page.messages {
			margin: 0;
		}
	}
}

/* B2B Company Warning - Shows globally
 * -------------------------------------------------
 */

 .message.company-warning {
	@include type__13;
	border-radius: 5px;
	padding: em(10);
	background-color: $warning-message-background;
	color: $warning-message-color;
	text-align: center;

	&:before {
		@include svg-background(
			$name: warning,
			$size: contain
		);
		content: "";
		display: inline-block;
		vertical-align: middle;
		width: em(18);
		height: em(18);
	}
}

/* contact page specific styles
 * -------------------------------------------------
 */

.contact-index-index  {
	.page {
		&.messages {
			order: 1;
		}
	}
}

// Custom message styles if category search results page is empty
.page-with-filter.page-products {
	.message.info {
		&.empty {
			max-width: 1280px;
			margin: 0 auto;
			text-align: center;
			padding: $fifty-gutter $twenty-gutter rem(300);

			div {
				@include type__20;
				font-style: normal;
			}

		}
	}
}

/* Basket specific styles
 * -------------------------------------------------
 */
.checkout-cart-index {
	.page {
		&.messages {
			margin: 0;
		}
	}
}

.account {
	.message.info.empty {
		padding: $fifteen-gutter 0 0;

		@include min-screen($screen__m) {
			padding: 0;
		}
	}
}
