/* -------------------------------------------------
 * -------------------------------------------------
 * Header Styling
 * -------------------------------------------------
 * @author Fisheye
 */

 .action.skip.contentarea {
	@extend %visuallyhidden;
}

// -------------------------------------------------
// Page Header Container
// -------------------------------------------------

.page-header {
	background-color: $header-background;
	width: 100%;
	padding-bottom: 0;
	display: flex;
	flex-direction: column;

	@include min-screen($screen__m) {
		display: block;
		position: relative;
	}

	&.fixed {
		position: fixed;
		z-index: 10;

		.header.content {
			display: flex;

			@include min_screen($screen__m) {
				display: none;
			}
		}

		.trust-building-header {
			[id="trustbuilder-slider"] {
				// height used here instead of display to avoid slick slider initialize every time
				// the user triggers the fixed header which makes the columns stack.
				// this method allows the slider to continue running while hidden
				height: 0;
				overflow: hidden;
				position: relative;
			}
		}
	}
}

// -------------------------------------------------
// Header Message Panel (Need Help, etc)
// -------------------------------------------------

.page-header {
	padding-top: calc(#{$header-panel-height} + #{$header-mobile-content-height});
	z-index: 90;

	@include min-screen($screen__m) {
		padding-top: $header-panel-height;
	}

	.panel.wrapper {
		align-items: center;
		background: $header-panel-background;
		display: flex;
		height: $header-panel-height;
		justify-content: center;
		padding: $header-panel-padding;
		position: fixed;
		text-align: center;
		text-transform: uppercase;
		top: 0;
		width: 100%;
		z-index: 95;

		@include min-screen($screen__m) {
			height: $header-panel-height;
		}

		@if $header-above-overlay == true {
			@include min-screen($screen__m) {
				z-index: 100;
			}
		}

		p {
			@include type__13(
				$color: $header-panel-color,
				$font-weight: $font-weight-semibold,
				$letter-spacing: rem(1)
			);

			@include min-screen($screen__ml) {
				@include type__16(
					$color: null,
					$font-weight: null,
					$letter-spacing: null
				);
			}

			span,
			a {
				margin-left: $five-gutter;
				@include type__13(
					$color: $header-panel-color,
					$font-family: $font-family-serif,
					$font-weight: $font-weight-medium,
					$letter-spacing: rem(1)
				);
				font-style: italic;
				text-transform: capitalize;

				@include min-screen($screen__ml) {
					@include type__16(
						$color: null,
						$font-family: null,
						$font-weight: null,
						$letter-spacing: null
					);
				}
			}
		}

		.wholesale-export-link {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;

			.pagebuilder-button-primary {
				display: flex;
				height: 100%;
				min-height: unset;
				padding: $five-gutter $gutter;
				background-color: $sea-nymph;
				border: none;
				border-radius: 0;

				span {
					@include type__13(
						$color: $white,
						$font-weight: $font-weight-semibold,
					);
					text-transform: capitalize;

					@include min-screen($screen__ml) {
						@include type__16(
							$color: null,
							$font-weight: null
						);
					}
				}
			}
		}
	}
}

// -------------------------------------------------
// Header Content
// -------------------------------------------------

.header.content {
	display: flex;
	flex-flow: row wrap;
	justify-content: $header-mobile-justify-content;
	height: $header-mobile-content-height;
	padding: $header-content-padding-mobile;
	background-color: $header-mobile-background;
	position: fixed; // defaults to fixed on mobile
	flex: 1 1 100%;
	top: $header-panel-height;
	width: 100%;
	box-shadow: 0 3px 3px rgba(0,0,0,0.2);

	.checkout-index-index & {
		position: relative;
		top: 0;
		box-shadow: 0 0 0 rgba(0,0,0,0.2);
	}

	@if $header-above-overlay == true {
		@include min-screen($screen__m) {
			z-index: 100;
		}
	}

	@include min-screen($screen__m) {
		background-color: $header-background;
		height: $header-desktop-content-height;
		padding: $header-content-padding-desktop;
		position: relative;
		top: 0;
		box-shadow: none;
	}

	@include min-screen($screen__ml) {
		padding: 0 $header-desktop-content-right-spacing 0 $header-desktop-content-left-spacing;
	}

	.block {
		// Cancels out margin added to blocks in _extends
		// block is also used in account area so don't want additional margins from that either
		margin: 0;
	}

	// -------------------------------------------------
	// All the icons first so they are the same width

	.navigation-toggle {
		display: flex;
		flex: 1 1 20%;
		padding: 0;

		@include min-screen($screen__m) {
			flex: $header-mobile-links-flex;
			justify-content: center;
			align-items: center;
		}

		@include min-screen($screen__l) {
			flex: 1 1 4%;
		}

		&.nav-open {
			justify-content: center;
		}
	}

	.block-search {
		display: flex;
		flex: $header-mobile-links-flex;
		justify-content: center;
		align-items: center;
		padding: 0;

		@include min-screen($screen__l) {
			flex: 1 1 4%;
		}
	}

	[data-content-type="row"],
	.icons-block {
		display: flex;
		align-items: center;
		flex: $header-mobile-links-flex;
		padding: 0;
		flex: 1 1 20%;

		@include min-screen($screen__m) {
			flex: $header-tablet-links-flex;
		}

		@include min-screen($screen__l) {
			flex: $header-large-desktop-links-flex;
			order: $header-minicart-flex-order;
		}

		.block-search,
		.customer-welcome,
		.customer-help-center,
		.minicart-wrapper .showcart {

			&:not(.active) {
				&:hover,
				&:focus {
					.block-title,
					.search-toggle,
					.customer-name button,
					.customer-title,
					.header-link-button button,
					.header-link-title,
					.minicart-container,
					.minicart-title {
						opacity: 0.65;
					}
				}
			}
		}
	}

	[data-content-type="row"] {
		order: 0;
	}

	.logo {
		display: flex;
		align-items: center;
		justify-content: center;
		flex: 1 1 60%;
		order: $header-mobile-logo-flex-order;

		@include min-screen($screen__m) {
			flex: $header-tablet-logo-flex;
			order: $header-logo-flex-order;
		}

		@include min-screen($screen__l) {
			flex: $header-desktop-logo-flex;
		}

		@include min-screen($screen__ll) {
			flex: $header-large-desktop-logo-flex;
		}

		// Center align the logo - nested because no class on output
		// we do not want this to affect any other links in the header
		a {
			display: block;
			max-width: $logo-mobile-max-width;
			width: 100%;
			margin: 0 auto;
			text-align: center;

			@include min-screen($screen__m) {
				max-width: $logo-tablet-max-width;
				margin: 0;
			}

			@include min-screen($screen__l) {
				max-width: $logo-max-width;
				margin: 0;
			}
		}
	}

	.icons-block {
		justify-content: flex-end;
		order: $header-mobile-minicart-flex-order;
		align-items: baseline;
	}

	[data-content-type="row"] {
		@include max-screen($screen__m - 1) {
			display: none !important; // To override inline styles
		}
	}

	.header-quick-links {
		justify-content: center; // To override inline styles

		ul {
			display: flex;
			justify-content: space-between;

			li {
				a {
					@include type__16;
				}

				&:not(:last-of-type) {
					margin-right: $twenty-gutter;

					@include min-screen($screen__ml) {
						margin-right: $gutter;
					}
				}
			}
		}
	}

	.icons-block,
	.minicart-wrapper,
	.block-search,
	.block-content {
		&.active {
			.block-minicart,
			.minisearch,
			.search-toggle {
				display: block;
				z-index: 101;
			}
		}

		.block-minicart,
		.customer-menu,
		.header-link-menu,
		.minisearch {
			position: absolute;
			background-color: $dropdown-background-color;
			z-index: 0; // needed to position open minicart on top of overlay on IE.
			right: 0;
			width: 100%;
			margin: 0 auto;
			box-shadow: none;
			border: none;
			padding: $dropdown-outer-spacing;

			&:after {
				display: none;
			}

			&:before {
				display: none;
			}

			@include min-screen($screen__m) {
				top: $dropdown-top-position;
				width: $dropdown-width;
			}

			.action-close {
				// close button inside popup
				&:hover,
				&:focus {
					background-color: $popup-background-color;
					color: currentColor;
					outline: none;
				}
			}

			&:not(.ui-dialog-content) {
				.greet.welcome,
				.header.links {
					display: none;
				}
			}
		}

		.customer-menu {
			max-width: 360px;
			min-width: 360px;
		}

		.help-center-menu:not(.ui-dialog-content) {
			display: none;
		}

		.minisearch {
			@include min-screen($screen__m) {
				position: relative;
				width: auto;
				top: auto;
				background: none;
			}

			.mage-dropdown-dialog {
				@include min-screen($screen__m) {
					display: block !important; // Important to override JS display style
				}
			}

			.label {
				display: none;
			}

			.header-links-overlay.active ~ & {
				z-index: 100;

				.modal-open &,
				.search-modal-open & {
					z-index: 100;
				}
			}

			.modal-open &,
			.search-modal-open & {
				display: flex;
				justify-content: center;
				width: 100%;
				background: $white;
				position: fixed;
				top: $header-panel-height; // position after fixed trustbuilder
				z-index: 100;
			}
		}

		.block-title,
		.header.links {
			padding: $dropdown-content-padding;
		}

		.header.links.trade {
			&:empty {
				display: none;
			}
		}

		.block-content > div {
			// this determines whether to use padding or margin for gutter, the reason for this is if we use margin over padding the dividing line will be indented and in line with
			// the rest of the minicart contents, vice versa means the dividing line is the full width of the container.
			@if $dropdown-dividing-line-full-width == false {
				margin-left: rem(17);
				margin-right: rem(17);
				width: auto;

				@include min-screen($screen__m) {
					margin-left: $dropdown-padding;
					margin-right: $dropdown-padding;
				}
			} @else {
				padding-left: rem(17);
				padding-right: rem(17);

				@include min-screen($screen__m) {
					padding-left: $dropdown-padding;
					padding-right: $dropdown-padding;
				}
			}
		}

		.block-content {
			strong.subtitle.empty {
				display: block;
				margin: rem(13) $twenty-gutter rem(17);
				font-weight: $font-weight-semibold;
				text-align: center;
			}

			strong {
				span {
					@include type__16($font-weight: $font-weight-normal);
				}
			}
		}

		.block-title {
			margin: 0; //remove any inherited margin

			@if $dropdown-dividing-line-full-width == false {
				//indented diving line
				margin: $dropdown-padding;
				padding: $dropdown-title-padding;
				margin-bottom: $block-title-margin-bottom;
			} @else {
				//full width dividing line
				padding: $dropdown-title-padding;
			}
		}

		.greet.welcome {
			margin-bottom: $five-gutter;
		}

		.greet.welcome,
		.block-title,
		.header-link-header-title {
			border-bottom: $dropdown-title-border;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: $dropdown-title-padding;

			.welcome-back,
			.customer-firstname,
			.header-link-header-text,
			.text span {
				@include type__17(
					$font-weight: $font-weight-medium,
					$letter-spacing: rem(1)
				);
				text-transform: uppercase;
				display: inline-block;
			}

			.customer-firstname {
				margin-left: em(2);
				color: $account-dropdown-username-color;
			}

			button {
				@extend %cancel-button;
				padding: 0;
			}

			.action span span,
			.greet-welcome-close span,
			.header-link-close-action span {
				@include action-w-icon__cross-black($color: $body-font-color);
				@include type__16;

				&::after {
					width: 10px;
					height: 10px;
				}

			}

			.items-total {
				@extend %visuallyhidden;
			}
		}

		.header-help-block {
			width: 100%;

			ul {
				padding: $dropdown-content-padding;

				li {
					padding-top: $dropdown-list-spacing;
					text-transform: capitalize;

					a {
						@include type__16;

						&:before {
							@include icon__arrow-black;
							margin-right: $five-gutter;
						}
					}
				}
			}

			.block-footer {
				padding: $twenty-gutter;
				background: $gallery;

				p {
					@include type__17(
						$font-weight: $font-weight-medium,
						$letter-spacing: rem(1)
					);
					text-transform: uppercase;
				}

				span {
					@include type__16(
						$font-family: $font-family-serif,
						$font-weight: $font-weight-medium,
						$letter-spacing: rem(1)
					);
					font-style: italic;
				}
			}
		}

		.greet.welcome,
		.header.links {
			&.trade {
				background-color: $silver;
			}
		}

		> .header.links {
			display: none;
			align-items: center;
			height: 100%;

			@include min-screen($header-links-display-breakpoint) {
				display: flex;
			}

			@if $header-links-flex-direction == 'row' {
				flex-direction: row;
			} @else {
				flex-direction: column;
				justify-content: center;
			}
		}
	}

	.block-search {
		display: none;

		@include min-screen($screen__m) {
			display: flex;
			flex: $header-tablet-search-flex;
			flex-direction: column;
			justify-content: center;
			order: $header-search-flex-order;
		}

		@include min-screen($screen__l) {
			flex: $header-desktop-search-flex;
		}

		@include min-screen($screen__ll) {
			flex: $header-large-desktop-search-flex;
		}

		.block-search-inner {
			@include min-screen($screen__m) {
				display: flex;
				flex-direction: column;
			}

			&.active {
				position: fixed;
				right: rem(20);
				z-index: 151;
				display: flex;
				flex-direction: column;
				justify-content: center;

				.close-btn-wrapper {
					.search-toggle {
						display: flex;
						align-items: baseline;
					}
				}

				.block-title {
					display: none;
				}
			}
		}

		.block-title {
			order: 1;
			padding: 0;

			strong {
				@include type__16($line-height: 1);
			}
		}

		form.minisearch {
			padding: $twenty-gutter;
			align-items: center;

			@include min-screen($screen__m) {
				padding: 0;
			}

			.input-text {
				@include input-style-three;
				padding: 0 0 !important; // !important to override mixin
				text-indent: $twenty-gutter;

				&::placeholder {
					@include min-screen($screen__m) {
						@include type__35(
							$font-family: $font-family-serif
						);
						line-height: normal !important;
						font-style: italic;
						text-transform: capitalize;
					}
				}
			}
		}

		.search-toggle {
			background: none;
			border: 0;
			height: 100%;
			flex-direction: row;
			display: flex;
			align-items: baseline;

			span.search-close {
				margin-right: rem(8);
			}

			svg {
				&.search-close {
					height: 8px;
					width: 8px;
				}
			}

			&.active {
				height: auto;
				width: auto;
				position: fixed;
				top: rem(65);
				right: $gutter;
				z-index: 101;

				&:before {
					content: "Close";
					display: inline-block;
					height: auto;
					width: auto;
					border: none;
					margin-right: $five-gutter;
					vertical-align: top;
					position: static;
					@include type__16;
				}
			}
		}

		.block-content {
			width: 100%;
			max-width: rem(250);
			height: 100%;

			&:not(.active) {
				@extend %visuallyhidden;
			}

			@include min-screen($screen__m) {
				height: auto;
			}

			@include min-screen($screen__ll) {
				max-width: rem(340);
			}

			@include min-screen(1610px) {
				max-width: rem(468);
			}

			&.active {
				z-index: 150;
			}
		}

		.actions {
			position: absolute;
			top: 0;
			right: $header-search-icon-right-position;
			bottom: 0;
			margin: auto;
			display: flex;
			align-items: center;

			@include min-screen($screen__m) {
				padding: 0 0 0 $five-gutter;
				margin: 0;
				position: static;
			}
		}

		.action {
			@extend %cancel-button;
			cursor: pointer;
			padding: 0;

			svg {
				fill: $header-search-icon-color;
				background-color: $header-search-icon-background-color;
				width: $header-search-icon-width;
				height: $header-search-icon-height;
			}
		}
	}

	.header.items {
		li.customer-welcome {
			order: 2;
		}
	}

	.header.items > li,
	.minicart-wrapper,
	.switcher-currency,
	.icons-block > .header.links {
		border-left: $header-mobile-border;
		padding: $header-links-padding;
		height: 100%;

		@include min-screen($screen__m) {
			width: $header-links-tablet-width;
			border-left: $header-border;
			padding: $header-links-padding-desktop;
			align-items: center;
		}

		@include min-screen($screen__ml) {
			position: relative;
			width: $header-links-desktop-width;
			padding: $header-links-padding-desktop;
		}

		svg {
			height: $header-icons-height-mobile;
			width: $header-icons-width-mobile;
			fill: $header-icons-color;

			@include min-screen($screen__m) {
				height: $header-icons-height-desktop;
			}
		}

		.currency-name,
		.minicart-title,
		.customer-title,
		.quickorder-title,
		.header-link-title {
			@include type__16(
				$color: $header-links-label-color,
				$line-height: 1
			);

			@if $header-links-flex-direction == 'row' {
				padding-left: $header-links-label-horizontal-spacing;
			} @else {
				padding-top: $header-links-label-horizontal-spacing;
			}
		}

		&.active {
			.currency-name,
			.minicart-title,
			.customer-title,
			.quickorder-title,
			.header-link-title {
				color: $header-links-label-color-active;
			}

			.icon {
				stroke: $header-icons-color-active;
			}
		}
	}

	.header.items {
		order: $header-mobile-links-flex-order;
		justify-content: flex-start;
		margin: 0;

		@include min-screen($screen__m) {
			display: flex;
			align-items: center;
			height: 100%;
			order: $header-links-flex-order;
			margin: 0 $twenty-gutter;
		}

		@include min-screen($screen__ll) {
			margin: 0 $forty-gutter;
		}
	}

	.switcher-currency {
		display: none;
		position: relative;

		@include min-screen($screen__m) {
			display: block;
		}

		.currency-symbol {
			border: $header-currency-icon-border;
			color: $header-currency-icon-color;
			border-radius: 200px;
			width: $header-icons-width;
			height: $header-icons-height-mobile;
			text-align: center;
			display: inline-block;
			padding-top: $header-currency-icon-spacing-top;

			@include min-screen($screen__m) {
				height: $header-icons-height-desktop;
			}
		}

		.switcher-dropdown {
			display: none;
			margin: 0 auto;
		}

		.switcher-options,
		.switcher-trigger {
			position: relative;
			height: 100%;
			cursor: pointer;

			&.active {
				z-index: 150;
				background-color: $header-links-active-background;

				@if $dropdown-enable-triangle == true {
					&:before {
						@include css-triangle(
							$triangle-size: 7px,
							$triangle-color: $dropdown-arrow-colour,
							$triangle-direction: bottom
						);
					}
				}
			}
		}

		strong[class^="language-"] {
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			@if $header-links-flex-direction == 'row' {
				flex-direction: row;
			} @else {
				flex-direction: column;
				justify-content: center;
			}
		}

		.ui-widget {
			display: none;
			position: absolute;
			width: 100%;
			padding: $currency-dropdown-inner-spacing;
			background: $currency-dropdown-background;

			.switcher-option {
				padding-top: $currency-dropdown-item-top-spacing;

				&.current {
					.currency-symbol {
						border: $header-currency-option-active-icon-border;
						color: $header-currency-option-icon-color;
					}

					.currency-name {
						color: $header-currency-option-icon-color;
					}
				}

				.currency-symbol {
					border: $header-currency-option-icon-border;
					margin-right: $header-currency-option-spacing-horizontal;
				}

				a {
					&:focus {
						color: $outer-space;
						opacity: 1;
					}
				}

				&:first-child {
					padding-top: 0;
				}

				span {
					@include type__16;
					display: inline-block;
				}

				&.current {
					span {
						font-weight: $font-weight-bold;
					}
				}
			}
		}
	}
}

.nav-overlay,
.header-links-overlay {
	&.active {
		~ .header.content {
			background-color: $header-background-overlay-darken; // Show child elements, in tandem with z-index
			z-index: 100; // We need to set this so that the inner elements appear above the overlay

			.logo {
				pointer-events: none; // Prevent page refresh when closing overlay
			}

			.icons-block {
				.minicart-wrapper {
					.counter-number {
						opacity: 0.1;
					}

					&.active {
						.counter-number {
							opacity: 1;
						}
					}
				}
			}
		}
	}
}

.nav-overlay {
	&.active {
		~ .header.content {
			@include min-screen($screen__m) {
				background-color: $header-background; // 'Reset' background on desktop
			}

			.counter-number {
				opacity: 0.2;

				@include min-screen($screen__m) {
					opacity: 1;
				}
			}
		}
	}
}

body.sticky-nav {
	@include min-screen($screen__m) {
		.nav-sections {
			position: fixed;
			top: $header-panel-height;
			box-shadow: 0 3px 3px rgba(0,0,0,0.2);
		}

		.page-header {
			padding-top: calc(#{$header-panel-height} + #{$desktop-header-nav-height});
		}

		&.scroll-up {
			.page-header {
				padding-top: calc(
					#{$header-panel-height} +
					#{$desktop-header-nav-height} +
					#{$header-desktop-content-height}
				);
			}

			.header.content {
				position: fixed;
				top: $header-panel-height;
			}

			.nav-sections {
				top: calc(#{$header-panel-height} + #{$header-desktop-content-height});
			}
		}
	}
}
