/* -------------------------------------------------
 * -------------------------------------------------
 * Cookie Consent Styling
 *
 * -------------------------------------------------
 * @author Fisheye
 */

 .global.cookie {
	width: 100%;
	padding: $small-gutter;
	background-color: rgba($color: $wild-sand, $alpha: 0.95);
	box-shadow: 0 -2px 1px rgba($color: $black, $alpha: 0.13);
	position: fixed;
	bottom: 0;
	z-index: 110;

	.content {
		max-width: 1440px;
		margin: 0 auto;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	p,
	strong,
	a {
		@include type__14;
	}

	a {
		@include link__underline;
	}

	.actions {
		margin-left: $twenty-gutter;
	}

	.allow {
		@include button__journey;
		text-align: center;

		@include min-screen($screen__m) {
			padding: $ten-gutter $twenty-gutter rem(11);
		}
		
		&:hover {
			background-color: $white;
			
			span {
				color: $shadow-green;
			}
		}
	}
}
