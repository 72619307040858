/* -------------------------------------------------
 * -------------------------------------------------
 * Auto related products module
 *
 * not bluefoot :(
 *
 * -------------------------------------------------
 * @author Fisheye
 */

.aw-arp-container {
	clear: both;
	@extend %row-container;
	padding-top: $row-gutter;
	padding-bottom: $row-gutter;

	h3,
	.related-categories {
		text-align: center;
	}
}
