/* -------------------------------------------------
 * Checkout Variables
 * -------------------------------------------------
 * Table of contents
 * 1. - Category Title Spacing
 * 2. - product listing
 *	2a. - product listing Item Spacing
 * 3. Category Seo Footer Content Variables
 * -------------------------------------------------
 * @author Fisheye
 */

// -------------------------------------------------
// 1. Category Title
// -------------------------------------------------

$category-title-spacing-mobile: $heading-gutter-mobile $twenty-gutter; // padding for category titke and search results
$category-title-spacing: $heading-gutter $twenty-gutter;
$category-subtitle-spacing-mobile: $twenty-gutter;
$category-subtitle-spacing: $small-gutter;
$category-description-spacing-mobile: $twenty-gutter;
$category-description-spacing: $small-gutter;


// -------------------------------------------------
// 2. Product Listing Variables
// -------------------------------------------------

// 2a. Product Listing Item Spacing
$category-product-item-name-bottom-spacing: $small-gutter;
$category-product-item-name-top-spacing: $fifteen-gutter;
$category-product-item-name-color: $brand-accent;
$category-product-item-name-weight: $font-weight-normal;
$category-product-item-review-bottom-spacing: $small-gutter;
$category-product-item-pricing-bottom-spacing: $small-gutter;
$category-product-item-basket-bottom-spacing: $small-gutter;
$category-product-item-add-to-bottom-spacing: $small-gutter;
$category-product-listing-spacing-mobile: 0 rem(7.5);
$category-product-listing-spacing: rem(72) rem(22);
$category-product-listing-button-display: block;
$category-product-listing-wishlist-display: none; // Set either none or inline-flex
$category-product-listing-compare-display: none; // Set either none or inline-flex
$category-product-listing-preorder-display: none; // Set either none or inline-flex
$category-compare-wishlist-spacing: 0 $small-gutter / 3; // creates spacing between icon and text
$category-swatches-flex: 0 1 25%;

// -------------------------------------------------
// 3. Category Seo Footer Content Variables
// -------------------------------------------------

$categoty-footer-seo-display-setting-mobile: none;
$categoty-footer-seo-display-setting: block;
$categoty-footer-seo-spacing-mobile: $twenty-gutter;
$categoty-footer-seo-spacing: $row-gutter $twenty-gutter;
