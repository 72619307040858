/* -------------------------------------------------
 * -------------------------------------------------
 * CMS Content Styles
 * Used for banners and repeatable CMS content
 * --------------------------------------------------
 * @author Fisheye
 */

// -------------------------------------------------
// Icons for CMS content
// -------------------------------------------------

.pagebuilder-column {
	.icon {
		&:before {
			content: "";
			display: inline-block;
			margin-right: $five-gutter;

			@include min-screen($screen__xs) {
				margin-right: $ten-gutter;
			}
		}

		&.truck {
			&:before {
				height: 15px;
				width: 23px;
				@include svg-background(
					$name: 'truck-icon',
					$size: contain
				);

				@include min-screen($screen__m) {
					height: 20px;
					width: 30px;
				}
			}
		}

		&.clock {
			&:before {
				height: 15px;
				width: 13px;
				@include svg-background(
					$name: 'clock-icon',
					$size: contain
				);

				@include min-screen($screen__m) {
					height: 23px;
					width: 21px;
				}
			}
		}

		&.calendar {
			&:before {
				height: 15px;
				width: 15px;
				@include svg-background(
					$name: 'calendar-icon',
					$size: contain
				);

				@include min-screen($screen__m) {
					height: 22px;
					width: 22px;
				}
			}
		}

		&.feefo {
			&:before {
				height: 20px;
				width: 40px;
				@include svg-background(
					$name: 'feefo',
					$size: contain
				);

				@include min-screen($screen__ml) {
					height: 15px;
					width: 65px;
				}
			}
		}
	}

	.feefo-block {
		display: flex;
		align-items: center;

		@include min-screen($screen__ml) {
			flex-direction: column;
			align-items: flex-start;
		}

		.stars {
			height: 20px;
			width: 40px;
			@include svg-background(
				$name: 'feefo-stars',
				$size: contain
			);

			@include min-screen($screen__xs) {
				margin-left: $five-gutter;
			}

			@include min-screen($screen__ml) {
				height: 15px;
				width: 210px;
			}
		}

		i {
			@include type__14($color: $boulder,
			$font-family: $font-family-serif
			);
			text-align: center;
			padding-left: rem(3);

			@include min-screen($screen__xs) {
				@include type__16(
					$color: $boulder,
					$font-family: $font-family-serif
				);
			}

			@include min-screen($screen__ml) {
				text-align: left;
			}
		}

		a.icon {
			padding: 0;
		}
	}
}

// -------------------------------------------------
// Text Slider - Used for global trustbuilder
// -------------------------------------------------

.cms-text-slider {
	padding: 0 $ten-gutter;
	min-height: 35px;

	@include min-screen($screen__m) {
		height: 58px;
	}

	.pagebuilder-column-group {
		.slick-slide {
			display: flex;
			justify-content: center;
		}

		.slick-arrow {
			width: 21px;
			height: 100%;
			background-color: transparent;
			background-size: 9px;
		}

		.slick-next {
			display: block !important; // Override inline JS style
			background-position: right;
		}

		&:not(.slick-initialized) {
			.pagebuilder-column {
				display: none !important; // Override inline JS style

				@include min-screen($screen__ml) {
					display: flex !important; // Override inline JS style
					justify-content: center !important; // Override inline JS style
					width: 25%;
					align-items: center;
				}
			}
		}

		.icon {
			display: flex;
			align-items: center;

			@include min-screen($screen__ml) {
				flex-direction: column;
				align-items: flex-start;
				padding: $ten-gutter $fifteen-gutter $ten-gutter $gutter;
				position: relative;

				&:before {
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					height: auto;
				}
			}

			&.feefo {
				@include min-screen($screen__ml) {
					flex-direction: row;
				}
			}


			&.truck {
				padding-left: $forty-gutter;
			}
		}

		p,
		a {
			@include type__13(
				$font-weight: $font-weight-semibold,
				$letter-spacing: 1
			);
			text-transform: uppercase;
			white-space: nowrap;

			@include min-screen($screen__xs) {
				@include type__16;
			}

			span {
				@include type__14(
					$color: $boulder,
					$font-family: $font-family-serif,
					$line-height: null
				);
				font-style: italic;
				text-transform: capitalize;
				padding-left: rem(3);

				@include min-screen($screen__xs) {
					@include type__16(
						$color: $boulder,
						$font-family: $font-family-serif
					);
				}

				@include min-screen($screen__ml) {
					padding-left: 0;
				}
			}
		}
	}
}

// -------------------------------------------------
// Homepage Carousel - Slider within a slider!
// -------------------------------------------------

.cms-homepage-slider {
	.pagebuilder-slide-wrapper {
		position: relative;

		.pagebuilder-slide-image > img {
			// Fixes CLS issue with image size when loading slider on homepage
			aspect-ratio: 1 / 1;

			@supports not (aspect-ratio: 1 / 1) {
				width: 100vw;
				height: 100vw;
			}

			@include min-screen($screen__m + 1) {
				aspect-ratio: 16 / 5;

				// aspect-ratio property not supported for Safari <= v14.8
				@supports not (aspect-ratio: 16 / 5) {
					width: 100vw;
					height: calc(100vw * (5 / 16));
				}
			}
		}

		.pagebuilder-overlay {
			background-color: rgba($color: #000000, $alpha: 0.2) !important; // Override pagebuilder styles
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			bottom: 0;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	.pagebuilder-poster-content {
		padding: 0 $gutter;

		h1,
		h2 {
			@include type__73;
			font-size: clamp(type-scale(52), 10vw, type-scale(123));
			text-transform: uppercase;

			@include min-screen($screen__ml) {
				@include type__123;
				font-size: clamp(type-scale(52), 10vw, type-scale(123));
			}
		}

		p {
			@include type__28;

			@include min-screen($screen__ml) {
				@include type__64;
			}
		}

		button {
			@include button__default(
				$background-color: $white,
				$border: 1px solid $white,
				$color: $body-font-color
			);
			margin-top: $twenty-gutter;

			@include min-screen($screen__ml) {
				margin-top: $gutter;
			}

			&:hover,
			&:focus {
				background-color: transparent;
				border-color: $white;
			}
		}
	}

	.slick-dots {
		width: 100%;
		position: absolute;
		bottom: rem(55);
		text-align: center;

		@include min-screen($screen__ml) {
			bottom: rem(65);
		}

		button {
			background-color: rgba($color: $gallery, $alpha: 0.4);
		}

		.slick-active {
			button {
				background-color: $gallery;
			}
		}
	}
}

// -------------------------------------------------
// CMS Banners
// -------------------------------------------------

.cms-banners {
	.pagebuilder-column-group {
		flex-flow: row wrap;

		.pagebuilder-column {
			@include min-screen($screen__m) {
				flex: 1 0 50%;
			}

			@include min-screen($screen__l) {
				flex: 1 0 25%;

				.cms-home & {
					flex: 1 0 50%;
				}
			}

			@include min-screen($screen__xl) {
				&:nth-child(4) {
					display: block !important; // Important to override inline styles from pagebuilder
				}

				.cms-home & {
					flex: 1 0 25%;
				}
			}

			.catalog-category-view & {
				&:nth-child(n+3) {
					@include min-screen($screen__m) {
						display: none !important; // Important to override inline styles from pagebuilder
					}

					@include min-screen($screen__l) {
						display: flex !important; // Important to override inline styles from pagebuilder
					}
				}
			}
		}
	}

	.pagebuilder-banner-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;

		.page-layout-2columns-landing &,
		.cms-page-view & {
			flex-flow: row wrap;

			.pagebuilder-banner-image-wrapper {
				width: 100%;

				img {
					width: 100%;
				}
			}
		}
	}

	.pagebuilder-banner-image-wrapper {
		order: 1;
		flex: 1 0 auto;
		text-align: right;

		img {
			height: rem(243);
		}
	}

	.pagebuilder-poster-content {
		p {
			@include type__13($color: $silver-chalice);

			&:first-child {
				@include type__24(
					$color: $silver-chalice
				);
				text-transform: uppercase;
				margin-bottom: rem(8);
			}
		}

		.pagebuilder-banner-button {
			@include button__default;
			margin-top: rem(19);
		}
	}
}

// -------------------------------------------------
// CMS Strapline Links
// -------------------------------------------------

.cms-strapline-links {
	padding: $fifteen-gutter;

	@include min-screen($screen__m) {
		padding: $gutter;
	}

	.pagebuilder-column-group {
		overflow: hidden;
	}

	.pagebuilder-column {
		position: relative;
		padding: rem(23) $ten-gutter rem(21);

		@include min-screen($screen__m) {
			padding: rem(28) $ten-gutter rem(24);
		}

		&:nth-child(2) {
			&:before {
				@include min-screen($screen__m) {
					content: "";
					position: absolute;
					width: 0;
					height: 0;
					top: rem(-10);
					left: rem(-15);
					border-style: solid;
					border-width: 0 0 rem(100) rem(16);
					border-color: transparent transparent $mine-shaft transparent;
				}
			}
		}
	}

	p {
		@include type__19($font-weight: $font-weight-bold);
		text-transform: uppercase;
		display: flex;
		justify-content: center;
		align-items: center;

		@include min-screen($screen__m) {
			@include type__22($font-weight: $font-weight-bold);
		}
	}
}

// -------------------------------------------------
// Brands Scroller - Used on homepage
// -------------------------------------------------

.cms-brands {
	white-space: nowrap;
	overflow: hidden;
	padding: $fifteen-gutter $page-gutter $fifty-gutter;
	border-top: 1px solid $white;

	@include min-screen($screen__m) {
		padding: $fifteen-gutter $gutter rem(73);
	}

	.pagebuilder-buttons-scroll-list.arrow-clicked {
		transition: all 1s ease-in-out;
	}

	.scroll-navigation-wrapper {
		position: relative;

		.next-arrow,
		.previous-arrow {
			@include svg-background(
				$name: 'double-arrows-right',
				$size: rem(20) rem(18)
			);
			background-color: $concrete;
			position: absolute;
			width: rem(39);
			cursor: pointer;
			top: 0;
			bottom: 0;
			margin: auto;
			z-index: 5;

			@include min-screen($screen__m) {
				width: rem(28);
			}

			&:before {
				content: "";
				position: absolute;
				top: 0;
				bottom: 0;
				width: 60px; // Static value, dont want this to change

				@include min-screen($screen__m) {
					width: 120px;
				}
			}

			&.disabled {
				display: none;
			}
		}

		.next-arrow {
			right: 0;
			background-position: left;

			&:before {
				@include horizontal-gradient(
					rgba(255, 255, 255, 0),
					rgba(246, 246, 246, 1)
				);
				right: rem(39); // Static value, dont want this to change

				@include min-screen($screen__m) {
					right: rem(28);
				}
			}
		}

		.previous-arrow {
			left: 0;
			background-image: url('../images/svg/double-arrows-left.svg');
			background-position: right;

			&:before {
				@include horizontal-gradient(
					rgba(255, 255, 255, 0),
					rgba(246, 246, 246, 1),
					$direction: left
				);
				left: rem(39); // Static value, dont want this to change

				@include min-screen($screen__m) {
					left: rem(28);
				}
			}
		}
	}

	li {
		@include min-screen($screen__m) {
			margin-right: $fifteen-gutter;
		}

		span {
			@include type__15($font-weight: $font-weight-semibold);
			text-transform: uppercase;
			display: block;
			margin-top: $five-gutter;
		}

		.pagebuilder-image-wrapper {
			max-width: rem(130);
			width: 100%;
			margin: 0 auto;

			@include min-screen($screen__m) {
				max-width: rem(160);
			}
		}
	}
}

// -------------------------------------------------
// Section Heading - Block heading
// -------------------------------------------------

.section-heading {
	h2 {
		@include type__33(
			$font-family: $font-family-serif,
			$letter-spacing: 1px,
			$line-height: 1
		);
		margin-bottom: $twenty-gutter;
		margin-top: $fifteen-gutter;
		text-align: center;

		@include min-screen($screen__m) {
			margin-bottom: $gutter;
			margin-top: 0;
			@include type__52(
				$font-family: null,
				$letter-spacing: 2px,
				$line-height: 1
			);
		}

		span {
			font-style: italic;
		}
	}

	&.small-heading {
		h2 {
			@include min-screen($screen__ml) {
				margin-bottom: $twenty-gutter;
				@include type__35(
					$font-family: $font-family-serif,
					$letter-spacing: 1px
				);
			}
		}
	}
}

// -------------------------------------------------
// CMS Heading - Page heading block for CMS pages
// -------------------------------------------------

.cms-heading {
	align-items: center;
	padding: rem(75) $fifteen-gutter $gutter;
	position: relative;

	@include min-screen($screen__m) {
		padding: rem(110) $gutter $sixty-gutter;
	}

	&:before {
		content: "";
		background-color: rgba($color: #000000, $alpha: 0.3);
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		pointer-events: none;
	}

	.cms-shops-and-cafes & {
		padding-top: rem(41);
		padding-bottom: rem(34);

		@include min-screen($screen__m) {
			padding-top: rem(37);
			padding-bottom: rem(54);
		}

		h2 {
			line-height: 1.2;
		}

		[data-content-type="text"] {
			margin-top: $five-gutter;
			position: relative;
			overflow: inherit;

			@include min-screen($screen__m) {
				margin-top: rem(13);
			}
		}
	}

	.cms-bath-shop &,
	.cms-belgravia-shop &,
	.cms-warehouse-shop & {
		padding-top: rem(66);

		@include min-screen($screen__m) {
			padding-top: rem(70);
			padding-bottom: rem(55);
		}

		h2 {
			@include type__33(
				$color: $white,
				$font-family: $font-family-sans-serif-alt
			);
			margin-top: rem(25);

			@include min-screen($screen__m) {
				@include type__55($color: $white);
				margin-top: rem(65);
			}
		}

		h3 {
			@include type__19(
				$color: $white,
				$font-family: $font-family-serif,
				$line-height: 1.4,
				$letter-spacing: rem(2)
			);
			text-transform: inherit;
			font-style: italic;
			margin-top: rem(12);

			@include min-screen($screen__m) {
				@include type__33(
					$color: $white,
					$font-family: $font-family-serif,
					$letter-spacing: rem(2)
				);
				margin-top: rem(18);
			}
		}
	}

	[data-content-type="heading"],
	[data-content-type="text"],
	[data-content-type="buttons"] {
		z-index: 1;
	}

	[data-content-type="heading"] {
		flex: 1 1 100%;
		@include type__45(
			$color: $white,
			$font-family: $font-family-sans-serif-alt,
			$line-height: 1
		);
		text-transform: uppercase;

		@include min-screen($screen__m) {
			@include type__85(
				$color: null,
				$font-family: null,
				$line-height: 1
			);
		}
	}

	[data-content-type="text"] {
		max-height: rem(55);
		-webkit-mask-image: linear-gradient(to bottom, #{$black} 75%, transparent 100%);
		mask-image: linear-gradient(to bottom, black 75%, transparent 100%);
		margin-top: $twenty-gutter;
		@include type__15($color: $white);
		overflow: hidden;
		@include transition(max-height 0.6s ease-in-out);

		@include min-screen($screen__m) {
			@include type__16($color: null);
		}

		@include min-screen($screen__ml) {
			max-width: 65%;
			margin-right: auto;
			margin-left: auto;
		}

		&.read-more {
			-webkit-mask-image: none;
			mask-image: none;
			max-height: none;
		}
	}

	[data-content-type="buttons"] {
		margin-top: $gutter;

		.pagebuilder-button-primary {
			padding: 0;
			border: none;
			min-height: none;

			span {
				@include button__default(
					$border: 1px solid $white,
					$color: $white
				);

				&:hover,
				&:focus {
					color: $mine-shaft;
					background-color: $white;
					border-color: $white;
				}
			}
		}
	}
}

// -------------------------------------------------
// Product Options - Mobile slider
// -------------------------------------------------

.cms-product-options {
	padding: $forty-gutter rem(46);
	background: $gallery;
	position: relative;

	@include min-screen($screen__ml) {
		padding: rem(70) $gutter;
	}

	&:before {
		content: "";
		display: block;
		height: 86px;
		width: 86px;
		@include svg-background(
			$name: 'cheese-icon',
			$size: 60%
		);
		background-color: $gallery;
		border-radius: 50%;
		margin: auto;
		position: absolute;
		top: -43px;
		right: 0;
		left: 0;

		@include min-screen($screen__ml) {
			height: 106px;
			width: 106px;
			top: -50px;
		}
	}

	// Fix CLS issue initialising slick slider on mobile
	[class*="pagebuilder-column-"]:not(.slick-initialized) {
		@include max-screen($screen__ml - 1) {
			.pagebuilder-column {
				display: none !important; // Hide slide elements during initialisation - overriding pagebuilder styles

				&:first-child {
					display: flex !important; // Keep first element displayed during initialisation
					width: 100% !important; // Override pagebuilder styles
				}
			}
		}
	}

	.slick-arrow {
		height: rem(58);
		width: rem(46);
		background-color: $alto;
		background-size: rem(8);

		&.slick-prev {
			background-position: 15px center;
			border-radius: 0 rem(29) rem(29) 0;
			left: rem(-46);
		}

		&.slick-next {
			background-position: right 15px center;
			border-radius: rem(29) 0 0 rem(29);
			right: rem(-46);
		}
	}

	.pagebuilder-column-group {
		justify-content: space-around;
	}

	.pagebuilder-poster-overlay {
		h2 {
			@include type__18($letter-spacing: 1px);
			text-transform: uppercase;

			@include min-screen($screen__ml) {
				@include type__22(
					$font-weight: $font-weight-normal,
					$letter-spacing: null
				);
			}
		}

		h3 {
			margin-top: $ten-gutter;
			@include type__30($font-family: $font-family-serif);
			font-style: italic;

			@include min-screen($screen__ml) {
				@include type__35($font-family: $font-family-serif);
			}
		}

		button {
			margin-top: $twenty-gutter;
			margin-bottom: rem(3);
		}
	}
}

// -------------------------------------------------
// Explore Banners - Home page banner grid
// -------------------------------------------------

.cms-explore.col-12 { // This will apply to anywhere where these classes are added on rows and cols of pagebuilder
	.pagebuilder-column-group .pagebuilder-column-line {
		flex: 1 1 100%;
		flex-wrap: wrap;

		.col-3 {
			flex: 1 1 50%;
			position: relative;

			@include min-screen($screen__ml) {
				flex: 1 1 25%;
			}
		}
	}
}

.cms-explore {
	.pagebuilder-column-group {
		.pagebuilder-column {
			@include screen($screen__m, ($screen__ml - 1)) {
				flex-basis: 50%;
				width: 50% !important; // To override pagebuilder styles
			}
		}

		[data-content-type="banner"] {
			position: relative;
			overflow: hidden;
		}

		.pagebuilder-poster-overlay {
			background: rgba($color: $white, $alpha: 0.8) !important; // To override pagebuilder styles
			position: absolute;
			text-align: center;
			position: absolute;
			bottom: 0;
			transform: translate(0%, 0%);
			display: flex;
			align-items: center;
			width: 100%;
			justify-content: center;
			min-height: 55px;

			@include min-screen($screen__m) {
				left: 50%;
				transform: translate(-50%, -80%);
				top: 90%;
				width: 80%;
			}

			p {
				@include type__19($font-family: $font-family-serif);
				font-style: italic;

				@include min-screen($screen__m) {
					@include type__24($font-family: $font-family-serif);
				}
			}
		}
	}

	&.odd-row {
		.pagebuilder-column {
			&:last-of-type {
				@include screen($screen__m, ($screen__ml - 1)) {
					display: none !important; // To override pagebuilder styles
				}
			}
		}
	}
}

// --------------------------------------------------------------
// Subscription Products - Home page best selling subscription
// --------------------------------------------------------------

.cms-subscription-products {
	align-items: center;
	flex-direction: row !important; // To override pagebuilder styles
	flex-wrap: wrap;
	justify-content: center !important; // To override pagebuilder styles
	padding: $gutter $fifteen-gutter rem(35);

	@include min-screen($screen__ml) {
		padding: rem(72) $gutter rem(66);
	}

	.section-heading {
		margin-bottom: rem(8);

		@include min-screen($screen__m) {
			flex-basis: 100%;
			margin-bottom: $five-gutter;
		}

		h2 {
			color: $white;
		}
	}

	[data-content-type="banner"] {
		background: $white;
		max-width: 75%;
		width: 100%;

		@include min-screen($screen__m) {
			flex-basis: rem(455);
			align-self: stretch;

			@include max-screen($screen__ml - 1) {
				flex-basis: calc((100% - 1px) / 2);
			}
		}

		.pagebuilder-banner-wrapper {
			padding: $ten-gutter;
		}

		.pagebuilder-banner-image-wrapper {
			margin-bottom: $fifteen-gutter;

			@include min-screen($screen__ml) {
				margin-bottom: $twenty-gutter;
			}
		}

		.pagebuilder-poster-overlay {
			padding: 0 $ten-gutter $ten-gutter;

			@include min-screen($screen__ml) {
				padding: 0 $twenty-gutter $twenty-gutter;
			}

			h3 {
				margin-bottom: $fifteen-gutter;
				@include type__18($letter-spacing: 1px);
				text-transform: uppercase;

				@include min-screen($screen__ml) {
					margin-bottom: $twenty-gutter;
					@include type__22(
						$font-weight: $font-weight-normal,
						$letter-spacing: 1px
					);
				}
			}

			p {
				@include type__15;

				@include min-screen($screen__ml) {
					@include type__16;
				}

				&:not(:last-of-type) {
					margin-bottom: $fifteen-gutter;
				}
			}

			strong {
				@include type__22($font-weight: $font-weight-bold);

				@include min-screen($screen__ml) {
					@include type__24($font-weight: $font-weight-bold);
				}
			}
		}

		& + [data-content-type="banner"] {
			@include max-screen($screen__m - 1) {
				margin-top: 1px;
			}

			@include min-screen($screen__m) {
				margin-left: 1px;
			}
		}
	}
}

// --------------------------------------------------------------
// Seasonal Ideas
// --------------------------------------------------------------

.cms-seasonal-ideas {
	padding: $gutter $fifteen-gutter;

	@include min-screen($screen__ml) {
		padding: rem(70) $gutter;
	}
}

// -------------------------------------------------
// Product Listing
// -------------------------------------------------

.cms-product-block {
	padding: $five-gutter $fifteen-gutter $fifteen-gutter;

	@include min-screen($screen__ml) {
		padding: $fifteen-gutter $gutter rem(70);
	}

	[data-content-type="buttons"] {
		margin-top: $fifteen-gutter;

		@include min-screen($screen__ml) {
			margin-top: $forty-gutter;
		}
	}
}

.block-products-list {
	padding: 0;

	.widget-product-carousel {
		width: 100vw;

		@include max-screen($screen__ml - 1) {
			margin-right: rem(-7.5);
			margin-left: rem(-7.5);
		}

		@include min-screen($screen__ml) {
			margin-right: -$fifteen-gutter;
			margin-left: -$fifteen-gutter;
		}

		&:not(.slick-initialized) {
			display: flex;

			.product-item {
				min-width: 200px;
				width: (100% / 6);
			}
		}

		.product-item {
			height: 100%;

			@include max-screen($screen__ml - 1) {
				padding: 0 rem(7.5);
			}

			@include min-screen($screen__ml) {
				padding: 0 $fifteen-gutter;
			}
		}

		.product-item-info {
			display: flex;
			flex-flow: column nowrap;

			.product-item-photo {
				display: block;

				.product-image-photo {
					width: 100%;
				}

				.product-short-desc {
					position: absolute;
					top: 0;
					width: 100%;
					height: 100%;
					@include type__17(
						$color: $white,
						$font-family: $font-family-serif,
						$letter-spacing: 1px
					);
					@include transition(opacity 0.4s ease-in-out);
					display: flex;
					flex-wrap: wrap;
					align-items: center;
					padding: $gutter;
					background: rgba($color: $mine-shaft, $alpha: 0.7);
					margin: auto;
					font-style: italic;
					opacity: 0;

					.short-desc-text {
						display: -webkit-box;
						-webkit-line-clamp: 6;
						-webkit-box-orient: vertical;
						overflow: hidden;
					}

					p,
					em {
						display: -webkit-box;
						-webkit-box-orient: vertical;
						overflow: hidden;
					}

					p {
						@include type__17(
							$color: $white,
							$font-family: $font-family-serif,
							$letter-spacing: 1px
						);
						-webkit-line-clamp: 4;
						margin-bottom: $five-gutter;
					}

					em {
						-webkit-line-clamp: 3;
					}
				}

				&:hover,
				&:focus {
					.product-short-desc {
						opacity: 1;
					}
				}
			}
		}

		.product-item-details {
			margin-top: $fifteen-gutter;
			text-align: left;
			display: flex;
			flex-wrap: wrap;
			min-height: 85px;
			flex: 1 1 100%;
			padding-top: 0;

			.product-item-name {
				a {
					@include type__15;
					text-transform: capitalize;

					@include min-screen($screen__ml) {
						@include type__16;
					}
				}
			}

			.dietary-tooltip-wrapper {
				align-self: flex-end;
			}

			.price-wrapper {
				flex: 0 1 60%;

				.price-box {
					margin: 0;
				}
			}

			.price-weight-container {
				flex-wrap: nowrap;

				.uom-denomination {
					> label {
						@extend %visuallyhidden;
					}
				}
			}

			.dietary-tooltip-wrapper {
				display: flex;
				flex: 0 1 40%;
				justify-content: flex-end;
				text-align: right;

				.tooltip {
					&:last-of-type {
						margin-right: 0;
					}
				}
			}

			.price-wrapper {
				flex: 0 1 auto;
				padding-right: $five-gutter;
			}

			.dietary-tooltip-wrapper {
				flex: 0 1 40%;
				text-align: right;

				.tooltip {
					&:last-of-type {
						margin-right: 0;
					}
				}
			}
		}

		.slick-dots {
			text-align: center;
			margin-top: $ten-gutter;

			@include min-screen($screen__m) {
				margin-top: $twenty-gutter;
			}
		}
	}
}

// -------------------------------------------------
//  SEO Content Block
// -------------------------------------------------

.cms-seo-block {
	padding: $gutter $fifteen-gutter;
	background: $gallery;

	@include min-screen($screen__ml) {
		padding: rem(70) $gutter;
	}

	[data-content-type="text"] {
		@include min-screen($screen__ml) {
			max-width: rem(1165);
			margin-right: auto;
			margin-left: auto;
		}

		p {
			@include type__15;

			@include min-screen($screen__ml) {
				@include type__16;
			}

			&:not(:last-of-type) {
				margin-bottom: $fifteen-gutter;
			}
		}
	}

	[data-content-type="buttons"] {
		margin-top: rem(25);
	}
}

// -------------------------------------------------
// Blog posts
// -------------------------------------------------

.cms-blog {
	padding: $gutter $fifteen-gutter;
	background: $gallery;
	margin-top: 1px;

	@include min-screen($screen__l) {
		padding: rem(70) $gutter;
	}

	.blog-widget {
		.post-list {
			.post-holder {
				&:not(:first-of-type) {
					display: none;

					@include min-screen($screen__m) {
						display: block;
					}
				}

				.post-info {
					padding: 0;
				}
			}

			.post-categories {
				margin-bottom: $five-gutter;

				.label,
				a {
					@include type__14($color: $boulder);
				}

				.label {
					display: inline-flex;
					align-items: center;

					&::after {
						margin-top: 3px; //Px used to be more specifc as its off slightly
					}
				}
			}
		}
	}
}

// -------------------------------------------------
// Row with background
// -------------------------------------------------

.cms-bg-content-block {
	background-color: $gallery;

	.pagebuilder-column-group {
		display: flex;
		flex-flow: column wrap;

		@include min-screen($screen__l) {
			flex-flow: row nowrap;
		}

		.pagebuilder-column {
			padding: rem(32) $page-padding-mobile rem(22);

			@include max-screen($screen__l - 1) {
				width: 100% !important; // To override pagebuilder styles
			}

			@include min-screen($screen__m) {
				padding-bottom: rem(25);
			}

			@include min-screen($screen__l) {
				justify-content: center !important; // To override pagebuilder styles
				padding: 0 $gutter;
			}

			&.image-column {
				background-position: bottom !important; // To override pagebuilder styles
				padding: 0;
				height: 100%;
				min-height: rem(246);
				background-size: auto rem(270) !important; // To override pagebuilder styles

				@include max-screen($screen__l - 1) {
					order: 1;
				}

				@include min-screen($screen__m) {
					min-height: rem(300);
					background-size: auto rem(333) !important; // To override pagebuilder styles
				}

				@include min-screen($screen__l) {
					min-height: rem(470);
					background-size: 100% rem(365) !important; // To override pagebuilder styles
				}

				@include min-screen($screen__xl) {
					min-height: rem(438);
					background-size: rem(790) 100% !important; // To override pagebuilder styles
				}

				.catalog-product-view & {
					min-height: revert;
					background-size: contain !important; // To override pagebuilder styles
				}

				[data-content-type="image"] {
					margin-top: auto;
					padding: 0 $fifteen-gutter;
				}

				img {
					width: 100%;
					height: 100%;
				}

				&.video-tour {
					iframe {
						width: 100%;

						@include max-screen($screen__m - 1) {
							max-height: rem(275);
						}
					}
				}
			}
		}
	}

	[data-content-type="banner"] {
		.pagebuilder-banner-image-wrapper {
			max-width: 90px;
			margin: 0 auto $five-gutter;

			@include min-screen($screen__m) {
				max-width: 120px;
				margin-bottom: rem(7);
			}
		}

		.pagebuilder-overlay {
			h2 {
				@include type__33($font-family: $font-family-serif);
				text-transform: none;

				@include min-screen($screen__ml) {
					@include type__45(
						$font-family: null,
						$line-height: 1
					);
				}

				@include min-screen($screen__ll) {
					@include type__52(
						$font-family: null,
						$line-height: 1
					);
				}
			}

			p {
				margin-top: rem(14);
				@include type__15;

				@include min-screen($screen__m) {
					@include type__16;
					max-width: rem(800);
					margin: $twenty-gutter auto 0;
				}
			}

			.pagebuilder-banner-button {
				margin-top: $gutter;

				@include min-screen($screen__m) {
					margin-top: rem(25);
				}
			}
		}
	}
}

// -------------------------------------------------
// Video block
// -------------------------------------------------

.cms-video-block {
	min-height: rem(245);
	padding: $gutter $fifteen-gutter;

	@include min-screen($screen__ml) {
		min-height: rem(465);
		padding: rem(70) $gutter;
	}

	h2 {
		@include type__33(
			$color: $white,
			$font-family: $font-family-serif,
			$letter-spacing: 1px
		);

		@include min-screen($screen__ml) {
			@include type__52(
				$color: null,
				$font-family: null,
				$letter-spacing: 2px
			);
		}
	}

	.modal-title {
		h3 {
			cursor: pointer;

			&:after {
				content: "";
				display: block;
				height: rem(85);
				width: rem(85);
				@include svg-background(
					$name: 'play-icon',
					$size: contain
				);
				margin: rem(17) auto 0;

				@include min-screen($screen__ml) {
					height: rem(92);
					width: rem(92);
					margin-top: rem(32);
				}
			}
		}
	}
}

// -------------------------------------------------
// Product Selections & What's Included Carousel
// -------------------------------------------------

.product-selections-carousel,
.hamper-whats-included {
	background-color: $gallery;
	padding: rem(32) rem(24) rem(33);

	@include min-screen($screen__m) {
		padding: rem(54) $gutter rem(55);
	}

	h2 {
		@include type__33(
			$font-family: $font-family-serif,
			$letter-spacing: 1px,
			$line-height: 1
		);
		padding-bottom: rem(24);
		text-align: center;

		@include min-screen($screen__m) {
			@include type__52(
				$font-family: null,
				$letter-spacing: 2px,
				$line-height: 1
			);
			padding-bottom: rem(26);
		}
	}

	.block-products-list {
		border: 0;
	}

	.slick-arrow {
		background-color: $alto;
		height: rem(48);
		width: rem(40);
		background-size: 13px 13px;
		background-position: center center;
		bottom: rem(53);

		@include max-screen($screen__m - 1) {
			display: none !important; // Override inline JS style - there should be no arrows shown on mobile
		}

		&.slick-disabled {
			display: none !important; // Override inline JS style - left arrow shouldn't be shown when slick disabled
		}

		&.slick-next {
			right: rem(-22);

			@include min-screen($screen__ml) {
				right: rem(-15);
			}
		}

		&.slick-next {
			border-top-left-radius: 70%;
			border-bottom-left-radius: 70%;
		}

		&.slick-prev {
			border-top-right-radius: 70%;
			border-bottom-right-radius: 70%;
			left: rem(-22);

			@include min-screen($screen__ml) {
				left: rem(-15);
			}
		}
	}

	.slick-track {
		display: inline-block !important; // Override !important in _product-list-blocks.scss
	}

	.slick-dots {
		text-align: center;
		margin-top: rem(8);

		@include min-screen($screen__m) {
			margin-top: $fifteen-gutter;
		}
	}

	.product-items {
		display: none !important; // Hide on initial load

		&.slick-initialized {
			display: block !important; // Override !important display
		}
	}

	.slick-slide {
		padding: 0; // Remove padding coming from other sources

		@include min-screen($screen__m) {
			padding: 0 $fifteen-gutter;
		}
	}

	.product-item {
		.price-box,
		.product-item-inner,
		.featured-label {
			@extend %visuallyhidden;
			margin: 0;
		}

		.product-item-info {
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			background-color: $white;

			.product-item-link,
			.product-item-name {
				min-height: revert;
			}

			.product-item-name {
				.product-item-link {
					font-weight: $font-weight-medium;
				}
			}

			.dietary-tooltip-wrapper {
				margin-left: auto;
			}

			.product-short-desc {
				line-height: 1.3;
				padding-top: $fifteen-gutter;
			}

			.product-image-photo,
			.product-item-photo,
			.product-image-container,
			.product-item-details,
			.image-wrapper {
				flex: 1;
			}

			.product-item-photo,
			.image-wrapper {
				display: flex;
				max-width: 50%;
			}

			.product-item-details {
				margin: 0;
				padding: $fifteen-gutter;
				max-width: 50%;

				@include min-screen($screen__m) {
					padding: $twenty-gutter;
				}
			}
		}
	}
}

.hamper-whats-included {
	padding-left: $fifteen-gutter;
	padding-right: $fifteen-gutter;

	// Duplication needed here in order to override padding from .product-selections-carousel
	@include min-screen($screen__m) {
		padding-left: $fifteen-gutter;
		padding-right: $fifteen-gutter;
	}

	.product-item-info {
		.image-wrapper {
			.product-image-wrapper {
				img {
					width: 100%;

					 @include min-screen($screen__ml) {
						height: 285px;
						object-fit: cover;
					 }
				}
			}
		}

		.product-item-details {
			display: flex;
			flex-flow: row wrap;

			.product-name,
			.product-description,
			.product-description p {
				@include type__16;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				overflow: hidden;
			}

			.product-name {
				font-weight: $font-weight-semibold;
				flex: 1 1 100%;
				-webkit-line-clamp: 2;

				@include min-screen($screen__m) {
					-webkit-line-clamp: 2;
				}
			}

			.product-qty-weight {
				margin-top: rem(8);
				align-self: center;

				@include min-screen($screen__m) {
					margin-top: $ten-gutter;
				}

				.qty {
					font-weight: $font-weight-semibold;

					&.count {
						@extend %visuallyhidden;
					}
				}

				.weight {
					font-weight: $font-weight-semibold;
					display: none; // This is a temporary fix to an issue that will be addressed post launch
				}
			}

			// Clamping required on the container & <p> tag, as some products do not
			// have a <p> tag nested
			.product-description,
			.product-description p {
				-webkit-line-clamp: 4;

				// Custom breakpoint required here in order to tackle long product descriptions
				// forcing the container to be bigger than it needs to be
				@include min-screen(1750px) {
					-webkit-line-clamp: 7;
				}
			}

			.product-description {
				margin-top: rem(12);
				width: 100%;

				@include min-screen($screen__m) {
					margin-top: rem(18);
				}
			}
		}
	}
}

// -------------------------------------------------
// Trust builder for products
// -------------------------------------------------

.cms-product-trustbuilder {
	border-top: 1px solid $white !important; // To override pagebuilder styles
	border-bottom: 1px solid $white !important; // To override pagebuilder styles

	@include max-screen($screen__m - 1) {
		margin-right: -$fifteen-gutter;
		margin-left: -$fifteen-gutter;
	}

	.pagebuilder-column-group {
		flex-wrap: nowrap;

		.pagebuilder-column {
			padding: $fifteen-gutter;

			.help-icon,
			.gift-wrap {
				max-height: 21px; // pixel value as em or rem values cause blurriness
				margin-bottom: rem(7);

				@include min-screen($screen__m) {
					margin-bottom: rem(4);
				}
			}

			&:not(:first-child) {
				border-left: 1px solid $white;
			}

			.pagebuilder-poster-content {
				p {
					@include type__13(
						$line-height: 1.2,
						$letter-spacing: 1px
					);
					text-transform: uppercase;
					max-width: rem(170);
					margin: auto;

					@include min-screen($screen__m) {
						@include type__14(
							$line-height: null,
							$letter-spacing: 1px
						);
						max-width: none;
						margin: 0;
					}

					@include min-screen($screen__ml) {
						max-width: rem(170);
						margin: auto;
					}

					// Custom breakpoint in order to tackle limited space
					@include min-screen(1071px) {
						max-width: none;
						margin: 0;
					}

					strong {
						padding-left: $five-gutter;
					}

					br {
						@include min-screen($screen__ml) {
							display: none;
						}
					}
				}

				p,
				strong {
					font-weight: $font-weight-semibold;
				}

				div[data-element="content"] {
					@include min-screen($screen__ml) {
						display: flex;
						flex-flow: row wrap;
						justify-content: center;
					}
				}
			}
		}

		&:not(:last-of-type) {
			border-right: 1px solid $white;
		}

		.help-icon {
			svg {
				g {
					.st0 {
						stroke-width: 10 !important; // To override st0 class styles for svgs
					}
				}
			}
		}

		.gift-wrap {
			svg {
				g {
					.st0 {
						stroke-width: 4 !important; // To override st0 class styles for svgs
					}
				}
			}
		}
	}

	&.subscription-trustbuilder {
		margin-top: rem(15);

		@include min-screen($screen__m) {
			margin-top: $gutter;
		}

		.pagebuilder-column {
			.pagebuilder-banner-image-wrapper {
				margin-bottom: $five-gutter;

				@include min-screen($screen__m) {
					margin-bottom: rem(2);
				}
			}

			&:first-child {
				.pagebuilder-poster-content {
					> div {
						@include min-screen($screen__m) {
							display: flex;
							justify-content: center;
						}
					}
				}
			}

			&:nth-child(2) {
				.pagebuilder-banner-image {
					img {
						width: 21px;
					}
				}

				.pagebuilder-poster-content {
					p {
						@include max-screen($screen__m - 1) {
							max-width: rem(180);
						}
					}
				}
			}
		}
	}
}

// -------------------------------------------------
// CMS icon blocks
// -------------------------------------------------

.icon-block-row {
	padding: $gutter $fifteen-gutter rem(34);

	@include min-screen($screen__m) {
		padding: $sixty-gutter 0 rem(65);
	}

	@include min-screen($screen__ll) {
		padding: $sixty-gutter $gutter rem(65);
	}

	.pagebuilder-column-group {
		@include max-screen($screen__ml - 1) {
			flex-wrap: wrap;
		}

		.pagebuilder-column {
			@include min-screen($screen__m) {
				padding: 0 rem(25);

				@include max-screen($screen__ml - 1) {
					flex-basis: 50%;
					margin-top: $gutter;
				}
			}

			&:not(:last-of-type) {
				@include max-screen($screen__m - 1) {
					margin-bottom: $gutter;
				}
			}

			.pagebuilder-banner-wrapper {
				img {
					height: 46px;
					margin: auto;
				}
			}

			.icon {
				&:before {
					display: block;
				}
			}

			.cms-banner-svg {
				svg {
					height: 50px;
					width: 70px;

					g {
						.st0 {
							stroke-width: 1.5;
							fill: none;
							stroke: $white;
						}
					}
				}

				&.thumbs-up {
					g {
						.st0 {
							stroke-width: 15;
						}
					}
				}
			}
		}
	}

	h2 {
		margin-bottom: $ten-gutter;
		@include type__33(
			$color: $white,
			$font-family: $font-family-serif,
			$letter-spacing: 1px
		);

		@include min-screen($screen__m) {
			margin-bottom: rem(34);
			@include type__52(
				$color: null,
				$font-family: null,
				$letter-spacing: 2px
			);
		}
	}

	[data-content-type="banner"] {
		.pagebuilder-poster-overlay {
			margin-top: $fifteen-gutter;

			@include min-screen($screen__m) {
				margin-top: rem(13);
			}

			h3 {
				@include type__22(
					$color: $white,
					$font-weight: $font-weight-normal,
					$letter-spacing: 1px
				);
				text-transform: uppercase;
			}

			p {
				margin-top: rem(16);
				@include type__16($color: $white);

				@include min-screen($screen__m) {
					margin-top: $ten-gutter;
				}
			}
		}
	}

	.slick-slider {
		flex-wrap: wrap;
	}

	.slick-dots {
		text-align: center;
		width: 100%;
		margin-top: rem(13);
	}

	.page-layout-subscriptions-landing & {
		.pagebuilder-buttons {
			margin-top: rem(25);

			@include min-screen($screen__m) {
				margin-top: $gutter;
			}
		}
	}

	&.journey-steps {
		padding-top: 0;

		h2 {
			color: $mine-shaft;
			max-width: rem(280);
			margin: 0 auto;
			padding-bottom: $five-gutter;

			@include min-screen($screen__xs) {
				max-width: 100%;
			}

			@include min-screen($screen__m) {
				padding-bottom: $gutter;
			}
		}

		.pagebuilder-banner-image-wrapper {
			background-color: $brand-accent;
			border-radius: 100%;
			height: rem(50);
			width: rem(50);
			margin: auto;
			display: flex;
			align-items: center;
			justify-content: center;

			&:before {
				@include type__40($color: $white, $font-weight: $font-weight-light);
				display: block;
				position: relative;
				top: rem(1);
				font-style: italic;
			}
		}

		.pagebuilder-column-group {
			.pagebuilder-column {
				margin: 0;

				h3 {
					@include min-screen($screen__lll) {
						max-width: rem(250);
						margin: 0 auto;
					}

					@include min-screen(1550px) { // ensure title text breaks to new lines at the same points
						max-width: 100%;
					}
				}
			}
		}

		.step-one {
			.pagebuilder-banner-image-wrapper {
				&:before {
					content: '1';
				}
			}
		}

		.step-two {
			.pagebuilder-banner-image-wrapper {
				&:before {
					content: '2';
				}
			}
		}

		.step-three {
			.pagebuilder-banner-image-wrapper {
				&:before {
					content: '3';
				}
			}
		}

		.step-four {
			.pagebuilder-banner-image-wrapper {
				&:before {
					content: '4';
				}
			}
		}

		.pagebuilder-poster-overlay {
			h3 {
				@include type__19(
					$color: $mine-shaft,
					$line-height: 1.3
				);
			}

			p {
				@include type__15;
			}
		}
	}

	&:not(.journey-steps) {
		.slick-dots {
			li {
				button {
					background-color: lighten($brand-accent, 15%);
				}

				&.slick-active {
					button {
						background-color: $white;
					}
				}
			}
		}
	}
}

// -------------------------------------------------
// CMS background banner
// -------------------------------------------------

.cms-bg-banner {
	margin-bottom: $ten-gutter;

	@include min-screen($screen__ml) {
		margin-bottom: $twenty-gutter;
	}

	.pagebuilder-banner-wrapper {
		padding: $forty-gutter $gutter;

		@include min-screen($screen__ml) {
			padding: rem(70) $fifty-gutter;
		}

		p {
			@include type__30(
				$color: $white,
				$font-family: $font-family-serif,
				$letter-spacing: 1px,
				$line-height: 1
			);
			font-style: italic;

			@include min-screen($screen__ml) {
				@include type__52(
					$color: null,
					$font-family: null,
					$letter-spacing: 2px,
					$line-height: 1
				);
			}
		}
	}
}

// -------------------------------------------------
// CMS text row
// -------------------------------------------------

.cms-text-row {
	padding: $twenty-gutter 0;

	@include max-screen($screen__m - 1) {
		text-align: center;
	}

	@include min-screen($screen__m) {
		padding: $gutter 0 $fifty-gutter;
	}

	p {
		&:not(:last-of-type) {
			margin-bottom: rem(25);
		}
	}

	h4,
	ul {
		margin-bottom: $twenty-gutter;
	}
}

// -------------------------------------------------
// CMS Subscription Delivery Block
// -------------------------------------------------
.subscription-delivery {
	background: $mine-shaft;
	padding: $twenty-gutter;
	text-align: center;

	.title {
		&:before {
			content: '';
			display: block;
			height: 28px;
			width: 28px;
			margin: auto auto rem(4);

			@include svg-background(
				$name: 'calendar-icon-white',
				$size: 100% 100%
			);

			.checkout-onepage-success & {
				display: none;
			}
		}

		p {
			@include type__22(
				$color: $white,
				$font-family: $font-family-serif,
				$font-weight: $font-weight-normal,
				$letter-spacing: 1px
			);
			padding-bottom: rem(7);

			.checkout-onepage-success & {
				padding-bottom: rem(2);
			}

			@include min-screen($screen__m) {
				@include type__27(
					$color: $white,
					$font-family: $font-family-serif,
					$font-weight: $font-weight-normal,
					$letter-spacing: 1px
				);

				.checkout-onepage-success & {
					@include type__22(
						$color: $white,
						$font-family: $font-family-serif,
						$font-weight: $font-weight-normal,
						$letter-spacing: 1px
					);
				}
			}
		}
	}

	.description {
		p {
			@include type__16(
				$color: $white,
				$font-weight: $font-weight-normal
			);

			@include min-screen($screen__l) {
				max-width: rem(500);
				margin: auto;

				.checkout-onepage-success & {
					max-width: none;
				}
			}
		}
	}
}

// -------------------------------------------------
// CMS margin row
// -------------------------------------------------

.cms-margin-row {
	padding-top: $gutter;

	@include min-screen($screen__m) {
		padding-top: $fifty-gutter;
	}
}

// -------------------------------------------------
// CMS Shop Banners
// -------------------------------------------------

.cms-shop-banners {
	margin: rem(33) 0;
	padding: 0 $fifteen-gutter;

	@include min-screen($screen__m) {
		margin: $sixty-gutter 0;
	}

	div[data-content-type="banner"] {
		&,
		> div,
		.pagebuilder-banner-wrapper,
		.pagebuilder-overlay,
		.pagebuilder-poster-content {
			height: 100%;
		}
	}

	.pagebuilder-banner-wrapper {
		display: flex;
		flex-direction: column;
	}

	.pagebuilder-poster-content {
		[data-element="content"] {
			height: 100%;

			div {
				height: 100%;
				display: flex;
				flex-direction: column;
			}
		}

		p.telephone {
			margin-top: rem(16);

			@include min-screen($screen__m) {
				margin-top: rem(12);
			}
		}
	}

	.pagebuilder-column {
		&:not(:first-child) {
			margin-top: rem(23);
			padding-top: rem(23);
			border-top: 1px solid $border-color-alt2 !important; // override inline styles
		}

		@include min-screen($screen__m) {
			padding: 0 $fifteen-gutter;

			&:not(:first-child) {
				margin-top: 0;
				border-top: 0 !important; // override inline styles
				padding-top: 0;
			}
		}
	}

	h3 {
		@include type__22($font-weight: $font-weight-normal);
		margin-top: rem(22);
	}

	.services {
		@include type__24($font-family: $font-family-serif);
		font-style: italic;
		margin-top: rem(11);

		@include min-screen($screen__m) {
			margin-top: rem(8);
		}
	}

	.address {
		padding-top: $ten-gutter;
		margin-top: auto;

		@include min-screen($screen__m) {
			padding-top: rem(6);
			min-height: rem(43);
		}

		// Custom breakpoint needed as this is the exact point when the address text moves onto one line
		@include min-screen(1074px) {
			min-height: auto;
		}
	}

	.email {
		@include max-screen($screen__m) { // Max media query to apply on mobile only
			margin-top: rem(3);
		}
	}

	.pagebuilder-button-primary {
		margin-top: rem(22);

		@include min-screen($screen__m) {
			margin-top: rem(27);
		}
	}
}

// -----------------------------------------------------
// CMS Accordion & Text Section
// Found on individual shop pages (Bath, Warehouse etc)
// -----------------------------------------------------
.accordion-text-content {
	.pagebuilder-column-group {
		@include min-screen($screen__m) {
			flex-wrap: wrap;
		}

		.description-text,
		.accordion {
			@include min-screen($screen__m) {
				flex: 1 1 50%;
			}
		}

		.pagebuilder-column {
			&:last-child {
				flex: 1 1 100%;
			}
		}
	}

	> [data-content-type="text"] {
		p {
			margin-bottom: rem(9);

			@include min-screen($screen__m) {
				margin-bottom: rem(29);
			}
		}
	}

	.description-text {
		max-height: rem(185);
		position: relative;
		overflow: hidden;
		text-align: center;

		@include min-screen($screen__m) {
			max-height: rem(155);
			text-align: left;
			padding-right: rem(57);
		}

		&:after {
			content: '';
			display: block;
			height: rem(100);
			width: 100%;
			background: rgb(255, 255, 255);
			background: linear-gradient(0deg, rgba(255, 255, 255, 1) 25%, rgba(255, 255, 255, 0) 100%);
			position: absolute;
			bottom: 0;
			opacity: 1;
			@include transition(opacity 0.4s ease-in-out);

			@include min-screen($screen__m) {
				height: 50%;
			}
		}

		p {
			margin-bottom: rem(28);

			&:last-child {
				margin-bottom: $gutter;

				@include min-screen($screen__m) {
					margin-bottom: 0;
				}
			}

			@include min-screen($screen__m) {
				margin-bottom: rem(21);
			}
		}

		[data-content-type="text"] {
			@include transition(all 100s ease-in-out); //here
		}

		&.read-more {
			max-height: none;

			&:after {
				display: none;
			}
		}
	}

	.accordion {
		border-top: 1px solid $border-color-alt2;
		border-bottom: 1px solid $border-color-alt2;

		@include max-screen($screen__m - 1) {
			order: 2;
		}
	}

	.pagebuilder-column {
		.read-more {
			margin-bottom: $gutter;

			@include min-screen($screen__m) {
				margin-top: $gutter;
				margin-bottom: 0;
			}
		}
	}
}

// -----------------------------------------------------
// CMS Map & Contact Information
// Found on individual shop pages (Bath, Warehouse etc)
// -----------------------------------------------------
.further-contact-info {
	.block-static-block {
		@include min-screen($screen__m) {
			display: flex;
		}

		[data-content-type="row"] {
			flex: 1;

			iframe {
				width: 100%;
			}

			&:last-child {
				background-color: $gallery;
			}
		}

		.contact-info {
			height: 100%;

			.pagebuilder-column-group {
				display: flex;
				flex-wrap: wrap;
				height: 100%;

				.pagebuilder-column {
					flex: 1 1 100%;
					align-items: center;
					padding: $gutter $gutter rem(34);

					@include min-screen($screen__m) {
						padding: 0 $forty-gutter;
						justify-content: center !important; // Override inline Pagebuilder style
						min-height: rem(215); // Required in order to have equal heights on Location & Contact / Opening Hours blocks
					}

					.title {
						p {
							@include type__17;
							margin-bottom: $fifteen-gutter;

							@include min-screen($screen__m) {
								@include type__22($font-weight: $font-weight-normal);
								margin-bottom: rem(13);
							}
						}
					}

					.further-info,
					.day-time {
						strong {
							display: block;
							margin-bottom: rem(14);

							@include min-screen($screen__m) {
								margin-bottom: rem(12);
							}
						}
					}

					.day-time {
						display: flex;

						p {
							&:last-child {
								padding-left: rem(27);

								@include min-screen($screen__m) {
									padding-left: rem(28);
								}
							}
						}
					}

					.further-info {
						p:last-of-type {
							a {
								color: $brand-accent;
							}
						}
					}

					&.opening-hours {
						border-top: 1px solid $white;
					}
				}
			}

			.location-info {
				.social-links {
					margin-top: rem(17);

					@include min-screen($screen__m) {
						margin-top: rem(13);
					}

					li {
						&:not(:last-child) {
							margin-right: $gutter;
						}

						img {
							max-height: rem(19);
						}
					}
				}
			}
		}
	}
}

// -----------------------------------------------------
// CMS Meet the Team Section
// Found on individual shop pages (Bath, Warehouse etc)
// -----------------------------------------------------
.meet-the-team {
	> [data-content-type="text"] {
		p {
			margin-bottom: rem(13);

			@include min-screen($screen__m) {
				margin-bottom: rem(35);
			}
		}
	}

	.pagebuilder-column-group {
		@include min-screen($screen__m) {
			flex-wrap: wrap;
		}

		@include min-screen($screen__l) {
			flex-wrap: nowrap;
		}

		.pagebuilder-column {
			flex: 1 1 100%;

			@include min-screen($screen__l) {
				flex: 1;
			}

			&.image-column {
				margin-bottom: rem(18);

				@include min-screen($screen__m) {
					margin-bottom: $gutter;
				}

				@include min-screen($screen__l) {
					margin-bottom: 0;
					background-position: center !important; // Override inline Pagebuilder style
					padding-right: $gutter;
				}

				@include min-screen($screen__xxl) {
					flex: 0 1 percentage(1/3);
				}

				img {
					@include min-screen($screen__ml) {
						height: 100%;
						object-fit: cover;
					}

					@include min-screen($screen__xxl) {
						object-fit: contain;
						height: auto;
					}
				}
			}

			&.text-column {
				display: flex;

				@include min-screen($screen__xxl) {
					flex-direction: row !important; // Override inline Pagebuilder style
				}

				[data-content-type="text"] {
					text-align: center;

					&:last-of-type {
						margin-top: $twenty-gutter;
					}

					@include min-screen($screen__m) {
						text-align: left;
					}

					@include min-screen($screen__xxl) {
						flex: 1;

						&:last-of-type {
							margin-top: 0;
						}
					}

					p {
						&:not(:last-of-type) {
							margin-bottom: rem(18);
						}

						&:first-of-type {
							@include type__17;
							margin-bottom: rem(13);
						}
					}

					&:first-of-type {
						@include min-screen($screen__xxl) {
							padding-right: $gutter;
						}
					}
				}
			}
		}
	}
}

// -----------------------------------------------------
// CMS Image & Video Gallery
// Found on individual shop pages (Bath, Warehouse etc)
// -----------------------------------------------------
.video-image-gallery {
	flex-flow: row wrap !important; // Override inline Pagebuilder style

	> [data-content-type="text"] {
		flex: 1 1 100%;
		margin-bottom: rem(13);

		@include min-screen($screen__m) {
			margin-bottom: rem(35);
		}
	}

	.pagebuilder-column-group,
	[data-content-type="image"] {
		position: relative;
		flex: 0 1 percentage(1/2);
		padding: 0 $fifteen-gutter / 2;

		@include max-screen($screen__m - 1) {
			&:nth-child(n+4) {
				margin-top: $fifteen-gutter;
			}
		}

		@include min-screen($screen__m) {
			flex: 0 1 percentage(1/3);
			padding: 0 $fifteen-gutter;
		}

		@include screen($screen__m, $screen__ll - 1) {
			&:nth-child(n+5) {
				margin-top: $gutter;
			}
		}

		@include min-screen($screen__ll) {
			flex: 0 1 percentage(1/4);

			&:nth-child(n+6) {
				margin-top: $gutter;
			}
		}
	}

	.video-image {
		justify-content: center !important; // Override inline Pagebuilder style

		[data-content-type="modal"] {
			position: absolute;
			width: 100%;

			.modal-title {
				display: block;
				height: rem(68);
				width: 100%;

				&:before {
					content: '';
					display: block;
					height: rem(68);
					width: rem(68);
					margin: auto;
					cursor: pointer;

					@include svg-background(
						$name: 'gallery-play-icon',
						$size: 100% 100%
					);
				}
			}
		}
	}
}

// CMS - custom text wrapper
.cms-custom-wrapper {
	margin: 0 auto;
	max-width: 700px;
	padding: $twenty-gutter;
}

// Main wrapper
.cms-wrapper-main {
	padding: 0 $ten-gutter 0 $ten-gutter;
	max-width: 1280px;
	margin: 0 auto;
}

// Some custom col sizes - Job vacancies page - can be used other areas with class names
.cms-wrapper-main.col-12 {
	flex: 1 1 100%;
	flex-wrap: wrap;

	.pagebuilder-column-group   {
		.pagebuilder-column.third-width {
			flex: 1 1 33.33%;

			figure {
				&[data-content-type="image"] {
					height: 100%;

					@include min-screen($screen__m) {
						height: 250px; // To keep height of images - if they change any images, may not be equal height
					}

					picture.pagebuilder-image {
						img {
							height: 100% !important; // Cant set to default on pagebuilder - keeps setting to auto
							width: 100%;
							object-fit: cover;
						}
					}
				}
			}
		}
	}
}

// Base styles sets list styles to none - added custom class if list styles required on cms-pages
.list-circle {
	ul {
		list-style: circle;
	}
}

// Custom title to match banner cms title
h2.cms-custom-heading {
	@include type__33($font-family: $font-family-serif);
	text-transform: none;
	padding: $twenty-gutter 0;
	background-color: transparent;
}

.cms-home {
	.blog-widget {
		.post-list {
			.post-holder {
				.post-description {
					display: block !important;

					p:first-child {
						display: -webkit-box;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 3;
						overflow: hidden;
					}

					p:nth-child(2) {
						display: none;
					}
				}
			}
		}
	}
}
