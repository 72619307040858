/* -------------------------------------------------
 * -------------------------------------------------
 * All form styles unless specific to an area
 *
 * Not included here are:
 * 1. Coupon fields on basket
 * 2. Newsletter form in footer
 *
 * -------------------------------------------------
 * @author Fisheye
 */

 form,
 .form,
 .gift-options-content {
	 fieldset,
	 .fieldset {
		 .field {
			width: 100%;
			margin-bottom: $fifteen-gutter;
			position: relative;

			@include min-screen($screen__m) {
				margin-bottom: $twenty-gutter;
			}

			 .label,
			 label {
				display: flex;
				position: absolute;
				top: rem(7);
				left: $fifteen-gutter;
				transition: all 0.2s ease-in-out 0s;

				span {
					@include type__11 (
						$font-weight: $font-weight-semibold,
						$letter-spacing: null,
						$line-height: 1,
						$color: $brand-accent
					);
					text-transform: uppercase;
					display: flex;
					transition: all 0.2s ease-in-out 0s;
				}
			}

			&.empty {
				.label {
					margin: 0;
					position: absolute;
					top: rem(13);
					left: $fifteen-gutter;
					z-index: 1;
					@include transition(all 0.4s ease-in-out);

					span {
						@include type__14(
							$font-weight: $font-weight-semibold,
							$letter-spacing: 1px,
							$line-height: 1
						);
					}
				}
			}

			&.street {
				.additional {
					.label {
						&:after {
							display: none; // Street Line 2 & 3 are not mandatory fields so asterisks need removing.
						}
					}
				}
			}

			.postcode-wrapper input,
			[name="postcode"],
			.postcode {
				text-transform: uppercase;
			}

			.control {
				flex: 0 1 100%;
			}

			select {
				@include input-style-one;
			}

			.input-text,
			select {
				padding-top: $fifteen-gutter;
				padding-bottom: $five-gutter;
			}

			.control {
				.note {
					display: block;
					padding-top: $item-gutter / 2;
				}
			}

			.search-bar {
				display: flex;

				.input-text {
					margin-right: $checkout-delivery-address-button-spacing;
					flex: 1 1 60%;
				}

				.primary {
					@include button__address;
					border: 0;
					width: auto;
					flex: auto;

					span {
						text-transform: capitalize;
					}
				}
			}

			 &.qty {
				flex-wrap: nowrap;
				margin: 0;

				.label {
					display: none;
				}

				.control {
					display: flex;
				}

				.input-text {
					min-height: rem(40);
					width: rem(40);
					padding: $ten-gutter 0;
					letter-spacing: 2px;
				}

				.qty-increment {
					min-height: rem(40);
					width: rem(20);
					background-color: $gallery;
					background-position: center;
					border: none;
					font-size: 0;

					&.qty-minus {
						@include svg-background(
							$name: "minus",
							$size: 10px
						);
						border-radius: 3px 0 0 3px;
					}

					&.qty-plus {
						@include svg-background(
							$name: "plus",
							$size: 10px
						);
						border-radius: 0 3px 3px 0;
					}
				}
			}

			&.choice {
				// This is the remember me and newsletter checkbox
				// Fix to counteract flex width on standard layouts
				.label {
					position: relative;
					top: 0;
					left: 0;

					span {
						@include type__15;
						text-transform: none;

						@include min-screen($screen__m) {
							@include type__16;
						}
					}
				}
			}

			.field-error,
			.mage-error,
			.hosted-error {
				margin-top: $item-gutter;

				&:not(.input-text) {
					@include type__13(
						$color: $red-ribbon
					);
				}
			}

			&.required,
			&._required {
				.label:after,
				label:after {
					content: '*';
					display: inline-block;
					padding: 0;
					margin-left: rem(3);
					vertical-align: top;
					@include type__11(
						$color: $form-required-input-icon-color,
						$font-weight: $font-weight-semibold
					);
				}
			}

			&.visuallyhidden {
				margin: 0;
			}

			 &:not(.empty):not(fieldset) {
				&:not(.choice) {
					&[name="shippingAddress.region_id"] {
						label {
							top: rem(12);
						}
					}
				}
			}
		}
	}

	.actions {
		.primary {
			button {
				@include button__journey;
				width: 100%;

				&,
				span {
					@include type__18(
						$color: null,
						$font-weight: null,
						$letter-spacing: null
					);
					padding-left: rem(2);
				}
			}
		}
	}
}

[id="form-validate"] {  // Hide duplicate street address text for line 1
	fieldset:nth-child(2) {
		.street.required {
			.primary {
				span {
					display: none;
				}
			}
		}
	}
}
