/* -------------------------------------------------
 * -------------------------------------------------
 * Braintree 3D Secure Container
 * Direct Port from LESS file
 * -------------------------------------------------
 * @author Gene Commerce (taken from vendor/gene/module-braintree/view/frontend/web/css/source/_extend.less)
 */

 #braintree-three-d-modal {
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	height: 100vh;
	width: 100vw;
	z-index: 100000;
	visibility: visible;
}

#braintree-three-d-modal .bt-modal-frame {
	height: 420px;
	width: 440px;
	margin: auto;
	background-color: #ffffff;
	z-index: 2;
	border-radius: 6px;
}

#braintree-three-d-modal .bt-modal-body {
	height: 380px;
	margin: 20px;
	background-color: white;
	border: 1px solid lightgray;
}

#braintree-three-d-modal .bt-mask {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background-color: black;
	opacity: 0.75;
}

@media (max-width: 650px) {
	#braintree-three-d-modal .bt-modal-frame {
		width: 100%;
	}
}
