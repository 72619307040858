/* -------------------------------------------------
 * -------------------------------------------------
 * Options that appear as tabs at the bottom of mobile menu
 * - Usually account and currency or vat switcher
 *
 * -------------------------------------------------
 * @author Fisheye
 */

.page-header {
	.navigation {
		max-height: 66vh;
		overflow: auto;

		@include min-screen($screen__m) {
			max-height: none;
			overflow: hidden;
		}

		// All styling for layout of this should be navigation.scss
		.mobile-menu {
			display: flex;
			flex-wrap: wrap;
			padding: 0;
			background-color: $white;

			.fisheye-mobile-menu-header {
				display: flex;
				flex: 0 1 100%;
				flex-wrap: wrap;
				align-items: center;
				justify-content: center;
				max-width: 100%;
				background: $gallery;
				margin-top: -53px;
				position: relative;
				text-align: center;
				padding: rem(8) $fifteen-gutter;
				height: rem(59);

				.mobile-menu-header-title,
				.back-button-title {
					display: block;
					flex: 1 1 100%;
					@include type__17(
						$font-weight: $font-weight-medium,
						$letter-spacing: 1px
					);
					text-transform: uppercase;
				}

				.mobile-menu-view-all {
					@include link__underline;
					@include type__15;
					position: relative;
					bottom: rem(3);
				}
			}

			.mobile-navigation {
				width: 100%;

				.mobile-menu-item {
					@include type__19(
						$font-weight: $font-weight-normal,
						$letter-spacing: 1px,
						$line-height: 1
					);
					text-transform: uppercase;
					cursor: pointer;

					.mobile-menu-link-wrapper,
					.mobile-menu-wrapper {
						display: flex;
						align-items: center;
						padding: rem(17);

						&:last-child {
							margin-bottom: 0;
						}

						 &:not(.display-children):after {
						 	content: '';
						 	@include svg-background('mobile-menu-arrow');
						 	display: inline-block;
						 	background-size: contain;
						 	height: 20px;
						 	width: 9px;
					    }

						img {
							border-radius: em(60);
							height: 44px;
							width: 44px;
							margin-right: $ten-gutter;
						}

						> span {
							flex: 1;
						}

						.child-listing {
							flex: 1 0 68%;
						}
					}

					&.a-z-container {
						margin-bottom: $forty-gutter;

						&:last-child {
							margin-bottom: 0;
						}

						.mobile-menu-wrapper {
							align-items: flex-start;

							&.display-children {
								.a-z-listing {
									position: relative;
									top: rem(-3); // align list items with letters

									.mobile-menu-link-wrapper {
										&:after {
											display: none;
										}
									}
								}
							}

							> span {
								@include type__19($color: $nobel);
								align-self: flex-start;
								text-align: right;
								margin-right: $twenty-gutter;
							}
						}
					}
				}

				li.mobile-menu-item {
					border-bottom: 1px solid $gallery;
				}
			}

			.mobile-menu-list-container {
				position: relative;
				order: 3;
				flex: 0 1 100%;
				max-width: 100%;

				.slide {
					position: absolute !important;
					top: 0;
					width: 100%;
					z-index: 100;
					background-color: $white;
				}
			}
		}

		.mobile-nav-footer {
			padding: $mobile-nav-footer-padding;
			background-color: $mobile-nav-footer-background-color;

			@include min-screen($screen__m) {
				display: none;
			}

			.account-area {
				margin-bottom: rem(13);
			}

			li {
				display: flex;
				align-items: center;

				&:before {
					content: "";
					display: inline-block;
					height: 9px;
					width: 5px;
					@include svg-background(
				 		$name: "arrow-right-black",
				 		$size: contain
				 	);
					margin-right: $ten-gutter;
				}

				a,
				[data-element="link_text"] {
					display: block;
					@include type__15;
				}

				&:not(:last-of-type) {
					margin-bottom: rem(14);
				}

				&.wholesale-export-link-mobile {
					padding: 0 nth($mobile-nav-footer-padding, 2);
					background-color: $sea-nymph;
					margin: 0 #{0 - nth($mobile-nav-footer-padding, 2)};

					[data-element="link_text"] {
						display: block;
						padding: rem(8) 0 $ten-gutter;
						@include type__15(
							$color: $white,
							$font-weight: $font-weight-semibold
						);
						text-transform: uppercase;
	
						&:before {
							content: "";
							display: inline-block;
							height: 9px;
							width: 5px;
							@include svg-background(
								$name: "arrow-right-white",
								$size: contain
							);
							margin-right: $ten-gutter;
						}
					}
				}
			}

			.mobile-quick-links {
				ul.pagebuilder {
					&.pagebuilder-buttons {
						display: flex;
						flex-direction: column;

						[data-content-type="button-item"] {
							display: flex !important; // To override inline styles from pagebuilder
							align-items: baseline;
						}
					}
				}
			}
		}

		&.nav-open {
			.mobile-nav-footer {
				display: flex;
				align-items: center;
			}

			.account-area,
			.switcher-currency {
				flex: 1;
			}

			.switcher-currency {
				cursor: pointer;
				
				.switcher-trigger:hover {
					opacity: $link-opacity;
				}

				.currency-symbol {
					border: $mobile-nav-footer-currency-border;
					color: $mine-shaft;
					border-radius: 200px;
					width: $mobile-nav-footer-currency-width;
					height: $mobile-nav-footer-currency-height;
					text-align: center;
					display: inline-block;
					padding-top: $mobile-nav-footer-currency-padding-top;
				}

				.switcher-dropdown {
					margin: $mobile-nav-footer-currency-dropdown-padding;
				}

				.switcher-option {
					margin: $mobile-nav-footer-currency-item-padding;

					&.current {
						.currency-symbol,
						.currency-name {
							color: $mobile-nav-footer-currency-selected-color;
							font-weight: $mobile-nav-footer-currency-selected-weight;
							border-color: $mobile-nav-footer-currency-selected-color;
						}
					}

					&:hover {
						opacity: $link-opacity;
					}
				}
			}
		}
	}

	.nav-overlay {
		~ .nav-sections {
			@include max-screen($screen__m - 1) {
				display: none;
			}			
		}

		&.active {
			~ .nav-sections {
				@include max-screen($screen__m - 1) {
					display: block;
				}
			}
		}
	}
}
