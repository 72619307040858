/* -------------------------------------------------
 * -------------------------------------------------
 * Quick Order
 *
 * -------------------------------------------------
 * @author Fisheye
 */

.quickorder-index-index {
	.column {
		.block-addbysku {
			.deletable-item:last-child {
				.remove {
					display: none;
				}
			}
		}
	}

	.column,
	.page-main {
		padding: 0;
	}

	.page-title-wrapper {
		padding-bottom: 0;

		.page-title {
			padding-bottom: $item-gutter;
		}

		.base {
			@include type__35();
		}
	}

	.strapline {
		background-color: $concrete;
		padding-bottom: $gutter;
		text-align: center;
		padding: 0 $page-gutter-mobile;

		@include min-screen($screen__m) {
			padding: rem(5) 0 rem(45) 0;
		}

		p {
			max-width: em(1000);
			margin: 0 auto;
		}
	}

	.wrap-sum-errors {
		background-color: $concrete;
		padding-bottom: $item-gutter;

		.product-error {
			max-width: em(1000);
			margin: 0 auto;
			background-color: $error-message-background;
			color: $error-message-color;

			.message {
				margin: 0;
			}
		}
	}

	// forms layout
	// ----------------
	.form {
		display: flex;
		flex-direction: column;

		.box {
			margin: 0 auto;
			width: 100%;
			padding: 0 $small-gutter;
		}

		.fieldset {
			max-width: em(1000);
			margin: 0 auto;
			background-color: $white;
			padding: $small-gutter;

			@include min-screen($screen__m) {
				padding: rem(25) $gutter $gutter $gutter;
			}
		}

		.box-items {
			flex: 0 1 auto;
			order: 1;
			background-color: $concrete;
		}

		.actions-toolbar {
			flex: 0 1 auto;
			order: 2;
			background-color: $concrete;
			padding: $small-gutter $small-gutter rem(25) $small-gutter;
			margin: 0 auto;
			width: 100%;

			@include min-screen($screen__m) {
				padding: $gutter 0 $row-gutter 0;
			}

			.primary {
				max-width: em(1000);
				margin: 0 auto;

				&:disabled {
					opacity: 0.8;
					cursor: not-allowed;
				}
			}
		}

		.actions-add-button {
			padding-top: $twenty-gutter;
		}

		.box-upload {
			flex: 0 1 auto;
			order: 3;
			padding-top: $gutter;

			@include min-screen($screen__m) {
				padding-top: $row-gutter;
			}
		}
	}

	// single SKU form
	// ----------------
	.fields {
		display: flex;
		position: relative;

		.field {
			width: auto;
			margin: 0;
			padding-top: $twenty-gutter;
			padding-bottom: $twenty-gutter;

			&.required {
				label {
					text-transform: capitalize;
					flex: 0 1 100%;
				}

				label:after {
					display: none;
				}
			}

			.ui-autocomplete {
				background: $white;
				border: $field-border;
				border-top: 0;
				position: absolute;
				z-index: 10;
				padding: $field-padding;

				li {
					@include type__16;
					cursor: pointer;

					&:hover {
						opacity: $link-opacity;
					}
				}
			}
		}

		.sku {
			flex: 1 1 auto;

			/* not ond design */
			.control {
				span[role="status"] {
					display: none;
				}
			}
			/* not ond design */
			.product-block {
				.product-error {
					display: none;
				}
			}
		}
	}

	.fields.deletable-item-title {
		border-bottom: solid 1px $border-color;

		.label {
			span {
				@include type__17;
			}
		}

		.field {
			padding-top: 0;
		}

		.sku {
			flex: 0 1 calc(100% - #{rem(115)});
		}

		.qty {
			flex: 0 1 $fifty-gutter;
			margin-right: $twenty-gutter;
		}
	}

	.deletable-item {
		flex-flow: row wrap;
		border-bottom: solid 1px $border-color;

		.field {
			flex-direction: row;
		}

		&:last-child {
			border-bottom: 0;

			.field {
				padding-bottom: 0;
			}
		}

		label {
			@extend %visuallyhidden;
		}

		.sku {
			padding-right: $twenty-gutter;

			.control {
				width: 100%;
			}
		}

		.field {
			.qty {
				width: $fifty-gutter;
				padding-left: em(5);
				padding-right: em(5);
				padding-top: rem(9);
				padding-bottom: $ten-gutter;
				margin-right: rem(20);
			}
		}

		.action.add {
			@include button__default($padding: rem(8) $ten-gutter rem(9));
		}

		// delete btn
		.actions-toolbar {
			margin: 0;
			position: absolute;
			right: rem(153);
			top: em(20);
			padding: 0;
			background-color: transparent;
			width: auto;

			.secondary {
				border-bottom: 0;
			}

			.remove {
				width: em(50);
				height: em(50);
				background: none;
				border: 0;
				padding: 0;

				span {
					@extend %visuallyhidden;
				}

				&:after {
					@include svg-background($name: "close-icon-white");
					content: "";
					width: em(27);
					height: em(27);
					background-color: $grey-dark;
					padding: $small-gutter;
					border-radius: 100px;
					display: inline-block;
				}
			}
		}

		.quick-order-wrapper {
			width: 100%;
			display: flex;
		}

		.product-error {
			margin: 0;

			.error {
				margin-top: $ten-gutter;
				padding: 0;
			}
		}

		.product-block {
			width: 100%;
			flex: 0 1 100%;

			.product-info {
				display: flex;
				align-items: center;
				background-color: $concrete;
				border: solid 1px $border-color;
				margin-bottom: $twenty-gutter;
				padding: em(5) $gutter em(5) em(5);

				.product-image {
					flex: 0 1 100px;
					flex-basis: 100px;

					img {
						width: 100%;
					}
				}

				.product-name {
					padding-left: $gutter;
					flex: 1 1 auto;
					display: flex;
					flex-wrap: wrap;

					p {
						flex: 0 1 100%;

						@include min-screen($screen__m) {
							flex: 0 1 50%;
						}
					}

					.name {
						@include type__19($font-family: $font-family-sans-serif-alt);
						order: 1;
					}

					.sku {
						@include type__16($font-weight: $font-weight-bold);
						order: 2;
						padding: em(5) 0;

						@include min-screen($screen__m) {
							order: 3;
							padding: 0;
						}
					}

					.price {
						@include type__19($color: $blaze-orange);
						order: 3;

						@include min-screen($screen__m) {
							text-align: right;
							order: 2;
						}
					}
				}
			}
		}
	}

	// Add to basket btn
	// ----------------
	.actions-toolbar {
		.primary {
			width: 100%;
		}
	}

	// Bulk add to list form
	// ----------------
	.box-upload {
		.fieldset,
		.actions-toolbar {
			padding: 0;
		}

		.additional {
			flex-wrap: wrap;

			.block-title {
				@include type__30($color: $boulder);
				flex: 0 1 100%;
				margin-bottom: 0;
			}
		}

		.fields {
			.field {
				display: block;
				padding-top: $small-gutter;
			}

			.sku {
				.input-text {
					min-height: em(150);
					resize: none;
				}
			}
		}

		.note {
			padding-top: $ten-gutter;

			p {
				font-style: italic;
				display: block;
			}

			a {
				@include link__underline;
				font-weight: $font-weight-bold;
				font-style: normal;
				display: inline-block;
				margin-top: $small-gutter;
				margin-right: 100%; // hacky but its the only way to make the link break onto a new line without
				min-width: em(165); // breaking the underline link
			}
		}

		.actions-toolbar {
			margin-top: 0;
			background-color: transparent;
			padding-top: 0;
			padding-bottom: $gutter;

			@include min-screen($screen__m) {
				padding-bottom: $row-gutter;
			}

			.secondary {
				width: 100%;
				margin-top: 0;
				border-bottom: 0;
			}

			.action {
				@include button__default();
				width: 100%;
			}
		}

		// uploads form
		// ----------------
		.fieldset {
			.upload {
				&.skus {
					padding-bottom: $gutter;

					@include min-screen($screen__m) {
						padding-bottom: $row-gutter;
					}

					.label {
						span {
							@include type__30($color: $boulder);
							text-transform: uppercase;
						}
					}

					.note {
						padding-top: $ten-gutter;
					}
				}
			}
		}

		.upload {
			border-top: 1px solid $border-color;
			padding-top: $twenty-gutter;

			@include min-screen($screen__m) {
				padding-top: $fifty-gutter;
				margin-bottom: $gutter;
			}

			.label {
				flex: 0 1 100%;
				padding-bottom: $twenty-gutter;
			}

			.control {
				max-width: 100%;
				display: flex;
				flex-direction: column;
				flex: 0 1 100%;

				.note {
					flex: 0 1 auto;
					order: 2;
					padding-top: 0;
				}

				.upload-container {
					flex: 0 1 auto;
					order: 1;
				}

				.file-upload {
					@extend %visuallyhidden;
				}

				// upload button styles
				// ----------------
				// hacky stuff to override default styles
				.action-upload {
					// this is an input with type="file"
					border: 0;
					color: transparent; // hides the "no file chosen"
					width: 100%;
					text-align: center;
					display: flex;
					background-color: transparent;
					padding: 0;
					margin: 0;
					cursor: pointer;
					max-height: em(60);
				}

				.action-upload::-webkit-file-upload-button {
					// hides the default choose while btn
					visibility: hidden;
				}

				.upload-container input {
					opacity: 0;
					padding: $button-default-padding;
				}

				.upload-container {
					position: relative;
					display: flex;
					align-items: center;
				}

				.upload-container:before {
					content: "Choose file";
					width: 100%;
					@include button__default();
					@include type__19;
					text-align: center;
					color: $black;
					margin: 0 auto;
					position: absolute;
					left: 0;
					top: 0;
					z-index: 0;
				}

				.action-upload {
					z-index: 1;
				}

				.action-upload:hover {
					&:before {
						background-color: darken($button-default-background, 12%);
					}
				}
			}
		}
	}
}
