/* -------------------------------------------------
 * -------------------------------------------------
 * Price Styles
 *
 * --------------------------------------------------
 * @author Fisheye
 */

// -------------------------------------------------
// Default / fallback price
// -------------------------------------------------

@mixin price($price-color: $mine-shaft) {
	@include type__16(
		$color: $price-color,
		$font-weight: $font-weight-semibold
	);
}

// -------------------------------------------------
// Summary price - Used on basket & checkout
// -------------------------------------------------

@mixin checkout-summary-price(
	$font-family: $font-family-sans-serif,
	$font-size: 16,
	$font-weight: $font-weight-semibold,
	$color: $body-font-color
) {
	@include type-setting($font-size);
	font-family: $font-family;
	font-weight: $font-weight;
	color: $color;
	text-align: right;
}

// -------------------------------------------------
// Category Product listing price
// -------------------------------------------------

@mixin category-listing-price(
	$new-price-color: $mine-shaft,
	$old-price-color: $acapulco
) {
	.price-box {
		display: flex;
		flex-direction: column;

		.special-price {
			order: 1;
		}
	}

	.old-price {
		.price-label,
		.price {
			@include type__15($color: $old-price-color);
		}

		.price {
			text-decoration: line-through;
		}
	}

	.special-price {
		.price-label {
			@include type__16(
				$color: $new-price-color,
				$font-weight: $font-weight-semibold
			);
		}
	}
}

// -------------------------------------------------
// Product page price
// -------------------------------------------------

@mixin product-page-price(
	$new-price-color: $mine-shaft,
	$old-price-color: $acapulco
) {
	.price-box {
		display: flex;

		.special-price {
			order: 1;
		}
	}

	.price-label,
	.price {
		@include type__19(
			$color: $new-price-color,
			$font-weight: $font-weight-semibold
		);

		@include min-screen($screen__ml) {
			@include type__27(
				$color: null,
				$font-weight: null,
				$letter-spacing: 1px
			);
		}
	}

	.old-price {
		margin-right: $fifteen-gutter;

		@include min-screen($screen__ml) {
			margin-right: $twenty-gutter;
		}

		.price-label,
		.price {
			@include type__19($color: $old-price-color);

			@include min-screen($screen__ml) {
				@include type__27(
					$color: null,
					$letter-spacing: 1px
				);
			}
		}

		.price {
			text-decoration: line-through;
		}
	}

	.price-from {
		display: flex;

		.price-final_price {
			order: 2;
		}
	}

	.price-to {
		@extend %visuallyhidden;
	}
}

// -------------------------------------------------
// Default / fallback price
// -------------------------------------------------

.price {
	@include price;
}

// -------------------------------------------------
// Product listing
// -------------------------------------------------

.product-item-info,
.product-shop {
	@include category-listing-price;
}

// -------------------------------------------------
// Product page
// -------------------------------------------------

.product-info-price {
	@include product-page-price;
}
