/* -------------------------------------------------
 * -------------------------------------------------
 * Useful Icon mixins
 *
 * Expected Usage:
 *	a {
 *		@include icon__cross-black;
 *	}
 *
 * Output:
 *	a {
 *		&:after {
			@include svg-background("close-icon-black");
			display: block;
			content: "";
			background-size: contain;
			height: em(15);
			width: em(15);
		}
 *	}
 * -------------------------------------------------
 * @author Fisheye
 */


/*
 * -------------------------------------------------
 * Collected styles and mixins to add black cross SVG :after
 *
 * @params: width & height of icon
 */
@mixin icon__cross-black(
	$width: em(8),
	$height: em(8)
) {
	&:after {
		content: "";
		@include svg-background("close-icon-black");
		display: inline-block;
		background-size: contain;
		height: $height;
		width: $width;
	}
}

/*
 * -------------------------------------------------
 * Collected styles and mixins to add white cross SVG :after
 *
 * @params: width & height of icon
 */
@mixin icon__cross-white(
	$width: em(15),
	$height: em(15),
	$background-color: $nobel
) {
 	&:after {
		content: "";
		@include svg-background(
			$name: "close-icon-white",
			$size: auto
		);
		display: inline-block;
		background-color: $background-color;
		height: $height;
		width: $width;
		padding: rem(13);
		border-radius: 100px;
		margin-left: $ten-gutter;
	}
}

/*
 * -------------------------------------------------
 * Collected styles and mixins to add a black arrow to buttons
 *
 * @params: width & height of arrow
 * @params: $rotation allows re-use of same icon on forwards and backwards buttons (good for pagination)
 *
 * Note: :after is not included in this mixin because these would be placed :before or :after depending on usage
 */
@mixin icon__arrow-black(
	$width: em(10),
 	$height: em(10),
 	$rotation: 0deg
) {
	content: '';
	display: inline-block;
	@include svg-background('arrow-right');
	background-size: contain;
	height: $height;
	width: $width;
	transform: rotate($rotation);
}

@mixin icon__arrow-white(
	$width: em(19),
	$height: em(19),
	$rotation: 0deg,
	$background-size: 7px 
) {
	content: '';
	display: block;
	@include svg-background(
		$name: 'arrow-right-white',
		$size: $background-size
	);
	height: $height;
	width: $width;
	transform: rotate($rotation);
}

/*
 * -------------------------------------------------
 * Collected styles and mixins to add PayPal logo to buttons
 *
 * @params: width & height of logo
 */
@mixin button__paypal-logo(
	$width: 73px,
	$height: 19px
) {
	&:after {
		display: inline-block;
		content: "";
		background-image: url("../images/png/paypal.png");
		background-repeat: no-repeat;
		background-size: contain;
		vertical-align: bottom;
		margin-left: 2px;
		height: $height;
		width: $width;

		@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
			background-image: url("../images/png/paypal@2x.png");
			background-repeat: no-repeat;
		}
	}
}
