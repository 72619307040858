/* -------------------------------------------------
 * -------------------------------------------------
 * Main area for product details. Add to basket form
 *
 * Options/swatches are in _product-options.scss
 *
 * -------------------------------------------------
 * @author Fisheye
 */

.catalog-product-view {
	.breadcrumbs {
		background-color: $gallery;
	}

	.product-info-main {
		text-align: center;

		@include min-screen($screen__ml) {
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding: rem(6) $gutter $fifteen-gutter;
		}

		.product.pricing {
			display: none;
		}

		.page-title-wrapper {
			background: 0;
			padding: 0;

			.page-title {
				.base {
					display: block;
					@include type__27;
					text-transform: uppercase;

					@include min-screen($screen__ll) {
						@include type__50;
					}

					em {
						font-style: normal;
					}
				}
			}
		}

		.product.alert {
			margin-top: $ten-gutter;

			&.price {
				margin-top: rem(25);
			}

			&.price,
			&.stock {
				.action-product-alert,
				.action-price-alert {
					display: flex;
					align-items: center;
					justify-content: center;
					text-transform: capitalize;
					cursor: pointer;

					&:before {
						content: '';
						display: inline-block;
						position: relative;
						top: rem(1);
						height: 8px;
						width: 5px;
						margin-right: $ten-gutter;

						@include svg-background(
							$name: 'arrow-right-black',
							$size: 100% 100%
						);
					}
				}
			}

			.alert-guest-form {
				position: relative;
				margin-top: $twenty-gutter;

				@include min-screen($screen__m) {
					max-width: rem(350);
					margin: $ten-gutter auto auto;
				}

				input[type="submit"] { // No ID or Class
					position: absolute;
					right: 0;
					top: 0;
					width: rem(30);
					color: transparent;
					background-color: transparent;
					border: 0;
					padding: 0;

					@include svg-background(
						$name: 'arrow-right-black',
						$size: 7px 100%
					);
				}
			}
		}

		.free__delivery {
			margin-top: $free-delivery-message-margin-top;
		}

		.prices-tier {
			display: flex;
			flex-wrap: wrap;
			flex: 1 1 100%;
			margin-top: rem(11);

			@include min-screen($screen__m) {
				flex: 1;
				margin: rem(6) 0;
			}

			@include min-screen($screen__l) {
				display: flex;
				flex-wrap: nowrap;
				flex: auto;
			}

			@include min-screen($screen__xl) {
				margin-left: $gutter;
			}

			.item {
				display: flex;
				align-items: center;
				background-color: $silver-chalice;
				padding: rem(7) $fifteen-gutter;
				@include type__13;
				border-bottom: 1px solid $white;
				flex: 1 1 100%;

				@include min-screen($screen__m) {
					@include type__14($font-weight: $font-weight-thin);
					text-align: center;
					border-right: 1px solid $white;
				}

				@include min-screen($screen__l) {
					padding: rem(13);
					flex: 1;
					flex-wrap: wrap;
					justify-content: center;
				}

				@include min-screen($screen__xl) {
					padding: rem(14);
				}

				.price-tier_price {
					flex: 1;
					text-align: right;
					margin-right: $five-gutter;

					@include min-screen($screen__l) {
						text-align: center;
						flex: 1 1 100%;
						margin-right: 0;
						margin-top: rem(6);
					}

					.price {
						display: flex;
						justify-content: flex-end;
						@include type__15(
							$color: $red-ribbon,
							$font-weight: $font-weight-bold
						);

						@include min-screen($screen__l) {
							justify-content: center;
							@include type__17(
								$color: $red-ribbon,
								$font-weight: $font-weight-bold,
								$line-height: 0.8
							);
						}
					}
				}

				.benefit {
					font-weight: $font-weight-semibold;
				}
			}
		}

		.reviews-additional-group-container {
			margin-top: $product-info-main-additional-margin-top;
		}

		.trustpilot-widget {
			display: inline-block;
			margin-left: rem(-16);
			margin-top: $ten-gutter;
		}

		.sku {
			.type,
			.value {
				display: inline-block;
			}

			.type {
				font-weight: $product-sku-weight;

				&:after {
					content: ":";
				}
			}
		}

		// Product Stock
		.product-info-stock-sku {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			align-items: center;

			.sku {
				margin-top: rem(13);
				width: 100%;

				@include min-screen($screen__m) {
					margin-top: $ten-gutter;
				}

				.type,
				.value {
					@include type__14;
				}
			}

			.stock {
				display: flex;
				justify-content: flex-end;
				text-align: $product-info-stock-align;
				margin-top: $five-gutter;

				&.available {
					span {
						background-color: $product-info-stock-background-color;
					}
				}

				&.unavailable {
					span {
						background-color: $red-ribbon;
					}
				}

				span {
					display: flex;
					align-items: center;
					text-transform: $product-info-stock-text-transform;
					padding: rem(4) $ten-gutter;

					@include type__13(
						$color: $product-info-stock-color,
						$font-weight: $font-weight-semibold
					);

					&:empty {
						display: none; // remove empty div element until content added
					}

					&:before {
						content: '';
						width: $product-info-stock-icon-width;
						height: $product-info-stock-icon-height;
						margin-right: $product-info-stock-icon-margin-right;
						@include svg-background(
							$name: $product-info-stock-available-icon-name,
							$size: contain
						);
					}
				}
			}
		}
	}

	.dietary-tooltip-wrapper {
		margin-top: $twenty-gutter;
	}

	.product-price-action {
		.featured-label {
			@include type__16(
				$color: $white,
				$font-weight: $font-weight-semibold,
				$line-height: 1
			);
			padding: rem(8) $ten-gutter rem(9);
			background-color: $mine-shaft;
			text-align: center;
			text-transform: uppercase;

			@include min-screen($screen__ml) {
				@include type__17(
					$color: null,
					$font-weight: null,
					$line-height: 1
				);
			}
		}

		.product-info-price {
			align-items: flex-end;
			background: $white;
			display: flex;
			padding: rem(12) $fifteen-gutter $ten-gutter;

			@include min-screen($screen__m) {
				padding: rem(23) $gutter $fifteen-gutter;
			}
		}

		.price-box {
			flex: 0 1 100%;
			text-align: left;

			.price-wrapper {
				margin-right: $ten-gutter;
			}

			.old-price {
				.uom-label {
					@extend %visuallyhidden;
				}
			}

			.minimal-price {
				display: flex;

				.price-final_price {
					order: 2;
				}

				.old-price {
					order: 1;
				}
			}
		}

		.cheese-buying-guide,
		.key-subscription-info {
			display: none;
			flex: 0 1 50%;

			@include min-screen($screen__m) {
				display: flex;
				justify-content: flex-end;
			}

			.title {
				padding-left: $fifteen-gutter;
				position: relative;
				@include type__16;
				cursor: pointer;

				&:before {
					content: "";
					display: block;
					height: 9px;
					width: 5px;
					@include svg-background(
						$name: 'arrow-right-black',
						$size: 5px
					);
					position: absolute;
					top: 7px;
					left: 0;
				}
			}
		}

		.cheese-buying-guide {
			flex: 0 0 50%; // Set text on one line

			.title {
				&:before {
					top: rem(6);
					@include min-screen($screen__ll) {
						top: rem(7);
					}
				}
			}
		}

		// Main add to cart area
		.product-info-addto {
			align-items: center;
			display: block;
			width: 100%;
			padding: $product-addto-padding;
			background: $white;

			@include min-screen($screen__m) {
				padding: $product-addto-padding-desktop;
			}

			.aw-sarp2-product-subscription-options {
				.subscription-options-title {
					margin-bottom: rem(12);
					text-align: left;

					strong {
						@include type__14($font-weight: $font-weight-semibold);
						text-transform: uppercase;
					}
				}

				.fieldset {
					.subscription-type {
						flex-wrap: nowrap;
					}
				}

				.aw-sarp2-subscription__options-list {
					background-color: $white;
					display: flex;
					flex-flow: column wrap;

					@include min-screen($screen__m) {
						flex-flow: row wrap;
					}

					@include min-screen($screen__ml) {
						flex-flow: column wrap;
					}

					@include min-screen($screen__ll) {
						flex-flow: row wrap;
					}

					.field {
						background-color: $gallery;
						color: $shadow-green;
						flex: 1;
						margin-bottom: 0;

						@include min-screen($screen__ml) {
							flex: auto;
						}

						@include min-screen($screen__ll) {
							flex: 0 1 percentage(1/2);
							border-bottom: 1px solid $white;
						}

						@include min-screen($screen__xxl) {
							flex: 0 1 percentage(1/3);
						}

						&:not(:last-child) {
							@include min-screen($screen__m) {
								display: flex;
							}

							@include min-screen($screen__ml) {
								display: block;
							}

							@include min-screen($screen__ll) {
								display: flex;
							}

							&:after {
								content: '';
								border-bottom: 1px solid $white;
								display: block;
								width: 100%;

								@include min-screen($screen__m) {
									border-bottom: 0;
									border-right: 1px solid $white;
									width: auto;
								}

								@include min-screen($screen__ml) {
									border-bottom: 1px solid $white;
									border-right: 0;
									width: 100%;
								}

								@include min-screen($screen__ll) {
									border-bottom: 0;
									border-right: 1px solid $white;
									width: auto;
								}
							}
							input {
								+ label {
									@include min-screen($screen__xxl) {
										border-bottom: 2px solid $gallery;
									}
								}
							}
						}

						input {
							+ label {
								align-items: center;
								border: 2px solid $gallery;
								display: flex;
								flex: 1;
								height: 100%;
								padding: rem(11) $ten-gutter rem(9);

								@include min-screen($screen__m) {
									padding: rem(14) $fifteen-gutter rem(12);
									justify-content: center;
								}

								@include min-screen($screen__ml) {
									justify-content: flex-start;
								}

								@include min-screen($screen__ll) {
									justify-content: center;
								}

								span {
									display: flex;
									align-self: center;
								}
							}

							&:checked {
								+ label {
									border: 2px solid $shadow-green;
									z-index: 1;
								}
							}
						}

						.label {
							display: flex;
							position: static;
							left: 0;

							span {
								text-transform: uppercase;
								@include type__14($font-weight: $font-weight-semibold);
							}
						}
					}
				}

				.subscription-details {
					@extend %visuallyhidden;
					padding: $ten-gutter;

					@include min-screen($screen__m) {
						padding: rem(14) $twenty-gutter rem(16);
					}

					.block-title,
					.details-field {
						text-transform: capitalize;
					}

					.details-field,
					.details-value,
					span {
						@include type__14;
					}

					.block-title strong,
					.details-field {
						font-weight: $font-weight-semibold;
					}

					li {
						padding-bottom: rem(11);

						&:last-child {
							padding-bottom: 0;
						}
					}

					.block-content {
						padding-top: $ten-gutter;
					}
				}
			}

			// ---------------------------
			// duplicated below for more style control.
			// Here only so that order can be easily viewed and changed
			.page.messages {
				order: 1;
			}

			.product-add-form {
				order: 3;
			}

			.product-price-stock {
				order: 2;
			}

			.product-add-form,
			.product-add-form .product-options-wrapper .fieldset {
				flex: 1;

				.field {
					&.uom-denomination,
					&.configurable {
						margin-bottom: $fifteen-gutter;
						position: relative;

						@include min-screen($screen__m) {
							margin-bottom: $twenty-gutter;
						}

						> label {
							position: absolute;
							top: rem(7);
							left: $fifteen-gutter;
							text-transform: uppercase;

							&:after {
								content: '*';
								display: inline-block;
								padding: 0;
								margin-left: rem(3);
								vertical-align: top;
								@include type__11(
									$color: $form-required-input-icon-color,
									$font-weight: $font-weight-semibold
								);
							}
						}

						> label,
						> label span {
							@include type__11 (
								$font-weight: $font-weight-semibold,
								$letter-spacing: null,
								$line-height: 1
							);
						}

						select {
							@include type__14($font-weight: $font-weight-medium);
							min-height: rem(40);
							border-radius: 3px;
							border: none;
							background-color: $gallery;
							background-size: rem(15) rem(10);
							background-position: calc(100% - #{$fifteen-gutter}) 15px;
							padding: $fifteen-gutter $fifteen-gutter $five-gutter $fifteen-gutter;
							width: 100%;
						}
					}

					&.select-month {
						select {
							padding: $five-gutter $fifteen-gutter;
						}
					}
				}

				.giftcard-amount {
					.giftcard-amount-entry {
						&.mage-error {
							margin-top: 0;
						}
					}
				}

				.product-options-wrapper {
					margin-bottom: rem(17);

					@include min-screen($screen__m) {
						margin-bottom: $twenty-gutter;
					}

					.fieldset {
						> .field {
							margin: 0;
							flex-direction: column;
							flex-wrap: wrap;

							@include min-screen($screen__m) {
								flex-wrap: nowrap;
							}

							&:not(:last-child) {
								margin-bottom: $fifteen-gutter;

								@include min-screen($screen__m) {
									margin-bottom: $gutter;
								}
							}

							&.subscription-length,
							&.subscription-starts {
								.options-list {
									.field {
										@include min-screen($screen__m) {
											flex: 0 1 percentage(1/3);
										}

										@include min-screen($screen__ml) {
											flex: 0 1 100%;
										}

										@include min-screen($screen__ll) {
											flex: 0 1 percentage(1/3);
										}
									}
								}
							}

							&.subscription-length {
								.price-notice {
									display: none; // Hides the 'additional cost' on subscription monthly options
								}

								.options-list {
									padding: 0;

									.label {
										height: 100%;

										@include min-screen($screen__ll) {
											flex-direction: column;
										}
									}
								}
							}

							&.subscription-starts {
								background-color: $gallery;

								> .label {
									display: flex;
									justify-content: space-between;
									padding: rem(12) rem(18);

									span {
										display: flex;
										align-items: center;
										font-weight: $font-weight-normal;

										&:before {
											content: '';
											display: inline-block;
											height: 26px;
											width: 26px;
											margin-right: $ten-gutter;
											@include svg-background(
												$name: 'calendar-icon',
												$size: 100% 100%
											);
										}

										strong {
											font-weight: $font-weight-semibold;
											padding-left: $five-gutter;
										}
									}

									&:after {
										display: none;
									}

									.edit {
										@extend %cancel-button;
										text-transform: uppercase;
										padding: 0;
										@include type__14($font-weight: $font-weight-semibold);

										&:after {
											content: '';
											display: inline-block;
											height: 9px;
											width: 9px;
											@include svg-background(
												$name: 'arrow-right-black',
												$size: 100% 100%
											);
										}
									}
								}

								.control {
									padding: rem(7) rem(18) $twenty-gutter;

									.options-list {
										padding: 0;

										@include min-screen($screen__ml) {
											justify-content: space-between;
										}

										.field {
											@include min-screen($screen__ll) {
												max-width: calc(50% - 7px);
											}

											@include min-screen($screen__xxl) {
												max-width: none;
											}

											.label {
												justify-content: flex-start;
												padding: $fifteen-gutter;
												background-color: $white;

												@include min-screen($screen__ll) {
													margin-bottom: rem(17);
												}

												@include min-screen($screen__xxl) {
													margin-bottom: 0;
												}

												&:after {
													display: none;
												}

												> span {
													@include type__12($font-weight: $font-weight-semibold);
												}
											}

											input[type="radio"] {
												&:checked {
													+ label {
														border-left: 2px solid $gallery;
														border-right: 2px solid $gallery;
														border-bottom: none;
														border-color: $gallery;

														@include min-screen($screen__m) {
															border-left: 2px solid $white;
														}
													}
												}
											}

											&:not(:first-child) {
												.label {
													border-top: 1px solid $gallery;

													@include min-screen($screen__m) {
														margin-left: $ten-gutter;
														border-top: 0;
													}

													@include min-screen($screen__ml) {
														margin-left: 0;
														border-top: 1px solid $gallery;
													}

													@include min-screen($screen__xxl) {
														margin-left: $ten-gutter;
														border-top: 0;
													}

													span {
														align-items: center;
													}
												}
											}

											&:last-child,
											&:nth-last-child(2) {
												.label {
													margin-bottom: 0;
												}
											}
										}
									}
								}

								[data-role="content"] {
									@extend %visuallyhidden; // DO NOT use display: none;
								}

								&.active {
									[data-role="content"] {
										@extend %cancel-visuallyhidden;
										height: 100%;
										overflow: initial;
										width: 100%;
									}
								}
							}

							.control {
								background-color: $gallery;
							}

							> label {
								position: static;
								padding-bottom: rem(13);

								&:after {
									display: none;
								}

								span {
									@include type__14($font-weight: $font-weight-semibold);
								}
							}

							&.bespoke-subscription-\(optional\) {
								> .label {
									background-color: $gallery;
									justify-content: center;
									padding-top: rem(13);
								}
							}
						}
					}

					.options-list {
						display: flex;
						flex-flow: column wrap;
						padding: 0 rem(17) rem(17) rem(17);

						@include min-screen($screen__m) {
							flex-flow: row wrap;
							justify-content: space-between;
						}

						@include min-screen($screen__ml) {
							flex-flow: row wrap;
						}

						.field {
							margin: 0;

							@include min-screen($screen__m) {
								flex: 0 1 calc(#{percentage(1/3)} - #{rem(7)});

								&:nth-child(n+4) {
									margin-top: rem(7);
								}
							}

							@include min-screen($screen__ml) {
								flex: 0 1 100%;

								&:nth-child(n+4) {
									margin-top: 0;
								}
							}

							@include min-screen($screen__ll) {
								flex: 0 1 calc(#{percentage(1/3)} - #{rem(7)});

								&:nth-child(n+4) {
									margin-top: rem(7);
								}
							}

							&:not(:first-child) {
								input {
									&[type="checkbox"] {
										+ .label {
											border-top: 1px solid $gallery;

											@include min-screen($screen__m) {
												border-top: 0;
											}

											@include min-screen($screen__ml) {
												border-top: 1px solid $gallery;
											}

											@include min-screen($screen__ll) {
												border-top: 0;
											}
										}
									}
								}
							}

							&:not(:last-child) {
								&[type="checkbox"] {
									+ .label {
										@include min-screen($screen__ml) {
											margin-bottom: rem(17);
										}

										@include min-screen($screen__ll) {
											margin-bottom: 0;
										}
									}
								}
							}

							&:nth-child(2) {
								input[type="checkbox"] {
									~ label {
										@include min-screen($screen__ll) {
											width: auto;
										}
									}
								}
							}

							input[type="checkbox"] {
								~ label {
									background-color: $white;
									justify-content: flex-start;
									padding-left: $fifty-gutter;

									&:before {
										margin-left: $fifteen-gutter;
									}
								}

								 .control {
									padding: rem(17);

									@include min-screen($screen__m) {
										padding: rem(13) $twenty-gutter $twenty-gutter;
									}
								}
							}

							.label {
								flex-flow: row wrap;
								padding: $fifteen-gutter rem(12);
								align-items: center;
								display: block;

								@include min-screen($screen__m) {
									justify-content: center;
									padding: $fifteen-gutter;
								}

								&:after {
									@extend %visuallyhidden;
								}

								> span {
									@include type__12($font-weight: $font-weight-semibold);
									text-transform: uppercase;

									@include min-screen($screen__ll) {
										max-width: rem(100);
									}

									@include min-screen($screen__xxl) {
										max-width: 100%;
									}
								}
							}

							input[type="radio"] {
								+ .label {
									display: flex;
									flex-flow: row nowrap;
									justify-content: center;
									border: 2px solid $gallery;

									@include min-screen($screen__m) {
										flex-flow: row wrap;
									}

									@include min-screen($screen__ml) {
										flex-flow: row nowrap;
									}

									@include min-screen($screen__ll) {
										align-items: center;
									}

									@include min-screen($screen__xl) {
										flex-flow: row wrap;
									}

									> span {
										@include type__14($font-weight: $font-weight-semibold);
										flex: 1;

										@include min-screen($screen__m) {
											flex: none;
										}

										@include min-screen($screen__ml) {
											flex: 1;
										}

										@include min-screen($screen__ll) {
											flex: none;
										}
									}

									.price-notice {
										color: $gallery;
										justify-content: center;
										flex: none;

										@include min-screen($screen__m) {
											flex: 1 1 100%;
										}

										@include min-screen($screen__ml) {
											flex: none;
										}

										@include min-screen($screen__xl) {
											flex: 1 1 100%;
										}

										.price-container {
											span {
												@include type__14;

												&:after {
													padding-left: $five-gutter;
												}
											}
										}
									}
								}

								+ .label {
									margin-left: 0;
									border-bottom: 1px solid $white;

									@include min-screen($screen__m) {
										border-left: 1px solid $white;
									}

									@include min-screen($screen__ml) {
										border-bottom: 1px solid $white;
										border-left: 0;
									}

									@include min-screen($screen__ll) {
										border-left: 1px solid $white;
										border-bottom: 1px solid $gallery;
									}
								}

								&:checked {
									+ label {
										border: 2px solid $shadow-green;
										z-index: 1;
									}
								}
							}
						}
					}
				}

				.box-tocart {
					.fieldset {
						display: flex;
					}

					.field.qty {
						flex: 1 1 rem(80);
						width: auto;
						margin-right: $fifteen-gutter;

						@include min-screen($screen__ml) {
							margin-right: $twenty-gutter;
						}
					}

					.actions {
						width: 100%;
						display: flex;
						flex-direction: column;

						.primary {
							order: 2;
							@include button__journey($padding: $five-gutter $twenty-gutter);
							width: 100%;

							span {
								padding-left: $gutter;
								position: relative;
								@include type__17(
									$color: $white,
									$font-weight: $font-weight-normal,
									$letter-spacing: 1px
								);

								&:before {
									content: "";
									display: block;
									height: rem(22);
									width: rem(21);
									@include svg-background(
										$name: "basket-icon-white",
										$size: contain
									);
									margin: auto;
									position: absolute;
									top: 0;
									bottom: 0;
									left: 0;
								}
							}

							&:hover,
							&:focus {
								span {
									&:before {
										@include svg-background(
											$name: "basket-icon-shadow-green",
											$size: null
										);
									}
								}
							}
						}
					}
				}
			}
		}

		.bundle-actions {
			.action {
				@include button__journey;
				width: 100%;
			}

			+ .product-info-addto {
				margin-top: 0;
				padding: 0;
			}
		}

		[data-element="leadTime"] {
			display: flex;
			justify-content: center;
			text-align: center;

			.lead-time-message {
				display: flex;
				flex: 1;
				align-items: center;
				flex-flow: row wrap;
				justify-content: center;
				padding: rem(17) 0 rem(14);
				@include type__16($letter-spacing: 1px);
				text-transform: uppercase;

				@include min-screen($screen__m) {
					padding: rem(23) $fifteen-gutter rem(22);
					@include type__17($letter-spacing: null);
				}

				@include min-screen($screen__ll) {
					align-items: flex-start;
				}

				&:before {
					content: "";
					display: inline-block;
					height: 23px;
					width: 23px;
					@include svg-background(
						$name: 'clock-icon',
						$size: 100% 100%
					);

					@include max-screen($screen__m - 1) {
						width: 100%;
					}

					@include min-screen($screen__m) {
						margin-right: $five-gutter;
						vertical-align: bottom;
					}
				}

				&:after {
					@include type__16(
						$line-height: 1,
						$color: $persian-red
					);
					content: '*';
					display: inline-block;
				}
			}

			.time-remaining {
				color: $sea-nymph-dark;
				font-weight: $font-weight-semibold;
			}
		}
	}

	.cms-strapline-links {
		padding: 0;
		margin-bottom: rem(36);

		@include min-screen($screen__m) {
			margin-bottom: rem(70);
		}
	}

	.block.related {
		padding-top: 0;
	}

	.select-options-button-wrapper {
		position: sticky;
		bottom: 0;
		z-index: 100;

		@include min-screen($screen__m) {
			display: none;
		}

		.select-options-button {
			@include button__journey($border-radius: 0);
			width: 100%;
		}
	}

	&.catalog_product_view_out_of_stock {
		.product-info-addto {
			padding-top: 0;
			padding-bottom: rem(2);

			@include min-screen($screen__m) {
				padding-bottom: $ten-gutter;
			}
		}
	}

	&.page-product-giftcard {
		.product-info-price {
			@extend %visuallyhidden;
		}

		.product-info-addto {
			padding: rem(21) $fifteen-gutter $twenty-gutter;

			@include min-screen($screen__m) {
				padding: $gutter;
			}

			.giftcard {
				&.form {
					.field {
						&:last-child {
							margin-bottom: 0;
						}
					}
				}
			}
		}
	}

	&.modal-open {
		.select-options-button-wrapper {
			z-index: 99;
		}
	}
}

// -------------------------------------------------
// Subscription product price
// -------------------------------------------------

.page-layout-subscription,
.page-layout-gift-subscription {
	.product-price-action {
		.trial-price,
		.duration-price {
			display: inline-block;
			margin-right: $twenty-gutter;
		}
	}
}

// -------------------------------------------------
// Product Links
// -------------------------------------------------

.product-addto-links {
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	margin: $product-links-margin;

	.towishlist,
	.tocompare,
	.todelivery,
	.social_links,
	.block-requisition-list {
		flex: $product-links-mobile-flex;
		margin-top: $product-links-item-margin-top;

		@include min-screen($product-info-icon-breakpoint) {
			flex: $product-links-flex;
		}
	}

	.towishlist span,
	.tocompare span,
	.todelivery,
	.social_links .trigger,
	.requisition-list-action button span {
		&:before {
			content: "";
			width: $product-links-icon-width;
			height: $product-links-icon-height;
			display: inline-block;
			vertical-align: text-top;
			margin-right: $product-links-icon-margin-right;
		}
	}

	.block-requisition-list {
		padding: 0;

		.requisition-list-action {
			button {
				-webkit-appearance: none;
				border: 0;
				background: transparent;
				padding: 0;

				span {
					padding-right: $twenty-gutter;
					white-space: nowrap;
					color: $body-font-color;
					/* color is set correctly already but if you dont set ane explicit colour on safari it shows up white when clicked */

					&:before {
						@include svg-background(
							$name: $product-links-requisition-icon-name,
							$size: contain
						);
					}
				}
			}

			.items {
				display: none;
				position: absolute;
				background: $product-requisition-dropdown-background;
				border: 1px solid $border-color;
				margin-top: 5px;

				&:before {
					content: ' ';
					@include css-triangle(10px, $border-color, bottom);
					top: -20px;
					left: 15px;
				}

				li:first-of-type {
					&:before {
						@include css-triangle(10px, $product-requisition-dropdown-background, bottom);
						top: -19px;
						left: 15px;
					}
				}
			}

			&.active {
				.items {
					display: block;

					ul {
						li {
							padding: $ten-gutter;
							cursor: pointer;
						}
					}
				}
			}
		}
	}

	.towishlist {
		span {
			&:before {
				@include svg-background(
					$name: $product-links-wishlist-icon-name,
					$size: contain
				);
			}
		}
	}

	.tocompare {
		span {
			&:before {
				@include svg-background(
					$name: $product-links-compare-icon-name,
					$size: contain
				);
			}
		}
	}

	@if ($product-links-social-icons-dropdown == true) {
		.social_links {
			display: inline-block;
			cursor: pointer;

			&.active {
				.content {
					display: block;
					position: absolute;
					margin-top: $product-links-social-margin-top;
				}
			}

			.content {
				display: none;
			}

			a[class*="-share"] {
				margin-right: $product-links-social-icons-margin-right;
			}

			.trigger {
				display: inline-block;

				&:before {
					@include svg-background(
						$name: $product-links-social-icon-name,
						$size: contain
					);
				}
			}
		}
	} @else {
		.social_links {
			.trigger {
				display: none;
			}
		}
	}

	.todelivery {
		&:before {
			@include svg-background(
				$name: $product-links-delivery-icon-name,
				$size: contain
			);
		}
	}
}

// -------------------------------------------------
// Brief Description Attribute
// -------------------------------------------------

.product.attribute {
	p {
		@include type__17;
		margin-bottom: $ten-gutter;

		@include min-screen($screen__m) {
			@include type__16;
		}

		&:last-of-type {
			margin-bottom: 0;
		}
	}

	&.overview {
		margin-top: $twenty-gutter;

		@include min-screen($screen__m) {
			margin-top: rem(25);
		}

		[itemprop="description"] {
			@include type__15;

			@include min-screen($screen__m) {
				@include type__16;
			}
		}

		li {
			@include type__13;
			margin-bottom: $ten-gutter;

			&:before {
				@include pseudoicon(14px, 11px, "tick.svg");
				display: inline-block;
				margin-right: $twenty-gutter * 0.3;
			}
		}

		.full-details-link {
			display: none; // Display none for pre-launch, this should be removed post launch
			cursor: pointer;
			@include type__13;
			@include link__underline();
		}
	}
}

// -------------------------------------------------
// Product Lead Time
// -------------------------------------------------

.product-countdown {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	margin-top: $product-info-stock-margin-top;
	padding: em(14) $twenty-gutter;
	background-color: $outer-space;
	margin-top: $gutter;

	.countdown-clock {
		display: $product-info-lead-time-message-display;
		text-transform: uppercase;
		flex-wrap: wrap;
		justify-content: center;

		@include type__13(
			$color: $product-info-lead-time-message-color,
			$font-weight: $product-info-lead-time-message-font-weight
		);

		@include min-screen($screen__s) {
			@include type__15(
				$color: $product-info-lead-time-message-color,
				$font-weight: $product-info-lead-time-message-font-weight
			);
		}

		.time-remaining {
			color: $red-ribbon;

			&:before,
			&:after {
				content: "\00a0"; // add a space before lead time value, otherwise results in "delivered byMonday"
			}
		}

		.dispatch-date-value {
			font-weight: $product-info-lead-time-message-dispatch-date-font-weight;

			&:before {
				content: "\00a0"; // add a space before lead time value, otherwise results in "delivered byMonday"
			}
		}
	}

	.more {
		margin-left: $ten-gutter;
		display: none;

		@include min-screen($screen__ml) {
			display: block;
		}

		> span {
			@include link__underline;
			@include type__13(
				$color: $product-info-lead-time-message-color,
				$font-weight: $product-info-lead-time-message-font-weight
			);
		}
	}
}

// -------------------------------------------------
// Related Categories
// -------------------------------------------------

.product-related-categories {
	h3 {
		text-transform: uppercase;

		@include type__15(
			$color: $blaze-orange,
			$font-weight: $font-weight-semibold
		);
	}

	.related-category {
		@include link__underline;
		@include type__13;
		margin-right: rem(17);
		display: inline-block;
		margin-top: rem(13);

		&:last-child {
			margin-right: 0;
		}

		@include min-screen($screen__m) {
			margin-top: $ten-gutter;
		}

		@include min-screen($screen__l) {
			margin-bottom: 0;
		}
	}
}

// -------------------------------------------------
// Product Review -- need converting to vars
// -------------------------------------------------

.product-reviews-summary {
	margin-top: $product-info-review-summary-margin-top;

	.rating-summary {
		display: $product-info-review-summary-display;

		.label {
			padding-right: $product-info-review-summary-label-spacing;

			span {
				@include type__16(
					$font-weight: $font-weight-bold
				);
			}
		}
	}

	.reviews-actions {
		display: $product-info-review-summary-actions-display;
		justify-content: $product-info-review-summary-actions-justify-content;
		margin-top: $product-info-review-summary-actions-margin-top;
		font-style: $product-info-review-summary-actions-font-style;
	}
}

// -------------------------------------------------
// Product Upsell -- You might also like and convert vars again
// -------------------------------------------------

.products.products-upsell {
	flex: $product-info-upsell-flex;

	ol.product-items {
		display: $product-info-upsell-product-items-display;

		li {
			flex: $product-info-upsell-product-item-flex;
		}
	}
}
