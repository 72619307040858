/* -------------------------------------------------
 * -------------------------------------------------
 * B2B Company Structure Page
 * Copied from Luma Theme 
 * -------------------------------------------------
 * @author Fisheye
 */

.company-index-index {
	.block-content {
		margin-top: $twenty-gutter;
	}
}

.account .box-actions {
	button.action {
		padding: $b2b-action-button-padding;
		margin-bottom: $b2b-action-margin-bottom;
		@extend %cancel-button;

		&:after {
			content: '';
			border-left: 1px solid $border-color;
			display: inline-block;
			height: 12px;
			margin: 0 $ten-gutter;
		}

		&:first-child {
			padding-left: 0;
		}
	}
}
