//Used to create spacing
@function spacer($num) {
	@return $num * 10px;
}

.test-space {
	padding: spacer(3);
	width: 100px;
	height: 100px;
	background: red;
}