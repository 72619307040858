/* -------------------------------------------------
 * -------------------------------------------------
 * Gift Card Registry Page
 *
 * Not usually used, these are basic styles
 *
 * -------------------------------------------------
 * @author Fisheye
 */

.account.magento-giftregistry-index-index {
	.actions-toolbar a {
		display: inline-block;
		margin-top: $account-giftcard-actions-margin-top;
	}
}