/* -------------------------------------------------
 * -------------------------------------------------
 * Breadcrumbs
 *
 * -------------------------------------------------
*/

.breadcrumbs {
	display: $breadcrumbs-display;
	padding: $breadcrumbs-padding;
	background-color: $breadcrumbs-background-color;
	border-bottom: $breadcrumbs-border-bottom;
	margin-bottom: rem(1);
	text-align: center;

	@include min-screen($screen__m) {
		display: $breadcrumbs-display-desktop;
		padding: $breadcrumbs-padding-desktop;
	}

	.catalog-category-view &,
	.catalogsearch-result-index & {
		margin: 0;
		padding: 0;
		z-index: 1;

		@include min-screen($screen__m) {
			margin-bottom: rem(12);
		}
	}

	li {
		display: inline-block;

		&:after {
			content: "";
			display: inline-block;
			height: 9px;
			width: 5px;
			@include svg-background(
				$name: "arrow-right-mine-shaft",
				$size: contain
			);
			margin: 0 $five-gutter;
		}

		&:last-child {
			&:after {
				display: none;
			}
		}

		// strong marks the current page but added font weight is usually not required
		a,
		strong {
			@include type__14;
		}
	}
}
