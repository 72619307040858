/* -------------------------------------------------
 * -------------------------------------------------
 * Pagination
 *
 * Appears in toolbars in category and account pages
 *
 *
 * -------------------------------------------------
 * @author Fisheye
 */

.toolbar {
// Pagination
	.pager {
		display: flex;
	}

	.pages {
		order: $toolbar-pagination-flex-order;
		display: flex;
		justify-content: flex-start;
		margin: 0;

		@include min-screen($screen__m) {
			flex: 0 1 auto;
			justify-content: flex-end;
			margin: 0 0 0 $twenty-gutter; // mobile top spacing only required when toolbar items wrap
		}

		.pages-items {
			display: flex;

			.item.current {
				.page {
					@include button__pagination(
						$background-color: $button-pagination-current-background
					);

					span {
						color: $button-pagination-current-color;
					}
				}
			}

			.item {
				margin: 0;

				.page {
					@include button__pagination($color: $white);

					span {
						color: $button-pagination-color;
						font-weight: $font-weight-semibold;
					}
				}

				.action {
					@include button__pagination;

					> span {
						@extend %visuallyhidden; // hide 'Next' and 'Previous' text to replace with arrows
					}
				}
			}
		}

		.label {
			@extend %visuallyhidden; // none of the labels within the pagination need to be shown
		}
	}
}
