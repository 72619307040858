// -----------------------------------------------------------------------------------
// -----------------------------------------------------------------------------------
// The actual MEGA menu
// -----------------------------------------------------------------------------------
// It should all be in a media query but that would stop the use of silent classes
// So only bits that affect the mobile menu are in break points
// -----------------------------------------------------------------------------------
.page-header {
	// make sure we are more specific than the nasty fallbacks Mwahahahaha!
	// -------------------------------------------------
	// -------------------------------------------------
	// This bit styles general backgrounds, layout etc. only. all type styles remain specific to child themes
	.nav-sections {
		// nested for readability
		.navigation {
			ul.submenu {
				// the ul for the "submenu" populated by Bluefoot - in case you were wondering ;)
				@include min-screen($screen__m) {
					width: 100%;
					padding: 0;
					background-color: $mega-menu-background-color;
					position: absolute;
					top: 100% !important; // Important to override JS inline styles
					left: 0 !important; // Important to override JS inline styles
					text-align: left; // because top level is aligned centre
					z-index: 100;
					box-shadow: 0px 2px 1px rgba($color: $black, $alpha: 0.13);
					@include transition(background-color 0.3s ease);
				}

				> li {
					width: $container-width-mobile;
					max-width: $container-width;
					margin: 0 auto;
				}

				.row {
					padding-bottom: 0; // cancels out the padding added to rows for banners
				}

				.pagebuilder-column-group {
					.pagebuilder-column {
						position: relative;

						&:not(:last-child) {
							.pagebuilder-buttons {
								display: flex;
								flex-direction: column;
								margin-top: rem(3);
							}
						}

						[data-content-type="buttons"] {
							&:not(:last-child) {
								padding-bottom: rem(34);
							}
						}

						[data-content-type="heading"] {
							@include type__17($letter-spacing: 1px);
							text-transform: uppercase;

							&.text-bold {
								font-weight: $font-weight-semibold;
							}
						}

						[data-content-type="button-item"] {
							margin-top: $ten-gutter;
							position: relative;
							display: flex !important; // Override Pagebuilder
							@include type__16;
							text-align: left !important; // To override pagebuilder styles

							&:before,
							&:after {
								position: relative;
								top: em(6);
								height: 9px;
								width: 5px;
								background-size: contain;

								@include min-screen($screen__ll) {
									top: em(7);
								}
							}

							&:before {
								content: "";
								display: inline-block;
								margin-right: $ten-gutter;

								@include svg-background(
									$name: "arrow-right",
									$size: contain
								);
							}

							&:hover,
							&:focus {
								cursor: pointer;
								opacity: 1;
								color: $acapulco;

								&:before {
									content: '';
									display: inline-block;
									@include svg-background('arrow-right-acapulco');
								}
							}

							.pagebuilder-button-link {
								color: inherit;

								&:hover,
								&:focus {
									opacity: 1;
								}
							}
						}

						.heading-link {
							.pagebuilder-button-link {
								padding-left: 0;
								@include type__17($letter-spacing: 1px);

								&:before {
									content: unset;
								}

								&:hover,
								&:focus {
									color: $acapulco;
								}
							}
						}

						.text-bold {
							.pagebuilder-button-link {
								font-weight: $font-weight-semibold;
							}

							&.shop-all-link {
								margin-top: rem(34);

								.pagebuilder-button-link {
									@include type__17(
										$letter-spacing: 1px,
										$font-weight: $font-weight-semibold,
										$color: inherit,
										$line-height: 1.25
									);
								}
							}
						}

						[data-content-type="banner"] {
							.pagebuilder-banner-image {
								margin: auto;
								position: absolute;
								top: 0;
								right: 0;
								bottom: 0;
								left: 0;

								img {
									height: 100%;
									width: 100%;
									object-fit: cover;
									object-position: center;
								}
							}
						}

						.pagebuilder-poster-overlay {
							padding: $fifteen-gutter;
							background: rgba($color: $white, $alpha: 0.8) !important; // To override inline styles
							position: absolute;
							right: $gutter;
							bottom: $gutter;
							left: $gutter;
				
							p {
								@include type__24($font-family: $font-family-serif);
								font-style: italic;
							}
						}

						&.border-right {
							border-right: 1px solid $angora !important; // To override pagebuilder styles
						}
					}
				}
				
				.one-banner-menu {
					.pagebuilder-column {
						@include max-screen($screen__ll - 1) {
							width: percentage(1/4) !important; // To override pagebuilder styles
						}

						&:not(:last-of-type) {
							padding: rem(26) $gutter;
						}

						&:last-of-type {
							@include max-screen($screen__ll - 1) {
								display: none !important; // To override pagebuilder styles
							}
						}
					}
				}

				.two-banner-menu {
					.pagebuilder-column {
						@include max-screen($screen__ll - 1) {
							width: percentage(1/3) !important; // To override pagebuilder styles
						}

						&:not(:nth-last-child(-n+2)) {
							padding: rem(26) $gutter;
						}

						&:nth-last-child(-n+2) {
							@include max-screen($screen__ll - 1) {
								display: none !important; // To override pagebuilder styles
							}
						}
					}
				}

				.three-banner-menu {
					.pagebuilder-column {
						@include max-screen($screen__ll - 1) {
							width: percentage(1/3) !important; // To override pagebuilder styles
						}

						&:not(:nth-last-child(-n+3)) {
							padding: rem(26) $gutter;
						}

						&:nth-last-child(-n+2) {
							@include max-screen($screen__ll - 1) {
								display: none !important; // To override pagebuilder styles
							}
						}
					}
				}

				.menu-default .category-listing {
					.pagebuilder-buttons {
						column-count: 3;
					}
				}

				.award-type-container {
					.pagebuilder-column {
						flex: 1;

						.hide-title {
							display: none;
						}

						.pagebuilder-buttons {
							column-count: 3;

							li {
								width: 100%;
								margin-right: $twenty-gutter;
								margin-bottom: rem(7);

								.pagebuilder-button-link {
									display: flex;
									align-items: center;

									img {
										width: 44px;
										border-radius: em(60);
										margin-right: $ten-gutter;
									}

									span {
										@include type__13;
									}
								}
							}
						}
					}

					.cms-banners {
						display: none !important; // override pagebuilder styles

						@include min-screen($screen__ll) {
							display: flex !important;
							flex: 0 1 38%
						}
					}
				}

				.a-z-container {
					.pagebuilder-column-group {
						@include min-screen($screen__l) {
							flex-wrap: nowrap;
						}

						.pagebuilder-column {
							&:last-child {
								padding-right: 0;
							}

							@include min-screen($screen__m) {
								flex: 1 1 50%;
							}

							@include min-screen($screen__l) {
								padding-right: $forty-gutter;
								display: grid !important; // override inline pagebuilder display: flex;
								grid-template-columns: auto auto;
								grid-auto-rows: min-content;
							}

							@include min-screen($screen__ll) {
								padding-right: rem(60);
							}

							h3 {
								@include type__19($color: $nobel);
								margin-bottom: $fifteen-gutter;

								@include min-screen($screen__l) {
									text-align: right;
									margin-right: rem(17);
								}
							}

							div[data-element="main"] { // no class or id
								margin-bottom: $gutter;

								&:last-child {
									margin-bottom: 0;
								}

								.pagebuilder-buttons {
									position: relative;
									top: rem(-3); // to force buttons with line-heights to be inline with letters
								}
							}

							.pagebuilder-buttons {
								li {
									width: 100%;
									margin-bottom: $five-gutter;

									&:last-child {
										margin-bottom: 0;
									}
									
									span {
										@include type__13;
									}

									.pagebuilder-button-link {
										display: inline-block;
									}
								}
							}
						}
					}
				}
			} // closes .submenu / fisheye-submenu
		}
	}
}
