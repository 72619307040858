/* -------------------------------------------------
 * -------------------------------------------------
 * Inner grid using flex for better directional control
 * Used for banners rather than page layout ie specific bugfixes
 *
 * -------------------------------------------------
 * @author Fisheye
 */

// ie likes to ignore flex-bases max-width is required to keep flex items within columns
.ie11 {
	.structure-three-quarters {
		max-width: 100%;
		@include min-screen($screen__m) {
			max-width: percentage(3/4);
		}
	}
	.structure-two-thirds {
		max-width: 100%;
		@include min-screen($screen__m) {
			max-width: percentage(2/3);
		}
	}
	.structure-half {
		max-width: 100%;
		@include min-screen($screen__m) {
			max-width: percentage(1/2);
		}
	}
	.structure-third {
		max-width: 100%;
		@include min-screen($screen__m) {
			max-width: percentage(1/3);
		}
	}
	.structure-quarter {
		max-width: percentage(1/2);
		@include min-screen($screen__m) {
			max-width: percentage(1/4);
		}
	}

	.dt-width-10 {
		max-width: percentage(1/3);
		@include min-screen($screen__m) {
			max-width: 1 10%;
		}
	}

	.dt-width-20 {
		max-width: percentage(1/3);
		@include min-screen($screen__m) {
			max-width: 20%;
		}
	}
	.dt-width-30 {
		max-width: 50%;
		@include min-screen($screen__m) {
			max-width: 30%;
		}
	}
	.dt-width-40 {
		max-width: 50%;
		@include min-screen($screen__m) {
			max-width: 40%;
		}
	}
	.dt-width-60 {
		max-width: 100%;
		@include min-screen($screen__m) {
			max-width: 60%;
		}
	}
	.dt-width-70 {
		max-width: 100%;
		@include min-screen($screen__m) {
			max-width: 70%;
		}
	}
	.dt-width-80 {
		max-width: 100%;
		@include min-screen($screen__m) {
			max-width: 80%;
		}
	}
	.dt-width-90 {
		max-width: 100%;
		@include min-screen($screen__m) {
			max-width: 90%;
		}
	}

	.dt-width-full,
	%dt-width-full {
		// Silent class added here so it can be applied to bluefoot classes that can't be removed as well
		max-width: 100%;
	}
	.dt-width-three-quarters {
		max-width: percentage(3/4);
	}
	.dt-width-two-thirds {
		max-width: percentage(2/3);
	}
	.dt-width-half {
		max-width: percentage(1/2);
	}

	.dt-width-third {
		max-width: 100%;

		@include min-screen($screen__m) {
			max-width: percentage(1/3);
		} // Any row with an odd number of items will default to one full width at the top
		// and then half width items in rows of two on mobile if this class is added
		&.odd {
			max-width: percentage(1/2);

			@include min-screen($screen__m) {
				max-width: percentage(1/3);
			}
		}
	}

	.dt-width-fifth {
		// Any row with an odd number of items will default to one full width at the top
		// and then half width items in rows of two on mobile
		max-width: percentage(1/2);

		@include min-screen($screen__m) {
			max-width: percentage(1/5);
		}

		&:first-child {
			max-width: 100%;
			@include min-screen($screen__m) {
				max-width: percentage(1/5);
			}
		}
	}

	.dt-width-seventh {
		max-width: percentage(1/2);
		@include min-screen($screen__m) {
			max-width: percentage(1/3);
		}
		@include min-screen($screen__l) {
			max-width: percentage(1/7);
		}
	}

	.dt-width-eighth {
		max-width: percentage(1/2);
		@include min-screen($screen__m) {
			max-width: percentage(1/4);
		}
		@include min-screen($screen__l) {
			max-width: percentage(1/8);
		}
	}

	.flex__item {
		img {
			width: 100%;
		}
	}
}
