/* -------------------------------------------------
 * -------------------------------------------------
 * Requisition List, and individual Requisition Lists
 *
 * -------------------------------------------------
 * @author Fisheye
 */
.requisition_list-requisition-index {
    .page-title-wrapper {
        .title-actions-links {

            button.action.add {
                @include button__default();
                margin-top: $item-gutter
            }
        }
    }
}

.requisition_list-requisition-view {
    .requisition-content {
        .block-requisition-management {
            display: flex;
            flex-wrap: wrap;

            .requisition-list-title {
                flex: 0 1 100%;

                button {
                    -webkit-appearance: none;
                    border: 0;
                    padding: 0;
                    margin-left: $item-gutter;
                    background: transparent;
                    @include link__underline();
                }

                h1 {
                    display: inline-block;
                }
            }

            .actions.requisition-view-links {
                text-align: right;
                flex: 0 1 100%;
            }

            .requisition-info,
            .requisition-toolbar {
                flex: 0 1 100%;
            }

            .requisition-toolbar {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;

                @include min-screen($screen__m) {
                    flex-wrap: nowrap;
                }

                .requisition-toolbar-select {
                    flex: 0 1 auto;
                    padding-bottom: $item-gutter;

                    label.label {
                        white-space: nowrap;
                    }
                }

                .requisition-toolbar-actions {
                    display: flex;
                    flex: 0 1 100%;
                    align-items: flex-end;
                    justify-content: flex-end;

                    button {
                        margin-left: 0;
                        -webkit-appearance: none;
                        border: 0;
                        background: transparent;

                        @include min-screen($screen__ml) {
                            @include button__default();
                        }

                        &.requisition-list-button.change {
                            margin-left: $twenty-gutter;
                        }
                    }

                    .items {
                        margin-left: $twenty-gutter;
                        background: $white;
                        border: 1px solid $border-color;

                        .item {
                            padding: $twenty-gutter;

                            &:hover {
                                background: $grey-light;
                            }
                        }
                    }

                    span.mobile-label {
                        display: block;

                        @include min-screen($screen__m) {
                            display: none;
                        }
                    }

                    .block.block-requisition-list.link-button {
                        .split.requisition-list-action {
                            position: relative;

                            .items {
                                display: none;
                                position: absolute;
                            }

                            &.active {
                                .items {
                                    display: block;
                                }
                            }
                        }
                    }
                }
            }
        }

        .requisition-grid.table-wrapper {
            form {
                table.data-table.table {
                    thead {
                        tr {
                            th {
                                padding: $twenty-gutter;

                                &.action {
                                    text-align: right;
                                }
                            }
                        }
                    }

                    tbody {
                        border-top: 1px solid $border-color;
                        margin-top: $item-gutter;

                        @include min-screen($screen__ml) {
                            border-top: none;
                            margin-top: 0;
                        }

                        tr {
                            td {
                                display: block;

                                .data-grid-cell-content {
                                    text-align: left;
                                }

                                @include min-screen($screen__ml) {
                                    display: table-cell;
                                }

                                &.col {
                                    padding: $item-gutter;

                                    @include min-screen($screen__ml) {
                                        padding: $twenty-gutter;
                                    }
                                }

                                &.col.number {
                                    width: 100px;
                                }

                                &.col-checkbox {
                                    display: table-cell;

                                    input {
                                        display: table-cell;
                                        border: 0;

                                        &:before {
                                            @include svg-background("checkbox_unchecked");
                                            content: '';
                                            height: $checkbox-height;
                                            width: $checkbox-height;
                                            display: block;
                                            margin-left: auto;
                                            margin-right: auto;
                                            background-size: contain;
                                        }

                                        &:checked {
                                            &:before {
                                                @include svg-background("checkbox_checked");
                                            }
                                        }
                                    }
                                }

                                &.col.product {
                                    @include min-screen($screen__l) {
                                        display: flex;
                                        justify-content: flex-start;
                                        align-items: center;
                                    }

                                    & .product-item-image {
                                        padding-left: $twenty-gutter;
                                        display: none;

                                        @include min-screen($screen__m) {
                                            display: block;
                                        }
                                    }
                                }

                                &.col.qty {
                                    input {
                                        @include input-style-one();
                                        width: em(41);
                                    }
                                }

                                &.action {
                                    @include min-screen($screen__l) {
                                        text-align: right;
                                    }

                                    .product-item-subtotal {
                                        .actions-toolbar {
                                            button {
                                                @include button__default();
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .actions-toolbar.requisition-view-buttons {
        .primary {
            @include min-screen($screen__ml) {
                display: flex;
                flex-direction: row;
            }

            button {
                display: block;
                width: 100%;

                @include min-screen($screen__ml) {
                    display: inline-block;
                    margin: $item-gutter;
                }

                &.primary {
                    padding: $button-default-padding;
                    
                    span {
                        @include type__17($color: $button-default-color);
                    }
                }

                &.secondary {
                    @include button__default();
                }
            }
        }
    }
}

.account.requisition_list-requisition-view {
    table {
        .col {
            @include min-screen($screen__ml) {
                width: auto;
            }
        }
    }
}
