.sales-order-history {
	.my-orders-search-advanced {
		button {
			width: 100%;
			@include button__journey();
		}

		.filter-close {
			display: none; //Initally hide on page - when filterShowBtn is clicked it will show
		}

		.search-sku {
			.search {
				margin-top: $twenty-gutter;
			}
		}

		.extra-order-search {
			display: none; //load section in a closed state

			.field-dates {
				display: flex;
				flex-direction: column;

				.field-date {
					.control {
						display: flex;

						input {
							width: 70%;
							flex: 0 1 70%;
						}

						button {
							margin-left: $twenty-gutter;
							width: 30%;
							flex: 0 1 30%;
						}
					}
				}
			}
		}
	}
}
