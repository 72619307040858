/**
 * Copyright © Fisheye Media Ltd. All rights reserved.
 * See LICENCE.txt for licence details.
 */

body:has(.location-popup-container) {
	overflow-y: hidden; // Prevent scroll when popup is displayed
}

.location-popup-backdrop {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.8);
}

.location-popup {
	position: relative;
	z-index: 110;
	background-color: $gallery;
	padding: ($gutter * 2) 0 $gutter;
}

.location-popup-close {
	position: absolute;
	top: $gutter;
	right: $gutter;
	cursor: pointer;
	display: flex;
	align-items: center;
	gap: $ten-gutter;
}

.location-popup-container {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 100;
	display: flex;
	justify-content: center;
	align-items: center;

	p, h2 {
		font-weight: $font-weight-medium;
		margin: 0 4rem;
	}

	p {
		text-transform: uppercase;
		text-align: center;
		max-width: 25rem;
		margin: 0 $gutter $gutter;

		@media screen and (min-width: $screen__m) {
			margin: 0 7rem $gutter;
		}
	}

	h2 {
		text-transform: uppercase;
		font-size: 1.25rem;
		text-align: center;
		margin-bottom: $fifteen-gutter;
	}

	.pagebuilder-buttons {
		border-top: 1px solid white;
		padding: $fifteen-gutter $gutter 0;
		display: flex;
		gap: $fifteen-gutter;
		flex-direction: column;

		@media screen and (min-width: $screen__m) {
			flex-direction: row;
		}

		li {
			width: 100%;

			a {
				width: 100%;
				padding: rem(11) $five-gutter rem(13);
			}
		}

		li:first-of-type > a {
			@include button__journey;
			padding: rem(11) $five-gutter rem(13);

			span {
				font-size: 1rem;
			}
		}

		li:last-of-type {
			height: rem(40);
			border: solid 2px $button-accent-color;
			border-radius: $button-default-radius;
			font-weight: $button-default-weight;
			display: flex !important; //override default styles
			justify-content: center;
			align-items: center;

			span {
				color: $shadow-green;
				text-transform: uppercase;
				font-size: 1rem;
			}
		}
	}
}

[class^=location-popup-item-]:not(.active) {
	display: none; // Hide the location popups when they don't match the current country
}

.location-popup-container:not(.active) {
	display: none; // Hide the location popup when the popup isn't required
}
