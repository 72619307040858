/* -------------------------------------------------
 * -------------------------------------------------
 * Top links on accoutn dashboard, normally My Orders,
 * Account Details, Wishlist etc.
 *
 * -------------------------------------------------
 * @author Fisheye
 */

.account-top-links {
	background-color: $account-toplinks-background-color;
	margin-top: $fifteen-gutter;
	padding: 0 $fifteen-gutter;
	text-align: left;

	@include min-screen($screen__m) {
		padding: rem(30) 0;
		margin-top: rem(25);
	}

	&:after {
		content: '';
		border-bottom: 1px solid $concrete;
		width: 100%;
		display: none;
	}

	.block {
		flex: 1 1 100%;
		padding: $twenty-gutter 0;

		@include min-screen($screen__m) {
			flex: 1 1 50%;
			padding: 0 $gutter $gutter;
		}

		@include min-screen($screen__xl) {
			flex: 1 1 25%;
			padding: 0 $gutter;
		}

		h3 {
			@include type__20($font-family: $font-family-serif);
			font-size: rem(29);
			margin-bottom: $five-gutter;
			text-transform: capitalize;

			@include min-screen($screen__m) {
				font-size: rem(35);
				margin-bottom: $fifteen-gutter;
			}
		}

		a {
			@include type__16($font-weight: $font-weight-normal);
			text-transform: capitalize;

			&:before {
				content: "";
				display: inline-block;
				height: 9px;
				width: 5px;
				padding-right: $fifteen-gutter;
				@include svg-background(
					$name: 'arrow-right-black',
					$size: 5px
				);
			}
		}

		&:not(:last-of-type) {
			@include max-screen($screen__m - 1) {
				border-bottom: 1px solid $grey-medium1;
			}

			@include min-screen($screen__m) {
				border-right: 1px solid $grey-medium1;
			}
		}
	}
}
