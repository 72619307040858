// -------------------------------------------------
// -------------------------------------------------
// This is the Hamburglar icon for the mobile menu
// -------------------------------------------------
// -------------------------------------------------
.header.content {
	// Open and close icon for mobile menu
	.navigation-toggle {
		order: $header-mobile-burger-flex-order;
		border-right: $header-mobile-border;
		text-align: center;
		
		@include min-screen($screen__m) {
			display: none;
			justify-content: left;
		}
	
		button {
			display: flex;
			align-items: center;
			padding: 0;
			background: none;
			border: 0;
			margin: 0;
			@include type__16(
				$color: $white,
				$font-weight: $font-weight-medium
			);
			text-transform: uppercase;
		}
	
		svg {
			stroke: $header-icons-color;
		}
	
		.menu-close {
			display: none;
		}
	
		&.nav-open {
			position: relative;
			z-index: 100;
	
			@if $dropdown-enable-triangle == true {
				&:before {
					@include css-triangle(
						$triangle-size: 7.5px,
						$triangle-color: $gallery,
						$triangle-direction: bottom
					);
					bottom: -9px;
				}
			}
	
			.menu-close {
				display: inline;
				stroke: none;
			}
	
			.menu-open {
				display: none;
			}
		}
	}
}

// -----------------------------------------------------------------------------------
// -----------------------------------------------------------------------------------
