/* -------------------------------------------------
 * Pagebuilder Component Variables
 * -------------------------------------------------
 * Table of contents
 * 1. - Tabs Variables
 * -------------------------------------------------
 * @author Fisheye
 */

// -------------------------------------------------
// 1. Tabs Variables
// -------------------------------------------------

$pagebuilder-tabs-tab-background-color: $concrete;
$pagebuilder-tabs-tab-background-color-active: $white;
$pagebuilder-tabs-tab-border: 1px solid $mercury;
$pagebuilder-tabs-tab-title-color: $black;
$pagebuilder-tabs-tab-title-color-active: $brand-accent;
$pagebuilder-tabs-tab-title-font-weight: $font-weight-bold;
$pagebuilder-tabs-tab-title-padding: $small-gutter;
$pagebuilder-tabs-tab-content-border: 1px solid $mercury;