/* -------------------------------------------------
 * -------------------------------------------------
 * Top of main product details area
 *
 * Includes product title, reviews, sku, instock
 *
 * -------------------------------------------------
 * @author Fisheye
 */

 .catalog-product-view {
    // page title styles should be in _typography.scss
    .product-info-top {
        padding-bottom: $row-gutter;

        /* Review stars and sku below title
         * ---------------------------------
         */
        .product-info-review-sku {
            display: flex;
            flex-direction: row;
            justify-content: space-between; // Review stars - Yotpo or trustpilot options
            .product-reviews-summary,
            .yotpo {

                &.yotpo-bottomline {
                    height: 0;
                }

                .reviews-actions,
                .yotpo-bottomline {

                    @include min-screen($screen__ll) {
                        position: relative;
                    }

                    .yotpo-stars {
                        .yotpo-icon {
                            color: $svg-icon-color-hover;
                        }
                    }

                    .write-review-btn-hidden {
                        font-family: $font-family-sans-serif;
                        padding-left: $item-gutter;
                        color: $body-font-color !important; // overwrite an important. Sorry :'(
                    }
                }
            }
        }
    }
}