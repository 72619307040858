/* -------------------------------------------------
 * -------------------------------------------------
 * Magento Cart Table - Shopping Basket
 *
 * -------------------------------------------------
 * @author Fisheye
 */

.cart-table-headings {
	border-bottom: 1px $border-color;
	display: none;
	flex-flow: row wrap;

	@include min-screen($screen__m) {
		display: flex;
	}

	.cart-table-col {
		flex: 1;
		padding: $twenty-gutter 0 $item-gutter * 2;

		span {
			font-weight: $font-weight-normal;
		}

		&.name {
			flex: 1 0 39%;

			@include min-screen($screen__l) {
				flex: 1 0 25%;
			}

			@include min-screen($screen__l) {
				flex: 1 0 23%;
			}

			@include min-screen(em(1400)) {
				flex: 1 0 28%;
			}
		}

		&.price {
			flex: 1 0 7%;

			@include min-screen($screen__l) {
				flex: 1 0 6%;
			}
		}

		&.qty {
			flex: 0 1 auto;
		}

		&.subtotal {
			flex: 1 0 7%;
			text-align: center;

			@include min-screen($screen__l) {
				flex: 1 0 9%;
			}

			@include min-screen($screen__l) {
				flex: 1 0 7%;
			}
		}
	}
}
