/* -------------------------------------------------
 * -------------------------------------------------
 * Magento Tooltip Styling
 *
 * tooltip icons and content usually found in checkout
 *
 * -------------------------------------------------
 * @author Fisheye
 */

.field {
	._with-tooltip,
	&.choice {
		position: relative;

		.field-tooltip {
			// icon container and holds hover action
			display: flex;
			align-items: center;
			height: 100%;
			padding-right: $ten-gutter;
			position: absolute;
			top: 0;
			right: 0;
			cursor: pointer;

			.label {
				@extend %visuallyhidden;
			}

			.field-tooltip-action {
				display: flex;
				align-items: center;
				justify-content: center;
				height: $gutter;
				width: $gutter;
				background-color: $gallery;
				border-radius: 50%;
				position: relative;

				&:before {
					content: "?";
					display: block;
					@include type__20(
						$font-family: $font-family-serif,
						$font-weight: $font-weight-semibold
					);
					font-style: italic;
				}
			}

			.field-tooltip-content {
				@include type__13(
					$color: $tooltip-text-color,
					$font-weight: $font-weight-normal
				);
				// white space set to normal to allow content to wrap on smaller screen sizes
				white-space: normal;
				min-width: em(250);
				background-color: $tooltip-background;
				right: 0;
				left: auto;
				top: em(45);
				display: none;
				position: absolute;
				padding: $tooltip-content-padding;
				margin-top: $item-gutter;
				z-index: 1;

				@include min-screen($screen__xs) {
					white-space: nowrap;
					width: auto;
				}

				// carets

				&:before {
					@include css-triangle(10px, $tooltip-background, bottom);
					right: em(5);
					left: auto;
					top: -20px; // Static value, don't want this to change
					border-bottom-color: $tooltip-arrow-color;
					margin: 0;
				}

				// this element is uaually used for an outline but may not be required for this site

				&:after {
					display: none;
				}
			}

			&._active {
				.field-tooltip-content {
					display: block;
				}
			}
		}
	}
}


/* checkbox with tool tips such as "save card for
 * future use" option in checkout
 * -------------------------------------------------
 */

.field.choice {
	.field-tooltip.toggle {
		.field-tooltip-action {
			position: absolute;
			top: em(-43);
			right: 0;
		}

		// tooltip icon
		.field-tooltip-action:before {
			content: "";
			@include svg-background("info-icon");
			width: 30px;
			height: 30px;
			background-size: contain;
			display: block;
		}
	}
}

/* tool tip specific to payment options
 * -------------------------------------------------
 */

.checkout-payment-method {
	.payment-method-braintree.payment-method {

		// the input is half the size so the tool tip icon needs to be moved to stay within it
		.ccard>.field.cvv>.control {

			// tool tip is hidden but overlaps pay now button so it cannot be clicked on mobile devices
			.field-tooltip {
				.field-tooltip-content {
					display: none;
				}

				&._active {
					.field-tooltip-content {
						display: block;
					}
				}
			}

			.field-tooltip-content {
				top: em(40);
				left: em(-130); //width required to house the card image
				width: em(300);
				height: em(147);

				&:before {
					left: 45%;
					right: auto;
					bottom: auto;
					margin: 0;
				}

				@include min-screen($screen__m) {
					right: auto;
					left: 0;

					&:before {
						left: em(5);
					}
				}

				img {
					width: 100%;
				}
			}
		}
	}
}

/* tool tip specific to category and product detail page
 * ---------------------------------------------------------
 */

.dietary-tooltip-wrapper {
	display: flex;
	justify-content: center;

	.tooltip {
		display: flex;
		align-items: center;
		justify-content: center;
		height: rem(25);
		width: rem(25);
		border-radius: 50%;
		margin: 0 calc(#{$five-gutter} / 2);
		position: relative;
		cursor: pointer;

		.tooltip-label {
			@include type__16(
				$color: $white,
				$font-weight: $font-weight-semibold,
				$line-height: rem(23)
			);
			display: block;

			&:hover,
			&:focus {
				opacity: 1;
			}
		}

		.tooltip-content {
			visibility: hidden;
			background-color: $black;
			text-align: center;
			border-radius: 6px;
			padding: rem(5) $fifteen-gutter rem(9);
			@include type__13($color: $white);

			/* Position the tooltip */
			position: absolute;
			right: 0;
			z-index: 99;
			bottom: 150%;
			width: rem(130);

			&::after {
				content: " ";
				position: absolute;
				top: 100%;
				/* At the bottom of the tooltip */
				left: rem(117);
				right: rem(13);
				margin-left: -5px;
				border-width: 5px;
				border-style: solid;
				border-color: $black transparent transparent transparent;
			}

			@include min-screen($screen__s) {
				width: rem(170);

				&::after {
					left: rem(157);
					right: rem(13);
				}
			}
		}

		&:hover .tooltip-content {
			visibility: visible;
		}
	}
}
