/* -------------------------------------------------
 * -------------------------------------------------
 * Default Product Labels
 * -------------------------------------------------
 * @author Fisheye
 */

 .cataloglabel {
	position: absolute;
	background-size: contain !important; // Important to override inline styles
	z-index: 1;
	width: rem(88) !important; // Important to override inline styles
	height: rem(88) !important; // Important to override inline styles
	margin: $fifteen-gutter;

	@include min-screen($screen__m) {
		width: rem(115) !important; // Important to override inline styles
		height: rem(115) !important; // Important to override inline styles
		margin: $gutter;
	}

	// Label top vertical positions
	&.position-TL,
	&.position-TR,
	&.position-TC,
	&.position-EMPTY {
		top: 0;
	}

	// Label middle vertical positions
	&.position-ML,
	&.position-MR,
	&.position-MC {
		top: 0;
		bottom: 0;
		margin: auto;
	}

	// Label bottom vertical positions
	&.position-BL,
	&.position-BR,
	&.position-BC {
		bottom: 0;
	}

	// Left horizontal positions
	&.position-TL,
	&.position-ML,
	&.position-BL {
		left: 0;
	}

	// Right horizontal positions
	&.position-TR,
	&.position-MR,
	&.position-BR,
	&.position-EMPTY {
		right: 0;
	}

	// Center horizontal positions
	&.position-TC,
	&.position-MC,
	&.position-BC {
		right: 0;
		left: 0;
		margin: auto;
	}
}
