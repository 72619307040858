/* -------------------------------------------------
 * -------------------------------------------------
 * Express checkout options
 * For minicart & add to basket popup
 * -------------------------------------------------
 * @author Fisheye
 */

 .modal-popup._show,
 .block-minicart {
 	.basket-popup-extra-actions,
 	.miniart-extra-actions { //this class name will need changing once mini cart extra actions is redone
 		width: 100%;
 		text-align: center;
 		order: 2;
		overflow: hidden; // Stop title lines overflowing container 

		.extra-actions-text {
			span {
				@include type__16();
      
				// Title lines (Lines to left and right of text)
				&:before,
				&:after {
					content: "";
					display: inline-block;
					width: 50%;
					margin: 0 .5em 0 -55%;
					vertical-align: super;
					border-bottom: $minicart-express-payment-border;
				}
        
				&:after {
					margin: 0 -55% 0 .5em;
				}
			}
		}

		.extra-actions-container {
			background: $white;
			display: flex;
			flex-flow: row wrap;
			margin-bottom: $item-gutter;

			// hide outer border and just have inner dividing lines
			@if $minicart-express-payment-options-hide-outer-border == true {
				outline: 2px solid $minicart-express-payment-options-background;
				outline-offset: -1px;
			}
			
			.checkout {
				flex: 1 0 100%;
				border-bottom: $minicart-express-payment-border;
				padding: $minicart-express-payment-options-gutter;
				margin-bottom: 0;
				border-radius: 0;

				// spoke with Rob about the display of the border's and this may be tweaked later down the line
				@include min-screen($screen__s) {
					flex: 0 1 50%;
					border-bottom: 0;
					border: $minicart-express-payment-border;

					&:nth-child(odd),
					&:nth-child(even){
						border: $minicart-express-payment-border;
					}

					&:nth-child(even) {
						border-left: 0;
					}

					&:nth-child(3) {
						border-top: 0;
					}

					&:nth-child(4) {
						border-top: 0;
					}
				}

				&.paypal {
					button {
						-webkit-appearance: none;
						border: 0;

						img {
							max-height: $minibasket-paypal-button-max-height;
						}
					}
				}
			}

			[id*="braintree-applepay-mini"] {
				border-right: 1px solid $concrete;
				flex: 1 0 100%;

				@include min-screen($screen__s) {
					flex: 1 0 50%;
					border-top: 1px solid $concrete;
				}

				.braintree-apple-pay-button {
					-webkit-appearance: -apple-pay-button;
					-apple-pay-button-type: plain;
					display: block;
					min-height: 40px;
					border: 1px solid black;
					background-image: -webkit-named-image(apple-pay-logo-black);
					background-size: 100% calc(62%);
					background-repeat: no-repeat;
					background-color: black;
					background-position: 50% 50%;
					border-radius: 5px;
					transition: background-color 0.15s;
					cursor: pointer;
					margin: $twenty-gutter $forty-gutter;
				}
			}
		}
	}
}
