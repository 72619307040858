/* -------------------------------------------------
 * -------------------------------------------------
 * Account Login Area
 *
 * -------------------------------------------------
 * @author Fisheye
 */


// -------------------------------------------------
// blocks layout
// -------------------------------------------------

.customer-account-login {
	.main {
		@include min-screen($screen__ml) {
			max-width: 1250px;
			margin: 0 auto;
			width: 100%;
		}
		padding-left: 0 !important; // Used !impportant because general account page styles has a padding left - makes this specific page off centre.
	}

	.page-main {
		background-color: $gallery;
	}

	.login-container {
		.block-title {
			strong {
				@include type__35(
					$color: $black,
					$font-family: $font-family-serif,
					$font-weight: $font-weight-normal
				);
				text-align: center;
			}
		}
	}

	.block {
		.block-title {
			margin: 0 auto;

			@include min-screen($screen__m) {
				margin: 0;
			}
		}
	}
}

.login-container {
	// so that two blocks can site next to each other
	@extend %flex__row;
	padding: $account-login-create-mobile-spacing;
	background-color: $account-login-create-background-color;

	@include min-screen($screen__ml) {
		padding: $gutter $gutter 0;
	}

	.block {
		@extend %flex__column;
		flex: 0 1 100%;

		@include min-screen($screen__ml) {
			// the following makes the padding right split into margin and padding while remaining completely center
			// the reason for this is so that we can apply borders or background colours to the blocks while remaining inline
			// and still having the correct spacing between
			flex-grow: 1;
			flex-shrink: 1;
			flex-basis: calc(50% - #{$account-login-create-spacing / 2});
		}
	}

	.block-customer-login {
		.block-content {
			.form-login {
				.remind {
					display: block;
					margin-top: $twenty-gutter;
					margin-bottom: $gutter;

					> span {
						@include type__16(
							$font-weight: $font-weight-normal
						);
						display: flex;
						align-items: center;
						padding-left: $fifteen-gutter;
						position: relative;

						&:before {
							content: "";
							display: block;
							height: 10px;
							width: 5px;
							@include svg-background(
								$name: 'arrow-right-black',
								$size: contain
							);
							margin: auto;
							position: absolute;
							top: 5px;
							left: 0;
						}
					}
				}
			}
		}
	}

	.block-customer-login {
		margin-bottom: $gutter;
	}

	.block-new-customer {
		display: flex;
		border-top: $account-create-block-border;
		padding-top: $twenty-gutter;
		margin-bottom: $gutter;
		margin-left: 0;
		padding-left: 0;

		@include min-screen($screen__ml) {
			border-left: $account-create-block-border;
			border-top: 0;
			padding-top: 0;
			margin-left: rem(25);
			padding-left: rem(25);
		}

		.block-new-customer-subheading {
			@include type__22(
				$font-family: $font-family-serif,
				$font-weight: $font-weight-normal
			);
			margin: $fifteen-gutter 0 $ten-gutter;
			font-style: italic;
		}

		.block-title {
			margin: 0 auto;

			@include min-screen($screen__m) {
				margin: 0;
			}
		}

		.new-customer {
			padding: $twenty-gutter 0;

			@include min-screen($screen__m) {
				flex: 0 1 50%;
				padding: $account-login-create-mobile-spacing;
			}

			@include min-screen($screen__ml) {
				padding: $account-login-create-spacing;
			}

			@include min-screen($screen__l) {
				flex: auto;
			}

			@include min-screen($screen__xl) {
				flex: 0 1 50%;
			}

			&.trade {
				border-top: 1px solid $gray-nurse;

				@include min-screen($screen__m) {
					border-top: 0;
					border-left: 1px solid $gray-nurse;
				}

				@include min-screen($screen__ml) {
					border-left: 0;
					border-top: 1px solid $gray-nurse;
				}

				@include min-screen($screen__xl) {
					border-left: 1px solid $gray-nurse;
					border-top: 0;
				}
			}
		}

		.block-content {
			.actions-toolbar {
				margin-top: 0;
			}
		}
	}

	// -------------------------------------------------
	// block content styles
	// -------------------------------------------------

	/* needed for overriding */
	.new-customer {
		.block-title {
			p {
				margin-top: $twenty-gutter;
				@include type__13;

				@include min-screen($screen__m) {
					margin-top: $gutter;
				}
			}
		}
	}

	.block-content {
		@extend %flex__column;
		flex: 1 1 auto; // allows both containers to stretch to remain the same height

		ul,
		.form-login {
			flex: 1 1 auto;

			.field {
				@include min-screen($screen__ml) { 
					flex-wrap: nowrap;
				}
			}
		}

		li {
			@include type__16(
				$font-weight: $font-weight-normal
			);
			display: flex;
			align-items: center;
			margin-bottom: $account-create-list-items-spacing;

			&:before {
				@include svg-background(
					$name: tick-icon,
					$size: contain
				);

				content: '';
				width: $account-create-list-items-width;
				height: $account-create-list-items-height;
				display: inline-block;
				margin-right: $account-create-list-icon-spacing;
				flex-shrink: 0; // dont shrink because we want them to be line up so need to be exactly the same size
			}
		}
	}
}
