/* -------------------------------------------------
 * -------------------------------------------------
 * Product lists added by Bluefoot
 * - or Fisheye Widgets
 *
 * Product details styles i.e. titles should be
 * in _product-listing.scss
 *
 * -------------------------------------------------
 * @author Fisheye
 */

.new-products {
    // This class is applied through Bluefoot to the row containing the Bluefoot
    // products list. Or needs adding to fisheye widget via layout
    .flex__row {
        flex-flow: column wrap;
    }

    .flex__column {
        width: 100%;
    } // ---------------------------------------
    .product-listing-container {
        &.flex__row {
            flex-flow: row wrap;
        }

        .product-listing {
            // sets all to display none to start with
            display: none;

            &:first-child,
            &:nth-child(2) {
                // sets the first two to display on mobile
                display: block;
            }

            @include min-screen($screen__m) {
                // sets all to display again from 768 up
                display: block;
            } // ---------------------------------------
            // This is used in the carousel of products used on the homepage on mobile only (thats set in js).
            &.slick-slide {
                // display all as default when product carousel used instead of only first two as above
                display: block;
            } // ---------------------------------------
        } // ---------------------------------------
        // This is used in the carousel of products used on the homepage on mobile only (thats set in js).
        // All styles from above still apply but arrows for carousel need styling
        .slick-prev,
        .slick-next {
            @extend %cancel-button;
            background-color: transparent;
            border: none;
            z-index: 250;
            height: em(49);
            width: em(49);
            background-size: contain;
            position: absolute;
            top: calc(50% - 25px);

            span {
                display: none;
            }
        }

        .slick-prev {
            @include svg-background("gallery-left");
            left: $item-gutter;
        }

        .slick-next {
            @include svg-background("gallery-right");
            right: $item-gutter;
        } // ---------------------------------------
    } // ---------------------------------------
    // This is the recent viewed products on the product page
    // Again this uses default new-products styles and only shows two products on mobile and all at 768.
    &.recently-viewed {
        .product-listing-container {
            justify-content: center;
        }
    } // product gird is usually set to width: auto but on ipad the slider stretches and bleads out of the row-container
    .products-grid.products {
        width: 100%;
    }
}